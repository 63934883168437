import { Component, Input, OnInit } from "@angular/core";
import { Tag } from "src/app/models/admin";

@Component({
  selector: "app-tags",
  templateUrl: "./tags.component.html",
  styleUrls: ["./tags.component.scss"],
})
export class TagsComponent implements OnInit {
  @Input() tags: Tag[] | undefined = [];
  @Input() outlined?: boolean;

  constructor() {}

  ngOnInit(): void {}
}
