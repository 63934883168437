import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

import { environment } from 'src/environments/environment';
import { CompanyContacts } from 'src/app/models/company-contacts';

@Component({
  selector: 'app-partnership',
  templateUrl: './partnership.component.html',
  styleUrls: ['./partnership.component.scss']
})
export class PartnershipComponent implements OnInit {
  companyContacts: CompanyContacts;

  constructor(
    private readonly titleService: Title,
    private readonly metaService: Meta,
  ) {
    this.companyContacts = {
      ...environment.contacts,
      tg: environment.contacts.tg?.replace(/[()\-\s]/g, ''),
      wa: environment.contacts.wa?.replace(/[^+\d]/g, ''),
    };
  }

  ngOnInit(): void {
    this.titleService.setTitle('Сотрудничество с Gelio Photo');
    this.metaService.updateTag({
      content: 'Сотрудничество с Gelio Photo. Фотографии городов России в хорошем качестве +7 (499) 455-65-90',
    }, 'name=description');
  }
}
