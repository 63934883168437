import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, from, Observable, throwError } from "rxjs";
import { AuthService } from "../services/auth.service";
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { environment } from "src/environments/environment";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    private isRefreshTokenInProgress = false
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null)

    constructor(private readonly auth: AuthService) {}

    intercept(
        req: HttpRequest<any>, 
        next: HttpHandler
        ): Observable<HttpEvent<any>>{
            
            // If not request to API, then pass
            if (!req.url.includes(environment.serverUrl)) {
                return next.handle(req)
            }
            
            // Catch 401 "Unauthorized" error and trying to refresh jwt token
            return next.handle(req).pipe(

                catchError((error: HttpErrorResponse) => {
                    
                    if (error.status === 401 && !req.url.includes('auth/') && this.auth.isLogged()) {
                        
                        if (this.isRefreshTokenInProgress) {
                            return this.refreshTokenSubject.pipe(
                                filter(result => result !== null),
                                take(1),
                                switchMap(() => next.handle(this.addAuthentificationToken(req)))
                            )
                        }
                        else {
                            this.isRefreshTokenInProgress = true
                            this.refreshTokenSubject.next(null)

                            return this.refreshAccessToken().pipe(
                                switchMap((success: boolean)=> {
                                    this.refreshTokenSubject.next(success)
                                    return next.handle(this.addAuthentificationToken(req))
                                }),
                                finalize(() => (this.isRefreshTokenInProgress = false))
                            )
                        }

                    } else {
                        return throwError(error)
                    }
                })
            )
        
    }

    private refreshAccessToken(): Observable<any> {
        console.log("Refreshing token");
        return from(this.auth.tryToRefreshToken());
    }
    
    // Attaches Bearer token to request
    private addAuthentificationToken(request: HttpRequest<any>): HttpRequest<any>{
        // If user is not logged, then pass request
        if (!this.auth.isLogged) {
            return request;
        }
        // If you are calling an outside domain then do not add the token.
        if (!request.url.match(/194.67.116.227:3001\//)) {
            return request;
        }
        console.log("Add auth token");
        
        return request.clone({
            headers: request.headers.set("Authorization", "Bearer " + this.auth.accessToken)
        });
    }
}
