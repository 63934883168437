import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalService } from 'src/app/services/admin/modal.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-edit-user-password-modal',
  templateUrl: './edit-user-password-modal.component.html',
  styleUrls: ['./edit-user-password-modal.component.scss']
})
export class EditUserPasswordModalComponent implements OnInit {
  isRequestSend = false;
  isPasswordVisible: boolean = false;
  isPasswordMatch: boolean = true;
  isSuccessMessageVisible = false
  isErrorVisible = false

  id: number = 0

  @Output() onClose = new EventEmitter()

  form: FormGroup = new FormGroup({
    password: new FormControl(null, [
      Validators.required,
      Validators.minLength(6),
      Validators.pattern('[a-zA-Z0-9]+')
    ]),
    passwordConfirmation: new FormControl(null, [
      Validators.required,
      Validators.minLength(6),
      Validators.pattern('[a-zA-Z0-9]+')
    ]),
  });

  constructor(
    private userService: UserService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.id = this.modalService.id
  }


  async submit() {
    this.isRequestSend = true;
    this.isErrorVisible = false;
    this.isSuccessMessageVisible = false;
    
    try {
      
      await this.userService.patchUser(this.id, { password: this.form.value.password })
      this.isRequestSend = false;
      this.isSuccessMessageVisible = true
      
    } catch (error) {
      this.isErrorVisible = true
      if (error instanceof HttpErrorResponse) {

        switch (error.status) {
          case 409:
            console.log(error);
            this.isRequestSend = false;
        }
      } else {
        console.log(error);
      }
      
    }
  }

  changeInputType() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  checkPasswordMatch() {
    if (
      this.form.get("password")!.value.trim() !==
      this.form.get("passwordConfirmation")!.value
    ) {
      this.isPasswordMatch = false;
      return;
    }

    this.isPasswordMatch = true;
  }

  closeModal() {    
    this.modalService.closeEditUserPasswordModal()
  }
}
