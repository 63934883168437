import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-checkbox",
  templateUrl: "./checkbox.component.html",
  styleUrls: ["./checkbox.component.scss"],
})
export class CheckboxComponent {
  @Input() checked? = true;
  @Input() disabled = false
  @Output() checkboxClick = new EventEmitter();

  constructor() {}

  onClick() {
    this.checkboxClick.emit();
  }
}
