import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { range } from 'lodash';

@Component({
  selector: "app-paginator",
  templateUrl: "./paginator.component.html",
  styleUrls: ["./paginator.component.scss"],
})
export class PaginatorComponent implements OnChanges {
  @Input()
  total!: number;
  @Input()
  current: number = 1;
  @Input()
  size: number = 10;
  @Output()
  pageSelected: EventEmitter<number> = new EventEmitter();

  public showStartEllipsis: boolean;
  public showEndEllipsis: boolean;

  public windowStart: number;
  public windowEnd: number;
  public windowPages: number[] = [];

  constructor() {}

  public ngOnChanges() {
    if (this.current > this.total) {
      this.current = this.total;
    }

    // TODO:improve? It's doesn't handle any combination of <size, total> correctly
    // 4 slots for first, last and 2 possible ellipsises
    const windowSize = Math.max(0, this.size - 4);
    const isOdd = !!(windowSize % 2);
    const windowHalfSize = windowSize / 2;
    const windowStartSize = isOdd ? Math.floor(windowHalfSize) : windowHalfSize - 1;
    const windowEndSize = isOdd ? Math.floor(windowHalfSize) : windowHalfSize;

    const windowTouchesStart = this.current - windowStartSize < 4;
    const windowTouchesEnd = this.current + windowEndSize > this.total - 3;

    this.windowStart = 0;
    this.windowEnd = 0;
    this.windowPages = [];
    this.showStartEllipsis = false;
    this.showEndEllipsis = false;

    if (3 < this.total) {
      this.windowStart = Math.max(3, this.current - windowStartSize);
      if (windowTouchesEnd) {
        this.windowStart = Math.min(
          this.windowStart,
          Math.max(3, this.total - 2 - (windowSize - 1))
        );
      }
      this.windowEnd = Math.min(this.total - 2, this.current + windowEndSize);
      if (windowTouchesStart) {
        this.windowEnd = Math.max(
          this.windowEnd,
          Math.min(2 + windowSize, this.total - 2)
        );
      }

      this.windowPages = range(this.windowStart, this.windowEnd + 1);

      this.showStartEllipsis = this.windowStart > 3;
      this.showEndEllipsis = this.windowEnd < this.total - 2;
    }
  }
}
