import { Injectable } from "@angular/core";
import { BaseRequestService } from "./base-request.service";
import { IImage } from "../models/image";

@Injectable({
  providedIn: "root",
})
export class PhotosService {
  constructor(
    private readonly requestService: BaseRequestService,
  ) {}

  async getPhotoById(id: number): Promise<IImage> {
    const response = await this.requestService.get<Array<IImage>>("/images/" + id);
    return response[0]
  }
}
