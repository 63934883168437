import { Injectable } from '@angular/core';
import { ILicense } from '../models/License';
import { BaseRequestService } from './base-request.service';

@Injectable({
  providedIn: 'root'
})
export class LicenseService {
  private baseUrl = "/images"
  
  constructor(private readonly _requestService: BaseRequestService) { }

  addPriceToImage(imageId: number, price: number): Promise<number> {
    const payload = {
      license: 0,
      value: price
    }
    
    return this._requestService.post(this.baseUrl + "/set-price/" + imageId, payload)  
  }

  editPrice(priceId: number, value: number): Promise<ILicense> {
    return this._requestService.patch(`${this.baseUrl}/edit-price/${priceId}/${value}`, null)
  }
}
