<article class="cart-item cart-item--position">
  <div class="cart-item__image-wrapper">
    <img
      [routerLink]="['/photos', photo.friendlyURL]"
      [src]="photo.img"
      alt="Товар в корзине: Элемент корзины"
      class="cart-item__img"
      appLazyLoad
    />
  </div>
  <!-- /.cart-item__image-wrapper -->
  <div class="cart-item__text-wrapper">
    <div>
      <legend class="cart-item__description">#{{ photo.id }}</legend>
      <h4 class="cart-item__title">{{ photo.name }}</h4>
    </div>
    <!-- <span class="cart-item__image-size cart-item__image-size--mobile"
      >5472 × 3648, JPG
    </span> -->
    <!-- <div class="cart-item__price-wrapper"> -->
    <legend class="cart-item__description">
      Минимальная стоимость: {{ photo.price }} ₽
    </legend>
    <!-- <span class="cart-item__image-size">5472 × 3648, JPG </span> -->
    <!-- </div> -->
    <!-- /.cart-item__price-wrapper -->
  </div>
  <!-- /.cart-item__text-wrapper -->
  <button class="cart-item__close-btn" (click)="transferItemToDelete(photo)">
    <svg class="cart-item__close-btn-icon">
      <use xlink:href="../../../assets/sprite.svg#close"></use>
    </svg>
  </button>
</article>
<!-- /.cart-item cart-item--position -->
