<app-header [isBlack]="true" [isSearch]="true"></app-header>
<section class="container prices">
  <h1 class="title">Цены</h1>
  <p>
    Фотографии из фотобанка geliophoto.ru предоставляются на коммерческой
    основе.
  </p>
  <h2>Стоимость использования фотографии зависит от нескольких факторов:</h2>
  <ul>
    <li>
      <p>— название компании / проекта;</p>
    </li>
    <li>
      <p>— вид использования (например, сайт/полиграфия/выставки/интерьер);</p>
    </li>
    <li>
      <p>— требуемое разрешение снимка;</p>
    </li>
    <li>
      <p>
        — продолжительность использования (для электронных или периодических
        печатных изданий);
      </p>
    </li>
    <li>
      <p>— тираж (для печатных изданий).</p>
    </li>
  </ul>
  <p>
    Обычно, стоимость использования одной фотографии начинается от 7 тысяч
    рублей.
  </p>
  <p class="prices__lastP">
    После того как вы добавите фотографии в корзину и оформите заказ, его
    получат наши менеджеры. Они свяжутся с вами для подтверждения заказа и
    сформируют для вас предложение по стоимости.
  </p>
  <app-contact-form></app-contact-form>
</section>
<app-footer></app-footer>
