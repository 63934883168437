import { Component, OnInit } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";

import { environment } from "src/environments/environment";
import { CompanyContacts } from "src/app/models/company-contacts";

@Component({
  selector: "app-contacts",
  templateUrl: "./contacts.component.html",
  styleUrls: ["./contacts.component.scss"],
})
export class ContactsComponent implements OnInit {
  companyContacts: CompanyContacts;

  constructor(
    private readonly titleService: Title,
    private readonly metaService: Meta,
  ) {
    this.companyContacts = {
      ...environment.contacts,
      tg: environment.contacts.tg?.replace(/[()\-\s]/g, ''),
      wa: environment.contacts.wa?.replace(/[^+\d]/g, ''),
    };
  }

  ngOnInit(): void {
    this.titleService.setTitle('Контакты фотобанка Gelio Photo');
    this.metaService.updateTag({
      content: 'Контакты Gelio Photo. Фотографии городов России в хорошем качестве +7 (499) 455-65-90',
    }, 'name=description');
  }
}
