import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ICatalogl1City, ICatalogl2City } from 'src/app/models/admin';
import { AdminCatalogService } from 'src/app/services/admin/catalog.service';
import { macroregions } from './macroregions';

export enum GeoCatalogViewMode {
  Provinces,
  Cities,
};

enum GeoCatalogItemType {
  L1City,
  L2City,
  Province,
}

interface GeoCatalogItem {
  type: GeoCatalogItemType,
  text: string,
  title: string,
  href?: string,
  onClick?: (e: MouseEvent) => void,
  isActive: boolean;
  children: GeoCatalogItem[],
};

interface GeoCatalogGroup {
  letter: string,
  items: GeoCatalogItem[],
}

@Component({
  selector: "app-geo-catalog",
  templateUrl: "./geo-catalog.component.html",
  styleUrls: ["./geo-catalog.component.scss"],
})
export class GeoCatalogComponent implements OnInit {
  // Expose type-classes to the template
  readonly ViewMode = GeoCatalogViewMode;
  readonly ItemType = GeoCatalogItemType;

  @Input('isOnMainPage') isOnMainPage = false;

  catalog: ICatalogl1City[] = [];

  /** The only option is hardcoded for now */
  countries: string[] = ["Россия"];

  selectedCountry: string = "Россия";

  macroregions: string[] = [];

  selectedMacroregion: string = null;

  selectedProvince: string = null;

  viewMode: GeoCatalogViewMode = GeoCatalogViewMode.Cities;

  constructor(
    private catalogService: AdminCatalogService,
    private router: Router
  ) {}

  async ngOnInit() {
    this.catalog = await this.catalogService.getCatalog();
    this.macroregions = this.catalog
      .map((l1City) => l1City.macroregion)
      // dedupe
      .filter((v, i, a) => a.indexOf(v) === i)
    ;
    this.macroregions.sort((m1, m2) => macroregions.indexOf(m1) - macroregions.indexOf(m2));
  }

  selectViewMode(viewMode: GeoCatalogViewMode) {
    this.viewMode = viewMode;
  }

  selectCountry(code: string) {
    this.selectedProvince = null;
    this.selectedMacroregion = null;
    this.selectedCountry = code;
  }

  selectMacroregion(code: string) {
    this.selectedProvince = null;
    this.selectedMacroregion = code;
  }

  selectProvince(code: string) {
    this.selectedProvince = code;
  }

  selectCity(code: string) {
    this.router.navigate(["/search-page"], {
      queryParams: {
        search: code.toLocaleLowerCase(),
      },
    });
  }

  getProvincesToDisplay() {
    const filteredL1Cities = (null === this.selectedMacroregion)
      // no filtration
      ? this.catalog
      : this.catalog.filter((l1City) => l1City.macroregion === this.selectedMacroregion)
    ;
    const provinces = filteredL1Cities
      .map((l1City) => l1City.province)
      // dedupe
      .filter((v, i, a) => a.indexOf(v) === i)
    ;
    return provinces;
  }

  getL1CitiesToDisplay() {
    let filteredL1Cities;

    if (null === this.selectedProvince && null === this.selectedMacroregion) {
      // no filtration
      filteredL1Cities = this.catalog;
    } else {
      let predicate: (l1City: ICatalogl1City) => boolean;

      if (null !== this.selectedProvince) {
        predicate = (l1City) => l1City.province === this.selectedProvince;
      } else if (null !== this.selectedMacroregion) {
        predicate = (l1City) => l1City.macroregion === this.selectedMacroregion;
      }

      filteredL1Cities = this.catalog.filter(predicate);
    }

    return filteredL1Cities;
  }

  getListGroups(): GeoCatalogGroup[] {
    let items: GeoCatalogItem[];
    let groups: GeoCatalogGroup[];

    if (GeoCatalogViewMode.Cities === this.viewMode || null !== this.selectedProvince) {
      // Display cities
      items = this.getL1CitiesToDisplay().map(this.mapL1CityToCatalogItem.bind(this));
    } else {
      // Display provinces
      items = this.getProvincesToDisplay().map(this.mapProvinceToCatalogItem.bind(this));
    }
    groups = this.groupCatalogItems(items);
    groups.sort((a, b) => a.letter.localeCompare(b.letter));

    return groups;
  }

  mapProvinceToCatalogItem(province: string): GeoCatalogItem {
    const item: GeoCatalogItem = {
      type: GeoCatalogItemType.Province,
      text: province,
      title: 'Показать города',
      onClick: () => this.selectProvince(province),
      children: [],
      isActive: true,
    };

    return item;
  }

  mapCityToCatalogItem(city: ICatalogl1City | ICatalogl2City): Partial<GeoCatalogItem> {
    let href: string = undefined;
    let title: string = undefined;

    if (!city.searchString) {
      title = 'Пока нет фотографий';
    } else {
      if (city.searchString.endsWith('/soon')) {
        title = 'Скоро';
      } else {
        href = this.router.createUrlTree(['/search-page'], {
          queryParams: {
            search: city.searchString,
          },
        }).toString();

        title = 'Найти фотографии города';
      }
    }

    const item: Partial<GeoCatalogItem> = {
      text: city.name,
      title,
      href,
      onClick: href ? (e: MouseEvent) => { e.preventDefault(); this.selectCity(city.searchString); } : undefined,
      children: [],
      isActive: !!href,
    };

    return item;
  }

  mapL2CityToCatalogItem(l2City: ICatalogl2City): GeoCatalogItem {
    const item = {
      ...this.mapCityToCatalogItem(l2City),
      ...{
        type: GeoCatalogItemType.L2City,
        order: l2City.order,
      },
    } as GeoCatalogItem;

    return item;
  }

  mapL1CityToCatalogItem(l1City: ICatalogl1City): GeoCatalogItem {
    const item = {
      ...this.mapCityToCatalogItem(l1City),
      ...{
        type: GeoCatalogItemType.L2City,
        children: l1City.l2Cities
          .filter((city: ICatalogl2City) => !this.isOnMainPage || city.showOnMainPage)
          .map(this.mapL2CityToCatalogItem.bind(this)) ?? []
        ,
      }
    } as GeoCatalogItem;

    return item;
  }

  groupCatalogItems(items: GeoCatalogItem[]): GeoCatalogGroup[] {
    const groupped = new Map<string, GeoCatalogItem[]>();

    items.forEach((item) => {
      const letter = item.text[0];
      const letterItems = groupped.get(letter) || [];
      letterItems.push(item);
      groupped.set(letter, letterItems);
    });

    const groups = Array.from(groupped.entries()).map(([letter, items]) => ({
      letter,
      items: items.sort((a, b) => a.text.localeCompare(b.text)),
    }));

    return groups;
  }
}
