import { IAdminImage } from "./../../../models/admin";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IAdminCompilation } from "src/app/models/admin";
import { AdminCompilationService } from "src/app/services/admin/admin-compilation.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-to-compilation-modal",
  templateUrl: "./to-compilation-modal.component.html",
  styleUrls: ["./to-compilation-modal.component.scss"],
})
export class ToCompilationModalComponent implements OnInit {
  @Input() images: IAdminImage[]

  @Output() close = new EventEmitter();

  compilations: IAdminCompilation[] = [];
  toCompilationId: number;

  compilationNameForCreating: string

  isCompilationsCreatingActive = false

  constructor(
    private compilationService: AdminCompilationService,
    private readonly router: Router
  ) { }

  async ngOnInit() {
    this.compilations = (await this.compilationService.getCompilations()).items;
  }

  async addImagesToCompilation() {
    const imagesIds = this.images.map((img) => img.id);
    await this.compilationService.addImages(this.toCompilationId, imagesIds);
    this.closeModal();

    // Reload current page
    setTimeout(() => location.reload(), 0);
  }

  selectCompilation(compilationId: number) {
    this.toCompilationId = compilationId;
  }

  async createCompilation() {
    const payload = {
      title: this.compilationNameForCreating,
      description: ""
    }

    this.compilationService.createCompilation(payload);
    this.compilations = (await this.compilationService.getCompilations()).items
  }

  toggleCompilationCreating() {
    this.isCompilationsCreatingActive = !this.isCompilationsCreatingActive
  }

  closeModal() {
    this.close.emit()
  }
}
