import { Validators } from "@angular/forms";
import { FormControl } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ModalService } from "src/app/services/admin/modal.service";
import { IFolder } from "src/app/models/admin";
import { AdminService } from "src/app/services/admin/admin.service";
import { FolderService } from "src/app/services/admin/folder.service";

@Component({
  selector: "app-folder-info-modal",
  templateUrl: "./folder-info-modal.component.html",
  styleUrls: ["./folder-info-modal.component.scss"],
})
export class FolderInfoModalComponent implements OnInit {
  form: FormGroup;
  
  @Input() currentFolderId: number;

  @Output() close = new EventEmitter();
  @Output() folderChanged = new EventEmitter<IFolder>();

  currentFolder: IFolder

  constructor(
    private modalService: ModalService,
    private folderService: FolderService,
    private readonly adminService: AdminService
  ) {
    this.form = new FormGroup({
      name: new FormControl("", [Validators.required, Validators.minLength(1)]),
    });
  }

  async ngOnInit() {
	  this.currentFolder = await this.folderService.getFolder(this.currentFolderId)
  }

  async submit() {
    if (this.currentFolder == null && this.form.value.name >= 2) {
      return;
    }

    await this.folderService.renameFolder(this.currentFolder.id, this.form.value.name)
    this.closeModal()
    this.currentFolder.name = this.form.value.name
    
    // Reload current page
    setTimeout(() => location.reload(), 100); 
  }

  deleteFolder() {
    this.adminService.setDeleteModalContent = "Folder";
    this.adminService.deletingItemsIds = [this.currentFolderId]
    this.modalService.openDeleteModal();
  }

  closeModal() {
    this.close.emit()
  }
}
