import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SearchService } from "src/app/services/search.service";


@Component({
  selector: "app-hero",
  templateUrl: "./hero.component.html",
  styleUrls: ["./hero.component.scss"],
})
export class HeroComponent implements OnInit {
  constructor(private router: Router, private searchService: SearchService) {}

  suggestions: string[] = [
    'Москва',
    'Санкт-Петербург',
    'Новосибирск',
    'Казань',
  ];

  ngOnInit(): void {}

  navigateToSearch(query: string) {
    this.router.navigate(["/search-page"], {
      queryParams: {
        search: query.trim(),
      },
    });
  }
}
