import { Injectable } from "@angular/core";
import { IViewSettings } from "src/app/models/models";
import { BaseRequestService } from "../base-request.service";

@Injectable({
  providedIn: "root",
})
export class ViewSettingsService {
  constructor(private readonly requestService: BaseRequestService) {}

  async getViewSettings(): Promise<IViewSettings> {
    const { viewType, sort, order } =
      await this.requestService.get<IViewSettings>("/users/view-setting");

    return { viewType, sort, order };
  }

  async updateViewSettings(newViewSettings: IViewSettings): Promise<any> {
    await this.requestService.put("/users/view-setting", newViewSettings);
  }
}
