<app-admin-modal (onClose)="closeModal()">
  <form class="folder-form" [formGroup]="form" (ngSubmit)="submit()">
    <div class="header-title">
      <h2>Редактирование папки</h2>
    </div>
    <div #folderModal class="form-group">
      <label
        >Название папки
        <input type="text" formControlName="name" />
      </label>
    </div>
    <div class="controls">
      <button type="submit" [disabled]="form.invalid" class="btn">
        Переименовать
      </button>
      <button type="button" (click)="deleteFolder()" class="btn delete-btn">
        Удалить
      </button>
    </div>
  </form>
</app-admin-modal>
