/** Predefined ordered list of macroregions */
export const macroregions = [
    'Центральная Россия',
    'Северо-Запад',
    'Поволжье',
    'Юг',
    'Кавказ',
    'Урал',
    'Сибирь',
    'Дальний Восток',
];