import {
  Directive,
  HostListener,
  ElementRef,
  Renderer2,
  AfterViewInit,
} from "@angular/core";

@Directive({
  selector: "[appLazyLoad]",
})
export class LazyLoadDirective implements AfterViewInit {
  @HostListener("load") onLoad() {
    this._renderer.setStyle(this._el.nativeElement, "opacity", "1");
    this._renderer.removeStyle(this._el.nativeElement, "background");
  }

  constructor(private _el: ElementRef, private _renderer: Renderer2) {
    const supports = 'loading' in HTMLImageElement.prototype;

    if (supports) {
      _el.nativeElement.setAttribute('loading', 'lazy');
    }
  }

  ngAfterViewInit(): void {
    this._renderer.setStyle(this._el.nativeElement, "opacity", "0");
    this._renderer.setStyle(this._el.nativeElement, "transition", "0.6s");
    if (this._el.nativeElement.parentNode.className !== "photo__img-wrapper") {
      this._renderer.setStyle(
        this._el.nativeElement.parentNode,
        "background",
        "#F3F3F3"
      );
    }
  }
}
