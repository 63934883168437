<div class="footer">
  <div class="container footer__container">
    <!-- <div class="locations footer__locations">
      <ul class="locations-list footer__locations-list" *ngFor="let list of citiesList" >
        <li class="locations-list__location" *ngFor="let city of list" >
          <a (click)="navigateToSearch(city)" class="locations-list__location-link">{{ city }}</a>
        </li>
      </ul>
    </div> -->
    <!-- /.footer-cities -->
    <div class="footer__content">
      <form class="notice-subscribe footer__notice-subscribe">
        <h3 class="notice-subscribe__title footer__title">
          Подпишитесь на уведомления
        </h3>
        <div class="notice-subscribe__input-wrapper">
          <input
            type="email"
            placeholder="Ваш email"
            class="notice-subscribe__input"
          />
          <button type="submit" class="notice-subscribe__submit-btn">
            Отправить
          </button>
        </div>
        <!-- ./notice-subscribe__input-wrapper -->
      </form>
      <!-- /.notice-subscribe footer__notice-subscribe -->
      <div class="navigation">
        <h3 class="footer__title">Gelio</h3>
        <div class="navigation__links-wrapper">
          <a
            *ngFor="let link of navbarLinks"
            [routerLink]="link.href"
            class="navigation__link"
          >
            {{ link.label }}
          </a>
        </div>
      </div>
      <!-- /.navigation -->
      <div class="feedback footer__feedback">
        <h3 class="feedback__title footer__title">Мы на связи</h3>
        <div class="feedback__communication-way">
          <img
            src="../../../assets/icons/phone.svg"
            alt="Способ связи: Иконка телефона"
            class="feedback__icon"
          />
          <a href="tel:{{ companyContacts?.tel }}" class="feedback__link">
            {{ companyContacts?.tel }}
          </a>
        </div>
        <!-- /.feedback__communication-way -->
        <div class="feedback__communication-way">
          <img
            src="../../../assets/icons/mail.svg"
            alt="Способ связи: Иконка электронной почты"
            class="feedback__icon"
          />
          <a href="mailto:{{ companyContacts?.email }}" class="feedback__link">
            {{ companyContacts?.email }}
          </a>
        </div>
        <!-- /.feedback__communication-way -->
        <div class="feedback__communication-way">
          <img
            src="../../../assets/icons/telegram-white.svg"
            alt="Способ связи: Иконка телеграма"
            class="feedback__icon"
          />
          <a
            href="https://t.me/{{ companyContacts?.tg }}"
            target="_tg"
            rel="noreferrer noopener"
            class="feedback__link"
          >
            Телеграм
          </a>
        </div>
        <!-- /.feedback__communication-way -->
        <div class="feedback__communication-way">
          <img
            src="../../../assets/icons/whatsapp-white.svg"
            alt="Способ связи: Иконка whatsapp"
            class="feedback__icon"
          />
          <a
            href="https://wa.me/{{ companyContacts?.wa }}"
            target="_wa"
            rel="noreferrer noopener"
            class="feedback__link"
          >
            Whatsapp
          </a>
        </div>
        <!-- /.feedback__communication-way -->
      </div>
      <!-- /.feedback footer__feedback -->
    </div>
    <!-- /.footer__content -->
    <div class="footer__copyright-wrapper">
      <p class="footer__copyright">&copy; {{ year }} Фотобанк geliophoto.ru</p>
      <!-- /.footer-navigation footer__navigation -->
    </div>
    <!-- /.footer__copyright-wrapper -->
  </div>
</div>
