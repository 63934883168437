<app-admin-modal (onClose)="closeModal()">
    <form
        (ngSubmit)="saveUserInfo()"
        [formGroup]="form"
        autocomplete="off"
    >

    <h3 class="form__title">Редактирование</h3>

            <div class="form__input-wrapper">
              <input
                type="text"
                formControlName="login"
                class="form__input"
                [class.active]="form.value.login.length > 0"
              />
              <small class="form__text" >Логин</small>

            </div>
            <!-- /.form__input-wrapper -->

            <p *ngIf="
              form.get('login')?.invalid &&
              form.get('login')?.dirty &&
              form.get('login')?.touched"
              class="form__error"
            >Минимальная длина логина - 4 символа
            </p>

            <p *ngIf="isUserExist" class="form__error">
              Такой пользователь уже существует
            </p>

            <div class="form__input-wrapper">
              <input
                type="text"
                formControlName="firstName"
                class="form__input"
                [class.active]="form.value.firstName.length > 0"
              />
              <small class="form__text">Имя</small>
            </div>
            <!-- /.form__input-wrapper -->


            <div class="form__input-wrapper cabinet-form__input-tile">
              <input
                type="email"
                formControlName="email"
                class="form__input cabinet-form__input"
                [class.active]="form.value.email.length > 0"
              />
              <small class="form__text">Email</small>
            </div>
            <!-- /.form__input-wrapper -->
            <p
              *ngIf="form.get('email')?.invalid && form.get('email')?.touched"
              class="form__error"
            >
              Введите корректный email
            </p>


            <div class="form__input-wrapper form__role-selector">
                <select
                    formControlName="role"
                    class="form__input"
                    [class.active]="form.value.role.length > 0"
                >
                    <option value="EDITOR">Редактор</option>
                    <option value="MANAGER">Менеджер</option>
                    <option value="ADMIN">Суперадмин</option>
                </select>

                <small class="form__text">Роль</small>
              </div>
              <!-- /.form__input-wrapper -->


              <p *ngIf="isErrorVisible" class="form__alert">
                Не удалось сохранить данные
              </p>
              <p *ngIf="isSuccessMessageVisible" class="form__message">
                Сохранено
              </p>

            <div class="form__buttons-wrapper">
                <button
              class="btn form__cancel-button"
              (click)="closeModal()"
            >
              Отмена
            </button>
            <button
              [disabled]="form.invalid || isRequestSend"
              class="btn form__delete-button"
              (click)="deleteUser(); $event.stopPropagation()"
            >
              Удалить
            </button>
            <button
              [disabled]="form.invalid || isRequestSend"
              type="submit"
              class="btn form__submit-button"
            >
              Сохранить
            </button>
            </div>

          </form>
</app-admin-modal>
