import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

export type ConfirmModalData = {
  title?: string,
  text?: string,
  okBtnText?: string,
  cancelBtnText?: string,
}

const defaultData: ConfirmModalData = {
  title: 'Подтвердите действие',
  text: 'Выполнить?',
  okBtnText: 'Да',
  cancelBtnText: 'Отменить',
}

@Component({
  selector: "app-admin-modals-confirm",
  templateUrl: "./confirm-modal.component.html",
  styleUrls: ["./confirm-modal.component.scss"],
})
export class ConfirmModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: ConfirmModalData = defaultData,
  ) {
    this.data = { ...defaultData, ...data };
  }

  ngOnInit(): void {}
}
