import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { UploadService } from "src/app/services/admin/upload.service";
import { Subscription } from "rxjs/internal/Subscription";
import { Observable } from "rxjs";

@Component({
  selector: "app-drag-ndrop",
  templateUrl: "./drag-ndrop.component.html",
  styleUrls: ["./drag-ndrop.component.scss"],
})
export class DragNdropComponent implements OnInit {
  @ViewChild("fileDropRef") fileDropEl!: ElementRef;

  files: any[] = [];
  currentFolderId: number;

  routerSubscription: Subscription;

  constructor(
    private readonly router: Router,
    private readonly uploadService: UploadService
  ) {}

  ngOnInit(): void {
    this.currentFolderId = +this.router.url.split("/").pop();

    this.routerSubscription = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.currentFolderId = +this.router.url.split("/").pop();
      }
    });
  }

  onFileDropped($event: any) {
    this.uploadService.closeLoadingBar();

    // setTimeout needed for loadedbar to close before it will be opened again
    setTimeout(() => {
      this.prepareFilesList($event);
      this.uploadService.setLoadingImages([...this.files]);

      const reqPromiseArr: Array<Observable<any>> = [];

      this.files.forEach((file) => {
        const imagesData = new FormData();
        imagesData.append("images", file);

        const request = this.uploadService.uploadImages(
          imagesData,
          this.currentFolderId
        );

        reqPromiseArr.push(request);
      });

      (async () => {
        for (let reqPromise of reqPromiseArr) {
          await reqPromise.toPromise();
        }
        this.files = [];
      })();
    }, 0);
  }

  fileBrowseHandler(event: any) {
    this.prepareFilesList(event.target.files);
  }

  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }

    this.fileDropEl.nativeElement.value = "";
  }
}
