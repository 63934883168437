<app-admin-header (search)="setFoundImages($event)"></app-admin-header>

<div class="directory">
  <div class="directory__title">
    <h1>
      <a
        *ngFor="let folder of foldersParents"
        [routerLink]="['/workspace/folders/', folder.id]"
        class="directory__bread-crumb"
      >
        {{ folder?.name }}
      </a>

      <a *ngIf="isFolderPage; else compilationName">
        {{ currentFolder?.name }}
      </a>

      <ng-template #compilationName>
        <a [routerLink]="['/workspace/compilations']">Подборки</a>
        <a>{{ currentCompilationInfo?.title }}</a>
      </ng-template>
    </h1>

    <div class="directory__title-sizes">
      <ul>
        <li
          (click)="setViewMode(ViewMode.Tile)"
          class="view-mode view-mode_tile"
          [class.active]="viewMode === ViewMode.Tile">
        </li>

        <li
          (click)="setViewMode(ViewMode.BiggerList)"
          class="view-mode view-mode_bigger-list"
          [class.active]="viewMode === ViewMode.BiggerList">
        </li>

        <li
          (click)="setViewMode(ViewMode.List)"
          class="view-mode view-mode_list"
          [class.active]="viewMode === ViewMode.List">
        </li>
      </ul>
    </div>
  </div>

  <app-admin-photos-grid
    [images]="images"
    [folders]="currentFolder?.children || []"
    [currentId]="isFolderPage ? currentFolder?.id : currentCompilationInfo?.id"
    [viewMode]="viewMode"
    [isFolderPage]="isFolderPage"
    [compilationCover]="currentCompilationInfo?.__cover__"
  ></app-admin-photos-grid>
</div>

<ng-container
  *ngIf="currentCompilationInfo"
>
  <div
    class="collection-controls-placeholder"
  ></div>

  <div
    class="collection-controls"
  >
    <button
      customButton
      text="Добавить фотографии"
      (click)="onEditCompilationImages(currentCompilationInfo)"
      color="primary"
    >
      <img
        src="../../../assets/icons/plus-active.svg"
        alt="Добавить фотографии"
        icon
      />
    </button>
  </div>
</ng-container>
