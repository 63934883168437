<app-header [isBlack]="true"></app-header>

<div class="cabinet">
  <div class="container cabinet__container">
    <div class="cabinet__title-wrapper">
      <!-- <button class="cabinet__go-back-btn" routerLink="/">
        <svg class="cabinet__btn-icon">
          <use xlink:href="../../../assets/sprite.svg#arrow-left"></use>
        </svg>
      </button> -->
      <h2 class="cabinet__title">Заказы</h2>
    </div>
    <!-- /.cabinet__title-wrapper -->

    <div class="cabinet__content-wrapper">
      <div class="cabinet__items-wrapper">
        <app-order-item
          *ngFor="let order of orders"
          [order]="order"
        ></app-order-item>
      </div>
      <!-- /.cabinet__content -->

      <form class="cabinet-form cainet__form" [formGroup]="form">
        <div class="cabinet-form__total-info">
          <div class="cabinet-form__heads">
            <h5 class="cabinet-form__head">Личные данные</h5>
          </div>
          <!-- /.carft-form__heads -->
        </div>
        <!-- /.cabinet-form__total-info -->

        <div class="cabinet-form__inputs cabinet-form__input-tile">
          <div class="form__input-wrapper"
          [ngClass]="isPasswordChangeExpanded? 'form__input-wrapper-blocked': ''">
            <input
              type="text"
              formControlName="login"
              class="form__input cabinet-form__input"
              [ngClass]="login.value.length ? 'active' : 'invalid'"
              #login
            />
            <small class="form__text">Логин</small>
          </div>

          <p
            class="form__error"
            *ngIf="
              form.get('login')?.invalid &&
              form.get('login')?.dirty &&
              form.get('login')?.touched
            "
          >
            Минимальная длина логина - 4 символа
          </p>
          <p class="form__error" *ngIf="isUserExist">
            Такой пользователь уже существует
          </p>

          <div class="form__input-wrapper"
          [ngClass]="isPasswordChangeExpanded? 'form__input-wrapper-blocked': ''">
            <input
              type="text"
              formControlName="firstName"
              class="form__input cabinet-form__input"
              [ngClass]="name.value.length ? 'active' : ''"
              #name
            />
            <small class="form__text">Имя</small>
          </div>

          <div class="form__input-wrapper"
          [ngClass]="isPasswordChangeExpanded? 'form__input-wrapper-blocked': ''">
            <input
              type="text"
              formControlName="lastName"
              class="form__input cabinet-form__input"
              [ngClass]="lastName.value.length ? 'active' : ''"
              #lastName
            />
            <small class="form__text">Фамилия</small>
          </div>

          <div class="form__input-wrapper"
          [ngClass]="isPasswordChangeExpanded? 'form__input-wrapper-blocked': ''">
            <input
              type="email"
              formControlName="email"
              class="form__input cabinet-form__input"
              [ngClass]="email.value.length ? 'active' : ''"
              #email
            />
            <small class="form__text">Email</small>
          </div>

          <p
            *ngIf="form.get('email')?.invalid && form.get('email')?.touched"
            class="form__error"
          >
            Введите корректный email
          </p>

        </div>
        <!-- /.cabinet-form__inputs -->

        <!-- <p class="cabinet-form__postscript">Политика конфиценциальности</p> -->

        <p>
          <button class="cabinet-form__expand-button" (click)="expandPasswordChange()" >
            Изменить пароль
            <img class="cabinet-form__expand-button-arrow" [ngClass]="isPasswordChangeExpanded? 'cabinet-form__expand-button-arrow-rotate' : ''" src="../../../assets/images/dropdown.png" alt="">
          </button>
        </p>

        <div [style.max-height]="isPasswordChangeExpanded? '400px' : '0'" class="cabinet-form__password-change">

          <div class="cabinet-form__inputs cabinet-form__input-tile">
            <div class="form__input-wrapper">
              <input
                type="password"
                formControlName="oldPassword"
                class="form__input cabinet-form__input"
                [ngClass]="oldPassword.value.length ? 'active' : ''"
                #oldPassword
              />
              <small class="form__text">Старый пароль</small>
            </div>

            <p *ngIf="isOldPasswordInvalid" class="form__error" 
            >Пароль неверен</p
            >

            <div class="form__input-wrapper">
              <input
                (input)="checkPasswordMatch()"
                formControlName="newPassword"
                [type]="isPasswordVisible ? 'text' : 'password'"
                class="form__input cabinet-form__input"
                [ngClass]="newPassword.value.length? 'active': ''" 
                #newPassword
              />
              <small class="form__text">Новый пароль</small>
              <div class="form__icons-wrapper" [ngSwitch]="isPasswordVisible">
                <svg
                  *ngSwitchCase="false"
                  (click)="changeInputType()"
                  class="form__eye-icon"
                >
                  <use xlink:href="../../../assets/sprite.svg#eye-on"></use>
                </svg>
                <svg
                  *ngSwitchCase="true"
                  (click)="changeInputType()"
                  class="form__eye-icon"
                >
                  <use xlink:href="../../../assets/sprite.svg#eye-off"></use>
                </svg>
              </div>
            </div>
            <!-- /.form__input-wrapper -->
      
            
            <p class="form__error" *ngIf="form.get('newPassword')?.touched && newPassword.value.length < 6"
              >Пароль должен содержать 6 символов
            </p>
            
            
            <p class="form__error"
              [ngClass]="form.get('newPassword')?.touched && newPassword.value.length < 6? 'cabinet-form__alert': ''" 
              *ngIf="form.get('newPassword')?.hasError('pattern')"
              >Пароль содержит недопустимые символы</p
            >
      
            <div class="form__input-wrapper">
              <input
                (input)="checkPasswordMatch()"
                formControlName="passwordConfirmation"
                [type]="isPasswordVisible ? 'text' : 'password'"
                class="form__input cabinet-form__input"
                [ngClass]="passwordConfirmation.value.length? 'active': ''" 
                #passwordConfirmation
              />
              <small class="form__text">Повторите пароль</small>
            </div>
            <!-- /.form__input-wrapper -->

            <p class="form__error" *ngIf="!isPasswordMatch && form.get('passwordConfirmation')?.dirty"
              >Пароли не совпадают</p
            >


            <!-- <div class="form__input-wrapper">
              <input
                type="text"
                formControlName="newPassword"
                class="form__input cabinet-form__input"
                [ngClass]="newPassword.value.length ? 'active' : ''"
                #newPassword
              />
              <small class="form__text">Новый пароль</small>
            </div>

            <p
              *ngIf="form.get('newPassword')?.invalid && form.get('newPassword')?.touched"
              class="form__error"
            >
              Минимальная длина пароля - 6 символов
            </p>
  
            <div class="form__input-wrapper cabinet-form__input-tile" >
              <input
                type="text"
                formControlName="repeatNewPassword"
                class="form__input cabinet-form__input"
                [ngClass]="repeatNewPassword.value.length ? 'active' : ''"
                #repeatNewPassword
              />
              <small class="form__text">Повторите новый пароль</small>
            </div> -->
  
          </div>
          <!-- /.cabinet-form__inputs -->

        </div>
        <!-- ./cabinet-form__password-change -->


        <p *ngIf="isErrorVisible" class="cabinet-form__alert">
          Не удалось сохранить данные
        </p>
        <p *ngIf="isSuccessMessageVisible" class="cabinet-form__message">
          Сохранено
        </p>


        <button
          class="btn cabinet-form__button"
          [disabled]="form.invalid || !isPasswordMatch"
          (click)="submit()"
        >
          Сохранить
        </button>
      </form>
      <!-- /.cabinet-form cabinet__form -->
    </div>
  </div>
</div>
<!-- /.cabinet -->

<app-footer></app-footer>
