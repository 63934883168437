import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CatalogL2City } from './catalog-l2-city';

@Component({
  selector: 'app-admin-catalog-l2-city',
  templateUrl: './admin-catalog-l2-city.component.html',
  styleUrls: [
    './admin-catalog-table.component.scss',
    './admin-catalog-l2-city.component.scss',
  ]
})
export class AdminCatalogL2CityComponent implements OnInit {
  @Input() l2City: CatalogL2City;
  @Output() modified = new EventEmitter<CatalogL2City>();
  @Output() deleteRequested = new EventEmitter<CatalogL2City>();
  @Output() editRequested = new EventEmitter<CatalogL2City>();
  @Output() editCancelled = new EventEmitter<CatalogL2City>();

  isInEditMode = false;
  newItem?: CatalogL2City;

  constructor() {}

  ngOnInit(): void {
    if (!this.l2City.id) {
      // Adding new item
      this.isInEditMode = true;
    }
  }

  onEdit() {
    this.isInEditMode = true;
    this.editRequested.emit(this.l2City);
  }

  onCancelEdit(l2City: CatalogL2City) {
    this.editCancelled.emit(l2City);
    this.isInEditMode = false;
  }

  onDelete() {
    this.deleteRequested.emit(this.l2City);
  }

  onSubmit(l2City: CatalogL2City) {
    this.modified.emit(l2City);
  }

  onShowOnMainPageChange(event: Event) {
    this.modified.emit({
      ...this.l2City,
      // @ts-ignore We asume the event is triggered on the checkbox
      ...{ showOnMainPage: event.target.checked },
    });
  }
}
