<app-admin-modal (onClose)="closeModal()">
    <form
        (ngSubmit)="submit()"
        [formGroup]="form"
        autocomplete="off"
    >
      
    <h3 class="form__title">Новый пользователь</h3>

            <div class="form__input-wrapper">
              <input 
                type="text" 
                formControlName="login" 
                class="form__input"
                [ngClass]="login.value.length? 'active': ''" 
                #login 
              />
              <small class="form__text" >Логин</small>
                
            </div>
            <!-- /.form__input-wrapper -->


            <p *ngIf="form.get('login')?.invalid && form.get('login')?.dirty && form.get('login')?.touched" class="form__error">Минимальная длина логина - 4 символа</p>
      

            <div class="form__input-wrapper">
              <input 
                type="text" 
                formControlName="name" 
                class="form__input"
                [ngClass]="name.value.length? 'active': ''" 
                #name 
              />
              <small class="form__text">Имя</small>
            </div>
            <!-- /.form__input-wrapper -->

            <div class="form__input-wrapper">
              <input 
                type="email" 
                formControlName="email" 
                class="form__input"
                [ngClass]="email.value.length? 'active': ''" 
                #email 
              />
              <small class="form__text">Email</small>
            </div>
            <!-- /.form__input-wrapper -->

            <p
              *ngIf="form.get('email')?.invalid && form.get('email')?.touched"
              class="form__error"
            >
              Введите корректный email
            </p>

            <div class="form__input-wrapper form__role-selector">
                <select 
                    formControlName="role" 
                    class="form__input"
                    [class.active]="role.value.length > 0"
                    #role
                >
                    <option value="EDITOR">Редактор</option>
                    <option value="MANAGER">Менеджер</option>
                    <option value="ADMIN">Суперадмин</option>
                </select>
                <!-- <input 
                  type="text" 
                  formControlName="name" 
                  class="form__input"
                  [ngClass]="name.value.length? 'active': ''" 
                  #name 
                /> -->
                <small class="form__text">Роль</small>
              </div>
              <!-- /.form__input-wrapper -->

            <div class="form__input-wrapper">
              <input
                (input)="checkPasswordMatch()"
                formControlName="password"
                [type]="isPasswordVisible ? 'text' : 'password'"
                class="form__input"
                [ngClass]="password.value.length? 'active': ''" 
                #password
              />
              <small class="form__text">Пароль</small>
              <div class="form__icons-wrapper" [ngSwitch]="isPasswordVisible">
                <svg
                  *ngSwitchCase="false"
                  (click)="changeInputType()"
                  class="form__eye-icon"
                >
                  <use xlink:href="../../../assets/sprite.svg#eye-on"></use>
                </svg>
                <svg
                  *ngSwitchCase="true"
                  (click)="changeInputType()"
                  class="form__eye-icon"
                >
                  <use xlink:href="../../../assets/sprite.svg#eye-off"></use>
                </svg>
              </div>
            </div>
            <!-- /.form__input-wrapper -->
      
            <ul class="form__errors-list" *ngIf="form.get('password')?.invalid && form.get('password')?.touched">
              <li>
                <small class="form__error" *ngIf="form.get('password')?.touched && password.value.length < 6"
                  >Пароль должен содержать 6 символов
                </small>
              </li>
              <li>
                <small class="form__error" *ngIf="form.get('password')?.hasError('pattern')"
                  >Пароль содержит недопустимые символы</small
                >
              </li>
            </ul>
      
            <div class="form__input-wrapper">
              <input
                (input)="checkPasswordMatch()"
                formControlName="passwordConfirmation"
                [type]="isPasswordVisible ? 'text' : 'password'"
                class="form__input"
                [ngClass]="repeatPassword.value.length? 'active': ''" 
                #repeatPassword
              />
              <small class="form__text">Повторите пароль</small>
            </div>
            <!-- /.form__input-wrapper -->

            <p class="form__error" *ngIf="!isPasswordMatch && form.get('passwordConfirmation')?.dirty"
              >Пароли не совпадают</p
            >


            <p *ngIf="isErrorVisible" class="form__error form__alert"
              >{{ errorText }}</p
            >

            <div class="form__buttons-wrapper">
                <button
              class="btn form__cancel-button"
              (click)="closeModal()"
            >
              Отменить
            </button>
            <button
              [disabled]="form.invalid || !isPasswordMatch || isRequestSend"
              type="submit"
              class="btn form__submit-button"
            >
              Создать
            </button>
            </div>
            
          </form>
</app-admin-modal>  