import { HttpErrorResponse } from "@angular/common/http";
import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { patterns } from "src/app/common/patterns";
import { AuthService } from "src/app/services/auth.service";
import { CartService } from "src/app/services/cart.service";
import { FavoriteService } from "src/app/services/favorite.service";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent {
  
  isSuccessPopupOpen = false;
  
  isRequestSend = false;
  isUserExist = false;
  
  isPasswordVisible = false;
  isPasswordMatch = true;
  
  timeout: any;

  form: FormGroup = new FormGroup({
    name: new FormControl("", Validators.required),
    phone: new FormControl("", [Validators.required, Validators.pattern(patterns.phone)]),
    email: new FormControl("", [ Validators.required, Validators.pattern(patterns.email)])
  });

  constructor(
    private authService: AuthService, 
    private router: Router,
    private cartService: CartService,
    private favouriteService: FavoriteService
  ) {}

  async submit() {
    this.isRequestSend = true;

    const payload = {
      firstName: this.form.value.name,
      phone: this.form.value.phone,
      email: this.form.value.email
    }

    try {
      await this.authService.registerViaEmail(payload)      
    } catch (error: any) {
      this.isRequestSend = false;
      this.handleError(error)
      return
    }

    this.toggleSuccessPopup()
    await this.cartService.synchronizeCart()
    await this.favouriteService.synchronizeFavorites()
    this.isRequestSend = false;
  }

  changeInputType() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  checkPasswordMatch() {
    if (
      this.form.get("password")!.value.trim() !==
      this.form.get("passwordConfirmation")!.value
    ) {
      this.isPasswordMatch = false;
      return;
    }

    this.isPasswordMatch = true;
  }

  isEmailInvalid() {
    let emailField = this.form.get("email")
    return emailField?.invalid && emailField?.dirty && emailField?.touched
  }

  isPhoneInvalid() {
    let phoneField = this.form.get("phone")
    return phoneField?.invalid && phoneField?.dirty && phoneField?.touched
  }

  onKeyPressOnNameField(event: KeyboardEvent) {
    // If invalid character, then prevent input
    return patterns.name.test(event.key)
  }

  onKeyPressOnPhoneField(event: KeyboardEvent) {
    // If invalid character, then prevent input
    return /[0-9\+]/.test(event.key)
  }

  toggleSuccessPopup() {
    this.isSuccessPopupOpen = !this.isSuccessPopupOpen
  }

  private handleError(error: HttpErrorResponse) {    
    switch (error.status) {
      case 409:
        console.log(error);
        this.isUserExist = true;
    }
  }
}
