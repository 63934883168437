import { Component, Input, OnInit } from "@angular/core";
import { PhotosService } from "./../../services/photos.service";
//import { MainPhotosService } from "src/app/services/main-photos.service";
import { IImage } from "./../../models/image";
import { DeviceDetectorService } from "ngx-device-detector";
//import { BaseRequestService } from "src/app/services/base-request.service";

@Component({
  selector: "app-popular",
  templateUrl: "./popular.component.html",
  styleUrls: ["./popular.component.scss"],
})
export class PopularComponent implements OnInit {
  @Input() populars: IImage[] = [];

  constructor(
    private deviceDetector: DeviceDetectorService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    // Check user agent is mobile or desktop
    if (this.deviceDetector.isMobile() || this.deviceDetector.isTablet()) {
      console.log("Mobile user agent");
      if (this.populars.length > 6) {
        this.populars.splice(6, this.populars.length - 6);
      }
    } else {
      if (this.populars.length > 16) {
        this.populars.splice(16, this.populars.length - 16);
      }
    }
  }
}
