<cdk-table [dataSource]="rows">
  <cdk-header-row *cdkHeaderRowDef="headerColumns; sticky: true"></cdk-header-row>

  <!-- Row template for L1 city display -->
  <cdk-row
    *cdkRowDef="let row; let index; when: isL1CityBeingDisplayed; columns: l1Columns;"
    class="table-row l1-city"
    [class.pending]="isPending(row)"
  ></cdk-row>

  <!-- Row template for L1 city edit (or add) form -->
  <cdk-row
    *cdkRowDef="let row; let i = index; when: isL1CityBeingEdited; columns: l1Columns"
    (keydown)="onRowKeypress(i, row, $event)"
    class="table-row table-row-form l1-city l1-city-form"
    [class.pending]="isPending(row)"
  ></cdk-row>

  <!-- Row template for L2 city display -->
  <cdk-row
    *cdkRowDef="let row; when: isL2CityBeingDisplayed; columns: l2Columns"
    class="table-row l2-city"
    [class.pending]="isPending(row)"
  ></cdk-row>

  <!-- Row template for L2 city edit (or add) form -->
  <cdk-row
    *cdkRowDef="let row; let i = index; when: isL2CityBeingEdited; columns: l2Columns"
    (keydown)="onRowKeypress(i, row, $event)"
    class="table-row table-row-form l2-city l2-city-form"
    [class.pending]="isPending(row)"
  ></cdk-row>

  <!-- L1 city name column -->
  <ng-container cdkColumnDef="l1CityName">
    <cdk-header-cell *cdkHeaderCellDef>Город</cdk-header-cell>

    <cdk-cell *cdkCellDef="let row; let index;">
      <ng-container
        *ngIf="'l2City' === row.type"
      ></ng-container>

      <ng-container
        *ngIf="'l1City' === row.type && !isBeingEdited(row)"
      >{{ row.city.name }}</ng-container>

      <input
        *ngIf="'l1City' === row.type && isBeingEdited(row)"
        [formControl]="$any(editing.form.get('name'))"
        class="btn-light text-input"
        type="text"
        placeholder="Название"
      />
    </cdk-cell>
  </ng-container>

  <!-- L1 city 'Add L2 city' button column -->
  <ng-container cdkColumnDef="l1CityAddL2City">
    <cdk-cell *cdkCellDef="let row; let index;">
      <ng-container
        *ngIf="!isBeingEdited(row)"
      >
        <button
          type="button"
          class="i-link"
          (click)="onAddChildItem(row.city)"
          [disabled]="!canAddNewChildItem"
        >
          Добавить
        </button>
      </ng-container>
    </cdk-cell>
  </ng-container>


  <!-- Сompilations column -->
  <ng-container cdkColumnDef="сompilations">
    <cdk-header-cell *cdkHeaderCellDef>Подборки</cdk-header-cell>

    <cdk-cell *cdkCellDef="let row; let index;">
      <ul
        class="city-compilations"
      >
        <li
          *ngFor="let compilation of row.city.compilations"
          class="compilation"
          [ngClass]="{ 'published': compilation.published }"
        >
          <span
            *ngIf="compilation.published"
            class="published-mark"
            title="Опубликована"
          ></span>

          <a
            [routerLink]="['./', compilation.id]"
            class="compilation-name link"
            title="Перейти к фотографиям"
          >{{ compilation.title }}</a>

          <!-- <span
            (click)="onEditCompilationImages(row, compilation)"
            class="compilation-name link interactive"
            title="Добавить фотографии"
          >{{ compilation.title }}</span> -->

          <div
            class="actions"
          >
            <button
              (click)="onEditCompilation(row, compilation)"
              class="action edit"
              title="Редактировать"
            ></button>

            <button
              (click)="onDeleteCompilation(row, compilation)"
              class="action delete"
              title="Удалить"
            ></button>
          </div>
        </li>

        <li
          class="new-compilation"
        >
          <button
            *ngIf="compilationEditing?.city !== row.city && row.city.id"
            [disabled]="compilationEditing || editing"
            (click)="onAddCompilation(row)"
            class="i-link"
           >Добавить</button>

          <input
            *ngIf="compilationEditing?.city === row.city"
            [formControl]="$any(compilationEditing.form.get('title'))"
            (keydown)="onCompilationKeypress(row, $event)"
            type="text"
            class="btn-light text-input"
          />
        </li>
      </ul>
    </cdk-cell>
  </ng-container>

  <!-- L2 city name column -->
  <ng-container cdkColumnDef="l2CityName">
    <cdk-header-cell *cdkHeaderCellDef>Города второго уровня</cdk-header-cell>

    <cdk-cell *cdkCellDef="let row; let index;">
      <ng-container
        *ngIf="!isBeingEdited(row)"
      >{{ row.city.name }}</ng-container>

      <input
        *ngIf="isBeingEdited(row)"
        [formControl]="$any(editing.form.get('name'))"
        type="text"
        placeholder="Название"
        class="btn-light text-input"
      />
    </cdk-cell>
  </ng-container>

  <!-- Actions column -->
  <ng-container cdkColumnDef="rowActions">
    <cdk-cell
      *cdkCellDef="let row"
      class="table-row-actions"
    >
      <button
        class="table-row-action table-row-action_edit"
        type="button"
        title="Редактировать"
        (click)="onEdit(row)"
      ></button>

      <button
        class="table-row-action table-row-action_delete"
        type="button"
        title="Удалить"
        (click)="onDelete(row)"
      ></button>
    </cdk-cell>
  </ng-container>
</cdk-table>

<div class="admin__form-btn">
  <button
    *ngIf="!isInEditMode()"
    customButton
    text="Добавить"
    (click)="onAddL1City()"
    color="primary"
  >
    <img
      src="../../../assets/icons/plus-active.svg"
      alt="Add new compilation city"
      icon
    />
  </button>

  <button
    *ngIf="isInEditMode()"
    customButton
    text="Отменить"
    (click)="cancelCurrentEditing()"
    color="primary"
  ></button>

  <button
    *ngIf="isInEditMode()"
    customButton
    text="Сохранить"
    (click)="submitCurrentEditing()"
  ></button>
</div>

<!-- <app-compilation-info-modal [currentCompilationId]="1"></app-compilation-info-modal> -->
