<label class="switch">
  <ng-container
    *ngIf="labelTmpl && 'before' === labelPosition; then labelTmpl"
  ></ng-container>

  <input
    type="checkbox"
    (click)="onClick($event)"
    [disabled]="disabled"
    [checked]="checked"
  />
  <span class="slider"></span>

  <ng-container
    *ngIf="labelTmpl && 'after' === labelPosition; then labelTmpl"
  ></ng-container>
</label>

<ng-template #labelTmpl>
  <span
    class="label input-label switch-label"
  >{{ label }}</span>
</ng-template>
