import { Injectable } from "@angular/core";
import { LocalStorage } from "src/libs/storage/src";
import { IImage } from "../models/image";
import { AuthService } from "../services/auth.service";
import { CartService } from "../services/cart.service";
import { FavoriteService } from "./../services/favorite.service";

@Injectable({
  providedIn: "root",
})
export class ProductMethods {
  constructor(
    public _cartService: CartService,
    public _favoriteService: FavoriteService,
    public _localStorage: LocalStorage,
    public _authService: AuthService
  ) {}

  // For CART
  async addItem(data: any): Promise<void> {
    if (!this._localStorage.getItem("cart")) {
      localStorage.setItem("cart", "[]");
    }
    if (this._authService.isLogged()) {
      await this._cartService.addItem(data);
      return;
    }

    const cartItems: IImage[] = JSON.parse(this._localStorage.getItem("cart")!);

    cartItems.push(data);
    this._cartService.setCart(cartItems);
    this._localStorage.setItem("cart", JSON.stringify(cartItems));
  }

  // Must be Ok
  async deleteItem(idToRemove: any): Promise<void> {
    if (this._authService.isLogged()) {
      await this._cartService.deleteItem(idToRemove);
      return;
    }

    if (this._localStorage.getItem("cart")) {
      const cartItems: IImage[] = JSON.parse(
        this._localStorage.getItem("cart")!
      );

      const id: number = cartItems.findIndex(
        (image: IImage) => +image.id === +idToRemove
      );

      cartItems.splice(id, 1);
      this._cartService.setCart(cartItems);
      this._localStorage.setItem("cart", JSON.stringify(cartItems));
    }
  }

  // Not used yet
  getTotalPrice(): number {
    const cartItems: IImage[] = JSON.parse(this._localStorage.getItem("cart")!);

    let totalPrice: number = 0;

    cartItems.forEach((item: IImage) => {
      totalPrice += item.price ? item.price : 0;
    });

    if (totalPrice === 0) {
      cartItems.forEach((item) => {
        const price = item.__prices__?.[0]?.value || 0;
        totalPrice += price;
      });
    }

    return totalPrice;
  }

  // Await for cartService enhancement
  getNumberOfItems(): number {
    if (this._authService.isLogged()) {
      return this._cartService.getAllCartItems().length;
    }
    const cartItems: IImage[] = JSON.parse(this._localStorage.getItem("cart")!);

    return cartItems.length;
  }

  // Ok
  async addFavorite(item: IImage): Promise<void> {
    if (this._authService.isLogged()) {
      await this._favoriteService.addToFavorites(item.id);
      return;
    }

    if (this._localStorage.getItem("favorites")) {
      const favorites: IImage[] = JSON.parse(
        this._localStorage.getItem("favorites")!
      );

      favorites.push(item);

      this._localStorage.setItem("favorites", JSON.stringify(favorites));
    } else {
      const favorites: IImage[] = [];
      favorites.push(item);

      this._localStorage.setItem("favorites", JSON.stringify(favorites));
    }
  }

  // Ok
  async deleteFavorite(idToRemove: number) {
    if (this._authService.isLogged()) {
      await this._favoriteService.deleteFromFavorites(idToRemove);
      return;
    }

    if (this._localStorage.getItem("favorites")) {
      const favorites: IImage[] = JSON.parse(
        this._localStorage.getItem("favorites")!
      );

      const id: number = favorites.findIndex(
        (image: IImage) => +image?.id === +idToRemove
      );

      favorites.splice(id, 1);

      this._localStorage.setItem("favorites", JSON.stringify(favorites));
    }
  }

  // Pizdec
  checkItemInFavorites(item: IImage): boolean {
    if (this._authService.isLogged() && item) {
      return this._favoriteService.checkItemInFavorites(item);
    }

    if (this._localStorage.getItem("favorites") && item) {
      const favorites: IImage[] = JSON.parse(
        this._localStorage.getItem("favorites")!
      );

      if (favorites) {
        const id: any = favorites.find((favorite: IImage) => {
          return +favorite?.id === +item.id;
        });

        return !!id;
      }

      return false;
    } else {
      this._localStorage.setItem("favorites", JSON.stringify([]));
    }

    return false;
  }

  // Pizdec
  checkItemInCart(item: IImage): boolean {
    if (this._authService.isLogged() && item) {
      return this._cartService.checkItemInCart(item);
    } else {
      let cartItems = JSON.parse(this._localStorage.getItem("cart")!);
      if (cartItems) {
        const id: any = cartItems.find((cartItem: IImage) => {
          return +cartItem?.id === +item?.id;
        });

        return !!id;
      }

      return false;
    }
    // let cartItems: IImage[] = []

    // if (this._authService.isLogged()) {

    // } else {
    //   cartItems = JSON.parse(this._localStorage.getItem("cart")!);
    //   if (cartItems) {
    //     const id: any = cartItems.find((cartItem: IImage) => {
    //       return +cartItem?.id === +item?.id;
    //     });

    //     return !!id;
    //   }

    //   return false;
    // }
  }
}
