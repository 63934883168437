import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { FolderService } from "src/app/services/admin/folder.service";
@Component({
  selector: "app-folder-modal",
  templateUrl: "./folder-modal.component.html",
  styleUrls: ["./folder-modal.component.scss"],
})
export class FolderModalComponent {
  form: FormGroup;

  @Input() currentFolderId: number
  @Input() isFolderInCurrentFolder = true

  @Output() close = new EventEmitter();
  @Output() folderCreated = new EventEmitter();

  constructor(
    private readonly folderService: FolderService,
  ) {
    this.form = new FormGroup({
      name: new FormControl("", [Validators.required, Validators.minLength(1)]),
    });
  }

  async closeModal() {
    this.close.emit()
  }

  async submit() {
    if (this.form.value.name < 2) {
      this.closeModal()
      return;
    }
    
    // Try-catch is necessary to close modal despite of the backend answer
    try {
      if (this.isFolderInCurrentFolder) {
        await this.folderService.createFolderInFolder(this.form.value.name, this.currentFolderId)
        this.folderCreated.emit()
      } else {
        await this.folderService.createFolder(this.form.value.name);
      }
    } finally{
      this.closeModal()
    }

  }
}
