import { AdminService } from "../../services/admin/admin.service";
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { ModalService } from "src/app/services/admin/modal.service";
import { IFolder, IAdminImage } from "src/app/models/admin";
import { Router } from "@angular/router";
import { HelperService } from "src/app/services/admin/helper.service";
import { Action } from "src/app/models/action";
import { AdminCompilationService } from "src/app/services/admin/admin-compilation.service";

@Component({
  selector: "app-admin-panel",
  templateUrl: "./admin-panel.component.html",
  styleUrls: ["./admin-panel.component.scss"],
})
export class AdminPanelComponent implements OnInit, OnChanges {

  @Input() selectedFiles: Array<IAdminImage | IFolder> = []
  @Input() compilationCoverId: number = null

  @Output() unchooseAll = new EventEmitter();
  @Output() chooseAll = new EventEmitter();

  @Output() action = new EventEmitter<Action>();

  isFolderPage = true

  selectedFilesNumber = "";

  get selectedImages() {
    return this.selectedFiles.filter(file => file.hasOwnProperty("onMainPage"))
  }

  get isImagesSelected() {
    return this.selectedFiles.some(item => item.hasOwnProperty("author"))
  }

  get selectedFilesLengthAsString() {
    return this.selectedFiles.length + " " + this.helper.formGenerator(this.selectedFiles.length)
  };

  isDropDown: boolean = false;

  Action = Action

  constructor(
    private adminService: AdminService,
    private helper: HelperService,
    private router: Router,
    private compilationService: AdminCompilationService
  ) { }

  onDropdownOutside(e: any) {
    if (e.target?.className !== "dropdown-trigger") {
      this.isDropDown = false;
    }
  }

  ngOnInit(): void {
    this.isFolderPage = this.router.url.includes("folders")
    this.initializeSelectedLenght()
  }

  ngOnChanges() {
    this.isFolderPage = this.router.url.includes("folders")
    this.initializeSelectedLenght()
  }

  chooseAllItems() {
    this.chooseAll.emit()
  }

  unchooseAllItems() {
    this.unchooseAll.emit()
  }

  emitAction(action: Action) {
    this.action.emit(action)
  }

  private initializeSelectedLenght() {
    this.selectedFilesNumber =
      this.adminService.chosenItemsLength +
      " " +
      this.helper.formGenerator(this.adminService.chosenItemsLength);
    this.selectedFiles.length = this.adminService.chosenItemsLength;
  }

  // onDelete() {
  //   //Check if selected contains folders
  // if (this.selectedFiles.find(item => item.level != null)) {
  //   this.adminService.deletingItemsIds = this.selectedFiles.map(img => img.id)
  //   this.adminService.setDeleteModalContent = "Folder";
  // } else {
  //   this.adminService.deletingItemsIds = this.selectedFiles.map(img => img.id)
  //   this.adminService.setDeleteModalContent = "Images";
  // }

  //   this.modalService.openDeleteModal();
  // }



  // onEdit() {
  //   this.modalService.openEditModal();
  // }

  // onReplace() {
  //   this.modalService.openReplaceModal();
  // }

  // onToCompilation() {
  //   this.modalService.openToCompilationModal();
  // }
}
