import { ChangeDetectionStrategy, Component, OnChanges, OnInit } from "@angular/core";
import { IImage } from "./../../models/image";
import { FavoriteService } from "src/app/services/favorite.service";
import { AuthService } from "src/app/services/auth.service";
import { LocalStorage } from "src/libs/storage/src";
import { ProductMethods } from "src/app/common/product-methods";

@Component({
  selector: "app-favorite",
  templateUrl: "./favorite.component.html",
  styleUrls: ["./favorite.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default
})
export class FavoriteComponent implements OnInit {
  favorites: IImage[] = [];
  amount = 0

  constructor(
    private _favoriteService: FavoriteService,
    private _authService: AuthService,
    private _localStorage: LocalStorage,
  ) {}
  
  ngOnInit () {
    this.getAllFavorites()
  }

  async getAllFavorites() {
    let response: any;

    if (!this._authService.isLogged()) {

      response = this._localStorage.getItem("favorites");
      this.favorites = JSON.parse(response);
      this.getFavoritesLength()
      return;
    }
    
    setTimeout( async () => {
      this.favorites = await this._favoriteService.getAllFavorites();
      this.getFavoritesLength()
    }, 0)
  }

  getFavoritesLength() {
    this.amount = this.favorites.length 
  }

}
