import { Subscription } from "rxjs";
import {
  ChangeDetectionStrategy,
  Component, Inject,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { Meta, Title } from "@angular/platform-browser";
import { environment } from "@env";
import { Language } from "@app/enums/language";
import { ICompilation } from "./../../models/models";
import { IImage } from "src/app/models/image";
import { CompilationService } from "src/app/services/compilation.service";
import { ImageSourceService } from "src/app/services/image-source.service";

@Component({
  selector: "app-compilation",
  templateUrl: "./compilation.component.html",
  styleUrls: ["./compilation.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CompilationComponent implements OnInit, OnDestroy {
  public compilation: ICompilation;
  public images: IImage[] = [];

  /** The variable 'descriptionColumns' contains two elements.
   The first element contains more than 600 characters before the first occurrence of a period.
   The second element contains the remaining part of the string. */
  public descriptionColumns: string[] = [];

  public id: number;
  public imagesLimit = 6;
  public pages = 0;
  public pageSize = 12;
  public currentPage = 1;

  public pgSize: number = 10;

  private querySub: Subscription;

  constructor(
    private readonly meta: Meta,
    private readonly compilationService: CompilationService,
    private readonly imageSourceService: ImageSourceService,
    private readonly activatedRoute: ActivatedRoute,
    public readonly router: Router,
    private titleService: Title,
    @Inject(DOCUMENT) private document: Document
  ) {}

  public async ngOnInit(): Promise<void> {
    this.id = this.activatedRoute.snapshot.params["id"];
    await this.loadCompilation();

    const seo = this.getSeo(this.compilation, Language.RU);
    seo?.title && this.titleService.setTitle(seo.title);

    this.querySub = this.activatedRoute.queryParams.subscribe((params) => {
      this.onPageChange(+(params.page ?? 1));
    });

    // Setting canonical URL to prevent pagination params in URL messing with SEO
    // and in case the page is loaded by compilation ID instead of friendlyURL
    const canonicalUrl = `${environment.siteUrl}/compilation/${this.compilation.friendlyURL || this.id}`;
    const link = <HTMLLinkElement> this.document.head.querySelector("link[rel='canonical']")
      || this.document.head.appendChild(this.document.createElement("link"));
    link.rel = 'canonical';
    link.href = canonicalUrl;
  }

  public ngOnDestroy(): void {
    this.querySub.unsubscribe();
    this.titleService.setTitle('');
    this.unsetMetaTags();

    const link = <HTMLLinkElement> this.document.head.querySelector("link[rel='canonical']");
    this.document.head.removeChild(link);
  }

  private onPageChange(page: number) {
    this.currentPage = page;
    this.loadCurrentPage();
  }

  private async loadCurrentPage() {
    const { items, total } = await this.compilationService.getImages(
      this.id,
      this.currentPage,
      this.pageSize
    );

    this.pages = Math.ceil(total / this.pageSize);
    this.images = items;
  }

  private getSeo(photo: ICompilation, language: Language) {
    const seo = photo?.__seoInfo__.find((seo) => seo.language === language);
    return seo;
  }

  setMetaTags() {
    const seo = this.getSeo(this.compilation, Language.RU);

    if (seo) {
      this.meta.updateTag({ name: "title", content: seo.title });
      this.meta.updateTag({
        name: "description",
        content: seo.description,
      });
      this.meta.updateTag({ name: "keywords", content: seo.keywords });
    }
  }

  unsetMetaTags(): void {
    this.meta.updateTag({ content: '' }, 'name=title');
    this.meta.updateTag({ content: '' }, 'name=description');
    this.meta.updateTag({ content: '' }, 'name=keywords');
  }

  async loadCompilation() {
    try {
      this.compilation = await this.compilationService.getCompilation(this.id);
      //  Set the ID in case the page is loaded by friendly URL
      this.id = this.compilation.id;
      this.getCompilationPreview();
      this.setMetaTags();
      this.splitDescription();
    } catch (error) {
      console.log(error);
    }
  }

  getImageSource(photo: IImage) {
    return this.imageSourceService.returnUrl(photo, "ORIGINAL");
  }

  getCompilationPreview() {
    if (!this.compilation) {
      return;
    }

    this.compilation.img = this.getImageSource(this.compilation.__cover__);
  }

  onPageSelected(page: number): void {
    this.router.navigate([], {
      queryParams: {
        page,
      },
    });
  }

  splitDescription() {
    const { description } = this.compilation;
    this.descriptionColumns[0] = '';
    this.descriptionColumns[1] = '';

    if (description) {
      const partMinLength = 600;
      const firstPartEnd = description.indexOf('.', partMinLength - 1);
      const secondPartStart = firstPartEnd + 1;

      if (secondPartStart > 0) {
        this.descriptionColumns[0] = description.slice(0, secondPartStart);
        this.descriptionColumns[1] = description.slice(secondPartStart).trimStart();
      } else {
        this.descriptionColumns[0] = description;
      }
    }
  }
}
