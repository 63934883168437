import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import {
  IAdminImage,
  IFolder,
  ISeoInfo,
  IUpdateImage,
  IUpdateImagesData,
} from "../../models/admin";
import { BaseRequestService } from "../base-request.service";
import { ImagesBulkAction } from "@app/enums/images-bulk-action";

@Injectable({
  providedIn: "root",
})
export class AdminService {
  chosenItems: Array<IAdminImage | IFolder> = [];
  isAdminPanel = this.chosenItems.length > 0 ? true : false;
  foldersToReplace: IFolder[] = [];

  // Fields for deleting items
  deleteModalContent = "";
  // If multiple items to delete, exmple - images
  deletingItemsIds: number[];

  isSearch = false;

  private selectedFiles = new Subject<any>();
  private reflectFormatSubject = new Subject<any>();
  private activeEssenceSubject = new Subject<any>();

  constructor(
    private router: Router,
    private requestService: BaseRequestService
  ) {}

  toggleImage(image: IAdminImage) {
    if (image) {
      image.isSelected = !image.isSelected;
    }

    const hasImage = this.chosenItems.find((chosen) => chosen.id === image.id);

    if (hasImage) {
      this.chosenItems = this.chosenItems.filter((img) => img.id !== image.id);
    } else {
      if (image) {
        this.chosenItems.push(image);
      }
    }

    this.selectedFiles.next(this.chosenItems);
  }

  toggleFolder(folder: IFolder) {
    // const folder = essence?.find((folder: IFolder) => folder.id === id);
    if (folder == null) {
      return;
    }

    folder.isSelected = !folder.isSelected;

    const hasFolder = this.chosenItems.find(
      (chosen) => chosen.id === folder.id
    );

    if (hasFolder) {
      const index = this.chosenItems.indexOf(folder);
      this.chosenItems.splice(index, 1);
    } else {
      this.chosenItems.push(folder);
    }

    this.selectedFiles.next(this.chosenItems);
  }

  // uploadNewFolder() {
  //   this.getFolder(this.currentFolder.id)
  //   this.unchooseAll()
  // }

  onReflectFormatChange(): Observable<any> {
    return this.reflectFormatSubject.asObservable();
  }
  onActiveEssenceChange(): Observable<any> {
    return this.activeEssenceSubject.asObservable();
  }

  //////////////////////////////////
  // SETTERS

  set setDeleteModalContent(content: string) {
    this.deleteModalContent = content;
  }

  //////////////////////////////////
  // GETTERS

  get chosenItemsLength() {
    return this.chosenItems.length;
  }

  get getDeleteModalContent() {
    return this.deleteModalContent;
  }

  get getChosenImages(): IAdminImage[] {
    return this.chosenItems.filter((item) =>
      this.isInstanceOfAdminImage(item)
    ) as IAdminImage[];
  }

  get getChosenItems() {
    return this.chosenItems;
  }

  get getFoldersToReplace() {
    return this.foldersToReplace;
  }

  get isCompilationPage() {
    if (this.router.url.includes("compilations")) {
      return true;
    }
    return false;
  }

  // ASYNC IMAGE FUNC

  async deleteImage(images: number[]) {
    try {
      await this.requestService.delete("/file-manager", {
        imageIds: images,
      });
      this.selectedFiles.next([]);
    } catch (e: any) {
      console.log("Error", e.message);
    }
  }

  async updateImage(id: number, imageData: IUpdateImage): Promise<IAdminImage> {
    return this.requestService.patch<IAdminImage>(
      `/images/edit/${id}`,
      imageData
    );
  }

  //////////////////////////////////
  public async getImage(id: number): Promise<IAdminImage> {
    // For some reason this request returns an array...
    return (await this.requestService.get<IAdminImage[]>(`/images/${id}`))[0];
  }

  // SEARCH REQUEST
  async searchImages(str: string) {
    try {
      const response = await this.requestService.post<IAdminImage[]>(
        `/search/10`,
        { searchString: str }
      );
      // this.currentFolder.images = response;
      this.isSearch = true;

      if (response.length === 0) {
        // this.currentFolder.name = "Поиск:по вашему запросу ничего не найдено";
      } else {
        // this.currentFolder.name = "Поиск";
        // this.compilationService.currentCompilation = response;
      }
      const searchFolder = {
        // id:this.currentFolder.id,
        name:
          response.length === 0
            ? "Поиск:по вашему запросу ничего не найдено"
            : "Поиск",
        images: response,
      };
      // return response;
    } catch (e: any) {
      console.error(e.message);
    }
  }

  // ASYNC FUNCTIONS FOR SEO
  async addSeoToImage(seoData: ISeoInfo) {
    try {
      const response = await this.requestService.post<ISeoInfo[]>(
        `/seoInfo/addToImage`,
        seoData
      );
      return response;
    } catch (e: any) {
      console.log("Error", e.message);
      return [];
    }
  }

  async updateSeoTag(seoData: any, seoId: any): Promise<ISeoInfo> {
    try {
      const response = await this.requestService.post<ISeoInfo>(
        `/seoInfo/${seoId}`,
        seoData
      );
      return response;
    } catch (e: any) {
      console.log("Error", e.message);
      throw e;
    }
  }

  // ASYNC FUNC FOR EDIT-MANY IMAGES

  async updateSeveralImages(
    imagesData: IUpdateImagesData
  ): Promise<IAdminImage[]> {
    try {
      const response = await this.requestService.patch<any>(
        `/images/edit-many`,
        imagesData
      );
      return response.updated;
    } catch (e: any) {
      console.log("Error", e.message);
      throw e;
    }
  }

  private isInstanceOfAdminImage(object: any): boolean {
    return "author" in object;
  }

  public async imagesBulkAction(ids: Array<IAdminImage['id']>, action: ImagesBulkAction): Promise<void> {
    const idsParam = ids.join(',');
    try {
      const response = await this.requestService.patch<any>(
        `/images?action=${action}&ids=${idsParam}`,
        undefined,
      );
      return response;
    } catch (e: any) {
      console.log("Error", e.message);
      throw e;
    }
  }
}
