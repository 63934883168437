export enum Action {
  bulkEdit,
  addToCompilation,
  delete,
  deleteFromCompilation,
  move,
  setCover,
  publish,
  unpublish,
  showOnMainPage,
  hideOnMainPage,
}
