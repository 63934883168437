<div *ngIf="selectedFiles.length > 0" class="panel">
  <ul class="panel__controls">
    <li class="panel__controls-counter">
      <span>{{ selectedFilesLengthAsString }}</span>
    </li>
    <li
      *ngIf="selectedFiles.length > 1"
      class="panel__controls-edit"
      (click)="emitAction(Action.bulkEdit)"
    >
      <a>Редактировать</a>
    </li>
    <li class="panel__controls-select" (click)="chooseAllItems()">
      <a>Выбрать все</a>
    </li>
    <li class="panel__controls-unselect" (click)="unchooseAllItems()">
      <a>Отменить выделенное</a>
    </li>

    <li class="panel__controls-other">
      <a class="dropdown-trigger" (click)="isDropDown = !isDropDown"></a>

      <div
        (clickOutside)="onDropdownOutside($event)"
        class="dropdown"
        [class.open]="isDropDown"
      >
        <a
          (click)="emitAction(Action.publish)"
        >
          Опубликовать
        </a>

        <a
          (click)="emitAction(Action.unpublish)"
        >
          Снять с публикации
        </a>

        <a
          (click)="emitAction(Action.addToCompilation)"
          *ngIf="isFolderPage && isImagesSelected"
        >
          Добавить в подборку
        </a>

        <a
          *ngIf="!isFolderPage && selectedFiles.length === 1"
          (click)="emitAction(Action.setCover)"
          [class.disabled]="selectedFiles[0].id === compilationCoverId"
        >
          Сделать обложкой
        </a>

        <a
          *ngIf="isFolderPage; else deleteFromCompilation"
          (click)="emitAction(Action.delete)"
        >
          Удалить
        </a>

        <ng-template #deleteFromCompilation>
          <a (click)="emitAction(Action.deleteFromCompilation)">
            Удалить из подборки
          </a>
        </ng-template>
      </div>
    </li>
  </ul>
</div>
