import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { CartService } from "src/app/services/cart.service";
import { FavoriteService } from "src/app/services/favorite.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent {
  form: FormGroup;
  isLoginOrPasswordInvalid = false;
  requestSend = false;

  isPasswordVisible: boolean = false;

  constructor(
    private router: Router, 
    private authService: AuthService,
    private cartService: CartService,
    private favoriteService: FavoriteService
  ) {
    this.form = new FormGroup({
      login: new FormControl(null, [
        Validators.required
      ]),
      password: new FormControl(null, [
        Validators.required ]),
      rememberMe: new FormControl(null),
    });
  }

  async submit() {
    this.requestSend = true;
    this.isLoginOrPasswordInvalid = false
    
    try {
      await this.authService.logIn(this.form.value.login, this.form.value.password)
      
      if (await this.authService.isAdmin()) {
        this.router.navigate(['/workspace'])
      } else {
        this.router.navigate(['/cabinet'])
        await this.cartService.synchronizeCart()
        await this.favoriteService.synchronizeFavorites()
      }

    } catch (error) {
      this.isLoginOrPasswordInvalid = true
    }

    this.requestSend = false;
  }

  changeInputType() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }
}
