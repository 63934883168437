import { FormControl } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { Component, Input, OnInit } from "@angular/core";
import { ModalService } from "src/app/services/admin/modal.service";
import { ISeoInfo } from "src/app/models/admin";
import { AdminCompilationService } from "src/app/services/admin/admin-compilation.service";
import { AdminService } from "src/app/services/admin/admin.service";

@Component({
  selector: "app-seo-modal",
  templateUrl: "./seo-modal.component.html",
  styleUrls: ["./seo-modal.component.scss"],
})
export class SeoModalComponent implements OnInit {
  form: FormGroup;
  
  @Input() seoInfo?: ISeoInfo[];
  @Input() essence: string = '';
  @Input() imageId?: number;
  
  seoElement = 0;

  constructor(
    private modalService: ModalService,
    private adminService: AdminService,
	  private compilationService:AdminCompilationService
  ) {
    this.form = new FormGroup({
      seodescription: new FormControl(""),
      seotitle: new FormControl(""),
      keywords: new FormControl(""),
      language: new FormControl("RU"),
    });
  }

  ngOnInit(): void {
    this.form
      .get("keywords")
      ?.patchValue(this.seoInfo?.[this.seoElement]?.keywords || "");
    this.form
      .get("language")
      ?.patchValue(this.seoInfo?.[this.seoElement]?.language || "RU");
    this.form
      .get("seotitle")
      ?.patchValue(this.seoInfo?.[this.seoElement]?.title || "");
    this.form
      .get("seodescription")
      ?.patchValue(this.seoInfo?.[this.seoElement]?.description || "");
    console.log("seoOfImage", this.seoInfo);
  }

  closeModal() {
    this.modalService.closeSeoInfoModal();
  }

  

  setSeoElement(value: number) {
    this.seoElement = value;
    this.form
      .get("keywords")
      ?.patchValue(this.seoInfo?.[this.seoElement]?.keywords || "");
    this.form
      .get("language")
      ?.patchValue(this.seoInfo?.[this.seoElement]?.language || "EN");
    this.form
      .get("seotitle")
      ?.patchValue(this.seoInfo?.[this.seoElement]?.title || "");
    this.form
      .get("seodescription")
      ?.patchValue(this.seoInfo?.[this.seoElement]?.description || "");
  }

  async submit() {
    const seoData = {
      title: this.form.value.seotitle,
      description: this.form.value.seodescription,
      keywords: this.form.value.keywords,
      language: this.form.value.language,
      imageId: this.imageId,
    };

	  const newSeoInfo = {
		  title: this.form.value.seotitle,
		  description: this.form.value.seodescription,
		  keywords: this.form.value.keywords,
	  };

	  const seoCompilationData = {
		  title: this.form.value.seotitle,
		  description: this.form.value.seodescription,
		  keywords: this.form.value.keywords,
		  language: this.form.value.language,
		  compilationId: this.imageId,
	  };

    
	  if(this.essence === 'compilationseo'){
		
		  if(this.seoInfo && this.seoInfo[this.seoElement]){
			  this.adminService.updateSeoTag(
				  newSeoInfo,
				  this.seoInfo[this.seoElement].id
			  );

		} else {
		  const res = await this.compilationService.addSeoToCompilation(seoCompilationData)
		  //this.seoInfo = res
		}

	} else if (
    this.essence === 'imageseo' && 
    this.seoInfo && 
    !this.seoInfo[this.seoElement]
  ){
		console.log('ADDSEOTOIMAGE', seoData);
		const res = await this.adminService.addSeoToImage(seoData);
		//this.seoInfo = res;

	} else if (
    this.seoInfo && 
    this.seoInfo[this.seoElement]
  ){
		console.log('UPDATESEOINFOTOIMAGE', seoData);
		this.adminService.updateSeoTag(
			newSeoInfo,
			this.seoInfo[this.seoElement].id
		  );
	  }
    this.closeModal();
  }
}
