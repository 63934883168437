<app-admin-modal
  [isLarge]="true"
  [isScrollable]="true"
  [withControls]="true"
  (onClose)="closeModal()"
  (onEscape)="handleEscapePress()"
  (onPrevious)="changeImage(-1)"
  (onNext)="changeImage(1)"
>
  <form>
    <div class="image-modal__header">
      <div class="image-modal__header-description">
        <div class="image-modal__header-description-title">
          <h2>{{ image?.name }}</h2>
        </div>

        <div class="controls image-modal__header-description-edit">
          <button
            *ngIf="!isEditMode"
            class="edit"
            (click)="enableEditMode()"
            type="button"
          >
            Редактировать
          </button>

          <button *ngIf="isEditMode" (click)="handleSave()" type="button">
            Сохранить
          </button>
        </div>
      </div>

      <div class="image-modal__header-controls">
        <div class="controls" *ngIf="isEditMode">
          <div class="form-group form-group_noBorder">
            <button (click)="onDelete()" type="button">Удалить</button>
          </div>
        </div>
      </div>
    </div>

    <div class="image-modal__content">
      <div *ngIf="!isFullOpen" class="image-modal__content-fields">
        <div class="form-group form-group_noBorder">
          <label>ID</label>
          <span class="form-group-content">{{ image?.id }}</span>
        </div>

        <div class="form-group">
          <label>Имя</label>
          <input
            *ngIf="isEditMode; else name"
            [(ngModel)]="image.name"
            name="name"
            type="text"
          />
          <ng-template #name>
            <span class="form-group-content">{{ image?.name }}</span>
          </ng-template>
        </div>

        <div class="form-group">
          <label>ЧПУ</label>
          <input
            *ngIf="isEditMode; else friendlyURL"
            [(ngModel)]="newFriendlyUrl"
            name="friendlyURL"
            type="text"
          />
          <ng-template #friendlyURL>
            <span class="form-group-content">{{ image?.friendlyURL }}</span>
          </ng-template>
        </div>

        <div class="seo-block">
          <div class="form-group">
            <label>Заголовок</label>
            <input
              *ngIf="isEditMode; else title"
              [(ngModel)]="image.title"
              name="title"
              type="text"
            />
            <ng-template #title>
              <span class="form-group-content">{{ image?.title }}</span>
            </ng-template>
          </div>

          <div class="form-group form-group_lightText">
            <label>Описание</label>
            <textarea
              *ngIf="isEditMode; else description"
              [(ngModel)]="image.description"
              name="description"
              rows="4"
            ></textarea>

            <ng-template #description>
              <span
                [title]="image?.description"
                class="form-group-content"
              >{{ image?.description }}</span>
            </ng-template>
          </div>

          <div class="form-group form-group_lightText tags">
            <label>Ключевые слова</label>
            <ul>
              <li *ngFor="let tag of existingTags" class="tags__element">
                <div class="tags__content">
                  <span>{{ tag.name }}</span>
                </div>

                <div class="tags__actions">
                  <button
                    type="button"
                    *ngIf="isEditMode"
                    (click)="deleteExistingTag(tag.id)"
                  >
                    &#10761;
                  </button>
                </div>
              </li>

              <li *ngFor="let tag of newTags; index as idx" class="tags__element">
                <div class="tags__content">
                  <span>{{ tag.tagName }}</span>
                </div>
                <div class="tags__actions">
                  <button
                    type="button"
                    *ngIf="isEditMode"
                    (click)="deleteNewTag(idx)"
                  >
                    &#10761;
                  </button>
                </div>
              </li>

              <li class="tags__input" *ngIf="isEditMode">
                <input
                  [(ngModel)]="currentTag"
                  (keydown)="onTagInputKeydown($event)"
                  type="text"
                  placeholder="Добавить тег"
                  name="tag"
                />
              </li>
            </ul>
          </div>

          <div class="form-group form-group-seo">
            <h4>SEO</h4>

            <div class="form-group-inline">
              <select
                *ngIf="isEditMode"
                class="form-group__select"
                [(ngModel)]="seoInfo.language"
                (change)="selectSeoLanguage(language.value)"
                name="language"
                #language
              >
                <option value="RU" selected="selected">Русский</option>
                <option value="EN">Английский</option>
              </select>

              <span
                *ngIf="!isEditMode"
                class="form-group-content"
              >Русский</span>
            </div>
          </div>

          <div class="form-group">
            <label>SEO-title</label>

            <div
              *ngIf="isEditMode; else seoTitle"
              class="value-container"
            >
              <input
                [(ngModel)]="seoInfo.title"
                name="seoTitle"
                type="text"
              />

              <button
                role="button"
                (click)="createSeoAttribute(this.SeoAttribute.TITLE)"
                [disabled]="!hasSeoAttributeTemplate(this.SeoAttribute.TITLE, seoInfo.language)"
                [title]="hasSeoAttributeTemplate(this.SeoAttribute.TITLE, seoInfo.language)
                ? makeSeoAttribute(this.SeoAttribute.TITLE, seoInfo.language, image)
                : 'Шаблон атрибута для выбранного языка не задан'
                "
              >Сгенерировать</button>
            </div>

            <ng-template #seoTitle>
              <span
                [title]="seoInfo?.title"
                class="form-group-content"
              >{{ seoInfo?.title }}</span>
            </ng-template>
          </div>

          <div class="form-group">
            <label>
              SEO-description
            </label>

            <div
              *ngIf="isEditMode; else seoDescription"
              class="value-container"
            >
              <textarea
                [(ngModel)]="seoInfo.description"
                name="seoDescription"
                rows="4"
              ></textarea>

              <button
                role="button"
                (click)="createSeoAttribute(this.SeoAttribute.DESCRIPTION)"
                [disabled]="!hasSeoAttributeTemplate(this.SeoAttribute.DESCRIPTION, seoInfo.language)"
                [title]="hasSeoAttributeTemplate(this.SeoAttribute.DESCRIPTION, seoInfo.language)
                  ? makeSeoAttribute(this.SeoAttribute.DESCRIPTION, seoInfo.language, image)
                  : 'Шаблон атрибута для выбранного языка не задан'
                "
              >Сгенерировать</button>
            </div>

            <ng-template #seoDescription>
              <span
                [title]="seoInfo?.description"
                class="form-group-content"
              >{{ seoInfo?.description }}</span>
            </ng-template>
          </div>

          <div class="form-group form-group_noBorder">
            <label
              title="Текст, отображаемый вместо изображения при невозможности его загрузки"
            >Альтернативный текст</label>

            <div
              *ngIf="isEditMode; else altDescription"
              class="value-container"
            >
              <input
                [(ngModel)]="image.altDescription"
                name="altDescription"
                type="text"
              />

              <button
                role="button"
                (click)="image.altDescription = makeSeoAttribute(SeoAttribute.IMG_ALT, seoInfo.language, image)"
                [disabled]="!hasSeoAttributeTemplate(this.SeoAttribute.IMG_ALT, seoInfo.language)"
                [title]="hasSeoAttributeTemplate(this.SeoAttribute.IMG_ALT, seoInfo.language)
                  ? makeSeoAttribute(this.SeoAttribute.IMG_ALT, seoInfo.language, image)
                  : 'Шаблон атрибута для выбранного языка не задан'
                "
              >Сгенерировать</button>
            </div>

            <ng-template #altDescription>
              <span
                class="form-group-content"
              >{{ image?.altDescription }}</span>
            </ng-template>
          </div>

          <div class="form-group form-group_noBorder">
            <label
              title="Текст для атрибута title тэга <img>"
            >Заголовок для тэга &lt;img&gt;</label>

            <div
              *ngIf="isEditMode; else imgTitle"
              class="value-container"
            >
              <input
                [(ngModel)]="image.imgTitle"
                name="imgTitle"
                type="text"
              />

              <button
                role="button"
                (click)="image.imgTitle = makeSeoAttribute(SeoAttribute.IMG_TITLE, seoInfo.language, image)"
                [disabled]="!hasSeoAttributeTemplate(this.SeoAttribute.IMG_TITLE, seoInfo.language)"
                [title]="hasSeoAttributeTemplate(this.SeoAttribute.IMG_TITLE, seoInfo.language)
                ? makeSeoAttribute(this.SeoAttribute.IMG_TITLE, seoInfo.language, image)
                : 'Шаблон атрибута для выбранного языка не задан'
                "
              >Сгенерировать</button>
            </div>

            <ng-template #imgTitle>
              <span
                class="form-group-content"
              >{{ image?.imgTitle }}</span>
            </ng-template>
          </div>
        </div>

        <div class="form-group form-group-publish">
          <label>Опубликовать</label>

          <app-switch
            [checked]="image.published"
            [disabled]="!isEditMode"
            (checkboxClick)="publishChange()"
          ></app-switch>
        </div>

        <div class="form-group form-group-publish">
          <label>Показать на главной странице</label>

          <app-switch
            (checkboxClick)="onMainPageChange()"
            [checked]="image.onMainPage"
            [disabled]="!isEditMode"
          ></app-switch>
        </div>

        <div class="form-group form-group_lightText">
          <label>Цена</label>

          <input
            *ngIf="isEditMode; else license"
            [(ngModel)]="price"
            name="price"
            type="number"
          />

          <ng-template #license>
            <span
              class="form-group-content"
            >{{ image?.__prices__?.[0]?.value }}</span>
          </ng-template>
        </div>

        <div class="form-group compilations tags__wrapper">
          <label>Отображать в подборках</label>

          <mat-chip-list
            #compilationsList
            class="tags__wrapper-items"
          >
            <mat-basic-chip
              *ngFor="let compilation of currentCompilations; let i = index"
              [selectable]="false"
              [removable]="isEditMode"
              (removed)="removeCompilation(i)"
              class="tags__wrapper-item"
            >
              <span
                class="tag-cotent"
              >{{ compilation.title }}</span>

              <div
                *ngIf="isEditMode"
                class="tag-actions"
              >
                <button type="button" matChipRemove>
                  &#10761;
                </button>
              </div>
            </mat-basic-chip>

            <div
              *ngIf="isEditMode"
              class="tags__wrapper-items-input"
            >
              <input
                type="text"
                [formControl]="$any(form.get('compilationInput'))"
                [matChipInputFor]="compilationsList"
                [matAutocomplete]="compilationsAutocomplete"
                minlength="2"
                placeholder="Начните вводить"
              />

              <mat-autocomplete
                #compilationsAutocomplete="matAutocomplete"
                (optionSelected)="addCompilation($event)"
                [displayWith]="displaySelectedCompilation"
                [panelWidth]="'auto'"
              >
                <mat-option
                  *ngFor="let compilation of filteredCompilations | async"
                  [value]="compilation"
                  [disabled]="isCompilationOptionDisabled(compilation)"
                >
                  {{ compilation.title }}
                </mat-option>
              </mat-autocomplete>
            </div>
          </mat-chip-list>
        </div>

        <div class="form-group form-group_lightText">
          <label>Автор</label>

          <input
            *ngIf="isEditMode; else author"
            [(ngModel)]="image.author"
            name="author"
            type="text"
          />

          <ng-template #author>
            <span class="form-group-content">{{ image?.author }}</span>
          </ng-template>
        </div>

        <div class="form-group form-group_lightText">
          <label>Геолокация</label>

          <input
            *ngIf="isEditMode; else coordinates"
            [(ngModel)]="image.coordinates"
            name="coordinates"
            type="text"
          />

          <ng-template #coordinates>
            <span class="form-group-content">{{ image?.coordinates }}</span>
          </ng-template>
        </div>

        <div class="form-group form-group_lightText">
          <label>Формат</label>
          <span class="form-group-content">{{ image?.format }}</span>
        </div>

        <div class="form-group">
          <label>Время создания</label>
          <span class="form-group-content">{{ image?.uploadDate | date : "dd.MM.YYYY, HH:mm" }}</span>
        </div>

        <div class="form-group">
          <label>Камера</label>
          <span class="form-group-content">{{ image?.camera }}</span>
        </div>

        <button
          *ngIf="activeEssence !== 'Папки'"
          (click)="onDeleteFromCompilation()"
          type="button"
          class="delete-btn comp-delete"
        >
          Убрать из подборки
        </button>
      </div>

      <div
        *ngIf="!isLoading; else loader"
        [class.full]="isFullOpen"
        class="image-modal__content-img"
      >
        <button (click)="isFullOpen = !isFullOpen"></button>

        <div class="image-wrap">
          <img [src]="imageSource" alt="Выбранное фото" />
        </div>
      </div>

      <ng-template #loader>
        <div class="image-modal__imageLoader">Загрузка...</div>
      </ng-template>
    </div>
  </form>
</app-admin-modal>
