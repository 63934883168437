import { Injectable } from '@angular/core';
import { IFolder, ICreateFolderAnswer, IRenameFolderAnswer } from '../../models/admin';
import { BaseRequestService } from '../base-request.service';

@Injectable({
  providedIn: 'root'
})
export class FolderService {

  baseUrl = "/folders"

  constructor(private readonly requestService: BaseRequestService) { }

  getRootFolders(): Promise<IFolder[]> {
    return this.requestService.get(this.baseUrl + "/root")
  }

  async getFoldersParents(folderId: number): Promise<IFolder[]> {
    const parents = await this.requestService.get<IFolder[]>(this.baseUrl + "/parents/" + folderId)

    if (parents.some(folder => folder.id === 1)) {
      const rootFolderIndex = parents.findIndex(folder => folder.id === 1)
      parents[rootFolderIndex].name = "Файловая система"
    }

    parents.sort((a, b) => a.level - b.level)

    return parents
  }

  getFolder(folderId: number): Promise<IFolder> {
    return this.requestService.get(this.baseUrl + "/" + folderId)
  }

  getFolderContent(folderId: number): Promise<IFolder> {
    return this.requestService.get(`${this.baseUrl}/${folderId}/content`);
  }

  deleteFolder(id: number) {
    return this.requestService.delete<{ name: string }>(`/folders/${id}`);
  }

  moveImagesToFolder(folderId: number, imagesId: number[]) {
    return this.requestService.patch<any>(`/folders/images/move`, { folderId, imagesId }
    );
  }

  createFolderInFolder(foldersName: string, parentFolderId: number) {
    return this.requestService.put<IFolder>(this.baseUrl + "/" + parentFolderId, { foldersName: [foldersName] })
  }

  async createFolder(foldersNames: string[]): Promise<IFolder[]> {
    const { createdFolders } =  await this.requestService.put<ICreateFolderAnswer>(`/folders/1`, { foldersName: foldersNames })
    return createdFolders
  }

  moveFolder(folderId: number, toFolderId: number) {
    return this.requestService.patch(this.baseUrl + "/move", { folderId: folderId, parrentId: toFolderId })
  }

  renameFolder(id: number, name: string) {
    return this.requestService.patch<IRenameFolderAnswer>(`/folders/${id}`,{ name });
  }
}
