import { IAdminImage } from "src/app/models/admin";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs/internal/Subscription";
import { SearchService } from "src/app/services/search.service";
@Component({
  selector: "app-admin-header",
  templateUrl: "./admin-header.component.html",
  styleUrls: ["./admin-header.component.scss"],
})
export class AdminHeaderComponent implements OnInit, OnDestroy {
  @Input() withSearch = true;
  @Output() search = new EventEmitter<IAdminImage[]>();
  searchQuery = "";

  activeEssenceName = "";

  controlsToggle = false;
  isProfile = false;
  isUsersPage = false
  isUserAdmin = false

  routeSubscription: Subscription

  constructor(
    private authService: AuthService,
    private router: Router,
    private readonly searchService: SearchService
  ) { }

  async ngOnInit() {
    this.isUserAdmin = await this.authService.getUserRole() === "ADMIN"

    this.routeSubscription = this.router.events
      .subscribe((val)=> {
      if (val instanceof NavigationEnd){
        if (val.url.includes("/users")){
          this.isUsersPage = true
        } else {
          this.isUsersPage = false
        }
      }
    })
  }

  ngOnDestroy() {
    if (this.routeSubscription != null) {
      this.routeSubscription.unsubscribe()
    }
  }

  async onSearch() {
    const {searchId, images} = await this.searchService.getPosts(this.searchQuery, null)
    this.search.emit(images as unknown as IAdminImage[]);
  }

  onProfileOutside(event: any) {
    if (event.target?.className !== "profile-icon") {
      this.isProfile = false;
    }
  }

  navigateToUsersPanel() {
    this.router.navigate(['/workspace/users'])
  }

  onLogout() {
    this.authService.logOut();
    this.router.navigate(["/"]);
  }
}
