<app-header [isBlack]="true" [isSearch]="true"></app-header>

<section class="how-to-buy container">
  <h1 class="title">Как купить фотографию</h1>

  <ul>
    <li *ngFor="let step of howToBuySteps; let i = index">
      <span>
        {{ i + 1 }}
      </span>

      <div class="how-to-buy__step-container">
        <p *ngFor="let paragraph of step.paragraphs">
          {{ paragraph }}
        </p>
        <img
          [src]="'../../../assets/images/how-to-buy-' + (i + 1) + '.png'"
          [alt]="step.imgAlt"
          class="how-to-buy__step-image"
        />
      </div>
    </li>
  </ul>
</section>

<app-footer></app-footer>
