import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { AdminGuard } from "./guards/admin.guard";
// import { AuthGuard } from "./guards/auth.guard";
import { PhotoResolver } from "./resolvers/photo.resolver";
import { AboutComponent } from "./views/about/about.component";
import { AdminCatalogComponent } from "./views/admin/admin-catalog/admin-catalog.component";
import { AdminComponent } from "./views/admin/admin.component";
import { SeoTemplatesComponent } from "./views/admin/seo-templates/seo-templates.component";
import { UsersPanelComponent } from "./views/admin/users-panel/users-panel.component";
import { WorkspaceComponent } from "./views/admin/workspace/workspace.component";
import { CartComponent } from "./views/cart/cart.component";
import { CheckoutComponent } from "./views/checkout/checkout.component";
import { CompilationComponent } from "./views/compilation/compilation.component";
import { ContactsComponent } from "./views/contacts/contacts.component";
import { FavoriteComponent } from "./views/favorite/favorite.component";
import { GeoCatalogPageComponent } from "./views/geo-catalog-page/geo-catalog-page.component";
import { HowToBuyComponent } from "./views/how-to-buy/how-to-buy.component";
import { LoginComponent } from "./views/login/login.component";
import { MainComponent } from "./views/main/main.component";
import { PartnershipComponent } from "./views/partnership/partnership.component";
// import { PersonalCabinetComponent } from "./views/personal-cabinet/personal-cabinet.component";
import { PhotoComponent } from "./views/photo/photo.component";
import { PricesComponent } from "./views/prices/prices.component";
import { RegisterComponent } from "./views/register/register.component";
import { EmailComponent } from "./views/reset-password/email/email.component";
import { ResetPasswordComponent } from "./views/reset-password/reset-password.component";
import { SearchPageComponent } from "./views/search-page/search-page.component";
import { TermsOfUseComponent } from "./views/terms-of-use/terms-of-use.component";
import { AdminCompilationsComponent } from "./admin/compilations/admin-compilations-component/admin-compilations.component";
import { BlogComponent } from "@app/views/blog/blog.component";

const routes: Routes = [
  { path: "", component: MainComponent },
  { path: "catalog", component: GeoCatalogPageComponent },
  {
    path: "photos/:url",
    component: PhotoComponent,
    resolve: {
      photo: PhotoResolver,
    },
  },
  { path: "compilation/:id", component: CompilationComponent },
  { path: "favorites", component: FavoriteComponent },
  { path: "checkout", component: CheckoutComponent },
  { path: "search-page", component: SearchPageComponent },
  { path: "cart", component: CartComponent },
  {
    path: "reset-password/:token",
    component: ResetPasswordComponent,
    pathMatch: "full",
  },
  { path: "reset-password", component: EmailComponent },
  { path: "prices", component: PricesComponent },
  { path: "blog", component: BlogComponent },
  { path: "how-to-buy", component: HowToBuyComponent },
  { path: "about", component: AboutComponent },
  { path: "partnership", component: PartnershipComponent },
  { path: "contacts", component: ContactsComponent },
  { path: "terms-of-use", component: TermsOfUseComponent },
  // {
  //   path: "cabinet",
  //   component: PersonalCabinetComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: "cabinet",
    redirectTo: "/",
  },
  { path: "login", component: LoginComponent },
  { path: "register", component: RegisterComponent },
  {
    path: "workspace",
    component: AdminComponent,
    canActivate: [AdminGuard],
    children: [
      { path: "folders/:id", component: WorkspaceComponent },
      {
        path: "compilations",
        component: AdminCompilationsComponent,
        pathMatch: 'full',
      },
      {
        path: "compilations/:id",
        component: WorkspaceComponent,
      },
      {
        path: "catalog",
        component: AdminCatalogComponent,
      },
      {
        path: "seo/templates",
        component: SeoTemplatesComponent,
      },
      {
        path: "users",
        component: UsersPanelComponent,
      },
    ],
  },
  // { path: "error", component: ErrorComponent },
  {
    path: "404",
    pathMatch: "full",
    component: NotFoundComponent,
  },
  {
    path: "**",
    redirectTo: "/404"
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: "enabled",
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
