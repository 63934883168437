import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { Subscription } from "rxjs/internal/Subscription";
import { IAdminImage, ICompilationInfo, IFolder } from "src/app/models/admin";
import { FolderService } from "src/app/services/admin/folder.service";
import { AdminCompilationService } from "src/app/services/admin/admin-compilation.service";
import { ViewSettingsService } from "src/app/services/admin/view-settings.service";
import { IViewSettings, IViewType } from "src/app/models/models";
import { AdminCompilationImagesModalComponent, AdminCompilationImagesModalData } from "@app/admin/compilations/admin-compilation-images-modal/admin-compilation-images.modal.component";
import { MatDialog } from "@angular/material/dialog";

export enum ViewMode {
  Tile = 'Tile',
  List = 'List',
  BiggerList = 'BiggerList',
};
const viewModeToViewTypeMap: Record<ViewMode, IViewType> = {
  [ViewMode.Tile]: 'TILES',
  [ViewMode.List]: 'TABLE',
  [ViewMode.BiggerList]: 'BIGGER_TABLE',
};

const viewTypeToViewModeMap: Partial<Record<IViewType, ViewMode>> = {};
// @ts-ignore: we know every type here
Object.entries(viewModeToViewTypeMap).forEach(([key, value]) => viewTypeToViewModeMap[value] = key);

@Component({
  selector: "app-workspace",
  templateUrl: "./workspace.component.html",
  styleUrls: ["./workspace.component.scss"],
})
export class WorkspaceComponent implements OnInit, OnDestroy {
  readonly ViewMode = ViewMode;

  viewMode: ViewMode;
  isSearch = false;
  isLoadedBar = false;

  currentFolder: IFolder;
  foldersParents: IFolder[] = [];
  viewSettings: IViewSettings | null = null;

  currentCompilationInfo: ICompilationInfo;

  // Folder or Compilation page
  isFolderPage = true;

  images: IAdminImage[] = [];

  routerSubscription: Subscription;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly folderService: FolderService,
    private readonly compilationService: AdminCompilationService,
    private readonly viewSettingsService: ViewSettingsService,
    private readonly matDialog: MatDialog,
  ) {}

  ngOnInit() {
    this.routerSubscription = this.route.params.subscribe((params) =>
      this.handleUrlChange(params)
    );
    this.getViewSettings();
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }

  async getViewSettings() {
    const savedViewSettings = await this.viewSettingsService.getViewSettings();
    this.viewSettings = savedViewSettings;
    this.viewMode = viewTypeToViewModeMap[savedViewSettings?.viewType || 'TABLE'];
  }

  async handleUrlChange(params?: Params) {
    if (undefined === params) {
      params = this.route.snapshot.params;
    }

    this.foldersParents = [];
    const isFolderPage = this.route.snapshot.url.toString().includes('folders');

    if (isFolderPage) {
      this.currentFolder = await this.folderService.getFolderContent(+params.id);
      this.images = this.currentFolder.images;

      // Check if root folder
      if (+params.id === 1) {
        this.currentFolder.name = "Файловая система";
        return;
      }

      const parents = await this.folderService.getFoldersParents(
        this.currentFolder.id
      );

      if (parents != null) {
        parents.forEach((folder) => this.foldersParents.push(folder));
      }
    } else {
      this.isFolderPage = false;
      this.currentCompilationInfo =
        await this.compilationService.getCompilationInfo(+params.id);
      this.images = (await this.compilationService.getCompilationImages(
        +params.id
      )).items;
    }
  }

  setViewMode(viewMode: ViewMode) {
    this.viewMode = viewMode;
    const viewType = viewModeToViewTypeMap[viewMode];

    this.viewSettingsService.updateViewSettings({
      ...this.viewSettings,
      viewType,
    });
  }

  async setFoundImages(foundImages: IAdminImage[]) {
    this.currentFolder = await this.folderService.getFolderContent(1);
    this.currentFolder.children = [];
    this.currentFolder.name = "Файловая система";
    this.foldersParents = [];

    this.images = foundImages;
  }

  public async onEditCompilationImages(
    compilationInfo: ICompilationInfo
  ): Promise<void> {
    const dlg = this.matDialog.open<
      AdminCompilationImagesModalComponent,
      AdminCompilationImagesModalData,
      ICompilationInfo | undefined
    >(AdminCompilationImagesModalComponent, {
      data: {
        compilationId: compilationInfo.id,
        tableViewMode: this.viewMode,
      },
      width: '1720px',
    });
    const closeResult = await dlg.afterClosed().toPromise();
    await this.handleUrlChange();
  }
}
