import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { IFolder } from 'src/app/models/admin';
import { AdminService } from 'src/app/services/admin/admin.service';
import { ViewMode } from 'src/app/views/admin/workspace/workspace.component';

@Component({
  selector: 'app-admin-folder',
  templateUrl: './admin-folder.component.html',
  styleUrls: ['./admin-folder.component.scss']
})
export class AdminFolderComponent implements OnChanges {
  readonly ViewMode = ViewMode;

  @Input() folder: IFolder;
  @Input() viewMode: ViewMode;

  @Output() folderToggle = new EventEmitter();
  
  isListing: boolean;

  constructor(
    private readonly router: Router
  ) {}

  ngOnChanges() {
    this.isListing  = [ViewMode.List, ViewMode.BiggerList].includes(this.viewMode);
  }

  openFolder() {
    this.router.navigateByUrl("/workspace/folders/" + this.folder.id)
  }

  toggleChecked() {
    this.folderToggle.emit(this.folder)
  }
}
