import { Component, Input } from "@angular/core";

@Component({
  selector: "app-terms-of-use-grid",
  templateUrl: "./terms-of-use-grid.component.html",
  styleUrls: ["./terms-of-use-grid.component.scss"],
})
export class TermsOfUseGridComponent {
  @Input() photos: any[] = [];

  isPopupCarouselVisible = false;
  isPopupPhotoVisible = false;
  activePhoto: any;
  photoIndex = 0;

  toggleCarousel(index: number) {
    this.isPopupCarouselVisible = !this.isPopupCarouselVisible;
    this.photoIndex = index;
  }

  togglePopupPhoto(photo: any) {
    this.activePhoto = photo;
    this.isPopupPhotoVisible = !this.isPopupPhotoVisible;
  }

  clickOnPhoto(index: number) {
    this.toggleCarousel(index);
  }
}
