<div class="tags__wrapper">
  <label> Тэги</label>
  <div class="tags__wrapper-items">
    <ul>
      <li *ngFor="let tag of tags" class="tags__wrapper-item">
        <div class="tag-content">
          <span>{{ tag }}</span>
        </div>
        <div class="tag-actions">
          <button>&#10761;</button>
        </div>
      </li>
      <li class="tags__wrapper-items-input">
        <input type="text" placeholder="Добавить тег" />
      </li>
    </ul>
  </div>
</div>
