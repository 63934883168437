import { Validators } from "@angular/forms";
import { FormControl } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { IAdminImage, IupdateImageTags } from "./../../models/admin";
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { environment } from "src/environments/environment";
import { TagService } from "src/app/services/admin/tag.service";
import { ViewMode } from "src/app/views/admin/workspace/workspace.component";

@Component({
  selector: "app-admin-image",
  templateUrl: "./admin-image.component.html",
  styleUrls: ["./admin-image.component.scss"],
})
export class AdminImageComponent implements OnInit, OnChanges {
  readonly ViewMode = ViewMode;

  @Input() folderItem!: IAdminImage;
  @Input() activeEssence: string = "Папки";
  @Input() viewMode: ViewMode;
  
  @Output() toggleImage = new EventEmitter();

  isListing: boolean;

  imageSrc?: string = environment.imagesServerUrl + "/";

  form: FormGroup = new FormGroup({
    tags: new FormControl("", [Validators.required, Validators.minLength(1)]),
  });

  constructor(
    private readonly tagService: TagService
  ) {}

  ngOnInit() {
    this.imageSrc = this.imageSrc + "" + this.folderItem.__files__.find((file) => file.format === "MINI")?.imageUrl;
  }

  ngOnChanges() {
    this.isListing  = [ViewMode.List, ViewMode.BiggerList].includes(this.viewMode);
  }

  toggleCheckbox() {   
    this.toggleImage.emit(this.folderItem)
  }

  deleteTag(id: number) {
    this.folderItem.__tags__ = this.folderItem.__tags__.filter(
      (tag) => tag.id !== id
    );
    this.tagService.deleteTag(id, [this.folderItem.id]);
  }

  async addTagToImage() {
    if (this.form.value.tags === "") {
      return;
    }
    const newTag: IupdateImageTags = {
      imageId: this.folderItem.id,
      tagName: this.form.value.tags,
      tagType: "HASHTAG",
    };

    const res = await this.tagService.addTagsToImage(newTag);
    this.form.get("tags")?.patchValue("");
    this.folderItem.__tags__ = res;
  }
}
