import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-prices',
  templateUrl: './prices.component.html',
  styleUrls: ['./prices.component.scss']
})
export class PricesComponent implements OnInit {

  constructor(
    private readonly titleService: Title,
    private readonly metaService: Meta,
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('Цены на фотографии Gelio Photo');
    this.metaService.updateTag({
      content: 'Цены на фото Gelio. Фотографии городов России в хорошем качестве +7 (499) 455-65-90',
    }, 'name=description');
  }
}
