<app-header [isBlack]="true" [isSearch]="true"></app-header>

<div class="photo container">
  <section class="photo__content">
    <div>
      <div
        *ngIf="null !== sourceSearchQuery"
        class="back-button-block"
      >
        <a
          [routerLink]="['/search-page']"
          [queryParams]="{ search: sourceSearchQuery, page: sourceSearchPage }"
          class="back-button-block__link"
        >
          <span class="back-button-block__arrow"></span>
          <span class="back-button-block__text">К результатам поиска</span>
        </a>
      </div>

      <div
        class="photo__block"
      >
        <div class="photo__img-wrapper">
          <img
            appLazyLoad
            (click)="togglePopupVisible()"
            [src]="getImageSrc('ORIGINAL')"
            [alt]="photo.altDescription"
            [title]="photo.imgTitle"
            class="photo__img"
          />
        </div>
        <!-- /.photo__img-wrapper -->

        <div class="photo__information-wrapper">
          <h3 class="photo__title">
            {{ photo.title }}

            <p class="photo__info">
              <span class="photo__id">#{{ photo.id }}</span>

              <a
                [routerLink]="['/search-page']"
                [queryParams]="{ search: photo.coordinates }"
                class="photo__coordinates"
                *ngIf="photo.coordinates"
              >
                <span class="photo__coordinates__location-mark"></span>

                <span class="photo__coordinates__text">{{ photo.coordinates }}</span>
              </a>
            </p>
          </h3>

          <p *ngFor="let license of photo.__prices__" class="photo__license">
            от {{ license.value }} ₽
          </p>

          <div class="photo-btns photo__btns">
            <button
              [item]="photo"
              [isItemInStorage]="checkItemInCart(photo)"
              appButtonState="cart"
              class="photo-btns__button"
            >
              В корзину
            </button>

            <button
              appButtonState="favorite"
              [item]="photo"
              [isItemInStorage]="checkItemInFavorites(photo)"
              class="photo-btns__round-btn photo-btns__round-btn--heart"
              title="Добавить в избранное"
            >
              <svg class="photo-btns__icon photo-btns__icon--heart">
                <use xlink:href="../../../assets/sprite.svg#heart"></use>
              </svg>
            </button>
            <!-- /.photo-btns__round-btn -->

            <button
              (click)="downloadImage(photo.id)"
              class="photo-btns__round-btn photo-btns__round-btn--download"
              title="Скачать изображение"
            >
              <svg class="photo-btns__icon photo-btns__icon--download">
                <use xlink:href="../../../assets/sprite.svg#arrow-download"></use>
              </svg>
            </button>
            <!-- /.photo-btns__round-btn -->
          </div>
          <!-- /.photo-btns -->

          <app-text-with-tooltip
            elementText="Доступное разрешение"
            tooltipText="Все снимки в нашем фотобанке доступны в максимальном разрешении. Разрешение файла влияет на стоимость его использования: вы сможете выбрать доступные варианты с нашим менеджером во время подтверждения заказа."
            tooltipPosition="below"
          ></app-text-with-tooltip>

          <p class="photo__text">{{ photo.description }}</p>
        </div>
        <!-- /.photo__information-wrapper -->
      </div>
    </div>
  </section>

  <app-popup-carousel
    [photos]="[photo]"
    [index]="0"
    [showGoToPhotoControl]="false"
    *ngIf="isPopupPhotoVisible"
    (closePopupCarousel)="togglePopupVisible()"
  ></app-popup-carousel>

  <section class="keywords">
    <h3 class="keywords__title">Ключевые слова</h3>
    <ul class="photo-tags photo__tags">
      <a *ngFor="let tag of getPhotoTags(photo)">
        <li
          (click)="navigateToSearch(tag.name)"
          class="photo-tag photo-tags__tag"
        >
          {{ tag.name }}
        </li>
      </a>
    </ul>
    <!-- /.photo-tags photo__tags -->
  </section>
  <!-- /.keywords -->
</div>

<app-footer></app-footer>
