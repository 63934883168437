import { CompilationCity, CompilationCityType } from './compilation-city';

export class CompilationL2City extends CompilationCity {
  order: number;

  get type(): CompilationCityType {
    return 'l2';
  }

  constructor(
    public readonly l1CityId: number,
    public readonly name: string = '',
  ) {
    super();

    this.name = name;
  }
}
