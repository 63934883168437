<div *ngIf="isFixed" class="spacer"></div>

<header
  class="header"
  [class.header-fixed]="isFixed"
  [class.header-black]="isBlack"
  [class.header-with-search]="isSearch"
>
  <div class="header__wrapper">
    <a
      routerLink="/"
      class="header__logo"
      [class.black]="isBlack"
    >
      <img *ngIf="useWhiteLogo" src="assets/images/logo.white.svg" alt="Gelio" />
      <img *ngIf="!useWhiteLogo" src="assets/images/logo.black.svg" alt="Gelio" />
    </a>

    <div class="header__navbar">
      <a
        *ngFor="let link of navbarLinks"
        [routerLink]="link.href"
        class="header__navlink"
        [class.black]="isBlack"
        >{{ link.label }}</a
      >
    </div>

    <div class="header__content" *ngIf="!isLogged">
      <a
        href="tel:+74994556590"
        class="header__phone"
        [ngClass]="isBlack ? 'black' : ''"
        >+7 (499) 455-65-90</a
      >
      <!-- <button
        class="header__button"
        [routerLink]="'/login'"
        [ngClass]="isBlack ? 'black' : ''"
      >
        Войти
      </button>
      <button
        class="header__button header__button--bordered"
        [routerLink]="'/register'"
        [ngClass]="isBlack ? 'black black-border' : ''"
      >
        Зарегистрироваться
      </button> -->

      <button
        class="header__button header__button--favorite"
        [routerLink]="'/favorites'"
        title="Перейти в избранное"
      >
        <svg class="icon" [ngClass]="isBlack ? 'black-icon' : ''">
          <use xlink:href="../../../assets/sprite.svg#heart-stroke"></use>
        </svg>
        <div
          class="header__button-sign header__button-sign-cart"
          *ngIf="isFavoritesPage"
        ></div>
      </button>

      <button
        class="header__button header__button--cart"
        [routerLink]="'/cart'"
        title="Перейти в корзину"
      >
        <img
          [ngClass]="isBlack ? 'black-icon' : ''"
          src="../../../assets/icons/cart.svg"
          alt="Иконка корзины"
        />
        <span *ngIf="cartCounter" class="header__button--cart-counter">{{
          cartCounter
        }}</span>

        <div
          class="header__button-sign header__button-sign-cart"
          *ngIf="isCartPage"
        ></div>
      </button>

      <button class="menu-btn" (click)="toggleMenu()" title="Открыть меню">
        <svg class="menu-btn__icon" [class.menu-btn__icon--black]="isBlack">
          <use xlink:href="../../../assets/sprite.svg#menu"></use>
        </svg>
      </button>
    </div>
    <!-- /.header__content-not-logged -->

    <div class="header__content" *ngIf="isLogged">
      <a
        href="tel:+74994556590"
        class="header__phone"
        [ngClass]="isBlack ? 'black' : ''"
        >+7 (499) 455-65-90</a
      >
      <button
        class="header__button"
        [routerLink]="'/favorites'"
        [ngClass]="isBlack ? 'black' : ''"
      >
        <svg
          [ngClass]="
            isBlack
              ? 'header__favorite-icon black-icon'
              : 'header__favorite-icon icon'
          "
        >
          <use xlink:href="../../../assets/sprite.svg#heart-stroke"></use>
        </svg>
        Избранное
        <span
          *ngIf="favoritesCounter"
          class="header__button--favorite-counter"
          [ngClass]="isBlack ? 'header__button--favorite-counter-black' : ''"
          >{{ favoritesCounter }}
        </span>
        <div
          class="header__button-sign header__button-sign-favorites"
          *ngIf="isFavoritesPage"
        ></div>
      </button>
      <!-- <button
        class="header__button header__button--bordered header__button--profile"
        [routerLink]="'/cabinet'"
        [ngClass]="isBlack ? 'black black-border' : ''"
        title="Перейти в личный кабинет"
      >
        <svg [ngClass]="isBlack ? 'black-icon' : 'icon'">
          <use xlink:href="../../../assets/sprite.svg#profile"></use>
        </svg>
      </button> -->
      <button
        class="header__button header__button--cart"
        [routerLink]="'/cart'"
        title="Перейти в корзину"
      >
        <img
          [ngClass]="isBlack ? 'black-icon' : ''"
          src="../../../assets/icons/cart.svg"
          alt="Иконка корзины"
        />
        <span class="header__button--cart-counter" *ngIf="cartCounter">{{
          cartCounter
        }}</span>
        <div
          class="header__button-sign header__button-sign-cart"
          *ngIf="isCartPage"
        ></div>
      </button>
      <button
        class="menu-btn"
        (click)="toggleMenu()"
        [ngClass]="isBlack ? 'black-icon' : ''"
        title="Открыть меню"
      >
        <img src="../../../assets/icons/menu.svg" alt="Меню" />
      </button>
    </div>
    <!-- /.header__content-logged-user -->
  </div>

  <app-search
    *ngIf="isSearch"
    [isGray]="isBlack"
    [total]="searchResultsTotal"
    class="header__search"
  ></app-search>

  <div
    [class.active]="isMobileMenuActive"
    class="mobile-menu header__mobile-menu"
  >
    <h4 class="header__logo header__logo--mobile">
      <a routerLink="/">Gelio</a>
    </h4>

    <div>
      <div class="mobile-menu__buttons-wrapper">
        <a
          class="mobile-menu__navlink"
          *ngFor="let link of navbarLinks"
          [routerLink]="link.href"
        >
          {{ link.label }}
        </a>
      </div>

      <!-- <div class="mobile-menu__buttons-wrapper" *ngIf="!isLogged">
        <button
          class="mobile-menu__button header__button header__button--bordered"
          [routerLink]="'/register'"
        >
          Зарегистрироваться
        </button>
        <button
          class="mobile-menu__button header__button"
          [routerLink]="'/login'"
        >
          Войти
        </button>
      </div> -->
      <!-- /.mobile-menu__buttons-wrapper-not-authorised-user -->

      <div class="mobile-menu__buttons-wrapper" *ngIf="isLogged">
        <!-- <button
          class="mobile-menu__button header__button"
          [routerLink]="'/cabinet'"
        >
          Профиль
        </button> -->
        <button
          class="mobile-menu__button header__button"
          [routerLink]="'/favorites'"
        >
          Избранное
          <span *ngIf="favoritesCounter" class="header__button-counter">{{
            favoritesCounter
          }}</span>
        </button>
      </div>
      <!-- /.mobile-menu__buttons-wrapper -->
    </div>

    <a href="tel:+74994556590" class="header__phone header__phone--mobile">
      +7 (499) 455-65-90
    </a>

    <button
      class="mobile-menu__close-btn"
      (click)="toggleMenu()"
      title="Закрыть меню"
    >
      <img src="../../../assets/images/close.svg" alt="Закрыть меню" />
    </button>
    <!-- /.mobile-menu__close-btn -->
  </div>
</header>
