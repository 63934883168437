import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IUser, IOrder, IPasswordChange } from 'src/app/models/models';
import { AuthService } from 'src/app/services/auth.service';
import { OrderService } from 'src/app/services/order.service';
import { UserService } from 'src/app/services/user.service';


@Component({
  selector: 'app-personal-cabinet',
  templateUrl: './personal-cabinet.component.html',
  styleUrls: ['./personal-cabinet.component.scss']
})
export class PersonalCabinetComponent implements OnInit {
  orders: IOrder[] = []
  
  isUserExist = false
  isOldPasswordInvalid: boolean = false
  isErrorVisible: boolean = false
  isSuccessMessageVisible: boolean = false

  isPasswordChangeExpanded: boolean = false

  isPasswordMatch: boolean = true;
  isPasswordVisible: boolean = false;


  userInfo: IUser = {
    id: 0,
    login: "Логин",
    firstName: "Имя",
    lastName: "Фамилия",
    email: "email",
    patronymic: "",
    role: 'user'
  }


  form: FormGroup = new FormGroup({
    login: new FormControl( this.userInfo.login , [Validators.required, Validators.minLength(4)]),
    firstName: new FormControl( this.userInfo.firstName, [Validators.required]),
    lastName: new FormControl( this.userInfo.lastName, [Validators.minLength(3)]),
    email: new FormControl( this.userInfo.email, [Validators.email]),
    oldPassword: new FormControl("", [Validators.required]),
    newPassword: new FormControl("", [Validators.required, Validators.minLength(6), Validators.pattern("[A-Za-z0-9]+")]),
    passwordConfirmation: new FormControl("", [Validators.required]),
  })


  constructor(
    private orderService: OrderService,
    private userService: UserService,
    private authService: AuthService 
  ) { }

 
  ngOnInit(): void {
    this.getAllOrders()
    this.getUserInfo()
    this.isDisabled = true
  }


  async getAllOrders() {
    try {
      let response = await this.orderService.getOrders()
      response.sort(function(a, b) { 
        return b.id - a.id;
      });
      this.orders = response
      
    } catch (error) {
      console.log(error);
    }
  }


  async submit() {
    this.isUserExist = false
    this.isErrorVisible = false
    this.isSuccessMessageVisible = false
    this.isOldPasswordInvalid = false
    
    let newUserInfo = this.makeObjectOfUserInfo()

    // If object is empty, it means that user didn't change any fields or they're invalid, so function end work
    if (!Object.keys(newUserInfo).length) {
      return;
    }

    if (this.isPasswordChangeExpanded) {
      this.saveUserPassword(newUserInfo)
    
    } else {
      this.saveUserInfo(newUserInfo)
    }
  }


  async getUserInfo() {
    
    try {
      this.userInfo = await this.userService.getMe()
      this.form.controls['login'].setValue(this.userInfo.login)
      this.form.controls['firstName'].setValue(this.userInfo.firstName)
      this.form.controls['lastName'].setValue(this.userInfo.lastName)
      this.form.controls['email'].setValue(this.userInfo.email)

    } catch (e) {
      console.log(e);
    }    
  }


  async saveUserInfo(newUserInfo: object) {
    try {
      await this.userService.patchMe(newUserInfo)

      this.isSuccessMessageVisible = true

    } catch (e) {
      this.handleError(e)
    }
  }


  async saveUserPassword(passwordObject: IPasswordChange | object) {
    try {
      await this.authService.changePassword(passwordObject)
      this.isSuccessMessageVisible = true

    } catch (error) {
      this.handleError(error)
    }
  }


  // Creates object with user info? that must be saved and posted to backend
  makeObjectOfUserInfo(): object | IPasswordChange {
    let newUserInfo: any | IPasswordChange = {}

    if (this.isPasswordChangeExpanded) {
      if (this.isPasswordMatch && this.form.valid) {
        return { 
          newPassword: this.form.value.newPassword,
          oldPassword: this.form.value.oldPassword
         }
      }
  
      return { 
        oldPassword: "",
        newPassword: ""
       }
    }


    for (let key of ["login", "firstName", "lastName", "email"]) {
      
      if (this.form.value[key] !== this.userInfo[key] && this.form.value[key]) {
        
        newUserInfo[key] = this.form.value[key]
        this.userInfo[key] = this.form.value[key]
      }
    }

    return newUserInfo
  }


  expandPasswordChange() {
    this.isDisabled = this.isPasswordChangeExpanded
    this.isPasswordChangeExpanded = !this.isPasswordChangeExpanded
  }


  // Disables/enables parts of the user info form
  set isDisabled(value: boolean) {
    
    // Enables/disables form of editing main user info
    for (let key of ["login", "firstName", "lastName", "email"]) {
      if(!value) {
        this.form.controls[key].disable();
        
      } else {
        this.form.controls[key].enable();
      }
    }

    // Enables/disables form of changing user password
    for (let key of ["oldPassword", "newPassword", "passwordConfirmation"]) {
      
      if(value) {
        this.form.controls[key].disable();
        
      } else {
        this.form.controls[key].enable();
      }
    }
   }


  changeInputType() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  checkPasswordMatch() {
    if (
      this.form.get("newPassword")!.value.trim() !==
      this.form.get("passwordConfirmation")!.value
    ) {
      this.isPasswordMatch = false;
      return;
    }

    this.isPasswordMatch = true;
  }


  handleError(error: Error) {
    if (this.isPasswordChangeExpanded) {
      if(error instanceof HttpErrorResponse) {
        switch (error.status) {
          case 409:
            this.isOldPasswordInvalid = true;
            return;
          case 400:
            this.isOldPasswordInvalid = true;
            return;
        }
      }  
    }
    if(error instanceof HttpErrorResponse) {
      switch (error.status) {
        case 409:
          this.isUserExist = true;
          return;
        case 403:
          break;
        case 400:
          break;
      }
    }
    console.log(error);
    this.isErrorVisible = true
  }
}
