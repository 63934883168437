<app-header [isBlack]=true [isSearch]=true ></app-header>

<div class="registration container">
    <div class="authorization__content">
      <form
        (ngSubmit)="submit()"
        ngNativeValidate
        class="form authorization__form"
      >


        <h3 class="form__title">Смена пароля</h3>

        <div class="form__input-wrapper">
          <input 
            [type]="isPasswordVisible? 'text' : 'password'" 
            [(ngModel)]="password"
            name="password"
            class="form__input"
            [class.active]="password.length > 0" 
            required
            minlength="8"
          />
          <small class="form__text" >Пароль</small>
          <div class="form__icons-wrapper" [ngSwitch]="isPasswordVisible">
            <svg
              *ngSwitchCase="false"
              (click)="togglePasswordVisibility()"
              class="form__eye-icon"
            >
              <use xlink:href="../../../assets/sprite.svg#eye-on"></use>
            </svg>
            <svg
              *ngSwitchCase="true"
              (click)="togglePasswordVisibility()"
              class="form__eye-icon"
            >
              <use xlink:href="../../../assets/sprite.svg#eye-off"></use>
            </svg>
          </div>
        </div>
        <!-- /.form__input-wrapper -->

        <div class="form__input-wrapper">
          <input 
            [type]="isRepeatPasswordVisible? 'text' : 'password'" 
            [(ngModel)]="repeatPassword"
            (input)="checkIfPasswordsMatchAndAlertIfNot()"
            name="password repeat"
            class="form__input"
            [ngClass]="repeatPassword.length? 'active': ''" 
            required
            minlength="8"
          />
          <small class="form__text" >Повторите пароль</small>
          <div class="form__icons-wrapper" [ngSwitch]="isRepeatPasswordVisible">
            <svg
              *ngSwitchCase="false"
              (click)="toggleRepeatPasswordVisibility()"
              class="form__eye-icon"
            >
              <use xlink:href="../../../assets/sprite.svg#eye-on"></use>
            </svg>
            <svg
              *ngSwitchCase="true"
              (click)="toggleRepeatPasswordVisibility()"
              class="form__eye-icon"
            >
              <use xlink:href="../../../assets/sprite.svg#eye-off"></use>
            </svg>
          </div>
        </div>
        <!-- /.form__input-wrapper -->
        
        <p [style.color]="'red'" *ngIf="isAlertVisible"><small>{{ alertText }}</small></p>

        <button
          [disabled]="!arePasswordsCorrect"
          type="submit"
          class="btn form__submit-button"
        >
          Изменить пароль
        </button>
      </form>
      <!-- /.form login__form -->
    </div>
    <!-- /.login__content -->
  </div>

<app-footer></app-footer>

<app-success-popup
  [isOpen]="isSuccessPopupVisible"
  message="Вы успешно изменили пароль"
  buttonText="Вернуться на главную"
  link="/"
></app-success-popup>