import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { INavbarLink } from "src/app/models/navbar-link";
import { AuthService } from "src/app/services/auth.service";
import { CartService } from "src/app/services/cart.service";
import { FavoriteService } from "src/app/services/favorite.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  isMobileMenuActive: boolean = false;

  @Input() useWhiteLogo = false;
  @Input() isBlack = false;
  @Input() isSearch = false;
  @Input() isDivider = true;
  @Input() isFixed = false;
  @Input() searchResultsTotal: number;

  // Used to sign on what page user
  isCartPage = false;
  isFavoritesPage = false;

  cartCounter = 0;
  favoritesCounter = 5;

  //subscriber = this.cartService.onCartChange

  isLogged = false;

  navbarLinks: INavbarLink[] = [
    {
      label: "Каталог",
      href: "/catalog",
    },
    {
      label: "О фотостоке",
      href: "/about",
    },
    {
      label: "Цены",
      href: "/prices",
    },
    {
      label: "Подборки",
      href: "/blog",
    },
    {
      label: "Как купить",
      href: "/how-to-buy",
    },
    {
      label: "Сотрудничество",
      href: "/partnership",
    },
    {
      label: "Контакты",
      href: "/contacts",
    },
  ];

  constructor(
    private auth: AuthService,
    private favoriteService: FavoriteService,
    private router: Router,
    private cartService: CartService
  ) {}

  ngOnInit(): void {
    this.isLogged = this.auth.isLogged();

    if (
      this.router.url.includes("cart") ||
      this.router.url.includes("favorites")
    ) {
      this.router.url.includes("cart")
        ? (this.isCartPage = true)
        : (this.isFavoritesPage = true);
    }

    this.getCartCounter();
    this.getFavoritesCounter();

    this.cartService.onCartChange.subscribe(() => {
      this.getCartCounter();
    });

    this.favoriteService.onFavoritesChange.subscribe(() => {
      this.getFavoritesCounter();
    });
  }

  logout() {
    this.auth.logOut();
  }

  toggleMenu(): void {
    this.isMobileMenuActive = !this.isMobileMenuActive;
    const body = document.body;
    if (this.isMobileMenuActive) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }
  }

  getCartCounter() {
    this.cartCounter = this.cartService.getCartCounter();
  }

  getFavoritesCounter() {
    this.favoritesCounter = this.favoriteService.getFavoritesCounter();
  }
}
