import { Direction } from './sorting-model';

const numericNameRexp = /^(\d+)$/;
const numericPrefixRexp = /^(\d+)_/;
const numericSuffixRexp = /_(\d+)$/;

export function filenameComparator(a: string, b: string, direction: Direction): number {
  // Try to compare by numeric name/prefix/suffix number
  const aName = a.trim();
  const bName = b.trim();
  const aPrefix = numericPrefixRexp.exec(aName) || numericNameRexp.exec(aName);
  const aSuffix = numericSuffixRexp.exec(aName);
  const bPrefix = numericPrefixRexp.exec(bName) || numericNameRexp.exec(bName);
  const bSuffix = numericSuffixRexp.exec(bName);

  // items with numeric names & number-prefixed items go first
  // number-suffixed go second
  // all other go after
  if (aPrefix && bPrefix) {
    return (+aPrefix[1] - +bPrefix[1]) * direction;
  } else if (aSuffix && bSuffix) {
    return (+aSuffix[1] - +bSuffix[1]) * direction;
  } else if (aPrefix) {
    return -1;
  } else if (bPrefix) {
    return +1;
  } else if (aSuffix) {
    return -1;
  } else if (bSuffix) {
    return +1;
  }

  // Items without numeric prefix/suffix always go after
  const stringCmpResult = aName.normalize().localeCompare(bName.normalize());

  return stringCmpResult * direction;
}
