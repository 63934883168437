import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: "app-geo-catalog-page",
  templateUrl: "./geo-catalog-page.component.html",
  styleUrls: ["./geo-catalog-page.component.scss"],
})
export class GeoCatalogPageComponent implements OnInit {
  constructor(
    private readonly titleService: Title,
    private readonly metaService: Meta,
  ) {}

  public ngOnInit(): void {
    this.titleService.setTitle('Каталог фотографий от Gelio Photo');
    this.metaService.updateTag({
      content: 'Фотографии городов России в хорошем качестве +7 (499) 455-65-90',
    }, 'name=description');
  }
}
