<div class="geo-catalog">
    <div class="geo-catalog__header">
        <ul class="geo-catalog__macroregions">
            <li
                (click)="selectCountry('Россия')"
                class="geo-catalog__macroregions-item"
                [class.selected]="selectedMacroregion === null"
                [class.clickable]="selectedMacroregion !== null"
            >
                Россия
            </li>

            <li
                *ngFor="let macroregion of macroregions"
                (click)="selectMacroregion(macroregion)"
                class="geo-catalog__macroregions-item geo-catalog__macroregion"
                [class.selected]="selectedMacroregion === macroregion"
                [class.clickable]="selectedMacroregion !== macroregion"
            >
                {{macroregion}}
            </li>
        </ul>

        <ul class="geo-catalog__view-mode-selector">
            <li
                (click)="selectViewMode(ViewMode.Provinces)"
                class="geo-catalog__view-mode-option"
                [class.selected]="ViewMode.Provinces === viewMode"
                [class.clickable]="ViewMode.Provinces !== viewMode"
            >
                Субъекты РФ
            </li>

            <li
                (click)="selectViewMode(ViewMode.Cities)"
                class="geo-catalog__view-mode-option"
                [class.selected]="ViewMode.Cities === viewMode"
                [class.clickable]="ViewMode.Cities !== viewMode"
            >
                Города
            </li>
        </ul>
    </div>

    <ul class="geo-catalog__breadcrumbs">
        <li
            *ngIf="selectedMacroregion || selectedProvince"
            class="geo-catalog__breadcrumbs-item"
        >
            <span (click)="selectCountry('Россия')" class="clickable">Россия</span>
        </li>

        <li
            *ngIf="selectedMacroregion"
            class="geo-catalog__breadcrumbs-item"
        >
            <span (click)="selectMacroregion(selectedMacroregion)" class="clickable">{{selectedMacroregion}}</span>
        </li>

        <li
            *ngIf="selectedProvince"
            class="geo-catalog__breadcrumbs-item"
        >
            <span (click)="selectProvince(selectedProvince)" class="clickable">{{selectedProvince}}</span>
        </li>
    </ul>

    <ul class="geo-catalog__list">
        <li
            *ngFor="let group of getListGroups()"
            class="geo-catalog__list-group"
        >
            <h3 class="geo-catalog__list-group-header">{{group.letter}}</h3>

            {{ '' // L1 items  }}
            <ul class="geo-catalog__list-group-items l1">
                <li
                    *ngFor="let item of group.items"
                    class="geo-catalog__list-item l1"
                >
                    {{ '' // "a" if there's real URL assigned  }}
                    <a
                        *ngIf="item.href"
                        (click)="item.onClick && item.onClick($event)"
                        href="{{item.href}}"
                        [title]="item.title"
                        class="geo-catalog__list-item-name"
                        [class.clickable]="item.isActive"
                        [class.link]="item.isActive"
                    >
                        {{item.text}}
                    </a>

                    {{ '' // "span" if no URL assigned  }}
                    <span
                        *ngIf="!item.href"
                        (click)="item.onClick && item.onClick($event)"
                        [title]="item.title"
                        class="geo-catalog__list-item-name"
                        [class.clickable]="item.isActive"
                        [class.link]="item.isActive"
                        [class.link_local]="item.isActive"
                    >
                        {{item.text}}
                    </span>

                    {{ '' // L2 items (child L2 cities of L1 cities) }}
                    <ul
                        *ngIf="ItemType.Province !== item.type && item.children.length"
                        class="geo-catalog__list-group-items l2"
                    >
                        <li
                            *ngFor="let item of (item.children | orderBy: 'order')"
                            class="geo-catalog__list-item l2"
                        >
                            <a
                                *ngIf="item.href"
                                (click)="item.onClick && item.onClick($event)"
                                href="{{item.href}}"
                                [title]="item.title"
                                class="geo-catalog__list-item-name"
                                [class.clickable]="item.isActive"
                                [class.link]="item.isActive"
                            >
                                {{item.text}}
                            </a>
                            
                            <span
                                *ngIf="!item.href"
                                (click)="item.onClick && item.onClick($event)"
                                [title]="item.title"
                                class="geo-catalog__list-item-name"
                                [class.clickable]="item.isActive"
                                [class.link]="item.isActive"
                                [class.link_local]="item.isActive"
                            >
                                {{item.text}}
                            </span>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
    </ul>
</div>