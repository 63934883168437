import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  token = ""
  alertText = ""

  password = ""
  repeatPassword = ""

  isPasswordVisible = false
  isRepeatPasswordVisible = false

  isAlertVisible = false
  isSuccessPopupVisible = false

  get arePasswordsCorrect() {
    return this.password.length >= 8 && this.repeatPassword.length >= 8 && this.password === this.repeatPassword
  }

  constructor(
    private readonly authService: AuthService,
    private readonly activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.token = this.activatedRoute.snapshot.params['token']
  }

  async submit() {
    if (this.token == null || this.token.length < 1) {
      this.alertText = "Ссылка не валидна"
      this.isAlertVisible = true
      return
    }

    try {
      await this.authService.resetPassword(this.token, this.password)
      this.isSuccessPopupVisible = true
      // this.router.navigate(['/cabinet'])

    } catch (error: any) {
      this.handleError(error)
    }
  }

  togglePasswordVisibility() {    
    this.isPasswordVisible = !this.isPasswordVisible
  }

  toggleRepeatPasswordVisibility() {    
    this.isRepeatPasswordVisible = !this.isRepeatPasswordVisible
  }

  checkIfPasswordsMatchAndAlertIfNot() { 
    if (this.password !== this.repeatPassword) {
      this.isAlertVisible = true
      this.alertText = "Пароли не совпадают"
    } else {
      this.isAlertVisible = false
    }
  }

  private handleError(error: HttpErrorResponse) {
    if(error.status === 404) {
      this.alertText = "Токен не найден"
    } else if (error.status === 409) {
      this.alertText = "Ссылка просрочена"
    }

    this.isAlertVisible = true
  }
}
