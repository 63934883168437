import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IupdateImageTags, Tag, IupdateCompilationTags, IUpdateImagesTag, NewTags, ISeoInfo } from 'src/app/models/admin';
import { IAPIResponse } from 'src/app/models/models';
import { BaseRequestService } from '../base-request.service';

@Injectable({
  providedIn: 'root'
})
export class TagService {

  constructor(
    private readonly requestService: BaseRequestService
  ) { }

  async getTags(imageId?: Number): Promise<Tag[]> {
    let queryParams = new HttpParams();
    let queryString = '';

    if (imageId) {
      queryParams = queryParams.set('imageId', String(imageId));
    }
    if (0 < queryParams.keys().length) {
      queryString = '?' + queryParams.toString();
    }
    return this.requestService.get<Tag[]>(`/tags${queryString}`);
  }

  async addTagsToImage(tagsData: IupdateImageTags): Promise<Tag[]> {
    return this.requestService.post<Tag[]>(`/tags/addToImage`, tagsData);
  }

  addTagToCompilation(tagsData: IupdateCompilationTags): Promise<Tag[]> {
    return this.requestService.post<Tag[]>(`/tags/addToCompilation`, tagsData);
  }

  async addTagToSeveralImages(tagsData: IUpdateImagesTag): Promise<NewTags[]> {
    const response = await this.requestService.post<any>(`/tags/addToImages`, tagsData);
    return response.success;
  }

  deleteTag(tagId: number, imageIds: number[]) {
    return this.requestService.delete(`/tags/images/${tagId}`, { imageIds: imageIds, tagType: "HASHTAG" });
  }

  async updateTags(tagId: number, images: any): Promise<ISeoInfo> {
    const response = await this.requestService.patch<IAPIResponse<ISeoInfo>>(`/tags/${tagId}`, images)
    return response.data;
  }
}
