<app-header [isBlack]="true" [isSearch]="true"></app-header>
<section class="error">
  <div class="error__wrapper">
    <h1>Страница не найдена</h1>
    <p>
      Страница, которую вы запрашиваете, не существует. Возможно, она устарела,
      была удалена или был введен неверный адрес в адресной строке.
    </p>
    <a routerLink="/" class="btn">Перейти на главную страницу</a>
  </div>
  <!-- <img src="../../../assets/images/404.png" alt="" class="error__bg" /> -->
</section>
<app-footer></app-footer>
