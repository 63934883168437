<!-- Prev page switcher -->
<span
  (click)="pageSelected.emit(current - 1)"
  class="page-switcher prev"
  [class.inactive]="current < 2"
  title="Назад"
></span>

<!-- Always show first -->
<app-paginator-bullet
  [page]="1"
  [isCurrent]="current === 1"
  (click)="pageSelected.emit(1)"
></app-paginator-bullet>

<!-- Start ellipsis -->
<app-paginator-bullet
  *ngIf="showStartEllipsis"
  text="..."
  class="ellipsis"
></app-paginator-bullet>

<!-- Show page 2 if the 'window' adjoins it, i.e. starts from 3. It's non-practical to show ellipsis instead in this case. -->
<app-paginator-bullet
  *ngIf="total > 1 && windowStart > 2 && !showStartEllipsis"
  [page]="2"
  [isCurrent]="current === 2"
  (click)="pageSelected.emit(2)"
></app-paginator-bullet>

<!-- Pages in the 'window' -->
<app-paginator-bullet
  *ngFor="let page of windowPages"
  [page]="page"
  [isCurrent]="current === page"
  (click)="pageSelected.emit(page)"
></app-paginator-bullet>

<!-- End ellipsis -->
<app-paginator-bullet
  *ngIf="showEndEllipsis"
  text="..."
></app-paginator-bullet>

<!-- Show second to last if the 'window' adjoins it, i.e. starts from total - 2. It's non-practical to show ellipsis instead in this case. -->
<app-paginator-bullet
  *ngIf="total > 2 && windowEnd < total - 1 && !showEndEllipsis"
  [page]="total - 1"
  [isCurrent]="current === total - 1"
  (click)="pageSelected.emit(total - 1)"
></app-paginator-bullet>

<!-- Always show last -->
<app-paginator-bullet
  *ngIf="total > 1"
  [page]="total"
  [isCurrent]="current === total"
  (click)="pageSelected.emit(total)"
></app-paginator-bullet>

<!-- Next page switcher -->
<span
  (click)="pageSelected.emit(current + 1)"
  class="page-switcher next"
  [class.inactive]="current == total"
  title="Вперёд"
></span>
