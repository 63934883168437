import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ConfirmModalComponent, ConfirmModalData } from './confirm-modal.component';

@Injectable({
  providedIn: 'root'
})
export class AdminModalsService {

  constructor(
    private matDialog: MatDialog,
  ) {}

  confirm(text: string, title: string = null, data?: ConfirmModalData): Promise<boolean> {
    const ref = this.matDialog.open(ConfirmModalComponent, {
      data: {
        ...data,
        title: title ?? data.title,
        text: text ?? data.text,
      },
    });

    return ref.afterClosed().toPromise<boolean>();
  }
}
