import { IAdminImage, ICompilationInfo, ICompilationSeo, ICreateCompilation, ISeoInfo, Tag } from 'src/app/models/admin';
import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AdminCompilationService } from 'src/app/services/admin/admin-compilation.service';
import { from } from 'rxjs';
import { delay } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Language } from '@app/enums/language';
import { Compilation } from '../admin-compilations-component/compilation';
import { pick } from 'lodash';
import { MatChipInputEvent } from '@angular/material/chips';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IImage } from '@app/models/image';
import { UploadService } from '@app/services/admin/upload.service';
import { ImageSourceService } from '@app/services/image-source.service';

export type AdminCompilationModalData = {
  compilationId: Compilation['id'],
}

/**
 * UI for editing a compilation
 */
@Component({
  selector: 'app-admin-compilation-modal',
  templateUrl: './admin-compilation.modal.component.html',
  styleUrls: ['./admin-compilation.modal.component.scss'],
})
export class AdminCompilationModalComponent {
  public readonly Language = Language;

  private compilationId!: Compilation['id'];

  @Output() compilationChanged = new EventEmitter<Compilation>();

  public form: FormGroup = null;

  private _isLoading = false;

  public set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }

  public get isLoading() {
    return this._isLoading;
  }

  private seoLanguages = [
    Language.RU,
    Language.EN,
  ];

  public selectedSeoLang: string = Language.RU;

  public readonly currentTags: string[] = [];

  public coverSource: SafeResourceUrl;

  private _currentCoverImage?: IAdminImage;
  private set currentCoverImage(image: IAdminImage) {
    this._currentCoverImage = image;
    this.coverSource = this.imageSourceService.returnUrl(image, 'ORIGINAL');
  }
  private get currentCoverImage() {
    return this._currentCoverImage;
  }

  private _selectedCoverFile?: File;
  private set selectedCoverFile(file: File | undefined) {
    this._selectedCoverFile = file;
    if (undefined === file) {
      return;
    }

    const objectUrl = URL.createObjectURL(file);
    this.coverSource = this.sanitizer.bypassSecurityTrustResourceUrl(objectUrl);
  }
  private get selectedCoverFile() {
    return this._selectedCoverFile;
  }

  constructor(
    public dialogRef: MatDialogRef<AdminCompilationModalComponent, ICompilationInfo | undefined>,
    @Inject(MAT_DIALOG_DATA)
    private readonly data: AdminCompilationModalData,
    private readonly compilationService: AdminCompilationService,
    private readonly fb: FormBuilder,
    private readonly sanitizer: DomSanitizer,
    private readonly uploadService: UploadService,
    private readonly imageSourceService: ImageSourceService,
  ) {
      this.compilationId = data.compilationId;
      this.form = this.buildCompilationForm();

      dialogRef.beforeClosed().subscribe((updatedCompilation) => {
        if (!updatedCompilation && this.form.touched) {
          console.warn('Closing the dialog without saving');
        }
      });
  }

  public async ngOnInit() {
    this.isLoading = true;
    const infoPromise = this.compilationService.getCompilationInfo(this.compilationId);
    const info$ = from(infoPromise);
    info$.pipe(delay(1000)).subscribe(() => this.isLoading = false);
    const compilationData = await infoPromise;
    this.setCompilationData(compilationData);
  }

  private buildCompilationForm(): FormGroup {
    const form = this.fb.group({
      title: ['', [
        Validators.required,
        Validators.minLength(2),
      ]],
      description: ['', [
        Validators.minLength(10),
      ]],
      shortDescription: ['', [
        Validators.minLength(10),
      ]],
      published: [false],
      onMainPage: [false],
      // Only added to the form to detect compilation changes in a uniform way
      tags: this.fb.control(''),
      coverId: [null],
      friendlyURL: ['', [
        Validators.minLength(2),
      ]],
    });

    // Build a SEO form for each language
    const seoInfosForm = this.fb.group({});
    this.seoLanguages.forEach((lang) => {
      const seoForm = this.buildSeoForm();
      seoInfosForm.registerControl(lang, seoForm);
    });
    form.registerControl('seo', seoInfosForm);

    return form;
  }

  private buildSeoForm(seoInfo?: ICompilationSeo): FormGroup {
    const form = this.fb.group({
      title: ['', [
        Validators.minLength(2),
      ]],
      description: ['', [
        Validators.minLength(10),
      ]],
    })

    seoInfo && form.patchValue(seoInfo);

    return form;
  }

  private buildTagForm(tag?: Tag): FormGroup {
    const form = this.fb.group({
      id: [null],
      name: ['', [
        Validators.required,
        Validators.minLength(2),
      ]],
    });

    tag && form.patchValue(tag);

    return form;
  }

  private setCompilationData(compilation: ICompilationInfo): void {
    // Compilation attrs
    const compilationData = pick(compilation, [
      'title',
      'description',
      'shortDescription',
      'published',
      'onMainPage',
      'friendlyURL',
    ]);
    this.form.patchValue(compilationData);

    // Compilation tags
    if (compilation.__tags__?.length) {
      compilation.__tags__.forEach((tag) => {
        // const tagForm = this.buildTagForm(tag);
        // const tagsFormArray = this.form.get('tags') as FormArray;
        // tagsFormArray.push(tagForm);
        this.currentTags.push(tag.name);
      });
    }

    // Compilation SEO
    if (compilation.__seoInfo__?.length) {
      compilation.__seoInfo__.forEach((seoInfo) => {
        const seoForm = this.form.get(['seo', seoInfo.language]);
        if (null === seoForm) {
          console.warn(`No SEO form for the language ${seoInfo.language} is built`);
          return;
        }

        seoForm.patchValue(seoInfo);
      });
    }

    // Compilation cover
    if (compilation.__cover__) {
      this.currentCoverImage = compilation.__cover__;
      this.form.get('coverId').setValue(compilation.__cover__.id);
    }
  }

  public getSeoFormControl(name: string): FormControl {
    return this.form.get(['seo', this.selectedSeoLang, name]) as FormControl;
  }

  public addTag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      this.currentTags.push(value);
      this.updateTagsControl();
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  public removeTag(idx: number): void{
    this.currentTags.splice(idx, 1);
    this.updateTagsControl();
  }

  private updateTagsControl(): void {
    const tagsCtrl = this.form.get('tags');
    tagsCtrl.setValue(this.currentTags.join(', '));
    tagsCtrl.markAsDirty();
  }

  public async save(): Promise<void> {
    if (this.form.invalid) {
      console.error('Attempt to save an invalid form');
      return;
    }

    this.form.disable();

    // Save the cover
    if (undefined !== this.selectedCoverFile) {
      const coverImage: IImage = await this.saveCover();
      this.form.get('coverId').setValue(coverImage.id);
    }

    const data = this.prepareData();

    const updatedCompilation = await this.compilationService.updateCompilation(this.compilationId, data);
    this.dialogRef.close(updatedCompilation);
  }

  private prepareData(): any {
    const formValues = this.form.value;
    // Compilations attrs
    const data: ICreateCompilation = pick(formValues, [
      'title',
      'description',
      'shortDescription',
      'published',
      'onMainPage',
      'coverId',
      'friendlyURL',
    ]);
    // Tags
    data.tags = this.currentTags;
    // SEO
    data.seo = Object.keys(formValues.seo).map((key) => (
      {
        ...formValues.seo[key],
        ...{
          language: key,
        },
      }
    ));

    return data;
  }

  public onSwitchClick(controlName: string): void {
    const control = this.form.get(controlName);
    control.setValue(!control.value);
    control.markAsDirty();
    control.markAsTouched();
  }

  public onCoverInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (0 === input.files.length) {
      this.resetCoverImageFile();
      return;
    }

    this.setCoverImageFile(input.files[0]);
  }

  private setCoverImageFile(file: File): void {
    this.selectedCoverFile = file;

    const coverIdCtrl = this.form.get('coverId');
    coverIdCtrl.setValue(null);
    coverIdCtrl.markAsDirty();
    coverIdCtrl.markAsTouched();
  }

  private resetCoverImageFile(): void {
    this.selectedCoverFile = undefined;

    const coverIdCtrl = this.form.get('coverId');
    coverIdCtrl.reset();
  }

  private async saveCover(): Promise<IImage> {
    if (undefined === this.selectedCoverFile) {
      throw new Error('No cover file selected');
    }

    const formData = new FormData();
    formData.append('images', this.selectedCoverFile);
    const uploadResult = await this.uploadService.uploadImages(formData).toPromise();
    return uploadResult.body.data.images[0];
  }
}
