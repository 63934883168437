<app-header [isBlack]="true" [isSearch]="true"></app-header>

<div class="cart">
  <div
    class="container cart__container"
    *ngIf="images.length > 0 && !isPurchaseSuccess"
  >
    <div class="cart__text-wrapper">
      <h2 class="cart__title">Корзина</h2>
      <p>
        Стоимость фотографии зависит от вида использования / носителя. Итоговую
        стоимость вы сможете узнать у наших менеджеров в момент подтверждения
        заказа
      </p>
    </div>
    <!-- /.cart__text-wrapper -->
    <div class="cart__content-wrapper">
      <div class="cart__items-wrapper">
        <app-cart-item
          (onDelete)="deleteCartItem($event.id)"
          *ngFor="let item of images"
          [photo]="item"
        ></app-cart-item>
      </div>
      <!-- /.cart__content -->
      <form class="cart-form cart__form" [formGroup]="form">
        <div class="cart-form__total-info">
          <div class="cart-form__heads">
            <h5 class="cart-form__head">В заказе</h5>
            <h5 class="cart-form__head">на сумму</h5>
          </div>
          <!-- /.carft-form__heads -->
          <div class="cart-form__values">
            <legend class="cart-form__value">{{ numberOfItems }}</legend>
            <legend class="cart-form__value">
              от {{ totalPrice || "0" }} ₽
            </legend>
          </div>
          <!-- /.carft-form__heads -->

          <div class="cart-form__details">
            <app-text-with-tooltip
              elementText="подробнее"
              tooltipText="Здесь указана стоимость для минимального
              вида использования фотографии. Итоговую стоимость
              вы сможете узнать у наших менеджеров
              в момент подтверждения заказа."
              tooltipPosition="below"
            ></app-text-with-tooltip>
          </div>
        </div>
        <!-- /.cart-form__total-info -->
        <div class="cart-form__inputs">
          <div class="cart-form__input-tile">
            <div class="form__input-wrapper">
              <input
                type="text"
                formControlName="name"
                class="form__input cabinet-form__input cart-form__input"
                [ngClass]="name.value.length ? 'active' : ''"
                #name
              />
              <small class="form__text form__text-centered">Имя</small>
            </div>
            <!-- <input type="text" class="cart-form__input" />
            <h5 class="cart-form__input-title">Имя</h5> -->
          </div>
          <!-- /.cart-form__input-tile -->
          <div class="cart-form__input-tile">
            <div class="form__input-wrapper">
              <input
                type="text"
                formControlName="phone"
                class="form__input cabinet-form__input cart-form__input"
                (keypress)="numberOnly($event)"
                [ngClass]="phone.value.length ? 'active' : ''"
                #phone
              />
              <small class="form__text form__text-centered"
                >Номер телефона</small
              >
            </div>

            <!-- <input type="tel" class="cart-form__input" />
            <h5 class="cart-form__input-title">Номер телефона</h5> -->
          </div>
          <!-- /.cart-form__input-tile -->

          <p
            *ngIf="form.get('phone')?.invalid && form.get('phone')?.touched"
            class="form__error"
          >
            Введите корректный телефон
          </p>

          <div class="cart-form__input-tile">
            <div class="form__input-wrapper">
              <input
                type="email"
                formControlName="email"
                class="form__input cabinet-form__input cart-form__input"
                [ngClass]="email.value.length ? 'active' : ''"
                #email
              />
              <small class="form__text form__text-centered">Email</small>
            </div>

            <!-- <input type="email" class="cart-form__input" />
            <h5 class="cart-form__input-title">Email</h5> -->
          </div>
          <p
            *ngIf="
              (form.get('email')?.invalid && form.get('email')?.touched) ||
              isEmailInvalid
            "
            class="form__error cart-form__alert"
          >
            Введите корректный email
          </p>
          <p class="form__error cart-form__alert" *ngIf="isAlertVisible">
            {{ alertText }}
          </p>

          <!-- /.cart-form__input-tile -->
        </div>
        <!-- /.cart-form__inputs -->

        <!-- <p class="cart-form__link">
          <a routerLink="/login" *ngIf="isAuthLinkVisible">
            <small>У меня есть аккаунт</small>
          </a>
        </p>

        <p class="cart-form__postscript">
          Стоимость фотографии зависит от вида использования / носителя.
          Итоговую стоимость вы сможете узнать у наших менеджеров в момент
          подтверждения заказа
        </p> -->

        <div class="cart-form__checkbox-wrapper">
          <label class="cart-form__checkbox">
            <input type="checkbox" formControlName="isEntity" #isEntity />
            Оплата по счёту для юр.лиц
          </label>

          <label class="cart-form__checkbox">
            <input
              type="checkbox"
              formControlName="isAgreeWithPolicy"
              #isAgreeWithPolicy
            />
            Согласен с обработкой персональных данных и пользовательским
            соглашением
          </label>
        </div>

        <button
          class="btn cart-form__button"
          [disabled]="form.invalid || !images.length"
          (click)="submitOrder()"
        >
          Заказать
        </button>
      </form>
      <!-- /.cart-form cart__form -->
    </div>
  </div>

  <div class="container cart__loader" *ngIf="isItemsLoading">
    <img src="../../../assets/icons/loader.png" alt="" />
    <p>Загрузка корзины...</p>
  </div>

  <div
    class="container cart__empty"
    *ngIf="images.length === 0 && !isItemsLoading"
  >
    <h2>Корзина пуста</h2>
    <p>
      Воспользуйтесь поиском или найдите интересующее фото в
      <a [routerLink]="['/']">каталоге</a>, а затем добавьте его в корзину.
    </p>
  </div>

  <div class="container success" *ngIf="isPurchaseSuccess">
    <div class="success__content-wrapper">
      <div class="success__image">
        <img src="../../../assets/images/purchase-success.png" alt="" />
      </div>
      <div class="success__text-wrapper">
        <h2 class="success__title">Спасибо за ваш заказ!</h2>
        <p class="success__text">
          Ваша заявка принята и уже поступила к нам. Обычно мы отвечаем в
          течение ближайшего рабочего дня. Мы отправили подтверждение заказа на
          вашу почту, указанную при оформлении заказа. Пожалуйста, проверьте
          также папку спам
        </p>
      </div>
    </div>
    <div class="success__content-wrapper">
      <div class="success__contacts-wrapper">
        <p class="success__text">
          В случае если мы не связались с вами или вам нужно очень-очень срочно,
          вы всегда можете связаться с нами:
        </p>
        <div class="success__contacts-list">
          <div class="success__contacts-item">
            <img
              src="../../../assets/icons/phone-dark.svg"
              alt="Способ связи: Иконка телефона"
            />
            <a
              href="tel:{{ companyContacts?.tel }}"
              rel="noreferrer noopener"
            >
            {{ companyContacts?.tel }}
            </a>
          </div>
          <div class="success__contacts-item">
            <img
              src="../../../assets/icons/mail-dark.svg"
              alt="Способ связи: Иконка электронной почты"
            />
            <a
              href="mailto:{{ companyContacts?.email }}"
              rel="noreferrer noopener"
            >
              {{ companyContacts?.email }}
            </a>
          </div>
          <div class="success__contacts-item">
            <img
              src="../../../assets/icons/telegram.svg"
              alt="Способ связи: Иконка телеграма"
            />
            <a
              href="https://t.me/{{ companyContacts?.tg }}"
              target="_tg"
              rel="noreferrer noopener"
            >
              Телеграм
            </a>
          </div>
          <div class="success__contacts-item">
            <img
              src="../../../assets/icons/whatsapp.svg"
              alt="Способ связи: Иконка whatsapp"
            />
            <a
              href="https://wa.me/{{ companyContacts?.wa }}"
              target="_wa"
              rel="noreferrer noopener"
            >
              Whatsapp
            </a>
          </div>
        </div>
        <a routerLink="/"> Вернуться на главную </a>
      </div>
    </div>
  </div>
</div>
<!-- /.cart -->

<app-footer></app-footer>
