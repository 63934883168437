import { Component, Input, OnInit, Output } from "@angular/core";
import { ModalService } from "src/app/services/admin/modal.service";
import { UserService } from "src/app/services/user.service";
import { EventEmitter } from "@angular/core";
import { AdminService } from "src/app/services/admin/admin.service";
import { HelperService } from "src/app/services/admin/helper.service";
import { Router } from "@angular/router";
import { CompilationService } from "src/app/services/compilation.service";
import { FolderService } from "src/app/services/admin/folder.service";
import { AdminCompilationService } from "src/app/services/admin/admin-compilation.service";
import { ThisReceiver } from "@angular/compiler";

@Component({
  selector: "app-delete-modal",
  templateUrl: "./delete-modal.component.html",
  styleUrls: ["./delete-modal.component.scss"],
})
export class DeleteModalComponent implements OnInit {

  @Input() modalContent: string;
  @Input() deletingItemsIds: number[]

  @Input() compilationId: number
  @Input() folderId: number

  @Output() close = new EventEmitter()
  @Output() error = new EventEmitter()

  endWord = "выбранную фотографию";

  constructor(
    private modalService: ModalService,
    private adminService: AdminService,
    private helperService: HelperService,
    private userService: UserService,
    private readonly router: Router,
    private readonly compilationService: AdminCompilationService,
    private readonly folderService: FolderService
  ) { }

  ngOnInit(): void {
    if (this.modalContent == null) {
      this.modalContent = this.adminService.getDeleteModalContent
    }

    if (this.deletingItemsIds == null) {
      this.deletingItemsIds = this.adminService.deletingItemsIds
    }

    this.endWord = this.helperService.deleteModalContentGenerator(this.modalContent);
  }

  async onDelete() {
    if (this.deletingItemsIds == null || this.deletingItemsIds.length < 1) {
      return
    }

    switch (this.modalContent) {
      case "Folder":
        for (const id of this.deletingItemsIds) {
          await this.folderService.deleteFolder(id);
        }
        this.router.navigateByUrl("workspace/folders/1");
        break;
      case "Image":
      case "Images":
        await this.adminService.deleteImage(this.deletingItemsIds)
        break;
      case "Compilation":
        await this.compilationService.deleteCompilation(this.deletingItemsIds[0]);
        break;
      case "User":
        await this.userService.deleteUser()
        this.modalService.closeEditUserModal()
        break;
      case "ImagesFromCompilation":
        for (const id of this.deletingItemsIds) {
          await this.compilationService.deleteImageFromCompilation(this.compilationId, id)
        }
        break;
    }

    this.closeModal()

    // Reload current page
    setTimeout(() => location.reload(), 0);
  }

  closeModal() {
    this.modalService.closeDeleteModal()
    // this.close.emit()
  }

}
