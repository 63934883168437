<app-header [isBlack]="true"></app-header>
<div class="login container">
  <div class="authorization__content">
    <form
      (ngSubmit)="submit()"
      [formGroup]="form"
      class="form authorization__form"
    >
      <h3 class="form__title">Авторизация</h3>
      <div class="form__input-wrapper">
        <input type="text" formControlName="login" class="form__input" [ngClass]="login.value.length? 'active': ''" #login />
      <small class="form__text" >Логин</small>
      </div>
      <!-- /.form__input-wrapper -->


      <div class="form__input-wrapper">
        <input
          formControlName="password"
          [type]="isPasswordVisible ? 'text' : 'password'"
          class="form__input"
          [ngClass]="password.value.length? 'active': ''"
          #password
        />
        <small class="form__text" >Пароль</small>
        <div class="form__icons-wrapper" [ngSwitch]="isPasswordVisible">
          <svg
            *ngSwitchCase="false"
            (click)="changeInputType()"
            class="form__eye-icon"
          >
            <use xlink:href="../../../assets/sprite.svg#eye-on"></use>
          </svg>
          <svg
            *ngSwitchCase="true"
            (click)="changeInputType()"
            class="form__eye-icon"
          >
            <use xlink:href="../../../assets/sprite.svg#eye-off"></use>
          </svg>
        </div>
      </div>
      <!-- /.form__input-wrapper -->


      <p class="form__error" *ngIf="isLoginOrPasswordInvalid">
        Неверный логин или пароль
      </p>
      <p class="form__link"><a routerLink="/reset-password" ><small>Забыли пароль?</small></a></p>
      <button
        [disabled]="form.invalid"
        type="submit"
        class="btn form__submit-button"
      >
        Войти
      </button>
    </form>
    <!-- /.form login__form -->
  </div>
  <!-- /.login__content -->
</div>
<app-footer></app-footer>
