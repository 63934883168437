<div class="workspace">

  <app-sidebar></app-sidebar>
  
  <div class="workspace__body">
    <router-outlet></router-outlet>
  </div>

</div>

<app-drag-ndrop></app-drag-ndrop>  
<app-delete-modal *ngIf="isDeleteModal"></app-delete-modal>