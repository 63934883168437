<!-- Table view modes -->
<cdk-table
  *ngIf="[
    ViewMode.List,
    ViewMode.BiggerList,
  ].includes(viewMode)"
  [dataSource]="items"
  class="view-mode-{{ viewMode | lowercase }}"
>
  <cdk-header-row
    *cdkHeaderRowDef="tableColumns; sticky: true"
  ></cdk-header-row>

  <cdk-row
    *cdkRowDef="let item; let index; columns: tableColumns;"
    (click)="selection.toggle(item)"
    class="table-row"
    [class.selected]="selection.isSelected(item)"
  ></cdk-row>

  <ng-container cdkColumnDef="selection">
    <cdk-header-cell *cdkHeaderCellDef>&nbsp;</cdk-header-cell>

    <cdk-cell *cdkCellDef="let item; let index;">
      <app-checkbox
        [checked]="selection.isSelected(item)"
      ></app-checkbox>
    </cdk-cell>
  </ng-container>

  <ng-container cdkColumnDef="name">
    <cdk-header-cell
      *cdkHeaderCellDef
    >
      <div
        (click)="toggleSorting('name')"
        class="sorting-switcher"
        [class.sorting-active]="sorting.activeSortings.has('name')"
        [class.sorting-direction-asc]="sorting.activeSortings.get('name') === SortingDirection.ASC"
        [class.sorting-direction-desc]="sorting.activeSortings.get('name') === SortingDirection.DESC"
      >
        Название
      </div>
    </cdk-header-cell>

    <cdk-cell *cdkCellDef="let item; let index;">
      <img
        [src]="getPhotoUrl(item)"
        class="photo"
      />
      {{ item.name }}
    </cdk-cell>
  </ng-container>

  <ng-container cdkColumnDef="tags">
    <cdk-header-cell *cdkHeaderCellDef>Тэги</cdk-header-cell>

    <cdk-cell
      *cdkCellDef="let item; let index;"
      class="tags"
    >
      <span
        *ngFor="let tag of item.__tags__"
        class="tag"
      >{{ tag.name }}</span>
    </cdk-cell>
  </ng-container>

  <ng-container cdkColumnDef="createdAt">
    <cdk-header-cell
      *cdkHeaderCellDef
    >
      <div
        (click)="toggleSorting('createdAt')"
        class="sorting-switcher"
        [class.sorting-active]="sorting.activeSortings.has('createdAt')"
        [class.sorting-direction-asc]="sorting.activeSortings.get('createdAt') === SortingDirection.ASC"
        [class.sorting-direction-desc]="sorting.activeSortings.get('createdAt') === SortingDirection.DESC"
      >
        Дата и время добавления
      </div>
    </cdk-header-cell>

    <cdk-cell *cdkCellDef="let item; let index;">
      {{ item.uploadDate | date:'dd.MM.yyyy HH:mm' }}
    </cdk-cell>
  </ng-container>

  <ng-container cdkColumnDef="format">
    <cdk-header-cell *cdkHeaderCellDef>Формат</cdk-header-cell>

    <cdk-cell *cdkCellDef="let item; let index;">
      {{ item.format }}
    </cdk-cell>
  </ng-container>

  <div
    *cdkNoDataRow
    class="table-row no-data"
  >
    {{ noDataText }}
  </div>
</cdk-table>

<!-- Tile (grid) view mode -->
<div
  *ngIf="[ViewMode.Tile].includes(viewMode)"
  class="tiles-container"
>
  <div
    *ngIf="0 === items.data.length"
    class="no-data"
  >
    {{ noDataText }}
  </div>

  <div
    *ngFor="let item of items.data"
    (click)="selection.toggle(item)"
    class="item-container"
    [class.selected]="selection.isSelected(item)"
  >
    <div
      class="item-selection"
    >
      <app-checkbox
        [checked]="selection.isSelected(item)"
      ></app-checkbox>
    </div>

    <div
      class="item-image-container"
    >
      <img
        [src]="getPhotoUrl(item)"
        class="photo"
      />
    </div>

    <div
      class="item-details"
    >
      <div
        class="photo-name"
      >
        <span
        class="name"
          title="{{ item.name }}.{{ item.format }}"
        >{{ item.name }}</span>

        <span class="extension">{{ item.format }}</span>
      </div>

      <div
        class="photo-tags"
        >
        #&nbsp;
        <span
          *ngFor="let tag of item.__tags__; let isLast = last"
        >{{ tag.name }}{{ isLast ? '' : ',&nbsp;' }}</span>
      </div>
    </div>
  </div>
</div>
