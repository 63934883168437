import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { Meta, Title } from "@angular/platform-browser";
import { NavigationEnd, Router } from "@angular/router";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit{
  title = "PhotoStock";

  constructor (
    private router: Router,
    private titleService: Title,
    private metaService: Meta,
    @Inject(PLATFORM_ID)
    private platformId: string,
  ) {}

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }

      if (isPlatformBrowser(this.platformId)) {
        this.scrollTop();
      }

      // Set default title. Child components may override it.
      const title = 'Уникальные фотографии городов России в хорошем качестве от Gelio';
      this.titleService.setTitle(title);
      // Set default meta data. Child components may override it.
      this.metaService.updateTag({ content: title, name: 'title' }, 'name=title');
      this.metaService.updateTag({ content: 'Фотобанк с видами городов России в большом разрешении' }, 'name=description');
    });
  }

  private scrollTop(): void {
    window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: 0,
    });
  }
}
