<app-header [isBlack]="true" [isSearch]="true"></app-header>

<div class="container favorites">
  <h2>
    Избранное <span class="counter">{{ amount }}</span>
  </h2>

  <p *ngIf="amount === 0">
    Ваше избранное пока пусто. Чтобы добавить сюда изображения, воспользуйтесь
    поиском или найдите интересующее фото в каталоге, а затем добавьте его в
    избранное <img src="../../../assets/icons/favorite.svg" alt="" />
  </p>

  <app-photos-grid
    [photos]="favorites"
    *ngIf="amount > 0"
  ></app-photos-grid>
</div>

<app-footer></app-footer>
