import { IAdminCompilation } from 'src/app/models/admin';

export type CompilationCityType = 'l1' | 'l2';

export abstract class CompilationCity{
  public readonly id?: number;
  public readonly l1CityId?: number;
  public readonly name: string = '';
  public readonly compilations: IAdminCompilation[] = [];

  get type(): CompilationCityType {
    throw new Error('Implement in the child class');
  }
}
