import { Injectable } from '@angular/core';
import { saveAs } from "file-saver"
import { IImage } from '../models/image';
import { ImageSourceService } from './image-source.service';

@Injectable({
  providedIn: 'root'
})
export class FileSaverService {

  constructor(
    private readonly _imageSourceService: ImageSourceService,
  ) { }

  async saveImage(image: IImage) {
    saveAs(this._imageSourceService.returnUrl(image, "ORIGINAL"), image.id.toString())
  }
}
