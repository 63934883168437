import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouteReuseStrategy } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from "@angular/material/dialog";
import { MatChipsModule } from "@angular/material/chips";
import { MatButtonModule } from "@angular/material/button";
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkTableModule } from '@angular/cdk/table';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxMasonryModule } from "ngx-masonry";
import { OrderModule } from "ngx-order-pipe";
import { DragToSelectModule } from "ngx-drag-to-select";
import { ClickOutsideModule } from "ng-click-outside";

import { AppRoutingModule } from "./app-routing.module";
import { StorageModule } from "src/libs/storage/src";
import { AppComponent } from "./app.component";
import { MainComponent } from "./views/main/main.component";
import { SearchPageComponent } from "./views/search-page/search-page.component";
import { FavoriteComponent } from "./views/favorite/favorite.component";
import { PhotoComponent } from "./views/photo/photo.component";
import { LoginComponent } from "./views/login/login.component";
import { CartComponent } from "./views/cart/cart.component";
import { CheckoutComponent } from "./views/checkout/checkout.component";
import { CompilationComponent } from "./views/compilation/compilation.component";
import { HeaderComponent } from "./components/header/header.component";
import { SearchComponent } from "./components/search/search.component";
import { FooterComponent } from "./components/footer/footer.component";
import { CategoriesComponent } from "./components/categories/categories.component";
import { PopularComponent } from "./components/popular/popular.component";
import { AdminComponent } from "./views/admin/admin.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { RegisterComponent } from "./views/register/register.component";
import { MainLayoutComponent } from "./layouts/main-layout/main-layout.component";
import { AdminHeaderComponent } from "./components/admin-header/admin-header.component";
import { PersonalCabinetComponent } from "./views/personal-cabinet/personal-cabinet.component";
import { PopupCarouselComponent } from "./components/popup-carousel/popup-carousel.component";
import { AdminModalComponent } from "./components/admin-modal/admin-modal.component";
import { CheckboxComponent } from "./components/elements/checkbox/checkbox.component";
import { QuestionMarkComponent } from "./components/elements/question-mark/question-mark.component";
import { AdminImageComponent } from "./components/admin-image/admin-image.component";
import { CompilationModalComponent } from "./components/blocks/compilation-modal/compilation-modal.component";
import { FolderModalComponent } from "./components/blocks/folder-modal/folder-modal.component";
import { ImageModalComponent } from "./components/blocks/image-modal/image-modal.component";
import { TagsComponent } from "./components/elements/tags/tags.component";
import { AdminPanelComponent } from "./components/admin-panel/admin-panel.component";
import { TagsModalComponent } from "./components/blocks/tags-modal/tags-modal.component";
import { DeleteModalComponent } from "./components/blocks/delete-modal/delete-modal.component";
import { EditModalComponent } from "./components/blocks/edit-modal/edit-modal.component";
import { HeroComponent } from "./components/hero/hero.component";
import { ReplaceModalComponent } from "./components/blocks/replace-modal/replace-modal.component";
import { ToCompilationModalComponent } from "./components/blocks/to-compilation-modal/to-compilation-modal.component";
import { ModalTagsComponent } from "./components/elements/modal-tags/modal-tags.component";
import { BaseRequestService } from "./services/base-request.service";
import { CartItemComponent } from "./views/cart/cart-item/cart-item.component";
import { DndDirective } from "./directives/dnd.directive";
import { ProgressComponent } from "./components/progress/progress.component";
import { DragNdropComponent } from "./components/drag-ndrop/drag-ndrop.component";
import { LoadedpopupComponent } from "./components/loadedpopup/loadedpopup.component";
import { TokenInterceptor } from "./Interceptor/api.auth-interceptor";
import { ButtonStateDirective } from "./directives/button-state.directive";
import { LazyLoadDirective } from "./directives/lazy-load.directive";
import { PhotosGridComponent } from "./components/photos-grid/photos-grid.component";
import { PhotoGridInfoComponent } from "./components/photos-grid/photo-grid-info/photo-grid-info.component";
import { CompilationInfoModalComponent } from "./components/blocks/compilation-info-modal/compilation-info-modal.component";
import { FolderInfoModalComponent } from "./components/blocks/folder-info-modal/folder-info-modal.component";
import { OrderItemComponent } from "./views/personal-cabinet/order-item/order-item.component";
import { SeoModalComponent } from "./components/blocks/seo-modal/seo-modal.component";
import { ResetPasswordComponent } from "./views/reset-password/reset-password.component";
import { EmailComponent } from "./views/reset-password/email/email.component";
import { UsersPanelComponent } from "./views/admin/users-panel/users-panel.component";
import { UserItemComponent } from "./views/admin/users-panel/user-item/user-item.component";
import { AddUserModalComponent } from "./components/blocks/add-user-modal/add-user-modal.component";
import { EditUserModalComponent } from "./components/blocks/edit-user-modal/edit-user-modal.component";
import { EditUserPasswordModalComponent } from "./components/blocks/edit-user-password-modal/edit-user-password-modal.component";
import { AdminFolderComponent } from "./components/admin-folder/admin-folder.component";
import { SuccessPopupComponent } from "./components/success-popup/success-popup.component";
import { RecursiveFoldersComponent } from "./components/recursive-folders/recursive-folders.component";
import { AdminPhotosGridComponent } from "./components/admin-photos-grid/admin-photos-grid.component";
import { WorkspaceComponent } from "./views/admin/workspace/workspace.component";
import { SwitchComponent } from "./components/elements/switch/switch.component";
import { PricesComponent } from "./views/prices/prices.component";
import { ContactFormComponent } from "./components/contact-form/contact-form.component";
import { AboutComponent } from "./views/about/about.component";
import { HowToBuyComponent } from "./views/how-to-buy/how-to-buy.component";
import { PartnershipComponent } from "./views/partnership/partnership.component";
import { ContactsComponent } from "./views/contacts/contacts.component";
import { TermsOfUseComponent } from "./views/terms-of-use/terms-of-use.component";
import { TermsOfUseGridComponent } from "./components/terms-of-use-grid/terms-of-use-grid.component";
import { TermsOfUsePopupComponent } from "./components/terms-of-use-popup/terms-of-use-popup.component";
import { TextWithTooltipComponent } from './components/text-with-tooltip/text-with-tooltip.component';
import { GeoCatalogComponent } from './components/geo-catalog/geo-catalog.component';
import { GeoCatalogPageComponent } from './views/geo-catalog-page/geo-catalog-page.component';
import { ButtonComponent } from './components/button/button.component';
import { AdminModalsModule } from "./admin-modals/admin-modals.module";
import { AdminCatalogComponent } from './views/admin/admin-catalog/admin-catalog.component';
import { AdminCatalogTableComponent } from "./components/admin-catalog-table/admin-catalog-table.component";
import { AdminCatalogL1CityComponent } from "./components/admin-catalog-table/admin-catalog-l1-city.component";
import { AdminCatalogL1CityFormComponent } from "./components/admin-catalog-table/admin-catalog-l1-city-form.component";
import { AdminCatalogL2CityComponent } from "./components/admin-catalog-table/admin-catalog-l2-city.component";
import { AdminCatalogL2CityFormComponent } from "./components/admin-catalog-table/admin-catalog-l2-city-form.component";
import { SeoTemplatesComponent } from "./views/admin/seo-templates/seo-templates.component";
import { SearchPageRoutingCacher } from "./services/search-page-routing-cacher.service";
import { AdminCompilationsTableComponent } from './admin/compilations/admin-compilations-table/admin-compilations-table.component';
import { AdminCompilationsComponent } from './admin/compilations/admin-compilations-component/admin-compilations.component';
import { AdminCompilationModalComponent } from "./admin/compilations/admin-compilation-modal/admin-compilation.modal.component";
import { AdminCompilationImagesModalComponent } from "./admin/compilations/admin-compilation-images-modal/admin-compilation-images.modal.component";
import { PaginatorComponent } from './components/paginator/paginator.component';
import { PaginatorBulletComponent } from "./components/paginator/paginator-bullet.component";
import { AdminPhotosTableComponent } from './admin/photos/admin-photos-table/admin-photos-table.component';

import { LayoutModule } from "@angular/cdk/layout";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BlogComponent } from "@app/views/blog/blog.component";

@NgModule({
  schemas: [
    // /**
    //  * Required to use the following Custom Elements:
    //  * - Swiper {@link https://swiperjs.com/element}
    //  */
    // CUSTOM_ELEMENTS_SCHEMA,
  ],
  declarations: [
    AppComponent,
    MainComponent,
    SearchPageComponent,
    SearchComponent,
    FavoriteComponent,
    PhotoComponent,
    CartComponent,
    CheckoutComponent,
    CompilationComponent,
    HeaderComponent,
    FooterComponent,
    PhotosGridComponent,
    PhotoGridInfoComponent,
    CategoriesComponent,
    PopularComponent,
    PersonalCabinetComponent,
    PopupCarouselComponent,
    AdminComponent,
    SidebarComponent,
    NotFoundComponent,
    LoginComponent,
    SearchComponent,
    RegisterComponent,
    MainLayoutComponent,
    LoginComponent,
    AdminHeaderComponent,
    AdminModalComponent,
    CheckboxComponent,
    QuestionMarkComponent,
    AdminImageComponent,
    CompilationModalComponent,
    FolderModalComponent,
    ImageModalComponent,
    TagsComponent,
    AdminPanelComponent,
    TagsModalComponent,
    DeleteModalComponent,
    OrderItemComponent,
    HeroComponent,
    ReplaceModalComponent,
    ToCompilationModalComponent,
    ModalTagsComponent,
    ProgressComponent,
    DragNdropComponent,
    LoadedpopupComponent,
    CartItemComponent,
    CompilationInfoModalComponent,
    DndDirective,
    ButtonStateDirective,
    LazyLoadDirective,
    FolderInfoModalComponent,
    SeoModalComponent,
    ResetPasswordComponent,
    EmailComponent,
    UsersPanelComponent,
    UserItemComponent,
    AddUserModalComponent,
    EditUserModalComponent,
    EditUserPasswordModalComponent,
    AdminFolderComponent,
    SuccessPopupComponent,
    RecursiveFoldersComponent,
    AdminPhotosGridComponent,
    WorkspaceComponent,
    EditModalComponent,
    SwitchComponent,
    PricesComponent,
    BlogComponent,
    ContactFormComponent,
    HowToBuyComponent,
    AboutComponent,
    PartnershipComponent,
    ContactsComponent,
    TermsOfUseComponent,
    TermsOfUseGridComponent,
    TermsOfUsePopupComponent,
    TextWithTooltipComponent,
    GeoCatalogComponent,
    GeoCatalogPageComponent,
    ButtonComponent,
    AdminCatalogComponent,
    AdminCatalogTableComponent,
    AdminCatalogL1CityComponent,
    AdminCatalogL1CityFormComponent,
    AdminCatalogL2CityComponent,
    AdminCatalogL2CityFormComponent,
    SeoTemplatesComponent,
    AdminCompilationsComponent,
    AdminCompilationsTableComponent,
    AdminCompilationModalComponent,
    AdminCompilationImagesModalComponent,
    PaginatorComponent,
    PaginatorBulletComponent,
    AdminPhotosTableComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    AppRoutingModule,
    FormsModule,
    DragDropModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ClickOutsideModule,
    StorageModule.forRoot(),
    DragToSelectModule.forRoot({
      selectedClass: "myselected",
    }),
    NgxMasonryModule,
    AdminModalsModule,
    OrderModule,
    CdkTableModule,
    // @ts-ignore
    NgxSkeletonLoaderModule.forRoot({
      animation: "pulse",
      theme: {
        // Enabling theme combination
        extendsFromRoot: true,
        marginBottom: ".4em",
      },
    }),
    MatDialogModule,
    MatChipsModule,
    MatButtonModule,
    MatAutocompleteModule,
    LayoutModule,
    MatTooltipModule,
  ],
  providers: [
    BaseRequestService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    // {
    //   Disabled in attempt to work around issue with items "collapsing" on the search page
    //   provide: RouteReuseStrategy,
    //   useClass: SearchPageRoutingCacher,
    // },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        maxWidth: '90vw',
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
