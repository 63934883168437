import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { AdminModalsService } from 'src/app/admin-modals/admin-modals.service';
import { ICatalogl1City, ICatalogl2City, ICatalogSetL1ChildrenOrder } from 'src/app/models/admin';
import { AdminCatalogService } from 'src/app/services/admin/catalog.service';
import { CatalogCity } from './catalog-city';
import { CatalogL1City } from './catalog-l1-city';
import { CatalogL2City } from './catalog-l2-city';

enum SortingColumn {
  Macroregion = 'macroregion',
  Province = 'province',
  L1City = 'l1City',
  Date = 'date',
}

type Sorting = {
  column: SortingColumn,
  direction: 'asc' | 'desc',
}

class EditedItem {
  readonly type: 'l1' | 'l2';

  constructor(
    readonly item: CatalogL1City | CatalogL2City,
  ) {
    if (item instanceof CatalogL1City) {
      this.type = 'l1';
    } else if (item instanceof CatalogL2City) {
      this.type = 'l2';
    }
  }

  get isNew() {
    return undefined === this.item.id;
  }

  isChildOf(l1City: CatalogL1City) {
    return 'l2' === this.type && !!l1City.id && (this.item as CatalogL2City).parentCityId === l1City.id;
  }
}

@Component({
  selector: 'app-admin-catalog-table',
  templateUrl: './admin-catalog-table.component.html',
  styleUrls: ['./admin-catalog-table.component.scss']
})
export class AdminCatalogTableComponent implements OnInit {
  readonly SortingColumn = SortingColumn;
  
  sorting?: Sorting = {
    column: SortingColumn.Date,
    direction: 'asc',
  };

  isLoading = false;

  catalog: CatalogL1City[] = [];

  editedItem: EditedItem;

  /** Is `true` whenever new item is being saved */
  itemOperationPending = false;
  
  constructor(
    readonly catalogService: AdminCatalogService,
    readonly modals: AdminModalsService,
  ) {}

  async ngOnInit() {
    await this.fetchData();
  }

  async fetchData() {
    this.isLoading = true;
    try {
      this.catalog = await this.catalogService.getCatalog();
      // Augment L2 items a bit
      this.catalog.forEach(l1 => l1.l2Cities.forEach(l2 => l2.parentCityId = l1.id));
    } catch (e) {
      // TODO:api-errors-handling
    } finally {
      this.isLoading = false;
    }
  }

  toggleSorting(column: SortingColumn) {
    if (this.sorting.column === column) {
      if (this.sorting.direction === 'asc') {
        this.sorting.direction = 'desc';
      } else {
        this.sorting.column = undefined;
      }
    } else {
      this.sorting = {
        column,
        direction: 'asc',
      };
    }
  }

  onAddItem() {
    this.editedItem = new EditedItem(new CatalogL1City());
  }

  onAddL2Item(l1City: CatalogL1City) {
    const l2City = new CatalogL2City(l1City.id);
    l2City.order = l1City.l2Cities.length + 1;
    this.editedItem = new EditedItem(l2City);
  }

  onEditItem(item: CatalogL1City | CatalogL2City) {
    // Cancel any current editing
    this.catalogService.cancelButtonPressed.emit(true);
    this.editedItem = new EditedItem(item);
  }

  onCancelEditItem(item: CatalogL1City | CatalogL2City) {
    this.editedItem = null;
  }

  async onSaveItem(l1City: CatalogL1City) {
    this.itemOperationPending = true;
    try {
      if (l1City.id) {
        this.updateItemOperation(l1City);
      } else {
        this.createItemOperation(l1City);
      }

      this.editedItem = null;
    } catch (err) {
      // TODO:api-errors-handling
      console.error(err);
    } finally {
      this.itemOperationPending = false;
    }
  }

  async createItemOperation(l1City: CatalogL1City) {
    const itemData = this.prepareCityData(l1City);

    const response = await this.catalogService.createL1City(itemData);

    this.catalog.push({
      id: response.id,
      ...itemData,
    });
  }

  async updateItemOperation(l1City: CatalogL1City) {
    const itemData = this.prepareCityData(l1City);

    const response = await this.catalogService.updateL1City(itemData as ICatalogl1City);

    this.catalog.splice(
      this.catalog.findIndex((c) => c.id === l1City.id),
      1,
      l1City
    );
  }

  onDeleteItem(l1City: CatalogL1City) {
    this.modals.confirm(
      `Вы действительно хотите удалить запись "${l1City.name} (${l1City.province})"?`,
      'Удалить',
      {
        okBtnText: 'Удалить',
      }
    ).then((confirmed) => confirmed && this.deleteItem(l1City));
  }

  async deleteItem(l1City: CatalogL1City) {
    if (!l1City.id) {
      console.error('The item doesn\'t have an ID');
      return;
    }
    await this.catalogService.deleteL1City(l1City.id);
    this.catalog.splice(this.catalog.findIndex((c) => c.id === l1City.id), 1);
  }

  async onDeleteL2Item(l2City: CatalogL2City) {
    this.modals.confirm(
      `Вы действительно хотите удалить запись "${l2City.name}"?`,
      'Удалить',
      {
        okBtnText: 'Удалить',
      }
    ).then((confirmed) => confirmed && this.deleteL2Item(l2City));
  }

  async deleteL2Item(l2City: CatalogL2City) {
    if (!l2City.id) {
      console.error('The item doesn\'t have an ID');
      return;
    }
    await this.catalogService.deleteL2City(l2City.id);

    // Remove from the right L1 item's children
    const l1City = this.catalog.find((c) => c.id === l2City.parentCityId);
    if (!l1City) {
      throw new Error('Can\'t find parent city');
    }
    l1City.l2Cities.splice(l1City.l2Cities.findIndex((c) => c.id === l2City.id), 1);
  }
  
  async onSaveL2Item(l2City: CatalogL2City) {
    this.itemOperationPending = true;
    try {
      if (l2City.id) {
        this.updateL2ItemOperation(l2City);
      } else {
        this.createL2ItemOperation(l2City);
      }

      this.editedItem = null;
    } catch (err) {
      // TODO:api-errors-handling
      console.error(err);
    } finally {
      this.itemOperationPending = false;
    }
  }

  async createL2ItemOperation(l2City: CatalogL2City) {
    const itemData = this.prepareCityData(l2City);

    const response = await this.catalogService.createL2City(itemData);

    // Insert into the right L1 item's children
    const l1City = this.catalog.find((c) => c.id === l2City.parentCityId);
    if (!l1City) {
      throw new Error('Can\'t find parent city');
    }
    l1City.l2Cities.unshift({
      id: response.id,
      ...itemData,
    });
  }

  async updateL2ItemOperation(l2City: CatalogL2City) {
    const itemData = this.prepareCityData(l2City);

    const response = await this.catalogService.updateL2City(itemData as ICatalogl2City);
    
    // Update the right L1 item's children
    const l1City = this.catalog.find((c) => c.id === l2City.parentCityId);
    if (!l1City) {
      throw new Error('Can\'t find parent city');
    }
    l1City.l2Cities.splice(l1City.l2Cities.findIndex((c) => c.id === l2City.id), 1, l2City);
  }

  private prepareCityData<T extends CatalogCity>(city: T): T {
    const prepared = {...city};
    if (prepared.searchString?.trim() === '') {
      prepared.searchString = null;
    }

    return prepared;
  }

  onL2ItemDrop(event: CdkDragDrop<CatalogL2City>) {
    const l2City = event.item.data;
    // Update the right L1 item's children
    const l1City = this.catalog.find((c) => c.id === l2City.parentCityId);
    if (!l1City) {
      throw new Error('Can\'t find parent city');
    }

    // This code relies on the fact the list is sorted by the 'order' field in the tempalte
    const sorted = [...l1City.l2Cities].sort((a, b) => a.order - b.order);
    moveItemInArray(sorted, event.previousIndex, event.currentIndex);
    
    // Update the 'order' fields according to the actual new order
    const newOrderList: ICatalogSetL1ChildrenOrder = {};
    sorted.forEach((l2c, idx) => {
      const currentOrder = idx + 1;
      if (l2c.order !== currentOrder) {
        newOrderList[l2c.id] = currentOrder;
        l1City.l2Cities.find(l2cUnsorted => l2cUnsorted.id == l2c.id).order = currentOrder;
      }
    });

    // Request the server to update the order of the affected items
    // Let it be fully optimistic
    this.catalogService.setL1ChildrenOrder(l1City.id, newOrderList);
  }
}
