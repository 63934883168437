import {
  Directive,
  Output,
  EventEmitter,
  HostBinding,
  HostListener,
} from "@angular/core";

@Directive({
  selector: "[appDnd]",
})
export class DndDirective {
  @Output() fileDropped = new EventEmitter<any>();
  
  @HostBinding("class.fileover") fileOver: boolean;

  lastTarget: any = null;

  constructor() {}

  @HostListener("window:dragenter", ["$event"]) onDragEnter(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.lastTarget = evt.target;
    this.fileOver = true;
  }

  @HostListener("window:dragover", ["$event"]) onDragOver(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener("window:dragleave", ["$event"]) public onDragLeave(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();

    if (evt.target === this.lastTarget || evt.target === document) {
      this.fileOver = false;
    }
  }

  @HostListener("drop", ["$event"]) public ondrop(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();

    this.fileOver = false;
    const files = evt.dataTransfer.files;
    
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
  }
}
