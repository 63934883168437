<form class="admin__catalog form" [formGroup]="form" (ngSubmit)="form.valid && onSubmit($event)">
  <ul class="admin__item listing">
    <li>
      <input
        class="btn-light text-input"
        type="text"
        [required]
        placeholder="Регион"
        formControlName="macroregion"
      />
    </li>

    <li>
      <input
        class="btn-light text-input"
        type="text"
        [required]
        placeholder="Субъект РФ"
        formControlName="province"
      />
    </li>

    <li>
      <input
        class="btn-light text-input"
        type="text"
        [required]
        placeholder="Город"
        formControlName="name"
      />
    </li>

    <li class="admin__item-name">&nbsp;</li>

    <li>
      <input
        class="btn-light text-input"
        type="text"
        placeholder="Ссылка на поисковую выдачу"
        formControlName="searchString"
      />
    </li>
  </ul>
</form>
