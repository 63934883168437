import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AdminCatalogService } from 'src/app/services/admin/catalog.service';
import { CatalogL2City } from './catalog-l2-city';

@Component({
  selector: 'app-admin-catalog-l2-city-form',
  templateUrl: './admin-catalog-l2-city-form.component.html',
  styleUrls: [
    './admin-catalog-table.component.scss',
    './admin-catalog-l2-city-form.component.scss',
  ]
})
export class AdminCatalogL2CityFormComponent implements OnInit, OnDestroy {
  @Input() l2City: CatalogL2City;
  @Input() isPending = false;
  @Output() cancelRequested = new EventEmitter<CatalogL2City>();
  @Output() submitted = new EventEmitter<CatalogL2City>();

  private saveButtonSub: Subscription;

  form: FormGroup = this.fb.group({
    name: ['', [Validators.required, Validators.minLength(1)]],
    searchString: ['', [Validators.minLength(1)]],
    showOnMainPage: [true],
  });

  constructor(
    private fb: FormBuilder,
    readonly catalogService: AdminCatalogService,
  ) {
    catalogService.saveButtonPressed.subscribe(() => this.onSubmit());
    catalogService.cancelButtonPressed.subscribe(() => this.onCancel());
  }

  ngOnInit(): void {
    this.form.patchValue(this.l2City);
  }

  ngOnDestroy(): void {
    this.saveButtonSub?.unsubscribe();
  }

  @HostListener('document:keydown.enter', ['$event'])
  onSubmit(event?: Event) {
    if (!this.form.valid) {
      console.warn('The form is invalid');
      event?.stopPropagation();
      return;
    }
    this.submitted.emit({
      ...this.l2City,
      ...this.form.value,
      parentCityId: this.l2City.parentCityId,
    });
  }

  @HostListener('document:keydown.escape', ['$event'])
  onCancel() {
    this.cancelRequested.emit(this.l2City);
  }
}
