import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-switch",
  templateUrl: "./switch.component.html",
  styleUrls: ["./switch.component.scss"],
})
export class SwitchComponent {
  @Input() checked? = true;
  @Input() disabled = false
  @Input() label?: string;
  @Input() labelPosition?: 'before' | 'after' = 'after';

  @Output() checkboxClick = new EventEmitter<MouseEvent>();

  constructor() {}

  onClick(event: MouseEvent) {
    this.checkboxClick.emit(event);
  }
}
