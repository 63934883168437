import { Component, OnInit } from '@angular/core';
import { CompilationL1City } from './compilation-l1-city';
import { AdminCompilationsCitiesService } from 'src/app/services/admin/compilations-cities.service';
import { CompilationCity } from './compilation-city';
import { IAddCompilationL1City, IAddCompilationL2City, ICreateCompilation, IUpdateCompilationL1City, IUpdateCompilationL2City } from 'src/app/models/admin';
import { CompilationL2City } from './compilation-l2-city';
import { AdminCompilationService } from 'src/app/services/admin/admin-compilation.service';
import { Compilation } from './compilation';

@Component({
  selector: 'app-admin-compilations',
  templateUrl: './admin-compilations.component.html',
  styleUrls: ['./admin-compilations.component.scss'],
  providers: [
    AdminCompilationService,
  ],
})
export class AdminCompilationsComponent implements OnInit {
  private _l1Cities: CompilationL1City[] = [];

  public get l1Cities() {
    return this._l1Cities;
  }

  public set l1Cities(cities) {
    this._l1Cities = cities;
    this._l1Cities.sort((a, b) => a.name.localeCompare(b.name));
    this.citiesById = this.flattenCities(this._l1Cities);
  }

  public citiesById: Map<CompilationCity['id'], CompilationCity>;

  constructor(
    private readonly citiesService: AdminCompilationsCitiesService,
    private readonly compilationsService: AdminCompilationService,
  ) {
    this.createL1City = this.createL1City.bind(this);
    this.createL2City = this.createL2City.bind(this);
    this.updateL1City = this.updateL1City.bind(this);
    this.updateL2City = this.updateL2City.bind(this);
    this.deleteL1City = this.deleteL1City.bind(this);
    this.deleteL2City = this.deleteL2City.bind(this);
    this.createCompilation = this.createCompilation.bind(this);
    this.updateCompilation = this.updateCompilation.bind(this);
    this.deleteCompilation = this.deleteCompilation.bind(this);

    this.addL1Record = this.addL1Record.bind(this);
    this.addL2Record = this.addL2Record.bind(this);
    this.replaceL1Record = this.replaceL1Record.bind(this);
    this.replaceL2Record = this.replaceL2Record.bind(this);
    this.removeL1Record = this.removeL1Record.bind(this);
    this.removeL2Record = this.removeL2Record.bind(this);
  }

  async ngOnInit(): Promise<void> {
    this.l1Cities = await this.citiesService.getL1Cities();
  }

  private flattenCities(l1Cities: CompilationL1City[]): Map<CompilationCity['id'], CompilationCity> {
    const map = new Map();
    l1Cities.forEach((c) => {
      map.set(c.id, {
        type: 'l1',
        compilations: [],
        ...c,
      });
      c.l2Cities?.forEach((c2) => map.set(c2.id, {
        type: 'l2',
        compilations: [],
        ...c2,
      }));
    });
    return map;
  }

  public createL1City(data: IAddCompilationL1City): Promise<CompilationL1City> {
    return this.citiesService.createL1City(data).then((newRecord) => {
      this.addL1Record(newRecord);
      return newRecord;
    });
  }

  public createL2City(data: IAddCompilationL2City): Promise<CompilationL2City> {
    return this.citiesService.createL2City(data).then((newRecord) => {
      this.addL2Record(newRecord);
      return newRecord;
    });
  }

  public updateL1City(id: CompilationL1City['id'], data: IUpdateCompilationL1City): Promise<CompilationL1City> {
    return this.citiesService.updateL1City(id, data).then((newRecord) => {
      this.replaceL1Record(newRecord);
      return newRecord;
    });
  }

  public updateL2City(id: CompilationL2City['id'], data: IUpdateCompilationL2City): Promise<CompilationL2City> {
    return this.citiesService.updateL2City(id, data).then((newRecord) => {
      this.replaceL2Record(newRecord);
      return newRecord;
    });
  }

  public deleteL1City(id: CompilationL1City['id']): Promise<CompilationL1City> {
    return this.citiesService.deleteL1City(id).then((record) => {
      this.removeL1Record(id);
      return record;
    });
  }

  public deleteL2City(id: CompilationL2City['id']): Promise<CompilationL2City> {
    return this.citiesService.deleteL2City(id).then((record) => {
      this.removeL2Record(record.l1CityId, id);
      return record;
    });
  }

  public createCompilation(data: ICreateCompilation): Promise<Compilation> {
    return this.compilationsService.createCompilation(data).then((newRecord) => {
      const city = this.citiesById.get(data.cityId);
      city.compilations.push(newRecord);
      if ('l1' === city.type) {
        this.replaceL1Record(city as CompilationL1City);
      }
      if ('l2' === city.type) {
        this.replaceL2Record(city as CompilationL2City);
      }

      return newRecord;
    });
  }

  public updateCompilation(id: Compilation['id'], cityId: CompilationCity['id'], data: ICreateCompilation): Promise<Compilation> {
    return this.compilationsService.updateCompilation(id, data).then((newRecord) => {
      if (!newRecord.cityId) {
        // Remove the compilation from the city
        const city = this.citiesById.get(data.cityId);
        const idx = city.compilations.findIndex((c) => c.id === newRecord.id);
        city.compilations.splice(idx, 1);
        if ('l1' === city.type) {
          this.replaceL1Record(city as CompilationL1City);
        }
        if ('l2' === city.type) {
          this.replaceL2Record(city as CompilationL2City);
        }
      }

      return newRecord;
    });
  }

  public deleteCompilation(id: Compilation['id'], cityId: CompilationCity['id']): Promise<Compilation> {
    return this.compilationsService.deleteCompilation(id).then((newRecord) => {
      // Remove the compilation from the city
      const city = this.citiesById.get(cityId);
      const idx = city.compilations.findIndex((c) => c.id === id);
      city.compilations.splice(idx, 1);
      if ('l1' === city.type) {
        this.replaceL1Record(city as CompilationL1City);
      }
      if ('l2' === city.type) {
        this.replaceL2Record(city as CompilationL2City);
      }

      return newRecord;
    });
  }

  private addL1Record(newRecord: CompilationL1City): void {
    const newRecords = [...this.l1Cities];
    const idx = newRecords.push(newRecord);
    this.l1Cities = newRecords;
  }

  private addL2Record(newRecord: CompilationL2City): void {
    const newRecords = [...this.l1Cities];
    const l1Record = newRecords.find((l1Record) => l1Record.id === newRecord.l1CityId);
    l1Record.l2Cities.push(newRecord);
    this.l1Cities = newRecords;
  }

  private replaceL1Record(newRecord: CompilationL1City): void {
    const newRecords = [...this.l1Cities];
    const oldRecord = this.citiesById.get(newRecord.id);
    const idx = newRecords.findIndex((oldRecord) => oldRecord.id === newRecord.id);
    newRecords.splice(idx, 1, {
      ...oldRecord,
      ...newRecord,
    } as any);
    this.l1Cities = newRecords;
  }

  private replaceL2Record(newRecord: CompilationL2City): void {
    const newRecords = [...this.l1Cities];
    const l1Record = newRecords.find((l1Record) => l1Record.id === newRecord.l1CityId);
    const oldRecord = this.citiesById.get(newRecord.id);
    const idx = l1Record.l2Cities.findIndex((oldRecord) => oldRecord.id === newRecord.id);
    l1Record.l2Cities.splice(idx, 1, {
      ...oldRecord,
      ...newRecord,
    } as any);
    this.l1Cities = newRecords;
  }

  private removeL1Record(id: CompilationL1City['id']): void {
    const newRecords = [...this.l1Cities];
    const idx = newRecords.findIndex((oldRecord) => oldRecord.id === id);
    newRecords.splice(idx, 1);
    this.l1Cities = newRecords;
  }

  private removeL2Record(parentId: CompilationL1City['id'], id: CompilationL2City['id']): void {
    const newRecords = [...this.l1Cities];
    const l1Record = newRecords.find((l1Record) => l1Record.id === parentId);
    const idx = l1Record.l2Cities.findIndex((oldRecord) => oldRecord.id === id);
    l1Record.l2Cities.splice(idx, 1);
    this.l1Cities = newRecords;
  }
}

