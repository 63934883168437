<app-admin-modal (onClose)="closeModal()">
  <form action="" class="folder-form" [formGroup]="form" (ngSubmit)="submit()">
    <div class="header-title">
      <h2>Создать новую подборку</h2>
    </div>
    <div #folderModal class="form-group">
      <label
        >Название подборки
        <input type="text" formControlName="title" />
      </label>
    </div>
    <div class="form-group">
      <label
        >Описание
        <textarea rows="4" formControlName="description"></textarea>
      </label>
    </div>
    <div class="controls">
      <div>
        <button type="submit" [disabled]="form.invalid" class="btn">
          Добавить
        </button>
      </div>
    </div>
  </form>
</app-admin-modal>
