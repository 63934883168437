import { IAdminImage, IAdminPhoto } from "../../models/admin";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  currentImage!: IAdminImage;
  id = 0;

  private isCreateFolderModal: boolean = false;
  private isCompilationModal: boolean = false;
  private isImageModal: boolean = true;
  private isTagsModal: boolean = false;
  public isDeleteModal: boolean = false;
  public isEditModal: boolean = false;
  public isReplaceModal: boolean = false;
  public isToCompilationModal: boolean = false;
  public isCompilationInfoModal: boolean = false;
  public isFolderInfoModal: boolean = false;
  public isSeoInfoModal: boolean = false;
  public isEditUserModalVisible: boolean = false;
  public isEditUserPasswordModalVisible: boolean = false;

  private createFolderSubject = new Subject<any>();
  private compilationSubject = new Subject<any>();
  private imageSubject = new Subject<any>();
  private tagsSubject = new Subject<any>();
  private deleteSubject = new Subject<any>();
  private editSubject = new Subject<any>();
  private replaceSubject = new Subject<any>();
  private toCompilationSubject = new Subject<any>();
  private compilationInfoSubject = new Subject<any>();
  private folderInfoSubject = new Subject<any>();
  private seoInfoSubject = new Subject<any>();
  private editUserSubject = new Subject<any>();
  private editUserPasswordSubject = new Subject<any>();

  constructor() {}

  // CREATE FOLDER
  openFolderModal() {
    this.isCreateFolderModal = true;
    this.createFolderSubject.next(this.isCreateFolderModal);
  }

  closeFolderModal() {
    this.isCreateFolderModal = false;
    this.createFolderSubject.next(this.isCreateFolderModal);
  }
  
  // onFolderToggle(): Observable<any> {
  //   return this.createFolderSubject.asObservable();
  // }

  //  CREATE COMPILATION
  openCompilationModal() {
    this.isCompilationModal = true;
    this.compilationSubject.next(this.isCompilationModal);
  }
  closeCompilationModal() {
    this.isCompilationModal = false;
    this.compilationSubject.next(this.isCompilationModal);
  }
  onCompilationToggle(): Observable<any> {
    return this.compilationSubject.asObservable();
  }

  // ADD TAGS
  openTagsModal() {
    this.isTagsModal = true;
    this.tagsSubject.next(this.isTagsModal);
  }
  closeTagsModal() {
    this.isTagsModal = false;
    this.tagsSubject.next(this.isTagsModal);
  }
  onTagsToggle(): Observable<any> {
    return this.tagsSubject.asObservable();
  }

  // IMAGE MODAL
  openImageModal(image: IAdminImage) {
    this.isImageModal = true;
    this.currentImage = image;
    this.imageSubject.next(this.isImageModal);
  }
  onImageToggle(): Observable<any> {
    return this.imageSubject.asObservable();
  }
  closeImageModal() {
    this.isImageModal = false;
    this.imageSubject.next(this.isImageModal);
  }

  // DELETE MODAL

  openDeleteModal() {
    this.isDeleteModal = true;
    this.deleteSubject.next(this.isDeleteModal);
  }
  closeDeleteModal() {
    this.isDeleteModal = false;
    this.deleteSubject.next(this.isDeleteModal);
  }

  onDeleteToggle(): Observable<any> {
    return this.deleteSubject.asObservable();
  }

  // EDIT MODAL
  openEditModal() {
    this.isEditModal = true;
    this.editSubject.next(this.isEditModal);
  }
  closeEditModal() {
    this.isEditModal = false;
    this.editSubject.next(this.isEditModal);
  }

  onEditToggle(): Observable<any> {
    return this.editSubject.asObservable();
  }
  // REPLACE MODAL
  openReplaceModal() {
    this.isReplaceModal = true;
    this.replaceSubject.next(this.isReplaceModal);
  }
  closeReplaceModal() {
    this.isReplaceModal = false;
    this.replaceSubject.next(this.isReplaceModal);
  }

  onReplaceToggle(): Observable<any> {
    return this.replaceSubject.asObservable();
  }
  // ADD TO COMPILATION
  openToCompilationModal() {
    this.isToCompilationModal = true;
    this.toCompilationSubject.next(this.isToCompilationModal);
  }
  closeToCompilationModal() {
    this.isToCompilationModal = false;
    this.toCompilationSubject.next(this.isToCompilationModal);
  }

  onToCompilationToggle(): Observable<any> {
    return this.toCompilationSubject.asObservable();
  }
  // COMPILATION INFO
  openCompilationInfoModal() {
    this.isCompilationInfoModal = true;
    this.compilationInfoSubject.next(this.isCompilationInfoModal);
  }
  closeCompilationInfoModal() {
    this.isCompilationInfoModal = false;
    this.compilationInfoSubject.next(this.isCompilationInfoModal);
  }

  onCompilationInfoToggle(): Observable<any> {
    return this.compilationInfoSubject.asObservable();
  }
  // FOLDER INFO
  openFolderInfoModal() {
    this.isFolderInfoModal = true;
    this.folderInfoSubject.next(this.isFolderInfoModal);
  }
  closeFolderInfoModal() {
    this.isFolderInfoModal = false;
    this.folderInfoSubject.next(this.isFolderInfoModal);
  }

  onFolderInfoToggle(): Observable<any> {
    return this.folderInfoSubject.asObservable();
  }
  // SEO INFO
  openSeoInfoModal() {
    this.isSeoInfoModal = true;
    this.seoInfoSubject.next(this.isSeoInfoModal);
  }
  closeSeoInfoModal() {
    this.isSeoInfoModal = false;
    this.seoInfoSubject.next(this.isSeoInfoModal);
  }

  onSeoInfoToggle(): Observable<any> {
    return this.seoInfoSubject.asObservable();
  }

  getCurrentImage() {
    return this.currentImage;
  }

  // EDIT USER
  openEditUserModal() {
    this.isEditUserModalVisible = true;
    this.editUserSubject.next(this.isEditUserModalVisible);
  }

  closeEditUserModal() {
    this.isEditUserModalVisible = false;
    this.editUserSubject.next(this.isEditUserModalVisible);
  }

  onEditUserModalToggle() {
    return this.editUserSubject.asObservable();
  }

  // EDIT USER PASSWORD
  openEditUserPasswordModal() {
    this.isEditUserPasswordModalVisible = true;
    this.editUserPasswordSubject.next(this.isEditUserPasswordModalVisible);
  }

  closeEditUserPasswordModal() {
    this.isEditUserPasswordModalVisible = false;
    this.editUserPasswordSubject.next(this.isEditUserPasswordModalVisible);
  }

  onEditUserPasswordModalToggle() {
    return this.editUserPasswordSubject.asObservable();
  }
}
