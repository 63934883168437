import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CdkTable } from '@angular/cdk/table';
import { IAddCompilationL1City, IAddCompilationL2City, IAdminCompilation, ICompilationInfo, ICreateCompilation, IUpdateCompilationL1City, IUpdateCompilationL2City } from 'src/app/models/admin';
import { CompilationL1City } from '../admin-compilations-component/compilation-l1-city';
import { CompilationL2City } from '../admin-compilations-component/compilation-l2-city';
import { CompilationCity } from '../admin-compilations-component/compilation-city';
import { AdminCompilationsCitiesService } from 'src/app/services/admin/compilations-cities.service';
import { AdminModalsService } from 'src/app/admin-modals/admin-modals.service';
import { Compilation } from '../admin-compilations-component/compilation';
import { AdminCompilationModalComponent, AdminCompilationModalData } from '../admin-compilation-modal/admin-compilation.modal.component';
import { AdminCompilationImagesModalComponent, AdminCompilationImagesModalData } from '../admin-compilation-images-modal/admin-compilation-images.modal.component';

type RowType = 'l1City' | 'l2City';

class TableRow {
  public readonly compilations: IAdminCompilation[];

  constructor(
    public readonly type: RowType,
    public readonly city: CompilationCity,
    public readonly form?: FormGroup,
  ) {
    this.compilations = city.compilations;
  }
}

class Editing {
  private _form: FormGroup;
  private _city: CompilationCity;

  // get form() {
  //   return this._form;
  // }

  get city() {
    return this._city;
  }

  get isNew() {
    return undefined === this.city.id;
  }

  constructor(
    public readonly row: TableRow,
    public readonly form: FormGroup,
  ) {
    this._city = row.city;

    // this._form = 'l1City' === row.type
    //   ? buildL1CityForm(this.city as CompilationL1City)
    //   : buildL2CityForm(this.city as CompilationL2City)
    // ;
  }
}

class CompilationEditing {
  private _form: FormGroup;
  private _city: CompilationCity;

  get city() {
    return this._city;
  }

  get isNew() {
    return undefined === this.compilation.id;
  }

  constructor(
    public compilation: Compilation,
    public readonly row: TableRow,
    public readonly form: FormGroup,
  ) {
    this._city = row.city;
  }
}

@Component({
  selector: 'app-admin-compilations-table',
  templateUrl: './admin-compilations-table.component.html',
  styleUrls: ['./admin-compilations-table.component.scss']
})
export class AdminCompilationsTableComponent implements OnInit, OnChanges {
  @Input()
  public l1Cities: CompilationL1City[] = [];

  // @Output()
  // public onSubmitNewCity: EventEmitter<CompilationCity> = new EventEmitter();
  // @Output()
  // public onSubmitCityChanges: EventEmitter<CompilationCity> = new EventEmitter();+
  // @Output()
  // public onDeleteCity: EventEmitter<CompilationCity> = new EventEmitter();
  @Input()
  public createL1City: (city: IAddCompilationL1City) => Promise<CompilationL1City>;
  @Input()
  public createL2City: (city: IAddCompilationL2City) => Promise<CompilationL2City>;
  @Input()
  public updateL1City: (id: CompilationL1City['id'], data: IUpdateCompilationL1City) => Promise<CompilationL1City>;
  @Input()
  public updateL2City: (id: CompilationL2City['id'], data: IUpdateCompilationL2City) => Promise<CompilationL2City>;
  @Input()
  public deleteL1City: (id: CompilationL1City['id']) => Promise<CompilationL1City>;
  @Input()
  public deleteL2City: (id: CompilationL2City['id']) => Promise<CompilationL2City>;
  @Input()
  public setChildrenOrder: (parentId: CompilationL1City['id'], childrenIds: CompilationL2City['id'][]) => Promise<CompilationL2City>;
  @Input()
  public createCompilation: (data: ICreateCompilation) => Promise<Compilation>;
  @Input()
  public updateCompilation: (id: Compilation['id'], cityId: CompilationCity['id'], data: ICreateCompilation) => Promise<Compilation>;
  @Input()
  public deleteCompilation: (id: Compilation['id'], cityId: CompilationCity['id']) => Promise<Compilation>;

  @ViewChild(CdkTable)
  public table: CdkTable<TableRow>;

  private _editing: Editing;

  public get editing() {
    return this._editing;
  }

  private _compilationEditing: CompilationEditing;

  public get compilationEditing() {
    return this._compilationEditing;
  }

  public get canAddNewChildItem(): boolean {{
    return undefined === this.editing;
  }};

  // public rows: ArrayDataSource<TableRow>;
  public rows: TableRow[];
  public readonly pendingRows: Set<TableRow> = new Set();

  public l1CityForm: FormGroup = undefined;
  public l2CityForm: FormGroup = undefined;
  public compilationEditForm: FormGroup = undefined;
  public headerColumns: string[] = [
    'l1CityName',
    'l2CityName',
    'сompilations',
  ];

  public l1Columns: string[] = [
    'l1CityName',
    'l1CityAddL2City',
    'сompilations',
    'rowActions',
  ];

  public l2Columns: string[] = [
    'l1CityName',
    'l2CityName',
    'сompilations',
    'rowActions',
  ];

  constructor(
    public readonly ccs: AdminCompilationsCitiesService,
    private readonly fb: FormBuilder,
    private readonly modals: AdminModalsService,
    private readonly matDialog: MatDialog,
  ) {
    this.isBeingEdited = this.isBeingEdited.bind(this);
    this.isL1CityBeingDisplayed = this.isL1CityBeingDisplayed.bind(this);
    this.isL1CityBeingEdited = this.isL1CityBeingEdited.bind(this);
    this.isL2CityBeingDisplayed = this.isL2CityBeingDisplayed.bind(this);
    this.isL2CityBeingEdited = this.isL2CityBeingEdited.bind(this);
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (undefined !== changes.l1Cities) {
      const rows = this.makeTableRows(changes.l1Cities.currentValue);
      // this.rows = new ArrayDataSource(rows);
      this.rows = rows;
      this._editing = undefined;
      this.pendingRows.clear();
    }
  }

  makeTableRows(l1Cities: CompilationL1City[]): TableRow[] {
    const rows: TableRow[] = [];
    l1Cities.forEach((l1City) => {
      rows.push(new TableRow('l1City', l1City));

      if (l1City?.l2Cities?.length) {
        l1City.l2Cities.forEach((l2City) => {
          rows.push(new TableRow('l2City', l2City));
        });
      }
    });

    return rows;
  }

  mapL1CityToTableRow(l1City: CompilationL1City): TableRow {
    return new TableRow('l1City', l1City);
  }

  isL1CityBeingEdited(i: number, row: TableRow): boolean {
    return 'l1City' === row.type && this.isBeingEdited(row);
    // return 'l1City' === row.type && row.city.id === this?.l1CityForm?.value?.id;
  }

  isL1CityBeingDisplayed(i: number, row: TableRow): boolean {
    return 'l1City' === row.type && !this.isBeingEdited(row);
    // return 'l1City' === row.type && undefined === row.form;
  }

  isL2CityBeingEdited(i: number, row: TableRow): boolean {
    return 'l2City' === row.type && this.isBeingEdited(row);
    // return 'l2City' === row.type && row.city.id === this?.l2CityForm?.value?.id;
  }

  isL2CityBeingDisplayed(i: number, row: TableRow): boolean {
    return 'l2City' === row.type && !this.isBeingEdited(row);
    // return 'l2City' === row.type && undefined === row.form;
  }

  onEdit(row: TableRow) {
    const city = row.city;
    const form = 'l1City' === row.type
      ? this.buildL1CityForm(city as CompilationL1City)
      : this.buildL2CityForm(city.l1CityId, city as CompilationL2City)
    ;
    this._editing = new Editing(row, form);
    this.table.renderRows();
  }

  isBeingEdited(row: TableRow) {
    return this?.editing?.city === row.city;
  }

  onDelete(row: TableRow): void {
    const city = row.city;
    this.modals.confirm(
      `Вы действительно хотите удалить запись "${city.name}"?`,
      'Удалить',
      {
        okBtnText: 'Удалить',
      }
    ).then((confirmed) => confirmed && this.deleteCity(row));
  }

  deleteCity(row: TableRow): void {
    const city = row.city;
    let operation: Promise<CompilationCity>;
    if ('l1City' === row.type) {
      operation = this.deleteL1City(city.id);
    } else if ('l2City' === row.type) {
      operation = this.deleteL2City(city.id);
    }

    this.execAsyncRowOperation(operation, row);
  }

  onDeleteCompilation(cityRow: TableRow, compilation: Compilation): void {
    this.modals.confirm(
      `Вы действительно хотите удалить подборку "${compilation.title}"?`,
      'Удалить',
      {
        okBtnText: 'Удалить',
      }
    ).then((confirmed) => confirmed && this._deleteCompilation(compilation.id, cityRow));
  }

  _deleteCompilation(id: Compilation['id'], cityRow: TableRow): void {
    const city = cityRow.city;
    const operation = this.deleteCompilation(id, city.id);

    this.execAsyncRowOperation(operation, cityRow);
  }

  hasL2Children(i: number, l1City: CompilationL1City): boolean {
    return 0 < l1City.l2Cities.length;
  }

  public onAddChildItem(l1City: CompilationL1City): void {
    const l1CityRowIdx = this.rows.findIndex((row) => row.city.id === l1City.id);
    const newL2City = new CompilationL2City(l1City.id);
    const newL2CityRow = new TableRow('l2City', newL2City);
    this.insertRow(newL2CityRow, l1CityRowIdx + 1, false);
    this.onEdit(newL2CityRow);
  }

  private insertRow(row: TableRow, index: number, refresh = true): void {
    this.rows.splice(index, 0, row);
    refresh && this.table.renderRows();
  }

  public onRowKeypress(index: number, row: TableRow, $event: KeyboardEvent): void {
    if ('Escape' === $event.key && this.isBeingEdited(row)) {
      this.cancelCurrentEditing();
      // this.cancelRowEditing(index, row);
    }
    if ('Enter' === $event.key && this.isBeingEdited(row)) {
      this.submitForm(row);
    }
  }

  public onCompilationKeypress(row: TableRow, $event: KeyboardEvent): void {
    if ('Escape' === $event.key && this.compilationEditing) {
      this.cancelCurrentCompilationEditing();
      // this.cancelRowEditing(index, row);
    }
    if ('Enter' === $event.key && this.compilationEditing) {
      this.submitCurrentCompilationEditing();
    }
  }

  public cancelCurrentEditing(): void {
    const row = this.editing.row;
    if (this.editing.isNew) {
      this.removeRow(row, false);
    }
    this._editing = undefined;
    this.table.renderRows();
  }

  private removeRow(row: TableRow, refresh = true): void {
    const newRows = [...this.rows];
    const index = newRows.findIndex((r) => r === row);
    const [oldRow] = this.rows.splice(index, 1);
    refresh && this.table.renderRows();
  }

  public cancelRowEditing(index: number, row: TableRow): void {
    if (undefined === row.city.id) {
      // Delete row if it was a new record
      this.rows.splice(index, 1);
    } else {
      // Replace row with one w/o form if it was editing of an existing record
      this.rows.splice(index, 1, new TableRow(row.type, row.city));
    }
    this['l1City' === row.type ? 'l1CityForm' : 'l2CityForm'] = undefined;
    this.table.renderRows();
  }

  public submitForm(row: TableRow): void {
    const form = this.editing.form;
    if (!form.valid) {
      console.warn('Form is invalid', form.errors);
      return;
    }

    this.saveCity(row);
  }

  public submitCurrentEditing(): void {
    this.submitForm(this.editing.row);
  }

  public saveCity(row: TableRow): void {
    const city = row.city;
    const form = this.editing.form;
    const formData = form.value;
    let operation: Promise<CompilationCity>;
    if (undefined === city.id) {
      if ('l1City' === row.type) {
        operation = this.createL1City(formData);
      } else if ('l2City' === row.type) {
        operation = this.createL2City(formData);
      }
    } else {
      if ('l1City' === row.type) {
        operation = this.updateL1City(city.id, formData);
      } else if ('l2City' === row.type) {
        operation = this.updateL2City(city.id, formData);
      }
    }

    if (undefined === operation) {
      throw new Error('Couldn\'t determine operation');
    }

    this.execAsyncRowOperation(operation, row);
  }

  private updateCityRowData(row: TableRow, city: CompilationCity): void {
    const newRow = new TableRow(row.type, city);
    this.replaceRow(row, newRow);
  }

  private execAsyncRowOperation(operation: Promise<any>, row: TableRow): void {
    this.markRowPending(row);
    operation.finally(() => {
      this.markRowNotPending(row, true);
    });
  }

  private markRowPending(row: TableRow, refresh = false): void {
    this.pendingRows.add(row);
    refresh && this.table.renderRows();
  }

  private markRowNotPending(row: TableRow, refresh = false): void {
    this.pendingRows.delete(row);
    refresh && this.table.renderRows();
  }

  public isPending(row: TableRow): boolean {
    return this.pendingRows.has(row);
  }

  private replaceRow(indexOrRow: number | TableRow, newRow: TableRow, refresh = true): void {
    let index: number;
    if (indexOrRow instanceof TableRow) {
      index = this.rows.findIndex((r) => r === indexOrRow);
    } else {
      index = indexOrRow;
    }
    const [oldRow] = this.rows.splice(index, 1, newRow);
    if (this.isPending(oldRow)) {
      this.markRowPending(newRow, false);
      this.markRowNotPending(oldRow, false);
    }
    refresh && this.table.renderRows();
  }

  public onAddL1City(): void {
    const newCity = new CompilationL1City();
    const form = this.buildL1CityForm(newCity);
    const row = new TableRow('l1City', newCity);
    this.rows.push(row);
    this._editing = new Editing(row, form);
    this.table.renderRows();
  }

  private buildUpdatedRow(row: TableRow, city: CompilationCity): TableRow {
    const newRow = new TableRow(row.type, city, row.form);
    return newRow;
  }

  private buildL1CityForm(l1City?: CompilationL1City): FormGroup {
    const form = this.fb.group({
      id: [l1City?.id],
      name: [l1City?.name, [
        Validators.required,
      ]],
    });

    return form;
  }

  private buildL2CityForm(l1CityId: number, l2City?: CompilationL2City): FormGroup {
    const form = this.fb.group({
      l1CityId: [l1CityId],
      id: [l2City?.id],
      name: [l2City?.name, [
        Validators.required,
      ]],
    });

    return form;
  }

  public isInEditMode(): boolean {
    return undefined !== this.editing;
  }

  public onAddCompilation(row: TableRow): void {
    const city = row.city;
    const newCompilation: Compilation = {
      cityId: city.id,
      title: '',
    };
    const form = this.fb.group({
      cityId: [newCompilation.cityId],
      title: [newCompilation.title],
    });
    this._compilationEditing = new CompilationEditing(
      newCompilation,
      row,
      form,
    );
  }

  public submitCurrentCompilationEditing(): void {
    const form = this.compilationEditing.form;
    if (!form.valid) {
      console.warn('Form is invalid', form.errors);
      return;
    }
    this.createCompilation(form.value);
    this._compilationEditing = undefined;
  }

  public cancelCurrentCompilationEditing(): void {
    const row = this.compilationEditing.row;
    const city = this.compilationEditing.city;
    const compilation = this.compilationEditing.compilation;
    if (this.compilationEditing.isNew) {
      // const idx = city.compilations.findIndex((c) => c.id === undefined);
      // city.compilations.splice(idx, 1);
      // this.updateCityRowData(row, city);
    }
    this._compilationEditing = undefined;
  }

  public async onEditCompilation(row: TableRow, compilation: Compilation): Promise<void> {
    const dlg = this.matDialog.open<
      AdminCompilationModalComponent,
      AdminCompilationModalData,
      ICompilationInfo | undefined
    >(AdminCompilationModalComponent, {
      data: {
        compilationId: compilation.id,
      },
      width: '1720px',
    });
    const updatedCompilation = await dlg.afterClosed().toPromise();
    if (updatedCompilation) {
      compilation.title = updatedCompilation.title;
      compilation.published = updatedCompilation.published;
    }
  }

  public async onEditCompilationImages(
    row: TableRow,
    compilation: Compilation
  ): Promise<void> {
    const dlg = this.matDialog.open<
      AdminCompilationImagesModalComponent,
      AdminCompilationImagesModalData,
      ICompilationInfo | undefined
    >(AdminCompilationImagesModalComponent, {
      data: {
        compilationId: compilation.id,
      },
      width: '1720px',
    });
  }
}
