import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NavigationEnd, Router, RouterEvent } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { FolderUtils } from "src/app/common/folder-utils";
import { AdminCompilationService } from "src/app/services/admin/admin-compilation.service";
import { FolderService } from "src/app/services/admin/folder.service";
import { HelperService } from "src/app/services/admin/helper.service";
import { UploadService } from "src/app/services/admin/upload.service";
import { IAdminCompilation, IEssenceValue, IFolder } from "../../models/admin";

export interface File {
  data: any;
  progress: number;
  inProgress: boolean;
}
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit, OnDestroy {
  @ViewChild("fileInput") fileInput!: ElementRef;

  isSidebarOpen = true;
  isActionModal = false;
  isDropDown = false;
  isCompilationModal = false;
  isCatalog = false;
  isEssenceDropdown = false;
  isCompilationEssence = false;

  isFolderCreationModalVisible = false;
  isFolderInfoModalVisible = false
  isFoldersTabVisible = true
  isCompilationInfoModalVisible = false

  activeEssenceName = "Папки";

  currentId: number

  chosenCompilationId: number
  chosenFolderId: number

  folders: IFolder[];
  compilations: IAdminCompilation[] = [];

  file: File = {
    data: null,
    inProgress: false,
    progress: 0,
  };

  routerSubscription: Subscription

  essenceValues: IEssenceValue[] = [
    {
      id: 0,
      name: "Папки",
    },
    {
      id: 1,
      name: "Подборки",
    },
    {
      id: 2,
      name: "Каталог",
    },
  ];

  state:boolean;

  constructor(
    private helperService: HelperService,
    private router: Router,
    private readonly compilationService: AdminCompilationService,
    private readonly folderService: FolderService,
    private readonly uploadService: UploadService,
  ) { }

  async ngOnInit() {
    this.routerSubscription = this.router.events.subscribe((event: any) => this.handleUrlChange(event));

    this.folders = await this.folderService.getRootFolders()
    this.folders = FolderUtils.sortFoldersByHierarchy(this.folders)

    this.compilations = (await this.compilationService.getCompilations()).items;

    const isFirstEntranceInPanel = this.router.url.split("/").pop() === "workspace"

    if (isFirstEntranceInPanel) {
      this.navigateToFirstFolder()
      return
    }

    // Restoring data after page reload or reopening
    this.currentId = this.getCurrentId()
    this.defineActiveEssence()
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe()
  }

  toggleNewFolderModal() {
    this.isFolderCreationModalVisible = !this.isFolderCreationModalVisible;
    this.isActionModal = false;
  }

  toggleCompilationModal() {
    this.isCompilationModal = !this.isCompilationModal;
    this.isActionModal = false;
  }

  onDropdownOutside(e: any) {
    if (e.target?.className !== "sort-wrapper-active") {
      this.isDropDown = false;
    }
  }

  onActionOutside(e: any) {
    if (e.target?.className !== "active") {
      this.isActionModal = false;
    }
  }

  onEssenceOutside(e: any) {
    if (e.target?.className !== "essence-wrapper-active") {
      this.isEssenceDropdown = false;
    }
  }

  onFileSelected(event: any) {
    const reqPromiseArr: Array<Observable<any>> = [];
    const fileList = event.target.files;

    this.uploadService.setLoadingImages([...fileList]);

    Array.prototype.forEach.call(fileList, (file) => {

      const imagesData = new FormData();
      imagesData.append("images", file);

      const request = this.uploadService.uploadImages(
        imagesData,
        this.currentId
      );

      reqPromiseArr.push(request);
    });

    this.isActionModal = false;

    (async () => {
      for (let reqPromise of reqPromiseArr) {
        await reqPromise.toPromise();
      }
    })();
  }

  onFolderSelected(event: any) {
	  const newFolderName = event.target.files[0].webkitRelativePath.split('/')[0]
    let validFiles = this.helperService.imageSorter(event.target.files);

	  const images: any[] = [];
    const imagesData = new FormData();

    Array.prototype.forEach.call(validFiles, (file) => {
      imagesData.append("images", file);
      images.push(file.name);
    });

    this.uploadService.setLoadingImages(images);
	  this.uploadService.uploadFolderWithImages(newFolderName, this.currentId, imagesData)
    this.isActionModal = false;
  }

  onEssenceToggle(essenceValue: string) {
    this.activeEssenceName = essenceValue;
    this.isEssenceDropdown = false;
  }

  navigateToFirstFolder() {
    this.router.navigate(['/workspace/folders/1'])
  }

  toggleCompilationInfoModal(compilationId: number = null) {
    this.chosenCompilationId = compilationId;
    this.isCompilationInfoModalVisible = !this.isCompilationInfoModalVisible;
  }

  toggleFolderInfoModal(folderId: number = null) {
    this.chosenFolderId = folderId;
    this.isFolderInfoModalVisible = !this.isFolderInfoModalVisible
  }

  reloadPage() {
    // Reload current page
    setTimeout(() => location.reload(), 0);
  }

  async updateCompilations() {
    this.compilations = (await this.compilationService.getCompilations()).items
  }

  async updateFolder(newFolder: IFolder) {
    FolderUtils.updateFolderInHierarchy(this.folders, newFolder)
  }

  toggleFoldersTabs() {
    this.isFoldersTabVisible = !this.isFoldersTabVisible

    if (!this.isFoldersTabVisible) {
      this.openFolder(1)
    }
  }

  openFolder(folderId: number) {
    this.router.navigateByUrl("/workspace/folders/" + folderId)
  }

  private handleUrlChange(event: RouterEvent) {
    if (event instanceof NavigationEnd) {
      this.currentId = this.getCurrentId()
      this.isFoldersTabVisible = true
      this.defineActiveEssence()
    }
  }

  private getCurrentId(): number {
    const currentId = this.router.url.split('/').pop()
    return +currentId;
  }

  private defineActiveEssence() {
    const isCompilationInstance = this.router.url.split("/").includes("compilations");
    const isCatalogInstance = this.router.url.split("/").includes("catalog");

    if (isCompilationInstance) {
      this.activeEssenceName = "Подборки";
      return
    }

    if (isCatalogInstance) {
      this.activeEssenceName = "Каталог";
      return
    }

    const restored = FolderUtils.restoreOpenedFolders(this.folders, this.currentId)

    if (restored != null) {
      this.folders = restored
    }
  }
}
