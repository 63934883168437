<div class="photo-info">
  <h3 class="photo-info-title">
    <a
      [routerLink]="['/photos', photo.friendlyURL || photo.id]"
      [queryParams]="{ search: this.searchQuery, page: this.searchPage }"
      title="Перейти к фотографии"
      class="link"
    >
      {{ photo.title }}
    </a>

    <a
      *ngIf="photo.coordinates"
      [routerLink]="['/search-page']"
      [queryParams]="{  search: photo.coordinates }"
      title="Найти все фотографии локации"
      class="photo-location"
    >
      {{ photo.coordinates }}
    </a>
  </h3>

  <div class="photo-info__buttons">
    <button
      class="btn photo-info__cart-btn photo-info__cart-btn-show-769"
      appButtonState="cart"
      [item]="photo"
      [isItemInStorage]="checkItemInCart(photo)"
      title="Добавить фото в корзину"
    >
      В корзину
    </button>

    <button
      class="btn photo-info__cart-btn photo-info__cart-btn-hide-769"
      appButtonState="cart"
      [item]="photo"
      [isItemInStorage]="checkItemInCart(photo)"
      [enableStyleHandling]="false"
      title="Добавить фото в корзину"
    >
      <img
        class="photo-info__cart-icon"
        src="../../../../assets/icons/cart.svg"
      />
    </button>

    <button
      class="photo-info__favorite-btn"
      appButtonState="favorite"
      [isItemInStorage]="checkItemInFavorites(photo)"
      [item]="photo"
      title="Добавить фото в избранные"
    >
      <svg class="photo-info__favorite-icon">
        <use xlink:href="../../../../assets/sprite.svg#heart"></use>
      </svg>
    </button>

    <button
      (click)="toggleCarousel()"
      class="photo-info__favorite-btn photo-info__magnify-btn"
      title="Увеличить"
    >
      <svg class="photo-info__favorite-icon">
        <use xlink:href="../../../../assets/sprite.svg#magnify-plus"></use>
      </svg>
    </button>
  </div>
</div>
