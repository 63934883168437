<app-header [isBlack]="true" [isSearch]="true"></app-header>

<section class="geo-catalog container">
  <h1 class="title">Каталог</h1>

  <app-geo-catalog></app-geo-catalog>

  <div class="description main__description">
    <h2 class="description__title">Gelio — фотобанк по городам России</h2>

    <div class="description__content">
      <div>
        <p class="description__text">
          Фотобанк по городам России Gelio Photo объединяет более 15 тысяч
          изображений с высоты птичьего полета! В фотобанке вы сможете купить
          качественные фотографии российских городов для наполнения сайта,
          буклетов, презентаций, корпоративного календаря, книги, создания
          рекламного баннера или для оформления интерьера.
        </p>
        <p class="description__text">
          Каталог сделан на основе работ фотографа Славы Степанова и включает
          оригинальный взгляд на достопримечательности и знаковые объекты в
          более чем 50 российских городах.
        </p>
        <p class="description__text">
          Фотобанк Gelio объединяет панорамные фотографии городов России,
          созданные в разное время суток и года. Вы найдете красивые изображения
          ночного города, мегаполисов зимой, на рассвете или закате и т. п.
        </p>
      </div>

      <!-- /.description__large-text-wrapper -->
      <div>
        <p class="description__text">
          Цена фото зависит от вида и срока использования, а также от разрешения
          файла. Чтобы купить фото города с высоты, добавьте изображения в
          корзину и оставьте заявку.
        </p>
        <p class="description__text">
          Российский фотобанк Gelio Photo — это большой каталог
          высококачественных изображений городов, снятых с уникальных ракурсов
          при помощи аэрофотосъемки и профессиональной фототехники.
        </p>
        <p class="description__text">
          Если ваш запрос звучит как «куплю фото городов», значит фотобанк Gelio
          Photo отлично подойдет для ваших задач. Однако если вы не нашли на
          сайте подходящее изображение, свяжитесь с нами любым удобным способом.
        </p>
      </div>
      <!-- /.description__small-text-wrapper -->
    </div>
    <!-- /.description__content -->
  </div>
</section>

<app-footer></app-footer>
