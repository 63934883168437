<app-admin-header [withSearch]="false"></app-admin-header>

<div class="directory">
    <div class="directory__title">
      <h1>Редактирование подборок</h1>
    </div>

    <app-admin-compilations-table
      [l1Cities]="l1Cities"
      [createL1City]="createL1City"
      [createL2City]="createL2City"
      [updateL1City]="updateL1City"
      [updateL2City]="updateL2City"
      [deleteL1City]="deleteL1City"
      [deleteL2City]="deleteL2City"
      [createCompilation]="createCompilation"
      [updateCompilation]="updateCompilation"
      [deleteCompilation]="deleteCompilation"
    ></app-admin-compilations-table>
</div>
