import { Direction } from './sorting-model';

export type ComparatorFn<T = any> = (a: T, b: T, direction?: Direction) => number;

/**
 * Naive JS comparator, casts arguments to Number and returns their difference
 */
export const naiveComparator: ComparatorFn = (a: any, b: any) => +a - +b;

export function wrapWithExtractor<TargetType = any, SourceType = any>(
  comparator: ComparatorFn<TargetType>,
  extractor: (item: SourceType) => TargetType
) {
  const wrappedComparator = (a: SourceType, b: SourceType, direction?: Direction) => {
    return comparator(extractor(a), extractor(b), direction);
  };
  return wrappedComparator;
}
