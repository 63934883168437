<article class="order">
  <div class="order__headers">
    <h4>Заказ #{{ order.id }}</h4>
    <h5>
      <!-- {{ order.status }} -->
    </h5>
  </div>

  <div
    class="order-item order-item--position"
    *ngFor="let photo of order.__images__ | slice: 0:size; let i = index"
  >
    <div class="order-item__image-wrapper">
      <img
        [routerLink]="['/photos', photo.id]"
        [src]="photo.img"
        alt="Товар в корзине: Элемент корзины"
        class="order-item__img"
        appLazyLoad
      />
      <div
        class="order-item__image-wrapper-hover"
        *ngIf="extra && i === 3"
        (click)="showAllPhotos()"
      >
        +{{ extra }}
      </div>
    </div>
    <!-- /.order-item__image-wrapper -->
    <div class="order-item__text-wrapper">
      <h4 class="order-item__title">{{ photo.name }}</h4>
      <!-- <span class="order-item__image-size order-item__image-size--mobile"
          >5472 × 3648, JPG
        </span> -->
      <div class="order-item__price-wrapper">
        <!-- <legend class="order-item__price">{{ 10 | currency }}</legend> -->
        <!-- <span class="order-item__image-size">5472 × 3648, JPG </span> -->
      </div>
      <!-- /.order-item__price-wrapper -->
    </div>
    <!-- /.order-item__text-wrapper -->
  </div>
  <!-- /.order-item order-item--position -->
</article>
