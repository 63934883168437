import { Injectable, Inject, PLATFORM_ID  } from "@angular/core";
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

import { LocalStorage } from "src/libs/storage/src";
import { IAPIResponse, IPasswordChange, ITokens } from "../models/models";
import { IRegisterViaEmail } from "../models/register-vie-email";
import { BaseRequestService } from "./base-request.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})

export class AuthService{
  baseUrl = "/auth"

  get accessToken() {
    return this._localStorage.getItem('accessToken')
  }

  get refreshToken() {
    return this._localStorage.getItem('refreshToken')
  }
  
  constructor(
    private readonly requestService: BaseRequestService,
    private readonly _localStorage: LocalStorage,
    private readonly userService: UserService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
  }

  async logIn(login: string, password: string) { 
    const responseTokens = await this.requestService.post<ITokens>(`${this.baseUrl}/signin`, { login: login, password: password })
    this.saveTokens(responseTokens)
    return responseTokens
  }

  async registerViaEmail(registrationDetails: IRegisterViaEmail) { 
    const responseTokens = await this.requestService.post<ITokens>(`${this.baseUrl}/signup-email`, registrationDetails)
    this.saveTokens(responseTokens)
  }

  logOut() {
    this._localStorage.removeItem('accessToken')
    this._localStorage.removeItem('refreshToken')
  }

  addUser(data: object) {
    return this.requestService.post(`${this.baseUrl}/add-user`, data)
  }

  isLogged(): boolean {
    return this.isServer() || this.accessToken != null && this.accessToken.length > 0;
  }

  async isAdmin(): Promise<boolean> {

    if (this.isServer()) {
      return true;
    }

    const user = await this.userService.getMe() 
    return user.role !== "USER"
  }

  async getUserRole(): Promise<String> {
    const user = await this.userService.getMe()
    return user.role
  }

  async tryToRefreshToken() {
    if (!this.isLogged()) {
      return
    }

    try {
      this.requestNewTokens()
    } catch (error) {
      this.logOut()
      throw error
    }
  }

  changePassword(data: IPasswordChange | object) {
    return this.requestService.patch(`${this.baseUrl}/password`, data)
  }  

  async resetPassword(token: string, password: string) {
    const responseTokens = await this.requestService.patch<ITokens>(`${this.baseUrl}/reset-password`, { token: token, password: password })  
    this.saveTokens(responseTokens)
  }

  sendRestorationEmail(email: string) {
    return this.requestService.post(`${this.baseUrl}/reset-password`, { email: email })
  }

  private async requestNewTokens() {
    const tokensPayload = {
      accessToken: this.accessToken, 
      refreshToken: this.refreshToken 
    }

    try {
      const responseTokens = await this.requestService.post<ITokens>(`${this.baseUrl}/refresh`, tokensPayload)
      this.saveTokens(responseTokens)
    } catch (error) {
      this.logOut()
    }
  }

  private saveTokens(tokens: ITokens) {
    if (tokens != null && tokens.accessToken && tokens.refreshToken) {
        this._localStorage.setItem('accessToken', tokens.accessToken)
        this._localStorage.setItem('refreshToken', tokens.refreshToken)
    } else {
      this.logOut()
    }
  }

  private isServer(): boolean {
    return isPlatformServer(this.platformId);
  }
}
