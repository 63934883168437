<dts-select-container
  (click)="onClick($event)"
  #container="dts-select-container"
  [selectOnDrag]="false"
  [selectOnClick]="false"
  [dragOverItems]="false"
  [disabled]="false"
  [selectWithShortcut]="false"
  (select)="onSelect($event)"
  [selectMode]="isSelectMode"
>
  <div class="directory__subtitle">
    <div class="directory__subtitle-statistic">
      <span>
        {{
          images?.length > 0
            ? images?.length + " файлов"
            : "Здесь пока нет фотографий"
        }}
      </span>
    </div>
  </div>

  <div class="directory__content">
    <div *ngIf="isListing" class="directory__listing-title">
      <div class="directory__listing-title-name" (click)="handleSort('NAME')">
        <p
          [class.sorting]="isSortedBy('NAME')"
          [class.sorting_arrowUp]="isSortOrderAsc === false"
        >
          Название
        </p>
      </div>

      <div class="directory__listing-title-tags"><p>Тэги</p></div>

      <div class="directory__listing-title-published"><p>Опубликовано</p></div>

      <div class="directory__listing-title-on-main-page"><p>На главной</p></div>

      <div class="directory__listing-title-date" (click)="handleSort('DATE')">
        <p
          [class.sorting]="isSortedBy('DATE')"
          [class.sorting_arrowUp]="isSortOrderAsc === false"
        >
          Дата и время добавления
        </p>
      </div>
      <div class="directory__listing-title-extension"><p>Формат</p></div>
      <!-- <div class="directory__listing-title-size">Размер файла</div> -->
    </div>

    <div *ngIf="!isListing" class="directory__tile-title">
      <div class="directory__tile-title-name" (click)="handleSort('NAME')">
        <p
          [class.sorting]="isSortedBy('NAME')"
          [class.sorting_arrowUp]="isSortOrderAsc === false"
        >
          Название
        </p>
      </div>
      <div class="directory__tile-title-date" (click)="handleSort('DATE')">
        <p
          [class.sorting]="isSortedBy('DATE')"
          [class.sorting_arrowUp]="isSortOrderAsc === false"
        >
          Дата и время добавления
        </p>
      </div>
      <!-- <div class="directory__listing-title-size">Размер файла</div> -->
    </div>

    <!-- <app-current-folder></app-current-folder> -->
    <div
      class="directory__items"
      [class.listing]="isListing"
    >
      <div
        *ngFor="let folder of folders"
        [dtsSelectItem]="folder"
        class="directory__items-item"
      >
        <app-admin-folder
          *ngIf="isFolderPage"
          [folder]="folder"
          [viewMode]="viewMode"
          (folderToggle)="toggleItemSelection($event)"
        ></app-admin-folder>
      </div>

      <div
        *ngFor="let image of images"
        (click)="$event.stopPropagation()"
        [dtsSelectItem]="image"
        class="directory__items-item"
      >
        <app-admin-image
          activeEssence="Папки"
          [folderItem]="image"
          [viewMode]="viewMode"
          (click)="toggleImageModal(image)"
          (toggleImage)="toggleItemSelection($event)"
        ></app-admin-image>
      </div>
    </div>
  </div>
</dts-select-container>

<app-admin-panel
  [selectedFiles]="selectedFiles"
  [compilationCoverId]="compilationCover?.id"
  (chooseAll)="chooseAllItems()"
  (unchooseAll)="unchooseAllItems()"
  (action)="handleAction($event)"
></app-admin-panel>

<app-image-modal
  *ngIf="isImageModalVisible"
  [image]="activeImage"
  (close)="toggleImageModal()"
  (imageChange)="changeImage($event)"
  (imageEdit)="replaceEditedImage($event)"
></app-image-modal>

<app-replace-modal
  *ngIf="isMoveModalVisible"
  [items]="selectedFiles"
  [currentFolderId]="currentId"
  (close)="toggleMoveImageModal()"
></app-replace-modal>

<app-to-compilation-modal
  *ngIf="isAddImagesToCompilationModalVisible"
  [images]="selectedImages"
  (close)="toggleAddImagesToCompilationModal()"
></app-to-compilation-modal>

<app-edit-modal
  *ngIf="isBulkEditModalVisible"
  [images]="selectedImages"
  (close)="toggleBulkEditModal()"
></app-edit-modal>

<app-delete-modal
  *ngIf="isDeleteFromImagesCompilationModalVisible"
  [deletingItemsIds]="selectedImagesIds"
  [compilationId]="currentId"
  modalContent="ImagesFromCompilation"
  (close)="toggleDeleteImagesFromCompilationModalVisible()"
></app-delete-modal>

<app-loaded-popup
  *ngIf="isLoadedBarOpen"
  (imagesLoaded)="addLoadedImages($event)"
  (openBulkEditor)="openBulkEditor()"
  (openImageModal)="openImageModal($event)"
></app-loaded-popup>
