import { CatalogCity } from './catalog-city';

export class CatalogL2City extends CatalogCity {
  constructor(
    public parentCityId: number,
    name: string = '',
    searchString: string = '',
  ) {
    super();

    this.name = name;
    this.searchString = searchString;
  }

  order: number;
  
  showOnMainPage = true;
}
