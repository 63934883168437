<app-admin-modal (onClose)="closeModal()">
    <form
        (ngSubmit)="submit()"
        [formGroup]="form"
        autocomplete="off"
    >
      
    <h3 class="form__title">Установить пароль</h3>

            <div class="form__input-wrapper">
              <input
                (input)="checkPasswordMatch()"
                formControlName="password"
                [type]="isPasswordVisible ? 'text' : 'password'"
                class="form__input"
                [ngClass]="password.value.length? 'active': ''" 
                #password
              />
              <small class="form__text">Пароль</small>
              <div class="form__icons-wrapper" [ngSwitch]="isPasswordVisible">
                <svg
                  *ngSwitchCase="false"
                  (click)="changeInputType()"
                  class="form__eye-icon"
                >
                  <use xlink:href="../../../assets/sprite.svg#eye-on"></use>
                </svg>
                <svg
                  *ngSwitchCase="true"
                  (click)="changeInputType()"
                  class="form__eye-icon"
                >
                  <use xlink:href="../../../assets/sprite.svg#eye-off"></use>
                </svg>
              </div>
            </div>
            <!-- /.form__input-wrapper -->
      
            <ul class="form__errors-list" *ngIf="form.get('password')?.invalid && form.get('password')?.touched">
              <li>
                <small class="form__error" *ngIf="form.get('password')?.touched && password.value.length < 6"
                  >Пароль должен содержать 6 символов
                </small>
              </li>
              <li>
                <small class="form__error" *ngIf="form.get('password')?.hasError('pattern')"
                  >Пароль содержит недопустимые символы</small
                >
              </li>
            </ul>
      
            <div class="form__input-wrapper">
              <input
                (input)="checkPasswordMatch()"
                formControlName="passwordConfirmation"
                [type]="isPasswordVisible ? 'text' : 'password'"
                class="form__input"
                [ngClass]="repeatPassword.value.length? 'active': ''" 
                #repeatPassword
              />
              <small class="form__text">Повторите пароль</small>
            </div>
            <!-- /.form__input-wrapper -->
            <p class="form__error" *ngIf="!isPasswordMatch && form.get('passwordConfirmation')?.dirty"
              >Пароли не совпадают</p
            >


            <p *ngIf="isErrorVisible" class="form__alert">
              Не удалось сохранить данные
            </p>
            <p *ngIf="isSuccessMessageVisible" class="form__message">
              Сохранено
            </p>

            
            <div class="form__buttons-wrapper">
                <button
              class="btn form__cancel-button"
              (click)="closeModal()"
            >
              Отмена
            </button>
            <button
              [disabled]="form.invalid || !isPasswordMatch || isRequestSend"
              type="submit"
              class="btn form__submit-button"
            >
              Сохранить
            </button>
            </div>
            
          </form>
</app-admin-modal>  