import { CartService } from "./../../services/cart.service";
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
//import { AuthService } from "src/app/services/auth.service";
import { HttpResponse } from "@angular/common/http";
import { OrderService } from "src/app/services/order.service";
import { IImage } from "src/app/models/image";
// import { BaseRequestService } from "src/app/services/base-request.service";

@Component({
  selector: "app-checkout",
  templateUrl: "./checkout.component.html",
  styleUrls: ["./checkout.component.scss"],
})
export class CheckoutComponent {
  orderItems: IImage[] = [];

  // form: FormGroup;
  // isAccountExist = false;
  // isLoginPopupVisible = false;

  constructor(
  //   private authService: AuthService,
  //   private _cartService: CartService,
  //   private orderService: OrderService,
  //   private router: Router
   ) {
  //   this.form = new FormGroup({
  //     name: new FormControl("", [Validators.required, Validators.minLength(2)]),
  //     email: new FormControl(null, [Validators.required, Validators.email]),
  //     phoneNumber: new FormControl(null, [
  //       Validators.required,
  //       Validators.minLength(11),
  //     ]),
  //   });
   }

  // ngOnInit(): void {
  //   this.getAllcartItems();
  // }

  // async getAllcartItems() {
  //   await this._cartService.getAllCartItems().then((items) => {
  //     console.log(items);
  //     let tempPhoto: IImage;
  //     for (let img of items.data.images) {
  //       tempPhoto = {
  //         id: img.id,
  //         img: "/src/assets/images/gavan.jpg",
  //         name: img.name,
  //         __tags__: [""],
  //         author: img.author,
  //         description: img.description,
  //       };
  //       this.orderItems.push(tempPhoto);
  //     }
  //   });
  // }

  // openLoginPopup() {
  //   console.log("Open login");
  // }

  // handleError(error: HttpResponse<any>) {
  //   console.log("Ошибка", error);
  // }

  // submit() {
  //   const formData = this.form.value;
  //   //let orders = this.orderProducts.map(x => x.id)
  //   let orderItems: number[] = []; //this.orderProducts
  //   console.log("Заказы: ", orderItems);

  //   console.log(formData);

  //   if (this.localStorage.getItem("token")) {
  //     console.log("Пользователь авторизован");
  //     this.orderService.createOrder(orderItems).then(
  //       (result: any) => {
  //         console.log(result);
  //         console.log("Successful request");
  //       },
  //       (error: HttpResponse<any>) => this.handleError(error)
  //     );
  //     console.log("done");
  //     this.router.navigate(["/cabinet"]);
  //   } else {
  //     console.log("Пользователь не авторизован");
  //     let registered = false;
  //     // this.authService.createUserWithoutPassword()
  //     //   .then((result) => {
  //     //     registered = true
  //     //   },
  //     //     (error) => handleError(error)
  //     //     })

  //     if (registered) {
  //       console.log("Пользователь уже зарегистрирован, но не авторизован");
  //       this.isAccountExist = true;
  //     } else {
  //       console.log("Новый пользователь зарегистрирован");
  //       this.orderService.createOrder(orderItems).then(
  //         (result: any) => {
  //           console.log(result);
  //           console.log("Successful request");
  //         },
  //         (error: HttpResponse<any>) => this.handleError(error)
  //       );
  //       this.router.navigate(["/cabinet"]);
  //     }
  //   }
  // }
}
