import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { CompanyContacts } from "src/app/models/company-contacts";
import { INavbarLink } from "src/app/models/navbar-link";
import { environment } from "src/environments/environment";


@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  citiesList = [
    ["Краснодар", "Уфа", "Красноярск", "Томск"],
    ["Берлин", "Лондон", "Штутгард", "Париж"],
    ["Токио", "Иерусалим", "Бангкок", "Киев"],
    ["Россия", "США", "Тайланд", "Китай"],
    ["Австрия", "Япония", "Бельгия", "Германия"],
    ["Монголия", "Израиль", "ОАЭ", "Латвия"],
  ];

  navbarLinks: INavbarLink[] = [
    {
      label: "Каталог",
      href: "/catalog",
    },
    {
      label: "О фотостоке",
      href: "/about",
    },
    {
      label: "Цены",
      href: "/prices",
    },
    {
      label: "Подборки",
      href: "/blog",
    },
    {
      label: "Как купить",
      href: "/how-to-buy",
    },
    {
      label: "Сотрудничество",
      href: "/partnership",
    },
    {
      label: "Контакты",
      href: "/contacts",
    },
    {
      label: "Пользовательское соглашение",
      href: "/terms-of-use",
    },
  ];

  year = 2021

  companyContacts: CompanyContacts;

  constructor(
    private router: Router,
  ) {}

  async ngOnInit() {
    this.year = new Date().getFullYear();

    this.companyContacts = {
      ...environment.contacts,
      tg: environment.contacts.tg?.replace(/[()\-\s]/g, ''),
      wa: environment.contacts.wa?.replace(/[^+\d]/g, ''),
    };
  }

  navigateToSearch(query: string) {
    this.router.navigate(["search-page"], {
      queryParams: {
        search: query,
      },
    });
  }
}
