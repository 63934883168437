<main class="hero">
  <app-header
    [useWhiteLogo]=true
    class="hero__header"
  ></app-header>

  <img
    class="hero__background"
    src="../../../assets/images/hero.bg.jpg"
    alt="Главный блок: Фоновая картинка"
  />

  <div class="container container--big hero__container">
    <h1 class="hero__title">Фотографии городов России и мира в большом разрешении</h1>

    <div class="hero__content-wrapper">
      <app-search
        backgroundColor="white"
        placeholder="Поиск изображений"
        classes="search-form__search"
      ></app-search>

      <div class="popular hero__popular">
        <h5 class="popular__text">Популярные:</h5>

        <ul class="popular__list">
          <li
            *ngFor="let suggestion of suggestions"
            class="popular__list-item"
          >
            <a (click)="navigateToSearch(suggestion)" class="popular__list-link"
              >{{ suggestion }}</a
            >
          </li>
        </ul>
        <!-- /.popular__list -->
      </div>
      <!-- /.popular hero__popular -->
    </div>
    <legend class="hero__photo-text">
      На фото: <a [routerLink]="'/photos/zolotoy-most-vo-vladivostoke-s-vysoty-135822439'" class="hero__photo-value">Золотой мост во Владивостоке</a>
    </legend>
  </div>
  <!-- /.container container--big hero__container -->
</main>
<!-- /.hero -->
