import { IAdminCompilation, ICompilationInfo, ISeoInfo, Tag } from "src/app/models/admin";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ModalService } from "src/app/services/admin/modal.service";
import { AdminCompilationService } from "src/app/services/admin/admin-compilation.service";
import { AdminService } from "src/app/services/admin/admin.service";
import { TagService } from "src/app/services/admin/tag.service";

@Component({
  selector: "app-compilation-info-modal",
  templateUrl: "./compilation-info-modal.component.html",
  styleUrls: ["./compilation-info-modal.component.scss"],
})
export class CompilationInfoModalComponent implements OnInit {

  isPublished = false;
  onMainPage = false;
  isSeoInfoModal = false;

  currentCompilationInfo!: ICompilationInfo;

  @Input() currentCompilationId?: number;

  @Output() close = new EventEmitter();
  @Output() compilationEdited = new EventEmitter();

  form: FormGroup = new FormGroup({
    title: new FormControl("", [Validators.required]),
    description: new FormControl(),
    tags: new FormControl(""),
    seoTitle: new FormControl(""),
    seoDescription: new FormControl(""),
    keywords: new FormControl(""),
    language: new FormControl("RU"),
  });


  // seoInfo displayed on window of ONE language
  seoInfo: ISeoInfo | undefined

  constructor(
    private modalService: ModalService,
    private adminService: AdminService,
    private readonly tagService: TagService,
    private compilationService: AdminCompilationService,
  ) { }

  async ngOnInit() {
    this.currentCompilationInfo = await this.compilationService.getCompilationInfo(this.currentCompilationId);
    this.isPublished = this.currentCompilationInfo.published;
    this.onMainPage = this.currentCompilationInfo.onMainPage;

    this.setFormFieldsValues()
  }

  async editCompilation() {

    const newCompilationInfo = await this.compilationService.updateCompilation(
      this.currentCompilationInfo.id,
      this.getCompInfoFromForm()
    );

    await this.updateCompilationSeo()

    this.closeModal();
    this.compilationEdited.emit()
  }

  async updateCompilationSeo() {
    if (this.didSeoFieldsChange()) {
      this.setCurrentCompSeo(this.seoInfo)
      return;
    }

    if (this.seoInfo && this.seoInfo?.id) {
      let response = await this.adminService.updateSeoTag(this.getSeoInfoFromForm(), this.seoInfo.id)
      this.seoInfo = response
      this.setCurrentCompSeo(response)

    } else {
      this.compilationService.addSeoToCompilation(this.getSeoInfoFromForm())
    }
  }

  setCurrentCompSeo(newSeo: ISeoInfo | undefined) {
    if (!newSeo) {
      return;
    }

    let oldSeo = this.currentCompilationInfo.__seoInfo__.find(seo => seo.language === newSeo.language)

    if (oldSeo) {
      let index = this.currentCompilationInfo.__seoInfo__.indexOf(oldSeo)
      this.currentCompilationInfo.__seoInfo__[index] = newSeo
    } else {
      this.currentCompilationInfo.__seoInfo__.push(newSeo)
    }
  }

  setFormFieldsValues() {
    this.form.get("title")?.patchValue(this.currentCompilationInfo.title);
    this.form
      .get("description")
      ?.patchValue(this.currentCompilationInfo.description);

    this.toggleFormSeoLanguage("RU")
  }

  // SEO functions
  toggleFormSeoLanguage(lang: "RU" | "EN" | string) {

    if (
      this.currentCompilationInfo.__seoInfo__ &&
      this.currentCompilationInfo.__seoInfo__.length
    ) {

      // Must be in function
      this.seoInfo =
        this.currentCompilationInfo.__seoInfo__
          .find(
            seo => seo.language === lang ? seo : null
          )

      this.form.get("seoTitle")?.patchValue(this.seoInfo?.title)
      this.form.get("seoDescription")?.patchValue(this.seoInfo?.description)
      this.form.get("keywords")?.patchValue(this.seoInfo?.keywords)
      this.form.get("language")?.patchValue(lang)
    }
  }

  didSeoFieldsChange(): boolean {
    if (
      (this.seoInfo?.title === this.form.value.seoTitle &&
        this.seoInfo?.description === this.form.value.seoDescription &&
        this.seoInfo?.keywords === this.form.value.keywords) ||
      (!this.form.value.seoTitle &&
        !this.form.value.seoDescription &&
        !this.form.value.keywords)
    ) {
      return false
    } else {
      return true
    }
  }

  // Object generators from form's inputs
  getCompInfoFromForm() {
    let newCompInfo = {
      title: this.form.value.title,
      description: this.form.value.description,
      published: this.isPublished,
      onMainPage: this.onMainPage,
    };

    if (this.currentCompilationInfo.title === newCompInfo.title) {
      delete newCompInfo.title;
    }

    return newCompInfo
  }

  getSeoInfoFromForm() {
    if (this.seoInfo && this.seoInfo.id) {
      return {
        title: this.form.value.seoTitle,
        description: this.form.value.seoDescription,
        keywords: this.form.value.keywords
      }
    }

    return {
      title: this.form.value.seoTitle,
      description: this.form.value.seoDescription,
      keywords: this.form.value.keywords,
      language: this.form.value.language,
      compilationId: this.currentCompilationInfo.id,
    };
  }

  getTagFromForm() {
    return {
      compilationId: this.currentCompilationInfo.id,
      tagName: this.form.value.tags,
      tagType: "HASHTAG",
    }
  }

  async addTagToCompilation() {
    if (this.form.value.tags === "") {
      return;
    }

    const res = await this.tagService.addTagToCompilation(this.getTagFromForm());

    this.currentCompilationInfo.__tags__ = res;
    this.form.get("tags")?.patchValue("");
  }

  deleteTag(tagId: number) {
    this.currentCompilationInfo.__tags__ =
      this.currentCompilationInfo.__tags__.filter((tag) => tag.id !== tagId);

    this.compilationService.deleteTagFromComp(
      tagId,
      {
        compilationId: this.currentCompilationInfo?.id,
        tagType: "HASHTAG"
      }
    );
  }

  // Other helpful functions
  publishChange() {
    this.isPublished = !this.isPublished;
  }
  onMainPageChange() {
    this.onMainPage = !this.onMainPage;
  }
  deleteCompilation() {
    this.adminService.deletingItemsIds = [this.currentCompilationInfo.id]
    this.adminService.deleteModalContent = "Compilation"
    this.modalService.openDeleteModal()
    this.closeModal();
  }

  closeModal() {
    this.close.emit()
    // this.modalService.closeCompilationInfoModal();
  }
}
