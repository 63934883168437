import { CatalogCity } from './catalog-city';
import { CatalogL2City } from './catalog-l2-city';

export class CatalogL1City extends CatalogCity {
  l2Cities: CatalogL2City[] = [];

  constructor(
    name: string = '',
    public macroregion: string = '',
    public province: string = '',
    searchString: string = '',
  ) {
    super();

    this.name = name;
    this.searchString = searchString;
  }
}
