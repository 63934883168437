<div
  class="carousel-popup"
  [style.margin-left]="margin"
  (touchstart)="handleTouchStart($event)"
  (touchend)="handleTouchEnd($event)"
>
  <div class="photo-popup" *ngFor="let photo of photos" (click)="closePopup()">
    <div
      class="photo-popup__container"
    >
      <img
        [src]="getImageSource(photo)"
        [alt]="photo.altDescription"
        class="photo-popup__picture"
        (click)="$event.stopPropagation()"
      />

      <div
        class="photo-popup__meta"
      >
        <h3 class="photo-title">
          <a
            *ngIf="showGoToPhotoControl; else noLinkTitle"
            [routerLink]="['/photos', photo.friendlyURL]"
            [queryParams]="{ search: searchQuery, page: searchPage }"
          >
            {{ photo.title }}
          </a>

          <ng-template #noLinkTitle>
            {{ photo.title }}
          </ng-template>
        </h3>

        <a
          [routerLink]="['/search-page']"
          [queryParams]="{ search: photo.coordinates }"
          class="photo-coordinates"
          *ngIf="photo.coordinates"
        >
          <span class="photo-coordinates__icon"></span>

          <span>{{ photo.coordinates }}</span>
        </a>
      </div>
    </div>
  </div>

  <div class="photo-popup__buttons-wrapper">
    <div class="photo-popup__action-buttons">
      <button
        appButtonState="iconCart"
        [item]="photos[index]"
        [isItemInStorage]="checkIfImageInCart()"
        (click)="$event.stopPropagation()"
        class="photo-popup__round-button photo-popup__round-button--basket"
        title="Добавить в корзину"
      >
        <svg class="photo-popup__button-icon">
          <use xlink:href="../../../assets/sprite.svg#cart"></use>
        </svg>
      </button>

      <button
        appButtonState="favorite"
        [item]="photos[index]"
        [isItemInStorage]="checkIfImageInFavorites()"
        (click)="$event.stopPropagation()"
        class="photo-popup__round-button photo-popup__round-button--favorite"
        title="Добавить в избранное"
      >
        <svg
          class="photo-popup__button-icon photo-popup__button-icon-favorite"
        >
          <use xlink:href="../../../assets/sprite.svg#heart"></use>
        </svg>
      </button>

      <button
        class="
          photo-popup__round-button photo-popup__round-button--download
        "
        (click)="downloadImage(); $event.stopPropagation()"
        title="Скачать изображение"
      >
        <svg class="photo-popup__button-icon">
          <use xlink:href="../../../assets/sprite.svg#arrow-download"></use>
        </svg>
      </button>

      <a
        *ngIf="showGoToPhotoControl"
        [routerLink]="['/photos', photos[index].friendlyURL]"
        [queryParams]="{ search: searchQuery, page: searchPage }"
        (click)="closePopup()"
        title="Перейти на страницу фотографии"
        class="photo-popup__round-button photo-popup__round-button--download"
      >
        <svg class="photo-popup__button-icon">
          <use xlink:href="../../../assets/sprite.svg#image"></use>
        </svg>
      </a>
    </div>
    <!-- ./photo-popup__action-buttons -->

    <button
      class="photo-popup__close-btn"
      (click)="closePopup(); $event.stopPropagation()"
      title="Закрыть окно"
    >
      <svg class="photo-popup__close-btn-icon">
        <use xlink:href="../../../assets/sprite.svg#close"></use>
      </svg>
    </button>
    <!-- ./photo-popup__close-btn -->

    <div class="photo-popup__leaf-buttons-wrapper">
      <button
        class="photo-popup__round-button photo-popup__round-button--previous"
        (click)="previousPhoto(); $event.stopPropagation()"
        title="Предыдущая фотография"
      >
        <svg class="photo-popup__button-icon">
          <use xlink:href="../../../assets/sprite.svg#next"></use>
        </svg>
      </button>
      <button
        class="photo-popup__round-button photo-popup__round-button--next"
        (click)="nextPhoto(); $event.stopPropagation()"
        title="Следующая фотография"
      >
        <svg class="photo-popup__button-icon">
          <use xlink:href="../../../assets/sprite.svg#next"></use>
        </svg>
      </button>
    </div>
    <!-- ./photo-popup__leaf-buttons-wrapper -->

  </div>
  <!-- /.photo-popup__buttons-wrapper -->

</div>
