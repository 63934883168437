<div
  class="admin__item"
  [class.myselected]="folderItem?.isSelected"
  [class.listing]="isListing"
  [class.bigger-listing]="ViewMode.BiggerList === viewMode"
>
  <div #checkbox class="admin__item-checkbox">
    <app-checkbox
      [checked]="folderItem?.isSelected"
      (click)="toggleCheckbox(); $event.stopPropagation()"
    ></app-checkbox>
  </div>

  <div class="admin__item-image">
    <img src="{{ imageSrc }}" alt="" />
  </div>

  <ng-container *ngIf="isListing; else tile">
    <div class="admin__item-name">
      {{ folderItem?.name }}
    </div>

    <div #tags class="admin__item-tags">
      <div class="tags">
        <form
          [formGroup]="form"
          (click)="$event.stopPropagation()"
        >
          <ul>
            <li *ngFor="let tag of folderItem.__tags__">
              <div class="tags__content">
                <span>{{ tag.name }}</span>
              </div>

              <div
                class="tags__actions"
                (click)="deleteTag(tag.id); $event.stopPropagation()"
                #deleteButton
              >
                <button type="button">&#10761;</button>
              </div>
            </li>

            <li class="tags__input" [class.outlined]="true">
              <input
                type="text"
                placeholder="Добавить тег"
                (keyup.enter)="addTagToImage()"
                formControlName="tags"
              />
            </li>
          </ul>
        </form>
      </div>
    </div>

    <div class="admin__item-published">
      <app-checkbox
        [disabled]="true"
        [checked]="true == folderItem.published"
      ></app-checkbox>
    </div>

    <div class="admin__item-on-main-page">
      <app-checkbox
        [disabled]="true"
        [checked]="true == folderItem.onMainPage"
      ></app-checkbox>
    </div>

    <div class="admin__item-dateWrapper">
      <div class="admin__item-date">
        {{ folderItem?.uploadDate | date: "dd.MM.YYYY" }}
      </div>

      <div class="admin__item-time">
        {{ folderItem?.uploadDate | date: "HH:mm" }}
      </div>
    </div>

    <div class="admin__item-extension">
      {{ folderItem?.format }}
    </div>
  </ng-container>

  <ng-template #tile>
    <div class="admin__item-details">
      <div class="admin__item-details-name">
        {{ folderItem?.name }}
        <div class="extension">
          {{ folderItem?.format }}
        </div>
      </div>

      <div
        class="admin__item-details-tags"
        *ngIf="folderItem?.__tags__?.length"
      >
        <a class="tag" *ngFor="let tag of folderItem?.__tags__"
          >{{ tag.name }},
        </a>
      </div>
    </div>
  </ng-template>
</div>
