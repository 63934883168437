<app-header [isBlack]="true" [isSearch]="true"></app-header>

<section class="about container">
  <h1 class="title">О фотостоке</h1>

  <h2><b>geliophoto.ru</b> — фотобанк о городах и регионах России.</h2>
  <p>
    Наша команда более 15 лет системно занимается фотосъёмкой городов и
    регионов. За эти годы мы сформировали большое актуальное портфолио с
    фотографиями России. В фотостоке <b>geliophoto.ru</b> собраны снимки с
    максимально «открыточными» видами российских регионов, каждый из которых
    может стать ключевым объектом вашего проекта.
  </p>
  <p>
    Фотобанк
    <a href="https://geliophoto.ru/" target="_blank" rel="noopener norefferer">
      geliophoto.ru
    </a>
    — проект компании
    <a href="https://geliomedia.ru/" target="_blank" rel="noopener norefferer">
      Gelio Media
    </a>
    . Фотобанк сделан на базе портфолио фотографа и блогера Славы Степанова
    (Gelio).
  </p>

  <h2>Gelio Photo — фотобанк красивых фотографий о России</h2>
  <p>
    Сегодня любой сайт, интернет-проект, буклет или книга требуют качественного
    контента.
  </p>

  <h2>Наш фотобанк пригодится вам, если:</h2>
  <ul>
    <li>
      <p>
        — вы делаете сайт и хотите подобрать ключевое (заглавное) фото для
        главной страницы сайта;
      </p>
    </li>
    <li>
      <p>
        — вам требуется подборка фотографий, чтобы показать регионы присутствия
        вашей компании;
      </p>
    </li>
    <li>
      <p>— деятельность вашей компании связана с разными регионами России;</p>
    </li>
    <li>
      <p>— вы делаете буклет, печатное издание или готовитесь к выставке;</p>
    </li>
    <li>
      <p>
        — вы нашли в фотобанке фотографию вашего офиса, здания, объектов,
        которые вас интересуют;
      </p>
    </li>
    <li>
      <p>— вам нужен актуальный фотоконтент по России.</p>
    </li>
  </ul>

  <app-contact-form></app-contact-form>
</section>

<app-footer></app-footer>
