import { Injectable } from "@angular/core";
//import { MatSnackBar } from "@angular/material/snack-bar";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { environment } from "./../../environments/environment";
import { IAPIResponse } from "../models/models";
import { LocalStorage } from "src/libs/storage/src";

export type Paginated<T> = {
  items: T[];
  total: number;
}

@Injectable({
  providedIn: "root",
})
export class BaseRequestService {
  constructor(
    public http: HttpClient, //public _snackBar: MatSnackBar,
    public localStorage: LocalStorage
  ) {}
  get<T>(url: string, headersObj?: any, useDataWrapper = true): Promise<T> {
    return this.request(url, "GET", headersObj, null, useDataWrapper);
  }

  post<T>(url: string, data: any, useDataWrapper = true): Promise<T> {
    return this.request(url, "POST", null, data, useDataWrapper);
  }

  delete<T>(url: string, data?: any, useDataWrapper = true): Promise<T> {
    return this.request(url, "DELETE", null, data, useDataWrapper);
  }

  patch<T>(url: string, data: any, useDataWrapper = true): Promise<T> {
    return this.request(url, "PATCH", null, data, useDataWrapper);
  }

  put<T>(url: string, data: any, useDataWrapper = true): Promise<T> {
    return this.request(url, "PUT", null, data, useDataWrapper);
  }

  private async request<T>(
    url: string,
    method: "GET" | "POST" | "DELETE" | "PATCH" | "PUT" = "GET",
    headersObj: any = {},
    data: any = null,
    useDataWrapper: boolean
  ): Promise<T> {
    let body;

    const headers = {
      Authorization: `Bearer ${this.localStorage.getItem("accessToken")}` || "",
      ...headersObj,
    };

    if (data) {
      headers["Content-Type"] = "application/json";
      body = data;
    }
    try {
      if (useDataWrapper) {
        const response = await this.http
          .request<IAPIResponse<T>>(method, environment.serverUrl + url, {
            headers,
            body,
          })
          .toPromise()
          .then(
            (response) => {
              return response;
            },
            (error) => {
              throw error;
            }
          );

        return response?.data;
      } else {
        const response = await this.http
          .request<T>(method, environment.serverUrl + url, {
            headers,
            body,
          })
          .toPromise();
        return response;
      }
    } catch (e) {
      const errorObject = e as HttpErrorResponse;
      //const errorData = errorObject.error as IAPIResponse<any>;
      // if (
      //   errorData != null &&
      //   errorData.errorMessage != null &&
      //   errorData.errorMessage !== ""
      // ) {
      //   this.openSnackBar(errorData.errorMessage, "error");
      // } else {
      //   this.openSnackBar(errorObject.message, "error");
      // }

      throw e;
    }
  }

  // private openSnackBar(message: string, action: string) {
  //   this._snackBar.open(message, action, {
  //     duration: 10000,
  //     verticalPosition: "top",
  //   });
  // }
}
