// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  siteUrl: "https://stage.geliophoto.ru",
  serverUrl: "https://stage.geliophoto.ru/api",
  imagesServerUrl: "https://stage.geliophoto.ru/public_images",
  imageCloudServerUrl: "https://stage.geliophoto.ru/public_images",
  contacts: {
    email: 'info@geliophoto.ru',
    tel: '+7 (499) 455-65-90',
    tg: 'geliophoto',
    wa: '+7 (985) 046-27-46',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
