import { CompilationCity, CompilationCityType } from './compilation-city';
import { CompilationL2City } from './compilation-l2-city';

export class CompilationL1City extends CompilationCity {
  l2Cities: CompilationL2City[] = [];

  get type(): CompilationCityType {
    return 'l1';
  }

  constructor(
    public readonly name: string = '',
  ) {
    super();

    this.name = name;
  }
}
