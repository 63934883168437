import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { ModalService } from "src/app/services/admin/modal.service";
@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.scss"],
})
export class AdminComponent implements OnInit, OnDestroy {
  isDeleteModal = false;

  deleteModalSubscription: Subscription;

  constructor(
    private modalService: ModalService,
  ) { }

  async ngOnInit() {    
    this.deleteModalSubscription = this.modalService
      .onDeleteToggle()
      .subscribe(value => setTimeout(() => this.isDeleteModal = value, 0));
  }

  ngOnDestroy() {
    this.deleteModalSubscription.unsubscribe();
  }
}
