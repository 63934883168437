<app-admin-modal (onClose)="closeModal()" [isScrollable]="true">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div class="header-title">
      <h2>Массовое редактирование</h2>
    </div>

    <div class="edit-modal__seo">
      <h3>SEO</h3>
      <div class="form-group">
        <label>
          Заголовок
          <div class="field">
            <input type="text" formControlName="seoTitle" />
          </div>
        </label>
      </div>
      <div class="form-group">
        <label>
          Описание
          <div class="field">
            <input type="text" formControlName="seoDescription" />
          </div>
        </label>
      </div>
      <div class="admin__item-tags">
        <label for="tagName">Ключевые слова</label>
        <div class="tags">
          <form [formGroup]="form">
            <ul>
              <li *ngFor="let tag of sameTags">
                <div class="tags__content">
                  <span>{{ tag.name }}</span>
                </div>
                <div
                  class="tags__actions"
                  (click)="deleteTag(tag); $event.stopPropagation()"
                  #deleteButton
                >
                  <button type="button">&#10761;</button>
                </div>
              </li>
              <li *ngFor="let tag of addedTags">
                <div class="tags__content">
                  <span>{{ tag.name }}</span>
                </div>
                <div
                  class="tags__actions"
                  (click)="deleteTag(tag); $event.stopPropagation()"
                  #deleteButton
                >
                  <button type="button">&#10761;</button>
                </div>
              </li>
              <li class="tags__input" [class.outlined]="true">
                <input
                  id="tagName"
                  type="text"
                  placeholder="Добавить тег"
                  (keyup.enter)="addTag(tagName.value)"
                  formControlName="tagName"
                  #tagName
                />
              </li>
            </ul>
          </form>
        </div>
      </div>
      <div class="form-group edit-modal__select">
        <label>
          Язык
          <div class="field">
            <select
              (change)="changeFormSeoTags(language.value)"
              formControlName="language"
              #language
            >
              <option value="RU">Русский</option>
              <option value="EN">English</option>
            </select>
          </div>
        </label>
      </div>
    </div>

    <div class="form-group form-group_small">
      <label>
        Цена
        <div class="field">
          <input type="number" formControlName="price" />
        </div>
      </label>
    </div>
    <div class="form-group">
      <label>
        Автор
        <div class="field">
          <input type="text" formControlName="author" />
        </div>
      </label>
    </div>
    <div class="form-group">
      <label>
        Комментарий
        <div class="field">
          <textarea rows="2" formControlName="description"></textarea>
        </div>
      </label>
    </div>
    <div class="form-group">
      <label>
        Геолокация
        <div class="field">
          <input type="text" formControlName="coordinates" />
        </div>
      </label>
    </div>

    <div class="controls">
      <div class="buttons">
        <button
          type="button"
          (click)="updateImages()"
          [disabled]="form.invalid"
          class="btn"
        >
          Сохранить
        </button>
      </div>
    </div>
  </form>
</app-admin-modal>
