import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { patterns } from 'src/app/common/patterns';
import { IUser } from 'src/app/models/models';
import { AdminService } from 'src/app/services/admin/admin.service';
import { ModalService } from 'src/app/services/admin/modal.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-edit-user-modal',
  templateUrl: './edit-user-modal.component.html',
  styleUrls: ['./edit-user-modal.component.scss']
})
export class EditUserModalComponent implements OnInit {

  isUserExist = false
  isErrorVisible = false
  isSuccessMessageVisible = false
  isRequestSend = false;

  id: number = 0

  userInfo: IUser = {
    id: 0,
    login: 'login',
    firstName: "Имя",
    lastName: "Фамилия",
    email: "",
    phone: "",
    patronymic: "",
    role: "EDITOR"
  }

  @Output() onClose = new EventEmitter()

  form: FormGroup = new FormGroup({
    firstName: new FormControl(this.userInfo.firstName, [Validators.required, Validators.minLength(3)]),
    login: new FormControl(this.userInfo.login, [
      Validators.required,
      Validators.minLength(4),
    ]),
    email: new FormControl(this.userInfo.email, [Validators.pattern(patterns.email)]),
    role: new FormControl(this.userInfo.role, [Validators.required])
  });

  constructor(
    private userService: UserService,
    private adminService: AdminService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.id = this.modalService.id
    this.getUserInfo()
  }

  async saveUserInfo() {
    this.isUserExist = false
    this.isErrorVisible = false
    this.isSuccessMessageVisible = false

    let newUserInfo = this.formUserInfo()

    // If object is empty, that means user didn't change fields, so function end work
    if (!Object.keys(newUserInfo).length) {
      return;
    }

    try {
      await this.userService.patchUser(this.id, newUserInfo)

      this.isSuccessMessageVisible = true

    } catch (e: any) {
      this.handleError(e)
    }
  }


  deleteUser() {
    this.adminService.setDeleteModalContent = "User";
    this.adminService.deletingItemsIds = [this.id];
    this.userService.setUserId = this.id
    this.modalService.openDeleteModal();
  }


  async getUserInfo() {
    try {
      this.userInfo = await this.userService.getUser(this.id)
      this.form.controls['login'].setValue(this.userInfo.login)
      this.form.controls['firstName'].setValue(this.userInfo.firstName)
      this.form.controls['email'].setValue(this.userInfo.email)
      this.form.controls['role'].setValue(this.userInfo.role)

    } catch (error) {
      console.log(error);
    }
  }

  // Forms user of user's inputed values
  formUserInfo(): object {

    let newUserInfo: any = {}

    for (let key of ["login", "firstName", "email", "role"]) {

      if (this.form.value[key] !== this.userInfo[key] && this.form.value[key]) {

        newUserInfo[key] = this.form.value[key]
        this.userInfo[key] = this.form.value[key]
      }
    }

    return newUserInfo
  }

  handleError(error: Error) {
    if(error instanceof HttpErrorResponse) {
      switch (error.status) {
        case 409:
          this.isUserExist = true;
          return;
        case 403:
          break;
        case 400:
          break;
      }
    }
    console.log(error);
    this.isErrorVisible = true
  }

  closeModal() {
    this.modalService.closeEditUserModal()
  }
}
