<app-header [isBlack]=true [isSearch]=true ></app-header>

<div class="registration container">
    <div class="authorization__content">
      <form
        (ngSubmit)="submit()"
        [formGroup]="form"
        class="form authorization__form"
      >


        <h3 class="form__title">Восстановление пароля</h3>

        <div class="form__input-wrapper">
          <input 
            formControlName="email" 
            class="form__input"
            [ngClass]="password.value.length? 'active': ''" 
            #password
          />
          <small class="form__text" >email</small>
        </div>
        <!-- /.form__input-wrapper -->
        
        <p class="form__error" *ngIf="isAlertVisible">{{ alertText }}</p>
        <p [style.color]="'green'" class="form__error" *ngIf="isSuccessMessageVisible">Письмо отправлено на почту</p>

        <button
          [disabled]="form.invalid"
          type="submit"
          class="btn form__submit-button"
        >
          Отправить
        </button>
      </form>
      <!-- /.form login__form -->
    </div>
    <!-- /.login__content -->
  </div>

<app-footer></app-footer>