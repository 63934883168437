import { Component, Input, OnInit } from '@angular/core';
import { IOrder } from 'src/app/models/models';
import { ImageSourceService } from 'src/app/services/image-source.service';

@Component({
  selector: 'app-order-item',
  templateUrl: './order-item.component.html',
  styleUrls: ['./order-item.component.scss']
})
export class OrderItemComponent implements OnInit {
  @Input() order!: IOrder
  extra: number = 0
  size = 4

  constructor(
    private imageSource: ImageSourceService
  ) { }

  ngOnInit(): void {
    if (this.order && this.order.__images__?.length > 4) {
      this.extra = this.order.__images__.length - 4
    }
    this.getImagesSource()
  }

  showAllPhotos() {
    this.size = 100
    this.extra = 0
  }

  getImagesSource() {
    
    if (this.order && this.order.__images__) {
      for (let photo of this.order.__images__) {
        photo.img = this.imageSource.returnUrl(photo, "MINI")
      }
    }
  }


}
