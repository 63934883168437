import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(
    private readonly titleService: Title,
    private readonly metaService: Meta,
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('О фотостоке Gelio Photo');
    this.metaService.updateTag({
      content: 'О фотостоке Gelio Photo. Фотографии городов России в хорошем качестве +7 (499) 455-65-90',
    }, 'name=description');
  }
}
