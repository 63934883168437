import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { SearchService } from "../../services/search.service";
import { Subscription } from "rxjs/internal/Subscription";
import { IAutoComplete } from "src/app/models/auto-complete";
import { ITag } from "src/app/models/tag";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit {
  @Input() backgroundColor: string = "";
  @Input() isGray: boolean = false;
  @Input() placeholder: string = "Введите поисковой запрос...";
  @Input() classes: string = "";
  @Input() total: number;

  requestText: string = "";

  hints: IAutoComplete;
  isHintsVisible = false;
  timeOut: any;

  routeSubscription!: Subscription;

  constructor(
    private searchService: SearchService,
    private router: Router,
    private readonly _activatedRoute: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.snapshotSearchQuery();

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.snapshotSearchQuery();
      }
    });
  }

  snapshotSearchQuery() {
    if (this.router.url.includes("/search-page")) {
      this.requestText = this._activatedRoute.snapshot.queryParams?.search;
    }
  }

  async onSubmit() {
    this.hideHints();

    this.router.navigate(["/search-page"], {
      queryParams: {
        search: this.requestText,
      },
    });
  }

  setSearchFieldValue(value: string) {
    this.requestText = value;
    this.onSubmit();
    this.hideHints();
  }

  async getHints() {
    if (this.requestText.length < 2) {
      clearTimeout(this.timeOut);
      this.hideHints();
      return;
    }

    clearTimeout(this.timeOut);

    this.timeOut = setTimeout(async () => {
      this.hints = await this.searchService.getHints(this.requestText);
      console.log(this.hints);
      const filteredHints: ITag[] = [];

      for (const hint of this.hints.seoInfo) {
        const isInFilteredHints = filteredHints.some(
          (seo) => seo.name === hint.name
        );

        if (!isInFilteredHints) {
          filteredHints.push(hint);
        }
      }

      this.hints.seoInfo = filteredHints;
      this.isHintsVisible = true;
    }, 1000);
  }

  hideHints() {
    this.isHintsVisible = false;
  }
}
