import { Injectable } from "@angular/core";
import { IAdminImage } from "../../models/admin";

@Injectable({
  providedIn: "root",
})
export class HelperService {
  constructor() {}

  formGenerator(quantity?: number) {
    let wordForm = "";
    let exceptions = [11, 12, 13, 14, 111, 112, 113, 114, 211, 212, 213, 214];
    if (quantity) {
      const str = quantity.toString();
      let lastNum = Number(str.split("")[str.length - 1]);
      if (exceptions.includes(quantity)) {
        wordForm = "файлов";
      } else if (lastNum === 1) {
        wordForm = "файл";
      } else if (lastNum > 1 && lastNum < 5) {
        wordForm = "файла";
      }
      if ((lastNum >= 5 && lastNum < 10) || lastNum === 0) {
        wordForm = "файлов";
      }
    }
    return wordForm;
  }

  imageSorter(files: any) {
    const images: any[] = [];
    const availableExtensions = ["jpg", "png", "webp", "tiff", "raw", "jpeg"];

    for (let file of files) {
      let extension = file.name.split(".").pop();

      if (availableExtensions.includes(extension)) {
        images.push(file);
      }
    }
    return images;
  }


  deleteModalContentGenerator(content: string) {

    switch (content) {
      case "Folder":
        return "выбранные папки"

      case "Image":
        return "выбранный файл"

      case "Compilation":
        return "данную подборку"

      case "Images":
        return "выбранные файлы"

      case "User":
        return "выбранного пользователя"
      
      case "ImagesFromCompilation":
        return "выбранные файлы из подборки"

      default:
        return "выбранный файл"
    }
  }

  commonTagsGenerator(selectedImages: IAdminImage[]) {
    console.log("DA", selectedImages);

    let allTags: any[] = [];
    let commonTags: any[] = [];
    selectedImages.forEach((selected) => {
      allTags = [...allTags, ...selected.__tags__];
    });

    allTags.forEach((tag) => {
      const isValid = selectedImages.every((image) =>
        image.__tags__.includes(tag.id)
      );
      console.log("isValid", isValid);

      if (isValid) {
        commonTags.push(tag);
      }
    });
    // selectedImages.forEach((sel, idx, arr) => {
    //   allTags.forEach((tag, index, array) => {
    //     if (!sel.__tags__.includes(tag)) {
    //       allTags = array.filter((t) => t !== tag);
    //     }
    //   });
    // });
    return commonTags;
  }
}
