import { Component, Input } from "@angular/core";

@Component({
  selector: "app-text-with-tooltip",
  templateUrl: "./text-with-tooltip.component.html",
  styleUrls: ["./text-with-tooltip.component.scss"],
})
export class TextWithTooltipComponent {
  @Input() elementText: string;
  @Input() tooltipWidth: number;
  @Input() tooltipText: string;
  @Input() tooltipPosition: 'left' | 'right' | 'above' | 'below' | 'before' | 'after';
}
