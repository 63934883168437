import { omit } from "lodash";
import { EventEmitter, Injectable } from "@angular/core";
import { BaseRequestService } from "../base-request.service";
import {
  IAddCompilationL1City,
  IAddCompilationL2City,
  ICompilationsSetL1ChildrenOrder,
  IUpdateCompilationL1City,
  IUpdateCompilationL2City,
} from "src/app/models/admin";
import { CompilationL1City } from "src/app/admin/compilations/admin-compilations-component/compilation-l1-city";
import { CompilationL2City } from "src/app/admin/compilations/admin-compilations-component/compilation-l2-city";

@Injectable({
  providedIn: "root",
})
export class AdminCompilationsCitiesService {
  baseUrl = "/compilations";
  readonly saveButtonPressed = new EventEmitter<true>();
  readonly cancelButtonPressed = new EventEmitter<true>();

  constructor(private readonly requestService: BaseRequestService) {}

  getL1Cities(): Promise<CompilationL1City[]> {
    return this.requestService.get<CompilationL1City[]>(
      `${this.baseUrl}/l1-cities`
    );
  }

  createL1City(
    compilationData: IAddCompilationL1City
  ): Promise<CompilationL1City> {
    return this.requestService.post<CompilationL1City>(
      `${this.baseUrl}/l1-cities`,
      omit(compilationData, ["l2Cities"])
    );
  }

  createL2City(
    compilationData: IAddCompilationL2City
  ): Promise<CompilationL2City> {
    return this.requestService.post<CompilationL2City>(
      `${this.baseUrl}/l2-cities`,
      compilationData
    );
  }

  updateL1City(
    id: CompilationL1City["id"],
    compilationData: IUpdateCompilationL1City
  ): Promise<CompilationL1City> {
    return this.requestService.patch<CompilationL1City>(
      `${this.baseUrl}/l1-cities/${id}`,
      omit(compilationData, ["id", "l2Cities"])
    );
  }

  updateL2City(
    id: CompilationL2City["id"],
    compilationData: IUpdateCompilationL2City
  ): Promise<CompilationL2City> {
    return this.requestService.patch<CompilationL2City>(
      `${this.baseUrl}/l2-cities/${id}`,
      omit(compilationData, ["id", "l1CityId"])
    );
  }

  deleteL1City(id: number): Promise<CompilationL1City> {
    return this.requestService.delete(`${this.baseUrl}/l1-cities/${id}`);
  }

  deleteL2City(id: number): Promise<CompilationL2City> {
    return this.requestService.delete(`${this.baseUrl}/l2-cities/${id}`);
  }

  setL1ChildrenOrder(
    l1Id: number,
    orderList: ICompilationsSetL1ChildrenOrder
  ): Promise<unknown> {
    return this.requestService.patch(
      `${this.baseUrl}/l1-cities/${l1Id}/children-order`,
      orderList
    );
  }
}
