<app-header
  [isBlack]="true"
  [isSearch]="true"
  [isFixed]="true"
  [searchResultsTotal]="total"
  class="search__header"
  #header
></app-header>

<div class="search">
  <div class="search__default-message" *ngIf="isDefault">
    <img src="../../../assets/icons/search.svg" alt="" />
    <p>
      Введите ваш запрос в поисковой строке<br />
      или воспользуйтесь
      <a [routerLink]="['/']">каталогом</a>
    </p>
  </div>

  <div class="search__no-data-message" *ngIf="isNoResult && !isLoading">
    <h2>К сожалению, по запросу «{{ searchQuery }}» ничего не найдено</h2>
    <ul>
      <li>
        <p>— Убедитесь в правильности написания</p>
      </li>
      <li>
        <p>— Попробуйте уточнить или изменить поисковый запрос</p>
      </li>
      <li>
        <p>— Попробуйте посмотреть нужный вам город в Каталоге</p>
      </li>
      <li *ngIf="suggestContactOnEmptyResults">
        <p>
          — Всё равно не нашли то что вам нужно?
          <!-- <a [routerLink]="['/']">Напишите нам!</a> -->
          <br/>
          <br/>
          <app-contact-form
            title="Свяжитесь с нами!"
            (completed)="onContactsSubmitted()"
          ></app-contact-form>
        </p>
      </li>
    </ul>
  </div>

  <div class="found-data searcher__found-data" #pagination *ngIf="posts.length">
    <div class="search__content">
      <app-photos-grid
        [photos]="posts"
        [searchQuery]="searchQuery"
        [searchPage]="page"
      ></app-photos-grid>
    </div>
  </div>

  <app-paginator
    *ngIf="1 < pages"
    [current]="page"
    [total]="pages"
    [size]="10"
    (pageSelected)="onPageSelected($event)"
  ></app-paginator>
</div>

<app-footer></app-footer>
