<h2 mat-dialog-title>Редактирование подборки</h2>

<mat-dialog-content>
  <div
    *ngIf="isLoading"
    class="compilation-form-layout"
  >
    <div class="column">
      <ngx-skeleton-loader
        count="1"
      ></ngx-skeleton-loader>

      <ngx-skeleton-loader
        count="6"
      ></ngx-skeleton-loader>

      <ngx-skeleton-loader
        count="2"
      ></ngx-skeleton-loader>
    </div>

    <div class="column">
      <ngx-skeleton-loader
        count="1"
      ></ngx-skeleton-loader>

      <ngx-skeleton-loader
        count="10"
      ></ngx-skeleton-loader>
    </div>
  </div>

  <form
    *ngIf="!isLoading"
    [formGroup]="form"
    class="compilation-form-layout"
  >
    <div
      class="column"
    >
      <div class="form-group">
        <label>
          ЧПУ

          <input
            formControlName="friendlyURL"
            type="text"
          />
        </label>
      </div>

      <div class="form-group">
        <label>
          Заголовок

          <input
            formControlName="title"
            type="text"
          />
        </label>
      </div>

      <div class="seo-wrapper">
        <h3>SEO</h3>

        <div class="form-group">
          <select
            [(ngModel)]="selectedSeoLang"
            [ngModelOptions]="{ standalone: true }"
          >
            <option [value]="Language.RU">Русский</option>
            <option [value]="Language.EN">English</option>
          </select>
        </div>

        <div class="form-group">
          <label>
            SEO-title

            <input
              [formControl]="getSeoFormControl('title')"
              type="text"
            />
          </label>
        </div>

        <div class="form-group">
          <label>
            SEO-desription

            <textarea
              [formControl]="getSeoFormControl('description')"
            ></textarea>
          </label>
        </div>
      </div>

      <div class="form-group form-group-checkbox">
        <app-switch
          (checkboxClick)="onSwitchClick('published')"
          [checked]="form.value.published"
          [disabled]="form.get('published').disabled"
        ></app-switch>

        <label>Опубликовать данную подборку</label>
      </div>

      <div class="form-group form-group-checkbox">
        <app-switch
          (checkboxClick)="onSwitchClick('onMainPage')"
          [checked]="form.value.onMainPage"
          [disabled]="form.get('onMainPage').disabled"
        ></app-switch>

        <label>Отображать на главной странице</label>
      </div>
    </div>

    <div
      class="column"
    >
      <div
        class="form-group"
      >
        <label>
          Обложка

          <div
            id="cover_container"
            [class.cover-not-set]="!coverSource"
          >
            <img
              *ngIf="coverSource"
              id="cover"
              [src]="coverSource"
            />

            <div
              id="cover_actions"
            >
              <button
                mat-stroked-button
                (click)="coverFileInput.click()"
                class="action action-change"
              >
                <img src="../../../../assets/icons/add-image.svg" alt="Загрузить фото"/>
                Загрузить фото
              </button>

              <input
                #coverFileInput
                id="cover_file"
                type="file"
                accept="image/*"
                (change)="onCoverInputChange($event)"
              />
            </div>
          </div>
        </label>
      </div>

      <div
        class="form-group"
      >
        <label>
          Описание
          <textarea
            rows="10"
            formControlName="description"
          ></textarea>
        </label>
      </div>

      <div
        class="form-group"
      >
        <label>
          Краткое описание
          <textarea
            rows="3"
            formControlName="shortDescription"
          ></textarea>
        </label>
      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions
  align="end"
>
  <button
    mat-button
    [disabled]="isLoading || form.pristine || !form.valid"
    (click)="save()"
    class="btn btn-light"
  >Сохранить</button>

  <button
    mat-button
    [mat-dialog-close]="false"
    class="btn btn-light"
  >Отмена</button>
</mat-dialog-actions>
