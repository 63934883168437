import { Injectable } from "@angular/core";
import { IContactForm } from "../models/models";
import { BaseRequestService } from "./base-request.service";

@Injectable({
  providedIn: "root",
})
export class ContactsService {
  baseUrl = "/contacts";

  constructor(private requestService: BaseRequestService) {}

  async sendContact(data: IContactForm) {
    return await this.requestService.post(`${this.baseUrl}/send`, data);
  }
}
