import { EventEmitter, Injectable } from '@angular/core';
import { BaseRequestService } from '../base-request.service';
import { IAddCatalogl1City, IAddCatalogl2City, ICatalogl1City, ICatalogl2City, ICatalogSetL1ChildrenOrder } from 'src/app/models/admin';
import { omit } from 'lodash';

@Injectable({
  providedIn: 'root'
})

export class AdminCatalogService {
  baseUrl = "/catalog"
  readonly saveButtonPressed = new EventEmitter<true>();
  readonly cancelButtonPressed = new EventEmitter<true>();

  constructor(protected readonly requestService: BaseRequestService) {}

  /**
   * Get catalog for client side
   *
   * For now it returns same response as for the admin method.
   * Does not require authorization.
   */
   getCatalog(): Promise<ICatalogl1City[]> {
    return this.requestService.get<ICatalogl1City[]>(this.baseUrl);
  }

  getL1Cities(): Promise<ICatalogl1City[]> {
    return this.requestService.get<ICatalogl1City[]>(`${this.baseUrl}/l1-cities/`);
  }

  createL1City(catalogData: IAddCatalogl1City) {
    return this.requestService.post<{ id: number }>(
      `${this.baseUrl}/l1-cities/`,
      omit(catalogData, ['l2Cities']),
    );
  }

  createL2City(catalogData: IAddCatalogl2City) {
    return this.requestService.post<{ id: number }>(
      `${this.baseUrl}/l2-cities/`,
      catalogData
    );
  }

  updateL1City(catalogData: ICatalogl1City) {
    return this.requestService.patch<{ id: number }>(
      `${this.baseUrl}/l1-cities/${catalogData.id}`,
      omit(catalogData, ['id', 'l2Cities']),
    );
  }

  updateL2City(catalogData: ICatalogl2City) {
    return this.requestService.patch<{ id: number }>(
      `${this.baseUrl}/l2-cities/${catalogData.id}`,
      omit(catalogData, ['id', 'l1CityId', 'parentCityId']),
    );
  }

  deleteL1City(id: number): Promise<unknown> {
    return this.requestService.delete(`${this.baseUrl}/l1-cities/${id}`);
  }

  deleteL2City(id: number): Promise<unknown> {
    return this.requestService.delete(`${this.baseUrl}/l2-cities/${id}`);
  }

  setL1ChildrenOrder(l1Id: number, orderList: ICatalogSetL1ChildrenOrder): Promise<unknown> {
    return this.requestService.patch(
      `${this.baseUrl}/l1-cities/${l1Id}/children-order`,
      orderList,
    );
  }
}
