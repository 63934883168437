<app-header [isBlack]="true"></app-header>
<div class="registration container">
  <div class="authorization__content">
    <form
      (ngSubmit)="submit()"
      [formGroup]="form"
      ngNativeValidate
      class="form authorization__form"
    >

      <h3 class="form__title">Регистрация</h3>

      <div class="form__input-wrapper">
        <input 
          type="text"
          pattern="[А-я-.]{1,}"
          formControlName="name" 
          class="form__input"
          [ngClass]="name.value.length? 'active': ''"
          (keypress)="onKeyPressOnNameField($event)"
          #name 
        />
        <small class="form__text">Имя</small>
      </div>

      <div class="form__input-wrapper">
        <input 
          type="tel" 
          formControlName="phone" 
          class="form__input"
          [ngClass]="phone.value.length? 'active': ''"
          (keypress)="onKeyPressOnPhoneField($event)"
          #phone 
        />
        <small class="form__text">Номер телефона</small>
      </div>

      <p><small *ngIf="isPhoneInvalid()" class="form__error">Некорректный номер телефона</small></p>

      <div class="form__input-wrapper">
        <input 
          type="text" 
          formControlName="email" 
          class="form__input"
          [ngClass]="login.value.length? 'active': ''" 
          #login 
        />
        <small class="form__text" >Email</small>
      </div>
      <!-- /.form__input-wrapper -->
      
      <p><small *ngIf="isEmailInvalid()" class="form__error">Введён некорректный e-mail</small></p>
      <small *ngIf="isUserExist" class="form__error">Такой пользователь уже существует</small>

      <!-- /.form__input-wrapper -->
      
      <!-- <div class="form__input-wrapper">
        <input
          (input)="checkPasswordMatch()"
          formControlName="password"
          [type]="isPasswordVisible ? 'text' : 'password'"
          class="form__input"
          [ngClass]="password.value.length? 'active': ''" 
          #password
        />
        <small class="form__text">Пароль</small>
        <div class="form__icons-wrapper" [ngSwitch]="isPasswordVisible">
          <svg
            *ngSwitchCase="false"
            (click)="changeInputType()"
            class="form__eye-icon"
          >
            <use xlink:href="../../../assets/sprite.svg#eye-on"></use>
          </svg>
          <svg
            *ngSwitchCase="true"
            (click)="changeInputType()"
            class="form__eye-icon"
          >
            <use xlink:href="../../../assets/sprite.svg#eye-off"></use>
          </svg>
        </div>
      </div> -->
      <!-- /.form__input-wrapper -->

      <!-- <ul>
        <li>
          <small class="form__error" *ngIf="form.get('password')?.touched && password.value.length < 6"
            >Пароль должен содержать 6 символов
          </small>
        </li>
        <li>
          <small class="form__error" *ngIf="form.get('password')?.hasError('pattern')"
            >Пароль содержит недопустимые символы</small
          >
        </li>
      </ul> -->

      <!-- <div class="form__input-wrapper">
        <input
          (input)="checkPasswordMatch()"
          formControlName="passwordConfirmation"
          [type]="isPasswordVisible ? 'text' : 'password'"
          class="form__input"
          [ngClass]="repeatPassword.value.length? 'active': ''" 
          #repeatPassword
        />
        <small class="form__text">Повторите пароль</small>
      </div>
      /.form__input-wrapper
      <small class="form__error" *ngIf="!isPasswordMatch && form.get('passwordConfirmation')?.dirty"
        >Пароли не совпадают</small
      > -->

      <small 
        class="form__link" 
        *ngIf="isUserExist" 
        routerLink="/login"
        >Авторизироваться
      </small>
      
      <button
        [disabled]="form.invalid || !isPasswordMatch || isRequestSend"
        type="submit"
        class="btn form__submit-button"
      >
        Регистрация
      </button>
    </form>
    <!-- /.form login__form -->
  </div>
  <!-- /.login__content -->
</div>
<app-footer></app-footer>

<app-success-popup
  [isOpen]="isSuccessPopupOpen"
  message="Вы успешно зарегистрированы"
  buttonText="Вернуться на главную"
  link="/"
></app-success-popup>