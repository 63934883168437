<div class="directory__content">
  <div class="directory__listing-title directory__listing-title-catalog">
    <div class="directory__listing-title-macroregion">
      <p
        [class.sorting]="sorting.column === this.SortingColumn.Macroregion"
        [class.sorting_arrowUp]="sorting.direction === 'asc'"
        (click)="toggleSorting(this.SortingColumn.Macroregion)"
      >
        Регион
      </p>
    </div>

    <div class="directory__listing-title-tags">
      <p
        [class.sorting]="sorting.column === this.SortingColumn.Province"
        [class.sorting_arrowUp]="sorting.direction === 'asc'"
        (click)="toggleSorting(this.SortingColumn.Province)"
      >
        Субъект РФ
      </p>
    </div>

    <div class="directory__listing-title-name">
      <p
      [class.sorting]="sorting.column === this.SortingColumn.L1City"
      [class.sorting_arrowUp]="sorting.direction === 'asc'"
      (click)="toggleSorting(this.SortingColumn.L1City)"
      >
        Город
      </p>
    </div>

    <div class="directory__listing-title-extension">
      <p>Города второго уровня</p>
    </div>

    <div class="directory__listing-title-extension">
      <p>Поисковая строка</p>
    </div>


    <div class="directory__listing-title-extension">
      <p title="Показывать на главной">На главной</p>
    </div>
  </div>

  <div class="directory__catalog">
    <div class="directory__catalog-view">
      {{ ''// L1 city }}
      <ng-container *ngFor="let l1City of catalog">
        <div class="directory__catalog-item l1">
          <div class="directory__catalog-item-content l1">
            <app-admin-catalog-l1-city
              [l1City]="l1City"
              [canAddNewChildItem]="!editedItem"
              (deleteRequested)="onDeleteItem(l1City)"
              (editRequested)="onEditItem($event)"
              (editCancelled)="onCancelEditItem($event)"
              (modified)="onSaveItem($event)"
              (newChildItem)="onAddL2Item(l1City)"
            ></app-admin-catalog-l1-city>
          </div>

          {{ ''// form for new child L2 city }}
          <div
            *ngIf="editedItem && editedItem.isNew && editedItem.isChildOf(l1City)"
            class="directory__catalog-item l2"
          >
            <app-admin-catalog-l2-city
              [l2City]="$any(editedItem.item) // we're sure it's an L2 item here"
              (editRequested)="onEditItem($event)"
              (editCancelled)="onCancelEditItem($event)"
              (modified)="onSaveL2Item($event)"
            ></app-admin-catalog-l2-city>
          </div>
          
          <div
            *ngIf="l1City.l2Cities.length"
            class="admin__catalog-item-children"
            cdkDropList
            cdkDropListLockAxis="y"
            (cdkDropListDropped)="onL2ItemDrop($event)"
          >
            {{ ''// child L2 city }}
            <div
              class="directory__catalog-item l2"
              *ngFor="let l2City of (l1City.l2Cities | orderBy: 'order')"
              cdkDrag
              [cdkDragData]="l2City"
              cdkDragBoundary=".admin__catalog-item-children"
            >
              <app-admin-catalog-l2-city
                [l2City]="l2City"
                (editRequested)="onEditItem($event)"
                (editCancelled)="onCancelEditItem($event)"
                (deleteRequested)="onDeleteL2Item(l2City)"
                (modified)="onSaveL2Item($event)"
              ></app-admin-catalog-l2-city>
            </div>
          </div>
        </div>
      </ng-container>

      {{ ''// form for new L1 city }}
      <div *ngIf="editedItem && editedItem.isNew && editedItem.type === 'l1'" class="directory__catalog-item">
        <app-admin-catalog-l1-city
          [l1City]="$any(editedItem.item) // we're sure it's an L1 item here"
          (editRequested)="onEditItem($event)"
          (editCancelled)="onCancelEditItem($event)"
          (modified)="onSaveItem($event)"
        ></app-admin-catalog-l1-city>
      </div>
    </div>

    <div class="admin__form-btn">
      <button
        *ngIf="!editedItem"
        customButton
        text="Добавить"
        (click)="onAddItem()"
        color="primary"
      >
        <img
          src="../../../assets/icons/plus-active.svg"
          alt="add catalog"
          icon
        />
      </button>

      <button
        *ngIf="editedItem"
        customButton
        text="Отменить"
        (click)="catalogService.cancelButtonPressed.emit(true)"
        color="primary"
      ></button>

      <button
        customButton
        text="Сохранить"
        [disabled]="!editedItem || itemOperationPending"
        (click)="catalogService.saveButtonPressed.next(true)"
      ></button>
    </div>
  </div>
</div>
