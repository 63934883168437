<app-header
  [isBlack]="true"
  [isSearch]="true"
  [isDivider]="false"
></app-header>

<div *ngIf="compilation" class="compilation__preview">
  <h1 class="compilation__preview-header">{{ compilation.title }}</h1>
  <div class="compilation__preview-dark-layer"></div>
  <img [src]="compilation.img" alt="" class="compilation__preview-img" />
</div>

<div *ngIf="compilation" class="compilation">
  <div class="photos-grid-wrap">
    <app-photos-grid [photos]="images" class="photos-grid"> </app-photos-grid>
  </div>

  <app-paginator
    *ngIf="1 < pages"
    [current]="currentPage"
    [total]="pages"
    [size]="10"
    (pageSelected)="onPageSelected($event)"
  ></app-paginator>

  <div *ngIf="currentPage === 1" class="compilation__welcome">
    <div>
      {{ descriptionColumns[0] }}
    </div>

    <div>
      {{ descriptionColumns[1] }}
    </div>
  </div>
</div>

<app-footer></app-footer>
