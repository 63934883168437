import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { patterns } from 'src/app/common/patterns';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {

  form = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.pattern(patterns.email)])
  })
  
  isAlertVisible = false
  alertText = ""
  isSuccessMessageVisible = false

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit(): void {
  }

  async submit() {
    this.isSuccessMessageVisible = false
    this.isAlertVisible = false

    try {
      await this.authService.sendRestorationEmail(this.form.value.email)
      this.isSuccessMessageVisible = true
      
    } catch (error) {
      if (error instanceof HttpErrorResponse) {

        if (error.status === 404) {
          this.alertText = "Пользователь не найден"
          
        } else if (error.status === 400) {
          this.alertText = "Неверный email"

        } else {
          this.alertText = "Пользователь не найден"
        }
        
        this.isAlertVisible = true

      } else {
        this.alertText = "Непредвиденная ошибка"
        this.isAlertVisible = true
        console.log(error);
      }
    }
  }


}
