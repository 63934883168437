import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { LocalStorage } from "src/libs/storage/src";
import { CartService } from "./../../services/cart.service";
import { FavoriteService } from "./../../services/favorite.service";
import { ProductMethods } from "src/app/common/product-methods";
import { patterns } from "src/app/common/patterns";
import { AuthService } from "src/app/services/auth.service";
import { OrderService } from "src/app/services/order.service";
import { UserService } from "src/app/services/user.service";
import { IImage } from "../../models/image";
import { CompanyContacts } from "src/app/models/company-contacts";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-cart",
  templateUrl: "./cart.component.html",
  styleUrls: ["./cart.component.scss"],
})
export class CartComponent extends ProductMethods implements OnInit {
  total: number = 0;
  images: IImage[] = [];
  alertText = "";
  isAlertVisible = false;
  isAuthLinkVisible = true;
  isEmailInvalid = false;
  isPurchaseSuccess = false;
  isItemsLoading = true;

  form: FormGroup = new FormGroup({
    name: new FormControl("", [Validators.required]),
    phone: new FormControl("", [
      Validators.required,
      Validators.pattern(patterns.phone),
    ]),
    email: new FormControl("", [
      Validators.pattern(patterns.email),
      Validators.required,
    ]),
    isEntity: new FormControl(false),
    isAgreeWithPolicy: new FormControl(false, Validators.requiredTrue),
  });

  companyContacts: CompanyContacts;

  constructor(
    private router: Router,
    public _localStorage: LocalStorage,
    public _cartService: CartService,
    public _favoriteService: FavoriteService,
    public _authService: AuthService,
    private orderService: OrderService,
    private userService: UserService
  ) {
    super(_cartService, _favoriteService, _localStorage, _authService);
    
    this.companyContacts = {
      ...environment.contacts,
      tg: environment.contacts.tg?.replace(/[()\-\s]/g, ''),
      wa: environment.contacts.wa?.replace(/[^+\d]/g, ''),
    };
  }

  async ngOnInit() {
    if (this._authService.isLogged()) {
      this.isAuthLinkVisible = false;
      let user = await this.userService.getMe();

      this.form.controls["name"].setValue(user.firstName);
      this.form.controls["phone"].setValue(user.phone);
      this.form.controls["email"].setValue(user.email);
    }
    this.getAllCartItems();
  }

  async getAllCartItems() {
    let response: any;

    if (!this._authService.isLogged()) {
      response = this._localStorage.getItem("cart");
      this.images = JSON.parse(response);
      this.isItemsLoading = false;
      return;
    }

    this.images = await this._cartService.getBasket();
    this.isItemsLoading = false;
  }

  async deleteCartItem(event: number) {
    await this.deleteItem(event);
    this.getAllCartItems();
  }

  async submitOrder() {
    this.alertText = "";
    this.isAlertVisible = false;
    this.isEmailInvalid = false;

    const {name, phone, email} = this.form.value

    // if (!this._authService.isLogged()) {
    //   const registrationPayload: IRegisterViaEmail = {
    //     firstName: this.form.value.name,
    //     phone: this.form.value.phone,
    //     email: this.form.value.email,
    //   };
    //   try {
    //     await this._authService.registerViaEmail(registrationPayload);
    //   } catch (error: any) {
    //     this.handleError(error, "account creation");
    //     return;
    //   }
    // }

    try {
      await this.orderService.createOrder(
        this.images.map((photo) => photo.id),
        name,
        phone,
        email
      );
      // for (let image of this.images) {
      //   await this._cartService.deleteItem(image.id);
      // }
      this._cartService.clearLocalCart();
      this.isPurchaseSuccess = true;
    } catch (error: any) {
      this.handleError(error, "order creation");
    }
  }

  handleError(
    error: HttpErrorResponse,
    where: "order creation" | "account creation"
  ) {
    if (where === "account creation") {
      switch (error.status) {
        case 409:
          this.alertText = "Войдите в аккаунт и попробуйте снова";
          break;
        case 400:
          this.isEmailInvalid = true;
          // Important for UI!
          return;
        default:
          this.alertText = "Непредвиденная ошибка";
      }
    } else if (where === "order creation") {
      if (error instanceof HttpErrorResponse) {
        switch (error.status) {
          case 409:
            this.alertText = "Войдите в аккаунт и попробуйте снова";
            break;
          case 400:
            this.isEmailInvalid = true;
            // Important for UI!
            return;
          default:
            this.alertText = "Ошибка сервера, попробуйте еще раз";
            break;
        }
      } else {
        this.alertText = "Непредвиденная ошибка";
      }
    }

    this.isAlertVisible = true;
  }

  get totalPrice(): number {
    return this.getTotalPrice();
  }

  get numberOfItems(): string {
    let number = this.getNumberOfItems();
    let lastDigit = number % 10;
    if (number === 0) {
      return number + " фотографий";
    } else if (
      lastDigit === 5 ||
      lastDigit === 6 ||
      lastDigit === 7 ||
      lastDigit === 8 ||
      lastDigit === 9 ||
      lastDigit === 0
    ) {
      return number + " фотографий";
    } else if (lastDigit === 2 || lastDigit === 3 || lastDigit === 4) {
      return number + " фотографии";
    } else {
      return number + " фотография";
    }
  }

  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 43) {
      return false;
    }
    return true;
  }
}
