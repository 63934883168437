import { Component } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";

type IHowToBuyStep = { paragraphs: string[]; imgAlt: string };

@Component({
  selector: "app-how-to-buy",
  templateUrl: "./how-to-buy.component.html",
  styleUrls: ["./how-to-buy.component.scss"],
})
export class HowToBuyComponent {
  howToBuySteps: IHowToBuyStep[] = [
    {
      paragraphs: [
        "Найдите интересующее вас фото с помощью поисковой строки",
        "Например: Новосибирск зимой",
      ],
      imgAlt: "Пример поискового запроса",
    },
    {
      paragraphs: [
        "Нажмите кнопку «В корзину» у всех фотографий, которые вам подходят",
      ],
      imgAlt: "Пример добавления в корзину",
    },
    {
      paragraphs: [
        "Перейдите в корзину, введите контактные данные и завершите оформление заказа",
      ],
      imgAlt: "Пример оформления заказа",
    },
    {
      paragraphs: [
        "Мы свяжемся с вами в течение ближайшего рабочего дня и поможем завершить заказ",
      ],
      imgAlt: "Оформленные заказы в личном кабинете",
    },
  ];

  constructor(
    private readonly titleService: Title,
    private readonly metaService: Meta,
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('Как купить фотографии Gelio Photo');
    this.metaService.updateTag({
      content: 'Как купить фото Gelio Photo. Фотографии городов России в хорошем качестве +7 (499) 455-65-90',
    }, 'name=description');
  }
}
