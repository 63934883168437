<h2 mat-dialog-title>Добавление фото в подборку</h2>

<mat-dialog-content
  class="compilation-photo-selector"
>
  <div
    class="header"
  >
    <div
      class="search-query-input-wrapper"
    >
      <input
        #searchInput
        (input)="onQueryChange($event)"
        class="search-query-input"
      />
    </div>

    <div
      class="view-mode-selector"
    >
      <div
        *ngFor="let mode of ['Tile', 'BiggerList', 'List']"
        (click)="tableViewMode = $any(mode)"
        class="view-mode-switch view-mode-{{ mode | lowercase }}"
        [class.active]="mode === tableViewMode"
      ></div>
    </div>
  </div>

  <div
    *ngIf="isLoading"
  >
    <ngx-skeleton-loader
      count="2"
    ></ngx-skeleton-loader>
  </div>

  <div
    *ngIf="!isLoading"
    class="photo-selector"
  >
    <app-admin-photos-table
      [photos]="foundImages"
      [noDataText]="noPhotosText"
      [viewMode]="tableViewMode"
      [selectedIds]="getCompilationImagesIds()"
      (selectionChanged)="onSelectionChange($event)"
    ></app-admin-photos-table>
  </div>
</mat-dialog-content>

<mat-dialog-actions
  align="end"
>
  <button
    mat-button
    [disabled]="isLoading || !hasChanges()"
    (click)="save()"
    class="btn btn-light"
  >Сохранить</button>

  <button
    mat-button
    [mat-dialog-close]="false"
    class="btn btn-light"
  >Отмена</button>
</mat-dialog-actions>
