import { AdminService } from "../../../services/admin/admin.service";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IFolder, IAdminImage } from "./../../../models/admin";
import { FolderService } from "src/app/services/admin/folder.service";
import { FolderUtils } from "src/app/common/folder-utils";
import { Router } from "@angular/router";

@Component({
  selector: "app-replace-modal",
  templateUrl: "./replace-modal.component.html",
  styleUrls: ["./replace-modal.component.scss"],
})
export class ReplaceModalComponent implements OnInit {
  
  @Input() items: Array<IAdminImage|IFolder>;
  @Input() currentFolderId!: number;

  @Output() close = new EventEmitter();

  folders: IFolder[]

  destinationFolderId: number

  constructor(
    private adminService: AdminService,
    private readonly folderService: FolderService,
    private readonly router: Router
  ) {}

  async ngOnInit() {
    this.folders = await this.folderService.getRootFolders()
    this.folders = FolderUtils.sortFoldersByHierarchy(this.folders)
  }

  selectDestinationFolder(folderId: number) {
    if (folderId === this.currentFolderId) {
      return
    }

    this.destinationFolderId = folderId
  }

  // replaceImage() {
  //   this.folderService.moveImagesToFolder(this.destinationFolderId, this.items.map(chosen => chosen.id));
  //   this.closeModal();
    
  //   // Relaoding page insted of subscribing to changes
  //   this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  //     this.router.onSameUrlNavigation = 'reload';
  //     this.router.navigateByUrl(this.router.url);
      
  //     setTimeout(() => {
  //       this.router.routeReuseStrategy.shouldReuseRoute = () => true;
  //     }, 200)
  // }

  async moveItems() {
    const images = this.items.filter((item) => item.hasOwnProperty("author"));

    if (images != null && images.length > 0) {
      const imagesIds = images.map((chosen) => chosen.id);
      await this.folderService.moveImagesToFolder(
        this.destinationFolderId,
        imagesIds
      );
    }

    const folders = this.items.filter((item) => item.hasOwnProperty("level"));

    if (folders != null && folders.length > 0) {
      const foldersIds = folders.map((chosen) => chosen.id);

      for (const id of foldersIds) {
        await this.folderService.moveFolder(id, this.destinationFolderId);
      }
    }

    // Reload current page
    setTimeout(() => location.reload(), 0);
  }

  closeModal() {
    this.close.emit()
  }
}
