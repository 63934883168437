import { Injectable } from '@angular/core';
import { IAPIResponse, IUser } from '../models/models';
import { BaseRequestService } from './base-request.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  baseUrl = "/users"
  
  id = 0

  constructor(
    private requestService: BaseRequestService
  ) { }
  
  async getMe(): Promise<IUser> {
    return await this.requestService.get<IUser>(`${this.baseUrl}/me`)
  }

  async getUser(id: number): Promise<IUser> {
    return await this.requestService.get<IUser>(`${this.baseUrl}/${id}`)
  }

  async patchMe(data: object) {
    return await this.requestService.patch(`${this.baseUrl}/me`, data)
  }

  async patchUser(id: number, data: object) {
    return await this.requestService.patch(`${this.baseUrl}/${id}`, data)
  }

  async getUsers(): Promise<IUser[]> {
    return await this.requestService.get<IUser[]>(`${this.baseUrl}/admins`)
  }

  async deleteUser() {
    return await this.requestService.delete(`${this.baseUrl}/${this.id}`)
  }

  set setUserId(id: number) {
    this.id = id
  }
}
