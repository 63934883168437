import { Subject } from 'rxjs';
import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { Component, Input, Output, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { Direction, SortingModel, naiveComparator, wrapWithExtractor, filenameComparator } from '@app/sorting';
import { IAdminImage } from '@app/models/admin';
import { IImage } from '@app/models/image';
import { ImageSourceService } from '@app/services/image-source.service';
import { ViewMode } from '@app/views/admin/workspace/workspace.component';

@Component({
  selector: 'app-admin-photos-table',
  templateUrl: './admin-photos-table.component.html',
  styleUrls: ['./admin-photos-table.component.scss'],
})
export class AdminPhotosTableComponent {
  public readonly ViewMode = ViewMode;
  public readonly SortingDirection = Direction;

  @Input()
  public viewMode: ViewMode = ViewMode.List;
  @Input()
  public photos: IAdminImage[] = [];
  @Input()
  public selectedIds: Array<IAdminImage['id']> = [];
  @Input()
  public noDataText: string = 'Нет данных для отображения';
  @Output()
  public selectionChanged: Subject<SelectionChange<IAdminImage>> = new Subject();

  public readonly items = new MatTableDataSource<IAdminImage>([]);
  public readonly selection = new SelectionModel<IAdminImage>(true, []);
  public readonly sorting = new SortingModel<IAdminImage>({
    name: {
      comparator: wrapWithExtractor(filenameComparator, (i: IAdminImage) => i.name),
      handlesDirection: true,
    },
    createdAt: wrapWithExtractor(naiveComparator, (i: IAdminImage) => Date.parse(i.uploadDate)),
  });

  public readonly tableColumns: string[] = [
    'selection',
    'name',
    'tags',
    'createdAt',
    'format',
  ];

  constructor(private readonly imageSourceService: ImageSourceService) {
    this.selection.changed.subscribe((v) => this.selectionChanged.next(v));
    // this.items.sortData = (data, sort) => data;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (undefined === changes.photos) {
      return;
    }

    this.selection.clear();
    const photos = changes.photos.currentValue as IAdminImage[];
    const items = photos.map((photo) => {
      // Set the selection
      this.selectedIds.includes(photo.id) && this.selection.select(photo);
      return photo;
    });
    this.items.data = items;
  }

  public toggleSorting(property: string): void {
    if (!this.sorting.activeSortings.has(property)) {
      this.sorting.deactivateAll();
    }

    const currentDirection = this.sorting.activeSortings.get(property);
    if (Direction.ASC === currentDirection) {
      this.sorting.activateSorting(property, Direction.DESC);
    } /* else if (Direction.DESC === currentDirection) {
      this.sorting.deactivateSorting(property);
    } */ else {
      this.sorting.activateSorting(property, Direction.ASC);
    }
    this.items.data = [...this.sorting.sort(this.items.data)];
  }

  public getPhotoUrl(
    photo: IAdminImage | IImage,
    viewMode: ViewMode = this.viewMode
  ): string {
    return this.imageSourceService.returnUrl(photo, 'MINI');
  }
}
