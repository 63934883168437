import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
// @ts-ignore TS complains about absence of this member among module's export for some reason
import { ICompilationPreview } from 'src/app/models/models';
import { ImageSourceService } from 'src/app/services/image-source.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnChanges
{
  @Input()
  categories: ICompilationPreview[] = [];

  preparedCategories: ICompilationPreview[] = [];

  constructor(
    private readonly imageSourceService: ImageSourceService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (undefined === changes.categories) {
      return;
    }
    this.prepareCategories();
  }

  private prepareCategories() {
    this.preparedCategories = [];
    this.categories.forEach((category) => {
      const previewUrl = this.imageSourceService.returnUrl(null, 'MINI', category);
      if (!previewUrl) {
        return;
      }
      category.src = previewUrl;
      this.preparedCategories.push(category);
    });
  }
}
