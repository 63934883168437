import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LocalStorage } from 'src/libs/storage/src';
import { FolderService } from './folder.service';

@Injectable({
  providedIn: "root",
})
export class UploadService {
  loadingImages: any = [];

  isLoadingBar = false;

  loadingTotal = 0;
  loadingProgress = 0;

  private isLoadedBarSubject = new Subject<any>();
  private loadingProgressSubject = new Subject<any>();

  constructor(
    private readonly http: HttpClient,
    private readonly folderService: FolderService,
    private readonly localStorage: LocalStorage
  ) {}

  async uploadFolderWithImages(
    folderName: string,
    currentFolderId: number,
    formData: FormData
  ) {
    const [folder] = await this.folderService.createFolder([folderName]);
    console.log("Created folder", folder);
    if (currentFolderId !== 1) {
      await this.folderService.moveFolder(folder.id, currentFolderId);
    }
    const subscription = this.uploadImages(formData, folder.id, true).subscribe(
      (event) => {
        console.log(event);
        if (
          event?.type === HttpEventType.Response ||
          event instanceof HttpErrorResponse
        ) {
          subscription.unsubscribe();
        }
      }
    );
  }

  uploadImages(
    formData: FormData,
    folderId?: number,
    uploadInFolder: boolean = false
  ): Observable<any> {
    this.isLoadedBarSubject.next(true);

    let requestUrl = `${environment.serverUrl}/file-manager/upload`;
    folderId && (requestUrl += `/${folderId}`);
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.localStorage.getItem("accessToken")}` || "",
    });

    const req = new HttpRequest("POST", requestUrl, formData, {
      reportProgress: true,
      headers,
    });

    return this.http.request(req).pipe(
      map((event) => this.getUploadProgress(event, uploadInFolder)),
      finalize(() => {
        this.setLoadingImages([]);
        this.loadingProgressSubject.next(100);
      }),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        return of("Upload failed");
      })
    );
  }

  private getUploadProgress(
    event: HttpEvent<any>,
    uploadInFolder: boolean = false
  ): HttpEvent<any> {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        const additionalPercents = Math.round(
          (100 * event.loaded) / this.loadingTotal
        );

        this.loadingProgress += additionalPercents;
        this.loadingProgressSubject.next(this.loadingProgress);
        break;

      case HttpEventType.Response:
        event.body.isInFolder = uploadInFolder;
        this.loadingProgressSubject.next(event);
        break;

      default:
        break;
    }

    return event;
  }

  closeLoadingBar() {
    this.isLoadedBarSubject.next(false);
  }

  //////////////////////////////////
  // OBSERVABLES
  onLoadedBarToggle(): Observable<any> {
    return this.isLoadedBarSubject.asObservable();
  }
  onLoadingBarChange(): Observable<any> {
    return this.loadingProgressSubject.asObservable();
  }

  set setLoadingBar(value: boolean) {
    this.isLoadingBar = value;
  }

  setLoadingImages(images: File[]) {
    this.loadingImages = images;

    this.loadingTotal = 0;
    this.loadingProgress = 0;

    let totalSize = 0;
    images.forEach((image) => (totalSize += image.size));

    this.loadingTotal = totalSize;
    this.loadingProgressSubject.next(0);
  }

  get getLoadedBar() {
    return this.isLoadingBar;
  }

  get getLoadingImages() {
    return this.loadingImages;
  }
}
