<form
  class="contact-form"
  [formGroup]="contactForm"
  (ngSubmit)="sendForm()"
  ngNativeValidate
>
  <h2 class="title">{{ title }}</h2>
  <p>Оставьте ваши контакты и мы вам перезвоним!</p>

  <div class="contact-form__wrapper">
    <label>
      <small *ngIf="isNameInvalid()" class="contact-form__error form__error">
        Проверьте введенное имя
      </small>
      <input type="text" formControlName="name" placeholder="Имя" #name />
    </label>

    <label>
      <small *ngIf="isPhoneInvalid()" class="contact-form__error form__error">
        Некорректный номер телефона
      </small>
      <input
        type="tel"
        formControlName="phoneNumber"
        placeholder="Телефон"
        (keypress)="onKeyPressOnPhoneField($event)"
        #phoneNumber
      />
    </label>

    <button
      type="submit"
      class="btn"
      [disabled]="contactForm.invalid || isFormSubmitting"
    >
      Отправить
    </button>
  </div>
</form>

<app-success-popup
  [isOpen]="isSuccessPopup"
  message="Ваши контакты успешно отправлены!"
  (close)="onClose()"
></app-success-popup>
