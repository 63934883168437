import { Component, Input } from "@angular/core";
import { IImage } from "@app/models/image";
import { CartService } from "@app/services/cart.service";
import { LocalStorage } from "../../../../libs/storage/src";
import { FavoriteService } from "@app/services/favorite.service";
import { AuthService } from "@app/services/auth.service";
import { ProductMethods } from "@app/common/product-methods";

@Component({
  selector: "app-photo-grid-info",
  templateUrl: "./photo-grid-info.component.html",
  styleUrls: ["./photo-grid-info.component.scss"],
})
export class PhotoGridInfoComponent extends ProductMethods {
  @Input() photo: IImage = null;
  @Input() toggleCarousel: () => void = () => {};
  @Input() searchQuery: string = undefined;
  @Input() searchPage = 1;

  constructor(
    public _cartService: CartService,
    public _localStorage: LocalStorage,
    public _favoriteService: FavoriteService,
    public _authService: AuthService,
  ) {
    super(_cartService, _favoriteService, _localStorage, _authService);
  }
}
