import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-success-popup',
  templateUrl: './success-popup.component.html',
  styleUrls: ['./success-popup.component.scss']
})
export class SuccessPopupComponent {
  @Input() isOpen = false
  @Input() message = "Success!"
  @Input() buttonText = "ОК"
  @Input() link: string

  @Output() close = new EventEmitter();

  constructor(
    private readonly router: Router
  ) { }

  closeModal() {
    this.isOpen = false
    this.close.emit(true);
    
    if (this.link != null) {
      this.router.navigate([this.link])
    }
  }
}
