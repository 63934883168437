import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';

import { Language } from 'src/app/enums/language';
import { ISeoTemplate } from 'src/app/models/admin';
import { SeoTemplatesService } from 'src/app/services/admin/seo-templates.service';

const titleFormControl = () => new FormControl('', {
  validators: [],
});
const descriptionFormControl = () => new FormControl('', {
  validators: [],
});
const imgAltFormControl = () => new FormControl('', {
  validators: [],
});
const imgTitleFormControl = () => new FormControl('', {
  validators: [],
});

@Component({
  selector: 'app-seo-templates',
  templateUrl: './seo-templates.component.html',
  styleUrls: ['./seo-templates.component.scss']
})
export class SeoTemplatesComponent implements OnInit {
  // TODO: obtain from the backend
  readonly SeoAttribute = SeoAttribute;
  readonly Language = Language;

  readonly seoAttributes: SeoAttribute[] = Object.entries(SeoAttribute).map(([prop, attr]) => attr);
  readonly languages: Language[] = Object.entries(Language).map(([prop, attr]) => attr);

  form: FormGroup = this.fb.group({
    [this.makeKey(SeoAttribute.TITLE, Language.RU)]: titleFormControl(),
    [this.makeKey(SeoAttribute.DESCRIPTION, Language.RU)]: descriptionFormControl(),
    [this.makeKey(SeoAttribute.IMG_ALT, Language.RU)]: imgAltFormControl(),
    [this.makeKey(SeoAttribute.IMG_TITLE, Language.RU)]: imgTitleFormControl(),
    [this.makeKey(SeoAttribute.TITLE, Language.EN)]: titleFormControl(),
    [this.makeKey(SeoAttribute.DESCRIPTION, Language.EN)]: descriptionFormControl(),
    [this.makeKey(SeoAttribute.IMG_ALT, Language.EN)]: imgAltFormControl(),
    [this.makeKey(SeoAttribute.IMG_TITLE, Language.EN)]: imgTitleFormControl(),
  });

  readonly templatesMap: Map<string, string> = new Map();

  isInEditMode = false;

  constructor(
    private fb: FormBuilder,
    readonly seoTemplatesService: SeoTemplatesService,
  ) {}

  async ngOnInit(): Promise<void> {
    const seoTemplates = await this.seoTemplatesService.getSeoTemplates();
    this.setTemplates(seoTemplates);
    this.resetForm();
  }

  setTemplates(templates: ISeoTemplate[]): void {
    for (const seoTemplate of templates) {
      const { attribute, language, template } = seoTemplate;
      this.templatesMap.set(this.makeKey(attribute, language), template);
    }
  }

  resetForm(): void {
    const data: Record<string, string> = {};
    this.templatesMap.forEach((template, key) => {
      data[key] = template;
    });
    this.form.patchValue(data);
  }

  toggleMode(): void {
    this.isInEditMode = !this.isInEditMode;
  }

  makeKey(attr: string, lang: string): string {
    return `${attr.toLowerCase()},${lang.toLowerCase()}`;
  }

  async save(): Promise<void>{
    try {
      /// @ts-ignore: we know template is a string
      const data: [ISeoTemplate] = Object.entries(this.form.value).map(([key, template]) => {
        const [attribute, language] = key.split(',');
        return {
          attribute,
          language,
          template,
        };
      });
      const updatedSeoTemplates = await this.seoTemplatesService.setSeoTemplates(data);

      this.setTemplates(updatedSeoTemplates);
      this.toggleMode();
      this.resetForm();
    } catch (err) {
      console.error(err);
    }
  }
}

enum SeoAttribute {
  TITLE = 'title',
  DESCRIPTION = 'description',
  IMG_ALT = 'img_alt',
  IMG_TITLE = 'img_title',
}
