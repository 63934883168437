import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CatalogL1City } from './catalog-l1-city';

@Component({
  selector: 'app-admin-catalog-l1-city',
  templateUrl: './admin-catalog-l1-city.component.html',
  styleUrls: [
    './admin-catalog-table.component.scss',
    './admin-catalog-l1-city.component.scss',
  ]
})
export class AdminCatalogL1CityComponent implements OnInit {
  @Input() l1City: CatalogL1City;
  @Input() canAddNewChildItem = true;
  @Output() modified = new EventEmitter<CatalogL1City>();
  @Output() deleteRequested = new EventEmitter<CatalogL1City>();
  @Output() editRequested = new EventEmitter<CatalogL1City>();
  @Output() editCancelled = new EventEmitter<CatalogL1City>();
  @Output() newChildItem = new EventEmitter<true>();

  isInEditMode = false;

  constructor() {}

  ngOnInit(): void {
    if (!this.l1City.id) {
      // Adding new item
      this.isInEditMode = true;
    }
  }

  onEdit() {
    this.isInEditMode = true;
    this.editRequested.emit(this.l1City);
  }

  onCancelEdit(l1City: CatalogL1City) {
    this.editCancelled.emit(l1City);
    this.isInEditMode = false;
  }

  onDelete() {
    this.deleteRequested.emit(this.l1City);
  }

  onSubmit(l1City: CatalogL1City) {
    this.modified.emit(l1City);
  }

  onAddChildItem() {
    this.newChildItem.emit(true);
  }
}
