import { Injectable } from '@angular/core';
import { BaseRequestService } from '../base-request.service';
import { ISeoTemplate } from 'src/app/models/admin';

@Injectable({
  providedIn: 'root'
})
export class SeoTemplatesService {
  baseUrl = '/seo-templates';

  constructor(private readonly requestService: BaseRequestService) {}

  getSeoTemplates(): Promise<ISeoTemplate[]> {
    return this.requestService.get<ISeoTemplate[]>(`${this.baseUrl}`);
  }

  setSeoTemplates(data: [ISeoTemplate]) {
    return this.requestService.patch<ISeoTemplate[]>(
      `${this.baseUrl}`,
      data,
    );
  }
}
