import { IFolder } from "src/app/models/admin";
import { IAdminImage } from "./../../../models/admin";
import { Validators } from "@angular/forms";
import { FormControl } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { ModalService } from "src/app/services/admin/modal.service";
import { AdminService } from "src/app/services/admin/admin.service";
import { TagService } from "src/app/services/admin/tag.service";

@Component({
  selector: "app-tags-modal",
  templateUrl: "./tags-modal.component.html",
  styleUrls: ["./tags-modal.component.scss"],
})
export class TagsModalComponent implements OnInit {
  tags: Array<string> = [];

  selectedImages: IAdminImage[] = [];
  currentFolder!: IFolder;
  form: FormGroup = new FormGroup({
    tagName: new FormControl("", [
      Validators.required,
      Validators.minLength(1),
    ]),
  });

  constructor(
    private modalService: ModalService,
    private adminService: AdminService,
    private readonly tagService: TagService
  ) {}

  ngOnInit(): void {
    this.selectedImages = this.adminService.getChosenImages;
  }

  addTag(tag: string) {
    if (!this.tags.includes(tag)){
      this.tags.push(tag)      
      this.form.get("tagName")?.setValue("")
    }
  }

  deleteTag(tag: string) {
    if (this.tags.includes(tag)) {
      this.tags.splice(this.tags.indexOf(tag), 1)
    } else {
      console.log(this.tags, tag);
    }
  }

  async saveTags() {

    for (let tag of this.tags) {

      const tagsData = {
        imagesId: this.selectedImages.map((selected) => selected.id),
        tagName: tag,
        tagType: "HASHTAG",
      };
      
      console.log(tagsData);
      const newTags = await this.tagService.addTagToSeveralImages(tagsData);
      
      this.currentFolder.images.forEach((img) => {
        newTags.forEach((tag: any) => {
          if (img.id === tag.imageId) {
            img.__tags__ = tag.tags;
            console.log("here we are");
          }
        });
      });
      this.closeModal();
    }
  }
    
  closeModal() {
    this.modalService.closeTagsModal();
  }
}
