import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { patterns } from "src/app/common/patterns";
import { IContactForm } from "src/app/models/models";
import { ContactsService } from "src/app/services/contacts.service";

@Component({
  selector: "app-contact-form",
  templateUrl: "./contact-form.component.html",
  styleUrls: ["./contact-form.component.scss"],
})
export class ContactFormComponent implements OnInit {
  @Input() title: string = 'Хотите связаться с нами?';
  @Output() completed = new EventEmitter<boolean>();

  contactForm: FormGroup = new FormGroup({
    name: new FormControl("", [
      Validators.required,
      Validators.pattern(/^[А-я ]+$/),
    ]),
    phoneNumber: new FormControl("", [
      Validators.required,
      Validators.pattern(patterns.phone),
    ]),
  });

  isSuccessPopup: boolean = false;
  isFormSubmitting: boolean = false;

  constructor(private readonly _contactsService: ContactsService) {}

  ngOnInit(): void {}

  async sendForm() {
    this.isFormSubmitting = true;

    const payload: IContactForm = {
      name: this.contactForm.value.name,
      phoneNumber: this.contactForm.value.phoneNumber,
    };

    try {
      await this._contactsService.sendContact(payload);
      this.isSuccessPopup = true;
      this.contactForm.reset();
      this.isFormSubmitting = false;
    } catch (error) {
      console.log(error);
      this.isFormSubmitting = false;
    }
  }

  onKeyPressOnPhoneField(event: KeyboardEvent) {
    // If invalid character, then prevent input
    return /[0-9\+]/.test(event.key);
  }

  isPhoneInvalid() {
    const phoneField = this.contactForm.get("phoneNumber");
    return phoneField?.invalid && phoneField?.dirty && phoneField?.touched;
  }

  isNameInvalid() {
    const nameField = this.contactForm.get("name");
    return nameField?.invalid && nameField?.dirty && nameField?.touched;
  }

  onClose() {
    this.completed.emit(true)
  }
}
