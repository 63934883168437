<span
  *ngIf="page"
  [title]="title"
  class="paginator-bullet"
  [class.current]="isCurrent"
>
  {{ page }}
</span>

<span
  *ngIf="!page"
  [title]="title"
  class="paginator-bullet"
>
  {{ text }}
</span>
