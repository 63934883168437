import { Component, Input, OnInit, Output, HostListener, EventEmitter, OnDestroy, Inject } from "@angular/core";
import { IImage } from "src/app/models/image";
import { ImageSourceService } from "src/app/services/image-source.service";
import { CartService } from "src/app/services/cart.service";
import { ProductMethods } from "src/app/common/product-methods";
import { AuthService } from "src/app/services/auth.service";
import { LocalStorage } from "src/libs/storage/src";
import { FavoriteService } from "src/app/services/favorite.service";
import { NavigationExtras, Router } from "@angular/router";
import { FileSaverService } from "src/app/services/file-saver.service";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "app-popup-carousel",
  templateUrl: "./popup-carousel.component.html",
  styleUrls: ["./popup-carousel.component.scss"],
})
export class PopupCarouselComponent implements OnInit, OnDestroy {
  /** Index of the current photo */
  @Input()
  public index!: number;

  @Input()
  public photos: IImage[] = [];

  @Input()
  public showGoToPhotoControl = true;

  /** The search query associated with the photos */
  @Input()
  public searchQuery?: string = undefined;

  @Input()
  public searchPage? = 1;

  @Output()
  public closePopupCarousel: EventEmitter<any> = new EventEmitter();

  private touchStartX: number;
  private touchEndX: number;
  private swipeSensitivity: number = 50;

  // Margin of carousel element to change photos
  margin: string = "";

  get currentImage(): IImage {
    return this.photos[this.index]
  }

  constructor(
    public _authService: AuthService,
    public _localStorage: LocalStorage,
    public _cartService: CartService,
    private _imageSourceService: ImageSourceService,
    public _favoriteService: FavoriteService,
    private router: Router,
    private readonly _fileSaverService: FileSaverService,
    private readonly _productMethods: ProductMethods,
    @Inject(DOCUMENT)
    private readonly document: Document,
  ) {}

  @HostListener("window:keydown", ["$event"])
  public checkBtn(event: any) {
    if (event.key === "Escape") {
      this.closePopupCarousel.emit();
    }

    if (event.key === "ArrowLeft") {
      this.previousPhoto();
    }

    if (event.key === "ArrowRight") {
      this.nextPhoto();
    }
  }

  private disableBodyScrolling(): void {
    this.document.body.classList.add('noscroll');
  }

  private restoreBodyScrolling(): void {
    this.document.body.classList.remove('noscroll');
  }

  ngOnDestroy(): void {
    this.restoreBodyScrolling();
  }

  ngOnInit(): void {
    this.disableBodyScrolling();

    this.calcMargin();
  }

  // Calculates margin-left property of .carousel element to change photos
  calcMargin() {
    if (this.index >= this.photos.length) {
      this.index = 0;
    } else if (this.index < 0) {
      this.index = this.photos.length - 1;
    }
    this.margin = this.index * -100 + "vw";
  }

  downloadImage() {
    this._fileSaverService.saveImage(this.currentImage)
  }

  navigateToPhotoPage(friendlyURL: string) {
    const options: NavigationExtras  = {};
    if (this.searchQuery) {
      options.queryParams = {
        search: this.searchQuery,
        page: this.searchPage,
      };
    }
    this.router.navigate(["/photos", friendlyURL], options);
  }

  checkIfImageInCart() {
    return this._productMethods.checkItemInCart(this.currentImage)
  }

  checkIfImageInFavorites() {
    return this._productMethods.checkItemInFavorites(this.currentImage)
  }

  getImageSource(photo: IImage) {
    return this._imageSourceService.returnUrl(photo, "ORIGINAL");
  }

  // Methods to change photo in carousel via changing index to index of next (previous) photo, then call calcMargin()
  nextPhoto() {
    this.index++;
    this.calcMargin();
  }

  previousPhoto() {
    this.index--;
    this.calcMargin();
  }

  closePopup() {
    this.restoreBodyScrolling();

    this.closePopupCarousel.emit();
  }

  handleTouchStart(event: TouchEvent): void {
    this.touchStartX = event.changedTouches[0].clientX;
  }

  handleTouchEnd(event: TouchEvent): void {
    this.touchEndX = event.changedTouches[0].clientX;
    this.handleSwipe();
  }

  handleSwipe(): void {
    const swipeDistance = this.touchStartX - this.touchEndX;

    if (Math.abs(swipeDistance) > this.swipeSensitivity) {
      if (swipeDistance > 0) {
        this.nextPhoto();
      } else {
        this.previousPhoto();
      }
    }
  }
}
