import {
  Directive,
  ElementRef,
  OnChanges,
  HostListener,
  Input,
  Renderer2,
} from "@angular/core";

import { FavoriteService } from "./../services/favorite.service";
import { CartService } from "src/app/services/cart.service";
import { LocalStorage } from "src/libs/storage/src";
import { ProductMethods } from "src/app/common/product-methods";

import { IImage } from "./../models/image";
import { AuthService } from "../services/auth.service";

@Directive({
  selector: "[appButtonState]",
})
export class ButtonStateDirective extends ProductMethods implements OnChanges {
  @Input("appButtonState") buttonName: "favorite" | "cart" | "iconCart" =
    "favorite";
  /**
   * Defines if the directive should handle the button appearance directly according to the tracked state.
   * Otherwise, external styling is implied.
   */
  @Input() enableStyleHandling: boolean = true;
  @Input() item!: IImage;
  @Input() isItemInStorage: boolean = false;

  @HostListener("click") onClick(): void {
    let button: HTMLElement
    let icon: HTMLElement;

    switch (this.buttonName) {

      case "favorite":
        button = this._ref.nativeElement
        icon = this._ref.nativeElement.childNodes[0];
        this._favoriteService.isItemInFavorites = this.isItemInStorage;


        if (this._favoriteService.isItemInFavorites) {

          this.enableStyleHandling && this.removeStyleFavorite(button);

          this.deleteFavorite(this.item.id);
        } else {

          this.enableStyleHandling && this.setStyleFavorite(button);

          this.addFavorite(this.item);
        }

        this.isItemInStorage = !this.isItemInStorage;
        this._favoriteService.isItemInFavorites = this.isItemInStorage;

        break;

      case "cart":
        button = this._ref.nativeElement;
        this._cartService.isItemInCart = this.isItemInStorage;

        if (this._cartService.isItemInCart) {

          this.enableStyleHandling && this.removeStyleCart(button);

          this.deleteItem(this.item.id);
        } else {

          this.enableStyleHandling && this.setStyleCart(button);

          this.addItem(this.item);
        }

        this.isItemInStorage = !this.isItemInStorage;
        this._cartService.isItemInCart = this.isItemInStorage;
        break;

      case "iconCart":
        button = this._ref.nativeElement;
        icon = this._ref.nativeElement.childNodes[0]

        this._cartService.isItemInCart = this.isItemInStorage;

        if (this._cartService.isItemInCart) {

          this.enableStyleHandling && this.removeStyleCartMini(button);

          this.deleteItem(this.item.id);
        } else {

          this.enableStyleHandling && this.setStyleCartMini(button);

          this.addItem(this.item);
        }

        this.isItemInStorage = !this.isItemInStorage;
        this._cartService.isItemInCart = this.isItemInStorage;
        break;
      }
  }


  constructor(
    private _ref: ElementRef,
    private _renderer: Renderer2,
    public _localStorage: LocalStorage,
    public _cartService: CartService,
    public _favoriteService: FavoriteService,
    public _authService: AuthService
  ) {
    super(_cartService, _favoriteService, _localStorage, _authService);
  }


  ngOnChanges(): void {

    if (this.buttonName === "cart" || this.buttonName === "iconCart") {
      this.onCartButtonLoad();
    } else if (this.buttonName === "favorite"){
      this.onFavoriteButtonLoad();
    }

  }


  onCartButtonLoad(): void {
    if (this.enableStyleHandling) {
      let button: HTMLElement = this._ref.nativeElement;

      this._cartService.isItemInCart = this.isItemInStorage;

      if (!this._cartService.isItemInCart) {

        this.buttonName === "cart"?
          this.removeStyleCart(button)
          :
          this.removeStyleCartMini(button)

        return;
      }

      this.buttonName === "cart"?
        this.setStyleCart(button)
        :
        this.setStyleCartMini(button)
    }
  }


  onFavoriteButtonLoad(): void {

    let button = this._ref.nativeElement
    let icon: HTMLElement = this._ref.nativeElement.childNodes[0];

    this._favoriteService.isItemInFavorites = this.isItemInStorage;

    if (this.enableStyleHandling) {
      if (!this._favoriteService.isItemInFavorites) {

        this.removeStyleFavorite(button)
        return;
      }

      this.setStyleFavorite(button)
    }
  }

  // Favorite button styling methods
  setStyleFavorite(button: HTMLElement) {
    this._renderer.setStyle(button, "backgroundColor", "white")
    // Styling inner Heart icon
    this._renderer.setStyle(button.childNodes[0], "fill", "red");
    this._renderer.setStyle(button.childNodes[0], "stroke", "red");
  }

  removeStyleFavorite(button: HTMLElement) {
    this._renderer.removeStyle(button, "backgroundColor");
    // Styling inner Heart icon
    this._renderer.removeStyle(button.childNodes[0], "fill");
    this._renderer.removeStyle(button.childNodes[0], "stroke");
  }


  // Cart button styling methods
  setStyleCart(button: HTMLElement) {

    this._renderer.setStyle(button, "background-color", "black");
    button.textContent = "В корзине";
  }

  removeStyleCart(button: HTMLElement) {
    this._renderer.removeStyle(button, "background-color");
    button.textContent = "В корзину";
  }


  // Mini cart button styling methods
  setStyleCartMini(button: HTMLElement) {

    this._renderer.setStyle(button, "background-color", "white");
    this._renderer.setStyle(button.childNodes[0], "fill", "black");
  }

  removeStyleCartMini(button: HTMLElement) {
    this._renderer.removeStyle(button, "background-color");
    this._renderer.removeStyle(button.childNodes[0], "fill");
  }
}
