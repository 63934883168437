<app-header [isBlack]="true" [isSearch]="true"></app-header>

<section class="terms-of-use container">
  <h1 class="title">Пользовательское соглашение</h1>
  <p>
    Фотографии, опубликованные в фотобанке geliophoto.ru предоставляются на
    коммерческой основе. Использование любых материалов сайта без согласия
    компании Gelio Media и/или ассоциированных с компанией юридических лиц не
    допускается. Несанкционированное использование фотографий отслеживается.
  </p>

  <app-terms-of-use-grid [photos]="termsOfUse"></app-terms-of-use-grid>
</section>

<app-footer></app-footer>
