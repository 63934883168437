import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from "@angular/core";

@Component({
  selector: "app-terms-of-use-popup",
  templateUrl: "./terms-of-use-popup.component.html",
  styleUrls: ["./terms-of-use-popup.component.scss"],
})
export class TermsOfUsePopupComponent implements OnInit {
  @HostListener("window:keydown", ["$event"]) checkBtn(event: any) {
    if (event.key === "Escape") {
      this.closePopupCarousel.emit();
    }

    if (event.key === "ArrowLeft") {
      this.previousPhoto();
    }

    if (event.key === "ArrowRight") {
      this.nextPhoto();
    }
  }

  // Index of photo in list
  @Input() index!: number;
  @Input() photos: { src: string; alt: string }[] = [];

  @Output() closePopupCarousel: EventEmitter<any> = new EventEmitter();

  // Margin of carousel element to change photos
  margin: string = "";

  ngOnInit(): void {
    this.calcMargin();
  }

  // Calculates margin-left property of .carousel element to change photos
  calcMargin() {
    if (this.index >= this.photos.length) {
      this.index = 0;
    } else if (this.index < 0) {
      this.index = this.photos.length - 1;
    }
    this.margin = this.index * -100 + "vw";
  }

  // Methods to change photo in carousel via changing index to index of next (previous) photo, then call calcMargin()
  nextPhoto() {
    this.index++;
    this.calcMargin();
  }

  previousPhoto() {
    this.index--;
    this.calcMargin();
  }

  closePopup() {
    this.closePopupCarousel.emit();
  }
}
