<app-admin-header [withSearch]="false"></app-admin-header>

<div class="directory">
    <div class="directory__title">
      <h1>SEO шаблоны</h1>
    </div>

    <form [formGroup]="form" class="seo-templates-form">
      <legend>
        Строка вида <i>{{'{'}}имя-переменной{{'}'}}</i> заменится на значение переменной. Список доступных переменных:
        <ul>
          <li>photo.id &mdash; ID фотографии</li>
          <li>photo.title &mdash; заголовок фотографии</li>
        </ul>
      </legend>

      <ng-container *ngFor="let lang of languages">
        <h4>{{lang}}</h4>

        <ng-container *ngFor="let attr of seoAttributes">
          <div class="form-group">
            <label>{{attr}}</label>

            <div class="value-container">
              <textarea
                *ngIf="isInEditMode; else view"
                [formControlName]="makeKey(attr, lang)"
                rows="2"
              ></textarea>

              <ng-template #view>
                <span class="value">{{templatesMap.get(makeKey(attr, lang))}}</span>
              </ng-template>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <div class="form-actions">
        <button
          *ngIf="!isInEditMode"
          text="Редактировать"
          (click)="toggleMode()"
          customButton
          color="secondary"
        ></button>

        <button
          *ngIf="isInEditMode"
          text="Отмена"
          (click)="toggleMode()"
          customButton
          color="primary"
        ></button>

        <button
          *ngIf="isInEditMode"
          text="Сохранить"
          (click)="save()"
          customButton
          color="secondary"
        ></button>
      </div>
    </form>
</div>
