<div class="upload-progress not-selectable" [class.collapsed]="isCollapsed">
  <div class="upload-header">
    <h3>
      Загружено {{ isLoaded ? loadedFiles.length : 0 }} из
      {{ loadingFiles.length }}
    </h3>
    <div class="controls">
      <a class="update" (click)="openEditor()">Редактировать</a>
      <a (click)="isCollapsed = !isCollapsed" class="toggle"></a>
      <a class="close" (click)="onClose()"></a>
    </div>
  </div>
  <div class="upload-progress-bar-wrapper">
    <div
      class="upload-progress-bar"
      [style.width]="loadingProgress + '%'"
    ></div>
  </div>
  <div class="summary">
    <span>{{ isLoaded ? loadedFiles.length : 0 }}</span>
    из {{ loadingFiles.length }}
    <span></span>
  </div>
  <ul class="file-list">
    <li class="completed" *ngFor="let file of loadingFiles; let i = index">
      <span
        class="value"
        [ngClass]="
          statuses[i] && file.name.includes(statuses[i]) ? '' : 'value-error'
        "
        *ngIf="statuses[i]; else spinner"
      ></span>
      <ng-template #spinner>
        <div *ngIf="!statuses[i]" class="spinner-wrapper">
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </ng-template>
      <span
        [class.loaded]="statuses[i] && file.name.includes(statuses[i])"
        class="name"
      >
        {{ file.name }}
      </span>
    </li>
  </ul>
</div>
