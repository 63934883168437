<app-header [isBlack]="true" [isSearch]="true"></app-header>

<section class="partnership container">
  <h1 class="title">Сотрудничество</h1>
  <p class="partnership__p">
    Наш проект — это результат более чем 15-летней работы по съёмке российских
    городов с высоты. Наша команда — это профессионалы в области высотной
    фотосъёмки, которые регулярно создают качественный контент о России. Мы
    хотим, чтобы как можно больше людей увидели нашу страну такой, какой её
    видим мы, а количество проектов с качественными фотографиями России
    увеличивалось.
  </p>
  <p class="partnership__p">
    Если у вас есть предложения по сотрудничеству —
    <a
      href="mailto:{{ companyContacts.email }}"
      target="_blank"
      rel="noopener noreferrer"
      >свяжитесь с нами</a
    >.
  </p>

  <h2>Для СМИ</h2>
  <p class="partnership__p">
    Если вы представляете СМИ и хотите задать вопрос или обсудить
    сотрудничество, напишите нам:
    <a
      href="mailto:{{ companyContacts.email }}"
      target="_blank"
      rel="noopener noreferrer"
      >{{ companyContacts.email }}</a
    >.
  </p>

  <h2>Корпоративным клиентам</h2>
  <p class="partnership__p">
    Наш проект сотрудничает с крупными компаниями, пресс-службами,
    департаментами по связям с общественностью, администрациями городов и
    регионов. Мы можем предоставлять фотографии из фотобанка по лицензионным
    соглашениям на регулярной основе. Пожалуйста, напишите ваш запрос на
    <a
      href="mailto:{{ companyContacts.email }}"
      target="_blank"
      rel="noopener noreferrer"
      >{{ companyContacts.email }}</a
    >.
  </p>
  <p class="partnership__p">
    Мы можем провести съёмку ваших объектов, компании или целого региона.
  </p>
  <p class="partnership__p">
    Наша компания — ведущая команда в РФ по высотной и промышленной съёмке.
    Посмотрите наше
    <a
      href="https://geliomedia.ru/photography"
      target="_blank"
      rel="noopener noreferrer"
      >портфолио</a
    >.
  </p>
  <p class="partnership__last-p">
    Проект команды
    <a href="https://geliomedia.ru/" target="_blank" rel="noopener noreferrer"
      >Gelio Media</a
    >.
  </p>

  <app-contact-form></app-contact-form>
</section>

<app-footer></app-footer>
