import { Component, OnInit } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";

@Component({
  selector: "app-terms-of-use",
  templateUrl: "./terms-of-use.component.html",
  styleUrls: ["./terms-of-use.component.scss"],
})
export class TermsOfUseComponent implements OnInit {
  termsOfUse = [...Array(4)].map((v, i) => ({
    src: `../../../assets/images/terms-${i + 1}.jpg`,
    alt: `Страница ${i + 1}`,
  }));

  constructor(
    private readonly titleService: Title,
    private readonly metaService: Meta,
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('Пользовательское соглашение');
    this.metaService.updateTag({
      content: 'Пользовательское соглашение geliophoto.ru',
    }, 'name=description');
  }
}
