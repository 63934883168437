<app-admin-modal class="modal" (onClose)="closeModal()">
  <form>
    <div class="header-title">
      <h2>Добавить в подборку</h2>
    </div>
    <div class="folders">
      <h4>Выберите подборку:</h4>
      <a
        *ngIf="!isCompilationsCreatingActive; else create"
        (click)="toggleCompilationCreating()"
        class="add"
        >Создать новую подборку</a
      >
      <ng-template #create>
        <div class="new-compilation-field">
          <div class="form-group row-form">
            <input
              [(ngModel)]="compilationNameForCreating"
              type="text"
              placeholder="Название подборки"
              name="compilationName"
            />
            <button class="btn light-blue-btn" (click)="createCompilation()">
              Создать
            </button>
          </div>
        </div>
      </ng-template>
      <ul class="modal__compilations" >
        <li *ngFor="let compilation of compilations">
          <input type="radio" />
          <label
            class="modal__compilation-title"
            [class.selected]="compilation.id === toCompilationId"
            (click)="selectCompilation(compilation.id)"
            >{{ compilation.title }}</label
          >
        </li>
      </ul>
    </div>
    <div class="controls">
      <div>
        <button
          (click)="addImagesToCompilation()"
          type="submit"
          [disabled]="toCompilationId == null"
          class="btn"
        >
          Добавить
        </button>
      </div>
    </div>
  </form>
</app-admin-modal>
