import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-modal-tags",
  templateUrl: "./modal-tags.component.html",
  styleUrls: ["./modal-tags.component.scss"],
})
export class ModalTagsComponent implements OnInit {
  tags: string[] = ["Красивые виды"];

  constructor() {}

  ngOnInit(): void {}
}
