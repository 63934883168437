<div class="users-panel">

  <div class="directory">
    <div class="directory__title">
      <h1>
        Пользователи
      </h1>
        <button class="directory__title__add-user-button" (click)="toggleNewUserModal()">
            Добавить пользователя
        </button>
    </div>

    <div class="directory__content">
      <div class="directory__listing-title">
        <div class="directory__listing-title-name">Имя</div>
        <div class="directory__listing-title-extension">Email</div>
        <div class="directory__listing-title-tags">Права пользователя</div>
        <div class="directory__listing-title-date">Действия</div>
      </div>

      
      <div
        class="directory__items"
      >
        <div
          class="directory__items-item"
          *ngFor="let user of users"
        >
            <app-user-item
                [user]="user"
            ></app-user-item>
        </div>
      </div>
    </div>

  </div>

</div>

<app-add-user-modal *ngIf="isNewUserModalVisible" (onClose)="toggleNewUserModal()">
</app-add-user-modal>

<app-edit-user-modal *ngIf="isEditUserModalVisible">
</app-edit-user-modal>

<app-edit-user-password-modal *ngIf="isEditUserPasswordModalVisible">
</app-edit-user-password-modal>