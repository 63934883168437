<div *ngIf="isOpen">
    <article (onClose)="closeModal()" class="popup">
        <img src="assets/icons/success.svg" alt="success" class="popup__icon" >
        <p class="popup__message" >{{ message }}</p>
        <button (click)="closeModal()" class="popup__btn" >{{ buttonText }}</button>
        <button (click)="closeModal()" class="popup__btn_close" >
            <img src="assets/icons/close-black.svg" alt="close">
        </button>
    </article>
    
    <div class="background"></div>
</div>

