import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AdminCompilationService } from "src/app/services/admin/admin-compilation.service";

/**
 * UI for creating a compilation
 */
@Component({
  selector: "app-compilation-modal",
  templateUrl: "./compilation-modal.component.html",
  styleUrls: ["./compilation-modal.component.scss"],
})
export class CompilationModalComponent implements OnInit {
  form: FormGroup;

  @Output() close = new EventEmitter();
  @Output() compilationCreated = new EventEmitter();

  constructor(
    private compilationService: AdminCompilationService
  ) {
    this.form = new FormGroup({
      title: new FormControl("", [
        Validators.required,
        Validators.minLength(1),
      ]),
      description: new FormControl(""),
    });
  }

  ngOnInit(): void {}

  closeModal() {
    this.close.emit()
  }

  submit() {
    this.compilationService.createCompilation(this.form.value);
    this.closeModal();
    this.compilationCreated.emit()
  }
}
