<div class="terms-of-use-popup" [style.margin-left]="margin">
  <div class="photo-popup" *ngFor="let photo of photos" (click)="closePopup()">
    <img
      [src]="photo.src"
      [alt]="photo.alt"
      class="photo-popup__picture"
      (click)="$event.stopPropagation()"
    />
  </div>
  <!-- ./photo-popup -->

  <div class="photo-popup__buttons-wrapper">
    <button
      class="photo-popup__close-btn"
      (click)="closePopup(); $event.stopPropagation()"
      title="Закрыть окно"
    >
      <svg class="photo-popup__close-btn-icon">
        <use xlink:href="../../../assets/sprite.svg#close"></use>
      </svg>
    </button>
    <!-- ./photo-popup__close-btn -->

    <div class="photo-popup__leaf-buttons-wrapper">
      <button
        class="photo-popup__round-button photo-popup__round-button--previous"
        (click)="previousPhoto(); $event.stopPropagation()"
        title="Предыдущая фотография"
      >
        <svg class="photo-popup__button-icon">
          <use xlink:href="../../../assets/sprite.svg#next"></use>
        </svg>
      </button>
      <!-- ./photo-popup__round-button--previous -->

      <button
        class="photo-popup__round-button photo-popup__round-button--next"
        (click)="nextPhoto(); $event.stopPropagation()"
        title="Следующая фотография"
      >
        <svg class="photo-popup__button-icon">
          <use xlink:href="../../../assets/sprite.svg#next"></use>
        </svg>
      </button>
      <!-- ./photo-popup__round-button--next -->
    </div>
    <!-- ./photo-popup__leaf-buttons-wrapper -->
  </div>
  <!-- /.photo-popup__buttons-wrapper -->
</div>
