import { Subscription } from "rxjs";
import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { ActivatedRoute, ActivationEnd, Router } from "@angular/router";

import { IImage } from "src/app/models/image";
import { SearchService } from "../../services/search.service";
import { SessionStorage } from "src/libs/storage/src";

@Component({
  selector: "app-search-page",
  templateUrl: "./search-page.component.html",
  styleUrls: ["./search-page.component.scss"],
})
export class SearchPageComponent implements OnInit, OnDestroy {
  private searchQuerySub: Subscription;
  timeout: any;

  posts: IImage[] = [];
  page = 1;
  pages: number = undefined;
  readonly pageSize = 50;
  searchQuery = "";
  searchId: string = null;
  total: number;

  // isMoreButtonActive = false;
  isDefault = true;
  isLoading = false;
  isNoResult = false;
  isNoMoreResults = false;

  readonly LAST_CONTACT_ON_EMPTY_SEARCH_KEY = 'lastContactOnEmptySearch';

  get suggestContactOnEmptyResults() {
    const lastContactOnEmptySearch = this.sessionStorage.getItem(this.LAST_CONTACT_ON_EMPTY_SEARCH_KEY);
    return !lastContactOnEmptySearch
      ? true
      // Not more often than once per day
      : new Date(+lastContactOnEmptySearch).getTime() - Date.now() > 1000 * 60 * 60 * 24
    ;
  }

  @ViewChild("pagination")
  imageContainer!: ElementRef<HTMLElement>;

  constructor(
    private router: Router,
    public readonly _searchService: SearchService,
    private readonly _route: ActivatedRoute,
    private readonly sessionStorage: SessionStorage,
    @Inject(DOCUMENT)
    private readonly document: Document,
  ) {}

  ngOnInit() {
    this.onSearchQueryChange();

    this.searchQuerySub = this.router.events.subscribe((event: any) => {
      if (event instanceof ActivationEnd) {
        if (event.snapshot.routeConfig != this._route.snapshot.routeConfig) {
          return;
        }

        const paramsSearchQuery = event.snapshot.queryParams.search;
        const paramsPage = event.snapshot.queryParams.page;

        const searhQueryChanged =
          paramsSearchQuery !== undefined
          && this.searchQuery != paramsSearchQuery
        ;
        const pageChanged =
          paramsPage !== undefined
          && String(this.page) != paramsPage
        ;
        if (searhQueryChanged) {
          this.onSearchQueryChange();
        } else if (pageChanged) {
          const parsedPage = +paramsPage;
          if (isFinite(parsedPage) && 0 < parsedPage) {
            this.onPageChanged(parsedPage);
          }
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.searchQuerySub.unsubscribe();
  }

  async onSearchQueryChange() {
    const searchQuery = this._route.snapshot.queryParams?.search || '';
    const page = +this._route.snapshot.queryParams?.page || 1;
    this.reset(searchQuery, page);

    if (this.searchQuery) {
      this.loadCurrentPage();
    }
  }

  public reset(searchQuery: string, page = 1) {
    this.searchId = null;
    this.searchQuery = searchQuery.trim();
    this.posts = [];
    this.page = page;
    this.pages = undefined;
    this.total = undefined;
    this.isDefault = '' === this.searchQuery;
    this.isLoading = false;
    this.isNoResult = false;
    this.isNoMoreResults = false;
  }

  private async loadCurrentPage() {
    const {searchId, images, total} = await this.fetchCurrentPage();
    this.total = total;
    this.pages = Math.ceil(total / this.pageSize);

    this.searchId = searchId;
    this.posts = images;
    this.scrollTop();

    if (0 === total) {
      this.isNoResult = true;
    }
    if (this.page === this.pages) {
      // We already got all results
      this.isNoMoreResults = true;
    }
  }

  private async fetchCurrentPage() {
    this.isLoading = true;

    const result = await this._searchService.getPosts(
      this.searchQuery,
      this.page,
      this.searchId,
    );

    this.isLoading = false;

    return result;
  }

  private scrollTop(): void {
    const window = this.document.defaultView;
    if (null === window) {
      // SSR awareness
      return;
    }

    window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: 0,
    });
  }

  public onContactsSubmitted() {
    this.sessionStorage.setItem(this.LAST_CONTACT_ON_EMPTY_SEARCH_KEY, String(Date.now()));
  }

  public onPageSelected(page: number): void {
    this.router.navigate([], {
      queryParams: {
        page: page,
      },
      queryParamsHandling: 'merge',
    });
  }

  public async onPageChanged(page: number): Promise<void> {
    this.page = page;
    await this.loadCurrentPage();
  }
}
