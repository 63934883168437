import { Injectable } from '@angular/core';
import { IImage } from '../models/image';
import { IAPIResponse, ICompilation, ICompilations } from '../models/models';
import { BaseRequestService } from './base-request.service';

@Injectable({
  providedIn: 'root',
})
export class CompilationService {
  baseUrl = '/compilations';

  constructor(
    private requestService: BaseRequestService
  ) {}

  async getCompilation(idOrUrl: number|string) {
    const compilation = await this.requestService.get<ICompilation>(`${this.baseUrl}/${idOrUrl}`);
    return compilation;
  }

  async getImages(compilationId: number, page: number, pageSize: number) {
    let images = await this.requestService.get<{
      items: IImage[],
      total: number,
    }>(
      `${this.baseUrl}/pagination/${compilationId}/${page}?size=${pageSize}`
    );

    return images;
  }

  async getCompilations(page: number, pageSize: number) {
    return await this.requestService.get<ICompilations>(
      `${this.baseUrl}?page=${page}&pageSize=${pageSize}`
    );
  }

  async createCompilation(data: ICompilation) {
    return await this.requestService.post<IAPIResponse<ICompilation>>(
      `${this.baseUrl}/create`,
      data
    );
  }

  async patchCompilation(id: number, data: ICompilation) {
    return await this.requestService.patch<IAPIResponse<ICompilation>>(
      `${this.baseUrl}/${id}`,
      data
    );
  }

  async deleteCompilation(id: number) {
    return await this.requestService.delete<IAPIResponse<ICompilation>>(
      `${this.baseUrl}/${id}`
    );
  }

  async addImagesToCompilation(id: number, imagesId: number[]) {
    return await this.requestService.patch<IAPIResponse<ICompilation>>(
      `${this.baseUrl}/addImages/${id}`,
      { imagesId: imagesId }
    );
  }
}
