import { Component, OnInit } from "@angular/core";

import { ActivatedRoute, Router } from "@angular/router";
import { ICompilation, ICompilations, IMainPageData } from "@app/models/models";
import { CompilationService } from "@app/services/compilation.service";
import { ImageSourceService } from "@app/services/image-source.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-blog",
  templateUrl: "./blog.component.html",
  styleUrls: ["./blog.component.scss"],
})
export class BlogComponent implements OnInit {
  public compilations: ICompilations;
  public pages = 0;
  public pageSize = 16;
  public currentPage = 1;
  private querySub: Subscription;

  constructor(
    private readonly compilationService: CompilationService,
    private readonly imageSourceService: ImageSourceService,
    public readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  async ngOnInit() {
    await this.loadCurrentPage();

    this.querySub = this.activatedRoute.queryParams.subscribe((params) => {
      if (undefined === params.page) {
        return;
      }
      this.onPageChange(+params.page || 1);
    });
  }
  getCompilationPreview(compilation: ICompilation) {
    return this.imageSourceService.returnUrl(compilation.__cover__, "ORIGINAL");
  }

  onPageSelected(page: number): void {
    this.router.navigate([], {
      queryParams: {
        page,
      },
    });
  }

  private onPageChange(page: number) {
    this.currentPage = page;
    this.loadCurrentPage();
  }

  private async loadCurrentPage() {
    this.compilations = await this.compilationService.getCompilations(
      this.currentPage,
      this.pageSize
    );

    this.pages = this.compilations.paging.total;
  }
}
