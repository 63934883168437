<div
  class="categories"
>
  <a
    *ngFor="let category of preparedCategories"
    [routerLink]="['/compilation', category.friendlyURL || category.id]"
    class="category-preview"
    title="Перейти в подборку"
  >
    <div
      class="category-preview-wrapper"
    >
      <img
        [src]="category.src"
        [title]="category.title"
        [alt]="category.description"
        class="category-preview-photo"
      />
    </div>

    <span
      class="category-preview-title"
    >{{ category.title }}</span>
  </a>
</div>
