import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from "@angular/core";

@Component({
  selector: "app-admin-modal",
  templateUrl: "./admin-modal.component.html",
  styleUrls: ["./admin-modal.component.scss"],
})
export class AdminModalComponent {
  @Input() width: string;
  @Input() maxWidth: string;

  @Input() isLarge: boolean = false;
  @Input() isScrollable: boolean = false;
  @Input() withControls: boolean = false;

  @Output() onClose = new EventEmitter();
  @Output() onEscape = new EventEmitter();
  @Output() onNext = new EventEmitter();
  @Output() onPrevious = new EventEmitter();

  @HostListener("document:keydown.escape")
  handleEscapePress() {
    if (0 === this.onEscape.observers.length) {
      this.closeModal();
    } else {
      this.onEscape.emit();
    }
  }

  constructor() {}

  closeModal() {
    this.onClose.emit();
  }

  next() {
    this.onNext.emit();
  }

  previous() {
    this.onPrevious.emit();
  }
}
