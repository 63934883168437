import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-add-user-modal',
  templateUrl: './add-user-modal.component.html',
  styleUrls: ['./add-user-modal.component.scss']
})
export class AddUserModalComponent implements OnInit {

  isRequestSend = false;
  isPasswordVisible = false;
  isPasswordMatch = true;
  isErrorVisible = false

  errorText = ""

  @Output() onClose = new EventEmitter()

  form: FormGroup = new FormGroup({
    
    name: new FormControl(null, [Validators.required, Validators.minLength(3)]),
    login: new FormControl(null, [
      Validators.required,
      Validators.minLength(4),
    ]),
    email: new FormControl("", [Validators.required, Validators.email]),
    role: new FormControl("", [Validators.required]),
    password: new FormControl(null, [
      Validators.required,
      Validators.minLength(6),
      Validators.pattern('[a-zA-Z0-9]+')
    ]),
    passwordConfirmation: new FormControl(null, [
      Validators.required,
      Validators.minLength(6),
      Validators.pattern('[a-zA-Z0-9]+')
    ]),
  });

  constructor(
    private authService: AuthService
  ) {}

  ngOnInit(): void {}
  
  async submit() {
    this.isRequestSend = true;
    this.isErrorVisible = false
    
    try {
      await this.authService.addUser(this.createUserObject())
      this.closeModal()

    } catch (e: any) {
      this.handleError(e)
    }
    
    this.isRequestSend = false;
  }

  handleError(e: HttpErrorResponse) {
    switch (e.status) {
      case 500:
        this.errorText = "Ошибка сервера"
        break;
      case 409:
        this.errorText = "Пользователь с таким логином или паролем уже существует"
        break;
      case 400:
        this.errorText = "Проверьте правильность заполнения формы"
        break;
    }
    this.isErrorVisible = true
  }

  checkPasswordMatch() {
    if (
      this.form.get("password")!.value.trim() !==
      this.form.get("passwordConfirmation")!.value
    ) {
      this.isPasswordMatch = false;
      return;
    }

    this.isPasswordMatch = true;
  }

  createUserObject() {
    return {
      login: this.form.value.login,
      firstName: this.form.value.name,
      email: this.form.value.email,
      role: this.form.value.role,
      password: this.form.value.password,
    }
  }

  changeInputType() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  closeModal() {    
    this.onClose.emit("")
  }
}
