import { Response } from 'express';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { HttpStatusCode } from '@angular/common/http';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  private response: Response;

  constructor(
    @Optional() @Inject(RESPONSE) response: Response,
  ) {
    this.response = response;
  }

  ngOnInit(): void {
    console.log('NotFoundComponent#ngOnInit(), this.response', this.response);

    if (this.response) {
      // Response will only be provided when SSR
      this.response.status(HttpStatusCode.NotFound);
    }
  }
}
