import { Component, OnInit } from '@angular/core';
import { IUser } from 'src/app/models/models';
import { ModalService } from 'src/app/services/admin/modal.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-users-panel',
  templateUrl: './users-panel.component.html',
  styleUrls: ['./users-panel.component.scss']
})
export class UsersPanelComponent implements OnInit {
  users: IUser[] = []

  isNewUserModalVisible = false
  isEditUserModalVisible = false
  isEditUserPasswordModalVisible = false

  constructor(
    private userService: UserService,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    this.getUsers()

    this.modalService.onEditUserModalToggle()
      .subscribe(val => { 
        this.isEditUserModalVisible = val; 
        this.getUsers() 
      })
    this.modalService.onEditUserPasswordModalToggle()
      .subscribe(val => { 
        this.isEditUserPasswordModalVisible = val; 
        this.getUsers() 
      })
  }

  async getUsers() {
    try {
      let response = await this.userService.getUsers()
      this.users = response  
      
    } catch (error) {
      console.log(error);
    }
  }

  toggleNewUserModal() {
    this.isNewUserModalVisible = !this.isNewUserModalVisible
    this.getUsers()
  }
}
