import { Component, Input, OnInit, Output } from '@angular/core';
import { IUser } from 'src/app/models/models';
import { ModalService } from 'src/app/services/admin/modal.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-item',
  templateUrl: './user-item.component.html',
  styleUrls: ['./user-item.component.scss']
})
export class UserItemComponent implements OnInit {

  isEditUserModalVisible = false

  @Input() user: IUser = {
    id: 0,
    login: "login",
    firstName: "Name",
    lastName: "LastName",
    email: "email",
    role: "USER",
    patronymic: ""
  }

  constructor(
    private modalService: ModalService,
    private userService: UserService
  ) {}

  ngOnInit() {
  }

  selectRole(role: any) {
    console.log(role);
    try {
      this.userService.patchUser(this.user.id, { role: role })
      this.user.role = role
    } catch (error) {
      // Should call snackbar or smthng like that
      console.log(error);
    }
  }

  openEditUserModal() {
    this.modalService.id = this.user.id
    this.modalService.openEditUserModal()
  }

  openEditUserPasswordModal() {
    this.modalService.id = this.user.id
    this.modalService.openEditUserPasswordModal()
  }
}
