import { Subscription } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";

import { Meta } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { ProductMethods } from "src/app/common/product-methods";
import { AuthService } from "src/app/services/auth.service";
import { CartService } from "src/app/services/cart.service";
import { ImageSourceService } from "src/app/services/image-source.service";
import { SearchService } from "src/app/services/search.service";
import { environment } from "src/environments/environment";
import { LocalStorage } from "src/libs/storage/src";
import { IImage } from "./../../models/image";
import { FavoriteService } from "./../../services/favorite.service";
import { PhotosService } from "./../../services/photos.service";
import { ITag } from "src/app/models/tag";
import { Language } from "src/app/enums/language";

@Component({
  selector: "app-photo",
  templateUrl: "./photo.component.html",
  styleUrls: ["./photo.component.scss"],
})
export class PhotoComponent extends ProductMethods implements OnInit, OnDestroy {
  isPopupPhotoVisible: boolean = false;
  src = environment.serverUrl;

  /** The user came from search page */
  public sourceSearchQuery: string = null;
  public sourceSearchPage: string = null;

  photo: IImage = {
    name: "",
  };

  private routeDataSub: Subscription;

  constructor(
    private http: HttpClient,
    public _cartService: CartService,
    public _favoriteService: FavoriteService,
    public _localStorage: LocalStorage,
    private route: ActivatedRoute,
    protected _photosService: PhotosService,
    private meta: Meta,
    private titleService: Title,
    private _imageSourceService: ImageSourceService,
    private router: Router,
    public _authService: AuthService
  ) {
    super(_cartService, _favoriteService, _localStorage, _authService);
  }

  async ngOnInit(): Promise<void> {
    this.routeDataSub = this.route.data.subscribe((data) => {
      this.photo = data.photo as IImage;
      const seo = this.getSeo(this.photo, Language.RU);
      seo?.title && this.titleService.setTitle(seo.title);
      this.setMetaTags(this.photo);
    });

    // Check if the user came from search page
    this.sourceSearchQuery = this.route.snapshot.queryParamMap.get('search');
    this.sourceSearchPage = this.route.snapshot.queryParamMap.get('page');
  }

  async ngOnDestroy(): Promise<void> {
    this.routeDataSub.unsubscribe();
    this.titleService.setTitle('');
    this.unsetMetaTags();
  }

  async downloadImage(imgId: number) {
    const response: any = await this.http
      .get(this.getImageSrc("ORIGINAL"), {
        headers: {
          Authorization: `Bearer ${this._localStorage.getItem("accessToken")}`,
        },
        responseType: "blob" as "json",
      })
      .toPromise();

    const file = new Blob([response], { type: response.type });
    const blob = window.URL.createObjectURL(file);
    const link = document.createElement("a");

    link.href = blob;
    link.download = imgId.toString();
    link.click();
  }

  getImageSrc(type: "MINI" | "ORIGINAL") {
    return this._imageSourceService.returnUrl(this.photo, type);
  }

  generatePictureName(img: HTMLImageElement) {
    return img.src.substr(img.src.lastIndexOf("/") + 1);
  }

  togglePopupVisible() {
    this.isPopupPhotoVisible = !this.isPopupPhotoVisible;
  }

  navigateToSearch(query: string) {
    this.router.navigate(["/search-page"], {
      queryParams: {
        search: query,
      },
    });
  }

  private getSeo(photo: IImage, language: Language) {
    const seo = photo?.__seoInfo__.find((seo) => seo.language === language);
    return seo;
  }

  private setMetaTags(photo: IImage): void {
    const info = this.getSeo(photo, Language.RU);
    if (info) {
      this.meta.updateTag({ name: "title", content: info.title });
      this.meta.updateTag({
        name: "description",
        content: info.description,
      });
      this.meta.updateTag({
        name: "keywords",
        content: photo.__tags__.map((t) => t.name).join(', '),
      });
    }

    // Extended standards meta tags
    const miniSrc = this.getImageSrc("MINI");
    this.meta.addTags([
      { property: "og:title", content: photo.name },
      { property: "og:description", content: photo.description || "" },
      { property: "og:url", content: document.location.href },
      { property: "og:image", content: `${miniSrc}?q=0` },
      { property: "og:image:secure_url", content: `${miniSrc}?q=0` },
      { property: "og:image:type", content: "image/png" },
      { property: "twitter:card", content: "summary_large_image" },
      { property: "twitter:image", content: `${miniSrc}?q=0` },
    ]);
  }

  unsetMetaTags(): void {
    this.meta.updateTag({ content: '' }, 'name=title');
    this.meta.updateTag({ content: '' }, 'name=description');
    this.meta.updateTag({ content: '' }, 'name=keywords');

    // Extended standards meta tags
    this.meta.removeTag('property="og:title"');
    this.meta.removeTag('property="og:description"');
    this.meta.removeTag('property="og:url"');
    this.meta.removeTag('property="og:image"');
    this.meta.removeTag('property="og:image:secure_url"');
    this.meta.removeTag('property="og:image:type"');
    this.meta.removeTag('property="twitter:card"');
    this.meta.removeTag('property="twitter:image"');
  }

  getPhotoTags(photo: IImage): ITag[]
  {
    const tags = Array.from(photo.__tags__);
    if (!photo.coordinates) {
      return tags;
    }

    // TODO:i18n
    const collator = Intl.Collator('ru', { caseFirst: 'false', sensitivity: 'base' });
    tags.sort((t1, t2) => {
      if (collator.compare(t1.name, photo.coordinates) === 0) {
        return -1;
      } else if (collator.compare(t2.name, photo.coordinates) === 0) {
        return +1;
      }

      return undefined;
    });

    return tags;
  }
}
