import { Injectable } from "@angular/core";
import {
  CanActivate,
  Router
} from "@angular/router";
import { AuthService } from "../services/auth.service";

@Injectable({
  providedIn: "root",
})
export class AdminGuard implements CanActivate {
  constructor(
    private authService: AuthService, 
    private router: Router
  ) {}

  async canActivate(): Promise<boolean> {
    if (!this.authService.isLogged()) {
      this.router.navigate(['/login']);
      return false
    }

    if (await this.authService.isAdmin()) {
      return true
    } else {

      this.router.navigate(['/'])
      return false
    }
  }
}
