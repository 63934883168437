import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { LocalStorage } from "src/libs/storage/src";

import { BaseRequestService } from "src/app/services/base-request.service";

import { IBasket } from "./../models/basket";
import { IImage } from "./../models/image";
import { AuthService } from "./auth.service";
@Injectable({
  providedIn: "root",
})
export class CartService extends BaseRequestService {
  baseUrl = "/cart";
  isItemInCart: boolean = false;

  public onCartChange = new EventEmitter()

  get cart(): IImage[] {
    return JSON.parse(this.localStorage.getItem("cart") || "[]"); 
  }

  constructor(
    public localStorage: LocalStorage,
    public http: HttpClient,
    public authService: AuthService
  ) {
    super(http, localStorage);
    this.getBasket()
  }

  async addItem(data: any): Promise<void> {
    this.cart = (
      await this.patch<IBasket>(`${this.baseUrl}/${data.id}`, data)
    ).images
    this.onCartChange.emit()
  }

  async deleteItem(idToRemove: number): Promise<void> {
    await this.delete(`${this.baseUrl}/${idToRemove}`, false);
    this.cart = this.cart.filter((image) => image.id !== idToRemove)
    this.onCartChange.emit()
  }

  getAllCartItems(): IImage[] {
    return this.cart
  }

  async getBasket(): Promise<Array<IImage>> {
    if (this.authService.isLogged()) {
      try {
        let temp_cart = await this.get<IBasket>(this.baseUrl, null)
        temp_cart ? (this.cart = temp_cart.images) : (this.cart = [])
        this.onCartChange.emit()
        return this.cart
      } catch (error) {
        if (error instanceof HttpErrorResponse) {
          switch (error.status) {
            case 500:
              console.log(error.statusText);
              break;
          }
        } else {
          console.log(error);
        }
        this.cart = []
      }
    } else {
      this.cart = JSON.parse(this.localStorage.getItem("cart")!);
    }
    this.onCartChange.emit()
    return []
  }

  checkItemInCart(photo: IImage): boolean {
    const id: any = this.cart?.find((cartItem: IImage) => {
      return +cartItem?.id === +photo?.id;
    });

    return !!id;
  }

  clearLocalCart() {
    this.localStorage.removeItem("cart")
    this.localStorage.setItem("cart", "[]")
  }

  createLocalStorageCart() {
    if (!this.localStorage.getItem("cart")) {
      this.localStorage.setItem("cart", "[]");
    }
  }

  getCartCounter(): number {
    if (this.cart) {
      return this.cart.length
    }
    return 0;
  }

  setCart(data: IImage[]) {
    this.cart = data
    this.onCartChange.emit()
  }

  async synchronizeCart() {
    if (this.localStorage.getItem("cart")) {
      let cart = JSON.parse(this.localStorage.getItem("cart") || "[]")

      for (let item of cart) {
        try {
          await this.addItem(item)
        } catch (error: any) {
          console.log(error.status)
        }
      }
    }
  }

  private set cart(images: IImage[]) {
    if (images == null) {
      return
    }
    
    this.localStorage.setItem("cart", JSON.stringify(images))
  }
}
