import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { Language } from "src/app/enums/language";
import { AdminCompilationService } from "src/app/services/admin/admin-compilation.service";
import { AdminService } from "src/app/services/admin/admin.service";
import { ModalService } from "src/app/services/admin/modal.service";
import { SeoTemplatesService } from "src/app/services/admin/seo-templates.service";
import { TagService } from "src/app/services/admin/tag.service";
import { LicenseService } from "src/app/services/license.service";
import { environment } from "src/environments/environment";
import {
  IAdminCompilation,
  IAdminImage,
  ICompilationInfo,
  IImageFile,
  ISeoInfo,
  ISeoTemplate,
  IUpdateImage,
  IupdateImageTags,
  Tag,
} from "./../../../models/admin";
import { Observable } from "rxjs";
import { FormBuilder, FormGroup } from "@angular/forms";
import { debounceTime, map, startWith, tap } from "rxjs/operators";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";

/**
 * Auxillary record type to facilitate autocomplete searching
 */
type CompilationRecord = {
  compilation: IAdminCompilation,
  id: number,
  title: string,
  tags: string,
};

@Component({
  selector: "app-image-modal",
  templateUrl: "./image-modal.component.html",
  styleUrls: ["./image-modal.component.scss"],
})
export class ImageModalComponent implements OnInit, OnChanges {
  @Input() activeEssence = "Папки";
  @Input() image: IAdminImage;
  @Input() currentCompilationInfo!: ICompilationInfo;

  @Output() close = new EventEmitter();
  @Output() imageChange = new EventEmitter<1 | -1>();
  @Output() imageEdit = new EventEmitter<IAdminImage>();

  readonly SeoAttribute = SeoAttribute;
  readonly Language = Language;

  @HostListener("window:keydown", ["$event"])
  handleArrowRight(event: KeyboardEvent) {
    const inputElementsTypes = [HTMLInputElement, HTMLTextAreaElement, HTMLSelectElement];
    if (inputElementsTypes.some(T => event.target instanceof T)) {
      // Ignore keyboard events from the form inputs
      return;
    }

    if (event.key === "ArrowRight") {
      this.changeImage(1);
    } else if (event.key === "ArrowLeft") {
      this.changeImage(-1);
    }
  }

  isFullOpen = false;
  isEditMode = false;

  // Current seoInfo displayed on window
  seoInfo: ISeoInfo = {
    id: null,
    title: "",
    description: "",
    keywords: "",
    language: "RU",
  };

  existingTags: Tag[] = [];
  newTags: IupdateImageTags[] = [];
  deletedTags: Tag[] = [];

  currentTag: string;
  price: number;

  originImageName: string;
  originFiles: IImageFile[];

  imageBaseURL = environment.imagesServerUrl + "/";
  imageSource = "";

  newFriendlyUrl = "";

  isLoading: boolean = false;

  readonly seoTemplatesMap: Map<string, string> = new Map();
  readonly compilations: Map<IAdminCompilation, CompilationRecord> = new Map();

  public filteredCompilations: Observable<IAdminCompilation[]>;

  public currentCompilations: IAdminCompilation[] = [];

  public form: FormGroup = null;

  private searchCache: Map<string, IAdminCompilation[]> = new Map();

  constructor(
    private readonly modalService: ModalService,
    private readonly adminService: AdminService,
    private readonly compilationService: AdminCompilationService,
    private readonly licenseService: LicenseService,
    private readonly tagService: TagService,
    private readonly seoTemplatesService: SeoTemplatesService,
    private readonly fb: FormBuilder,
  ) {
    this.form = this.buildImageForm();

    this.filteredCompilations = this.form.get('compilationInput')
      .valueChanges
      .pipe(
        startWith(''),
        debounceTime(600),
        map((query) => this.filterCompilations(query)),
      )
    ;
  }

  async ngOnInit(): Promise<void> {
    await Promise.all([
      this.fetchSeoTemplates(),
      this.fetchCompilations(),
      this.prepareImageData(),
    ]);
  }

  async ngOnChanges() {
    await this.prepareImageData();
  }

  private buildImageForm(): FormGroup {
    const form = this.fb.group({
      // title: ['', [
      //   Validators.required,
      //   Validators.minLength(2),
      // ]],
      // description: ['', [
      //   Validators.minLength(10),
      // ]],
      // published: [false],
      // onMainPage: [false],
      // Only added to the form to detect compilation changes in a uniform way
      // tags: this.fb.control(''),
      // Only added to the form to detect compilation changes in a uniform way
      compilations: this.fb.control(''),
      compilationInput: this.fb.control(''),
      // coverId: [null],
    });

    // Build a SEO form for each language
    // const seoInfosForm = this.fb.group({});
    // this.seoLanguages.forEach((lang) => {
    //   const seoForm = this.buildSeoForm();
    //   seoInfosForm.registerControl(lang, seoForm);
    // });
    // form.registerControl('seo', seoInfosForm);

    return form;
  }

  public addCompilation(event: MatAutocompleteSelectedEvent): void {
    const compilation = event.option.value;
    this.currentCompilations.push(compilation);
    this.form.get('compilationInput').reset('');
    this.updateCompilationsControl();
  }

  public removeCompilation(idx: number): void {
    this.currentCompilations.splice(idx, 1);
    this.updateCompilationsControl();
  }

  private updateCompilationsControl(): void {
    this.form.get('compilations').setValue(this.currentCompilations.map((c) => c.title).join(', '));
  }

  async fetchSeoTemplates(): Promise<void> {
    this.setSeoTemplates(await this.seoTemplatesService.getSeoTemplates());
  }

  setSeoTemplates(templates: ISeoTemplate[]): void {
    for (const seoTemplate of templates) {
      const { attribute, language, template } = seoTemplate;
      this.seoTemplatesMap.set(makeSeoTemplateKey(attribute, language), template);
    }
  }

  async fetchCompilations(): Promise<void> {
    this.setCompilations((await this.compilationService.getCompilations()).items);
  }

  setCompilations(compilations: IAdminCompilation[]): void {
    this.searchCache.clear();

    for (const compilation of compilations) {
      this.compilations.set(compilation, {
        id: compilation.id,
        title: compilation.title,
        tags: compilation.__tags__.map((tag) => tag.name).join(' '),
        compilation,
      });
    }
  }

  filterCompilations(query: string): IAdminCompilation[] {
    const normRexp = /\s+/g;
    const normQuery = query
      .toLowerCase()
      .replace(normRexp, ' ')
      .trim()
    ;

    if (this.searchCache.has(normQuery)) {
      return this.searchCache.get(normQuery);
    }

    const records = [...this.compilations.values()];

    if (normQuery.length < 2) {
      return records.map((record) => record.compilation);
    }

    const filteredRecords: Array<[CompilationRecord, number]> = [];
    records.forEach((record) => {
      let titleScore = 0;
      let tagsScore = 0;

      const positionInTitle = record.title.toLowerCase().indexOf(normQuery);
      if (-1 !== positionInTitle) {
        // Simple ranking model:
        // 1. The closer to string's beginning the substring occurrence, the higher the score
        // 2. The longer the query, the higher the score
        const positionFactor = 1 - positionInTitle / record.title.length;
        const queryLengthFactor = normQuery.length / record.title.length;
        titleScore = queryLengthFactor * positionFactor;
      }

      if (record.tags.toLowerCase().includes(normQuery)) {
        // No sense to evaluate position in the concatenated string, consider only query length
        tagsScore = normQuery.length / record.tags.length;
      }

      const totalScore = titleScore + tagsScore;

      if (totalScore > 0) {
        filteredRecords.push([record, totalScore]);
      }
    });

    // Sort by 'relevance' score in DESC
    filteredRecords.sort((a, b) => b[1] - a[1]);
    const filteredCompilations = filteredRecords.map(([record]) => record.compilation);
    this.searchCache.set(query, filteredCompilations);

    return filteredCompilations;
  }

  public isCompilationOptionDisabled(compilation: IAdminCompilation): boolean {
    return this.currentCompilations.some((current) => current.id == compilation.id);
  }

  public displaySelectedCompilation(compilation: IAdminCompilation): string {
    // We don't need to display the selected option in the input, we add it to the this.currentCompilations
    return '';
  }

  async prepareImageData() {
    this.isLoading = true;

    this.image = await this.adminService.getImage(this.image.id);

    this.originImageName = this.image.name;
    this.originFiles = this.image.__files__;

    if (
      this.image?.__prices__ != null &&
      this.image?.__prices__[0]?.value != null
    ) {
      this.price = this.image.__prices__[0].value;
    }

    this.resetImageTags(this.image?.__tags__ ? this.image.__tags__ : []);

    this.newFriendlyUrl = this.image.friendlyURL;

    this.selectSeoLanguage("RU");
    this.setImageSource();

    // Image compilations
    this.currentCompilations = [];
    if (this.image.__compilations__?.length) {
      this.image.__compilations__.forEach((compilation) => {
        this.currentCompilations.push(compilation);
      });
    }

    // Fake loading
    setTimeout(() => {
      this.isLoading = false;
    }, 500);
  }

  resetImageTags(tags: Tag[]): void {
    this.existingTags = tags;
    this.newTags = [];
    this.deletedTags = [];
    this.currentTag = "";
  }

  async handleSave() {
    await this.saveImageData();
    this.isEditMode = false;
  }

  async saveImageData() {
    if (this.image == null || this.image.id == null) {
      return;
    }

    const imageData = this.getImageDataForBackend();

    if (this.originImageName === this.image.name) {
      delete imageData.name;
    }

    if (
      !this.newFriendlyUrl ||
      this.newFriendlyUrl === this.image.friendlyURL
    ) {
      imageData.friendlyURL = null;
    } else {
      imageData.friendlyURL = this.newFriendlyUrl;
      this.image.friendlyURL = this.newFriendlyUrl;
    }

    imageData.compilations = this.currentCompilations.map((c) => c.id);

    await this.updateImage(imageData);

    if (this.deletedTags.length > 0) {
      const deletedTagsIds: number[] = [];
      const deleteTagPromises = this.deletedTags.map((deletedTag) => {
        deletedTagsIds.push(deletedTag.id);
        this.tagService.deleteTag(deletedTag.id, [this.image.id]);
      });
      await Promise.all(deleteTagPromises);

      this.image.__tags__ = this.image.__tags__.filter((tag) => !deletedTagsIds.includes(tag.id));
      this.deletedTags = [];
    }

    if (this.newTags.length > 0) {
      // FIXME: The order of requests is undefined. Create API method for single request update.
      await Promise.all(this.newTags.map((tag) => this.tagService.addTagsToImage(tag)));

      const updatedTags = await this.tagService.getTags(this.image.id);

      this.resetImageTags(updatedTags);
      this.image.__tags__ = updatedTags;
    }
  }

  async updateImage(imageData: IUpdateImage) {
    await this.updateImagesSeoInfo();

    await this.adminService.updateImage(this.image?.id, imageData);
    // Adding image data that is not returning from server
    this.image.__files__ = this.originFiles;

    await this.updateImagesPrice();

    this.originImageName = this.image.name;
    this.imageEdit.emit(this.image);
  }

  async updateImagesSeoInfo() {
    if (this.seoInfo.id != null) {
      const response = await this.adminService.updateSeoTag(
        this.getSeoDataFromForm(),
        this.seoInfo?.id
      );
      const index = this.image.__seoInfo__.findIndex(
        (seo) => seo.id === this.seoInfo.id
      );

      if (index != -1) {
        this.image.__seoInfo__[index] = response;
      }
    } else {
      const response = await this.adminService.addSeoToImage(
        this.getSeoDataFromFormWithLanguage()
      );
      this.image.__seoInfo__ = response;
    }
  }

  selectSeoLanguage(lang: "RU" | "EN" | string) {
    if (this.image.__seoInfo__ == null || this.image.__seoInfo__.length < 1) {
      this.seoInfo = {
        title: "",
        description: "",
        keywords: "",
        language: lang,
      };
      return;
    }

    const selectedSeoInfo = this.image.__seoInfo__.find(
      (seo) => seo.language === lang
    );

    if (selectedSeoInfo != null) {
      const selectedSeoInfoCopy = JSON.parse(JSON.stringify(selectedSeoInfo));
      this.seoInfo = selectedSeoInfoCopy;
    } else {
      this.seoInfo = {
        title: "",
        description: "",
        keywords: "",
        language: lang,
      };
    }
  }

  deleteExistingTag(id: number) {
    const tagIndex = this.existingTags.findIndex((tag) => tag.id === id);
    const [deletedTag] = this.existingTags.splice(tagIndex, 1);
    this.deletedTags.push(deletedTag);
  }

  deleteNewTag(idx: number) {
    this.newTags.splice(idx, 1);
  }

  /**
   * Makes Enter and Tab presses (without modifiers) to add the current tag
   */
  onTagInputKeydown(event: KeyboardEvent) {
    const modifiersToSkip = ['Control', 'Alt', 'Shift'];
    const modifierIsActive = modifiersToSkip.some(k => event.getModifierState(k));
    if (modifierIsActive || !['Enter', 'Tab'].includes(event.key)) {
      return;
    }

    event.stopPropagation();
    event.preventDefault();

    this.addTagToImage();
  }

  async addTagToImage() {
    if (this.currentTag === "") {
      return;
    }

    const tagData = {
      imageId: this.image.id,
      tagName: this.currentTag,
      tagType: "HASHTAG",
    };

    // const newTag = await this.tagService.addTagsToImage(tagData);
    this.currentTag = "";
    // this.image.__tags__ = newTag;
    this.newTags.push(tagData);
  }

  onDelete() {
    this.adminService.setDeleteModalContent = "Image";
    this.adminService.deletingItemsIds = [this.image.id];
    this.modalService.openDeleteModal();
  }

  publishChange() {
    this.image.published = !this.image.published;
  }

  onMainPageChange() {
    this.image.onMainPage = !this.image.onMainPage;
  }

  onDeleteFromCompilation() {
    this.compilationService.deleteImageFromCompilation(
      this.currentCompilationInfo.id,
      this.image.id
    );
    this.closeModal();
  }

  enableEditMode() {
    this.isEditMode = true;
  }

  async changeImage(action: 1 | -1) {
    if (this.isEditMode) {
      await this.handleSave();
    }
    this.imageChange.emit(action);
  }

  handleEscapePress() {
    if (this.isEditMode) {
      this.isEditMode = false;
    } else {
      this.closeModal();
    }
  }

  closeModal() {
    this.close.emit();
  }

  private async updateImagesPrice() {
    if (this.price == null) {
      return;
    }

    if (this.image.__prices__ == null || this.image.__prices__.length < 1) {
      const priceId = await this.licenseService.addPriceToImage(
        this.image.id,
        this.price
      );

      const license = {
        id: priceId,
        value: this.price,
        license: 0,
      };

      this.image.__prices__ = [];
      this.image.__prices__.push(license);
    } else {
      await this.licenseService.editPrice(
        this.image.__prices__[0].id,
        this.price
      );
      this.image.__prices__[0].value = this.price;
    }
  }

  private getSeoDataFromFormWithLanguage() {
    return {
      title: this.seoInfo.title,
      description: this.seoInfo.description,
      keywords: this.seoInfo.keywords,
      language: this.seoInfo.language,
      imageId: this.image.id,
    };
  }

  private getSeoDataFromForm() {
    return {
      title: this.seoInfo.title,
      description: this.seoInfo.description,
      // keywords: this.form.value.keywords
    };
  }

  private getImageDataForBackend() {
    return {
      name: this.image.name,
      title: this.image.title,
      published: this.image.published,
      onMainPage: this.image.onMainPage,
      author: this.image.author,
      description: this.image.description,
      coordinates: this.image.coordinates,
      altDescription: this.image.altDescription,
      imgTitle: this.image.imgTitle,
      friendlyURL: this.image.friendlyURL,
      compilations: (this.image.__compilations__ || []).map((c) => c.id),
    };
  }

  private setImageSource() {
    this.imageSource =
      this.imageBaseURL +
      this.image?.__files__?.find((file) => file.format === "ORIGINAL")
        ?.imageUrl;
  }

  createSeoAttribute(attr: SeoAttribute): void {
    const value = this.makeSeoAttribute(attr, this.seoInfo.language, this.image);
    if (undefined === value) {
      console.error(`Can\'t create SEO attribute: ${attr}`);
      return;
    }
    // @ts-ignore
    this.seoInfo[attr] = value;
  }

  makeSeoAttribute(attr: SeoAttribute, lang: string, photo: IAdminImage): string {
    const template = this.seoTemplatesMap.get(makeSeoTemplateKey(attr, lang));
    if (undefined === template) {
      console.error(`No template for SEO attribute found: ${attr} (${lang})`);
      return '';
    }
    const value = template
      .replace('{photo.title}', photo.title ?? '')
      .replace('{photo.id}', String(photo.id) ?? '')
      // ... other available replacements
    ;

    return value;
  }

  hasSeoAttributeTemplate(attr: SeoAttribute, lang: string): boolean {
    const template = this.seoTemplatesMap.get(makeSeoTemplateKey(attr, lang));
    return !!template;
  }
}

enum SeoAttribute {
  TITLE = 'title',
  DESCRIPTION = 'description',
  IMG_ALT = 'img_alt',
  IMG_TITLE = 'img_title',
}

function makeSeoTemplateKey(attr: string, lang: string): string {
  return `${attr.toLowerCase()},${lang.toLowerCase()}`;
}
