import {
  Component,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ImagesBulkAction } from "@app/enums/images-bulk-action";
import { Subscription } from "rxjs/internal/Subscription";
import { Action } from "src/app/models/action";
import { IAdminImage, IFolder } from "src/app/models/admin";
import { IViewOrder, IViewSettings, IViewSort } from "src/app/models/models";
import { AdminCompilationService } from "src/app/services/admin/admin-compilation.service";
import { AdminService } from "src/app/services/admin/admin.service";
import { ModalService } from "src/app/services/admin/modal.service";
import { UploadService } from "src/app/services/admin/upload.service";
import { ViewSettingsService } from "src/app/services/admin/view-settings.service";
import { ViewMode } from "src/app/views/admin/workspace/workspace.component";

@Component({
  selector: "app-admin-photos-grid",
  templateUrl: "./admin-photos-grid.component.html",
  styleUrls: ["./admin-photos-grid.component.scss"],
})
export class AdminPhotosGridComponent implements OnChanges, OnInit, OnDestroy {
  readonly ViewMode = ViewMode;

  // Folder or compilation ID
  @Input() currentId: number;
  @Input() images: IAdminImage[] = [];
  @Input() folders: IFolder[] = [];
  @Input() viewMode: ViewMode;
  @Input() isFolderPage = false;
  @Input() compilationCover: IAdminImage;

  isListing: boolean;

  isLoadedBarOpen = false;

  loadedBarSubscription: Subscription;

  selectedFiles: Array<IAdminImage | IFolder> = [];
  selectedImages: IAdminImage[] = [];

  filesNumber: string = "";
  activeImage: IAdminImage;

  isSelectMode = false;
  isDtsContainer = false;

  isImageModalVisible = false;
  isMoveModalVisible = false;
  isAddToCompilationModalVisible = false;
  isBulkEditModalVisible = false;
  isAddImagesToCompilationModalVisible = false;
  isDeleteFromImagesCompilationModalVisible = false;

  selectedImagesIds: number[];

  sortBy: IViewSort = null;
  isSortOrderAsc: boolean = null;

  viewSettings: IViewSettings | null = null;

  constructor(
    private readonly modalService: ModalService,
    private readonly adminService: AdminService,
    private readonly uploadService: UploadService,
    private readonly compilationService: AdminCompilationService,
    private readonly viewSettingsService: ViewSettingsService
  ) {}

  @HostListener("click", ["$event"]) onClick(evt: any) {
    if (evt.target.className === "dts-select-container") {
      this.isDtsContainer = true;
    } else {
      this.isDtsContainer = false;
    }
  }

  async ngOnInit() {
    this.loadedBarSubscription = this.uploadService
      .onLoadedBarToggle()
      .subscribe((isOpen: boolean) => (this.isLoadedBarOpen = isOpen))
    ;

    await this.restoreViewSettings();
    this.sort();
  }

  ngOnDestroy() {
    this.loadedBarSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.isListing  = [ViewMode.List, ViewMode.BiggerList].includes(this.viewMode);

    this.unchooseAllItems();

    if (changes.images && !changes.images.isFirstChange()) {
      this.sort();
    }
  }

  async restoreViewSettings() {
    const savedViewSettings = await this.viewSettingsService.getViewSettings();
    this.viewSettings = savedViewSettings;
    this.sortBy = savedViewSettings.sort;

    this.isSortOrderAsc = true;
    if (savedViewSettings.order === "DESC") {
      this.isSortOrderAsc = false;
    }
  }

  onSelect(selectedItems: Array<IAdminImage | IFolder>) {
    if (selectedItems == null || selectedItems.length < 1) {
      return;
    }

    for (const item of selectedItems) {
      const itemIndex = this.selectedFiles.findIndex(
        (file) => file.id === item.id
      );

      if (itemIndex === -1) {
        item.isSelected = true;
        this.selectedFiles.unshift(item);
      }
    }

    this.selectedImages = this.selectedFiles.filter((item) =>
      item.hasOwnProperty("onMainPage")
    ) as IAdminImage[];
  }

  toggleItemSelection(selectedItem: IAdminImage | IFolder) {
    const itemIndex = this.selectedFiles.findIndex(
      (chosen) => chosen.id === selectedItem.id
    );

    if (itemIndex != -1) {
      this.selectedFiles.splice(itemIndex, 1);
    } else {
      this.selectedFiles.push(selectedItem);
    }

    selectedItem.isSelected = !selectedItem.isSelected;
    this.selectedImages = this.selectedFiles.filter((item) =>
      item.hasOwnProperty("onMainPage")
    ) as IAdminImage[];
  }

  toggleImageModal(image: IAdminImage = null) {
    this.activeImage = image;
    this.isImageModalVisible = !this.isImageModalVisible;
  }

  changeImage(action: 1 | -1) {
    let newIndex = this.images.indexOf(this.activeImage) + action;

    if (newIndex > this.images.length - 1) {
      newIndex = 0;
    } else if (newIndex < 0) {
      newIndex = this.images.length - 1;
    }

    this.activeImage = this.images[newIndex];
  }

  handleAction(action: Action) {
    switch (action) {
      case Action.delete:
        this.openDeleteModal();
        break;

      case Action.move:
        this.toggleMoveImageModal();
        break;

      case Action.addToCompilation:
        this.toggleAddImagesToCompilationModal();
        break;

      case Action.deleteFromCompilation:
        this.toggleDeleteImagesFromCompilationModalVisible();
        break;

      case Action.bulkEdit:
        this.toggleBulkEditModal();
        break;

      case Action.setCover:
        this.handleSetCover();
        break;

      case Action.publish:
        this.handlePublish();
        break;

      case Action.unpublish:
        this.handleUnpublish();
        break;
    }
  }

  chooseAllItems() {
    const items: Array<IAdminImage | IFolder> = [
      ...this.images,
      ...this.folders,
    ];

    for (const item of items) {
      item.isSelected = true;

      if (!this.selectedFiles.includes(item)) {
        this.selectedFiles.push(item);
      }
    }

    this.selectedImages = this.selectedFiles.filter((item) =>
      item.hasOwnProperty("onMainPage")
    ) as IAdminImage[];
  }

  unchooseAllItems() {
    for (const item of this.selectedFiles) {
      item.isSelected = false;
    }

    this.selectedFiles.length = 0;
    this.selectedImages.length = 0;
  }

  replaceEditedImage(image: IAdminImage) {
    const index = this.images.indexOf(this.activeImage);
    this.images[index] = image;
    this.activeImage = this.images[index];
  }

  toggleMoveImageModal() {
    this.isMoveModalVisible = !this.isMoveModalVisible;
  }

  toggleBulkEditModal() {
    this.isBulkEditModalVisible = !this.isBulkEditModalVisible;
  }

  toggleAddImagesToCompilationModal() {
    this.isAddImagesToCompilationModalVisible =
      !this.isAddImagesToCompilationModalVisible;
  }

  toggleDeleteImagesFromCompilationModalVisible() {
    this.selectedImagesIds = this.selectedImages.map((i) => i.id);
    this.isDeleteFromImagesCompilationModalVisible =
      !this.isDeleteFromImagesCompilationModalVisible;
  }

  addLoadedImages(loadedImages: IAdminImage[]) {
    this.unchooseAllItems();

    for (const image of loadedImages) {
      this.images.push(image);
    }

    this.onSelect(loadedImages);
  }

  openBulkEditor() {
    this.toggleBulkEditModal();
  }

  openImageModal(image: IAdminImage) {
    this.toggleImageModal(image);
  }

  async handleSetCover() {
    const newCover = this.selectedFiles[0].id;
    await this.compilationService.updateCompilation(this.currentId, {
      imageIdCover: newCover,
    });
    setTimeout(() => location.reload(), 0);
  }

  handleSort(sortBy: IViewSort) {
    if (this.sortBy === null || this.sortBy !== sortBy) {
      this.sortBy = sortBy;
      this.isSortOrderAsc = true;
    } else {
      this.isSortOrderAsc = !this.isSortOrderAsc;
    }

    this.sort();

    this.viewSettingsService.updateViewSettings({
      ...this.viewSettings,
      sort: sortBy,
      order: this.isSortOrderAsc ? 'INC' : 'DESC',
    });
  }

  async handlePublish() {
    const ids = this.selectedImages.map((i) => i.id);
    await this.adminService.imagesBulkAction(ids, ImagesBulkAction.Publish);
    setTimeout(() => location.reload(), 0);
  }

  async handleUnpublish() {
    const ids = this.selectedImages.map((i) => i.id);
    await this.adminService.imagesBulkAction(ids, ImagesBulkAction.Unpublish);
    setTimeout(() => location.reload(), 0);
  }

  getSortingPredicate(sortBy: IViewSort, direction: number) {
    if (sortBy === 'NAME') {
      return AdminPhotosGridComponent.getSortByNamePredicate(direction);
    } else if (sortBy === 'DATE') {
      return (a: IAdminImage, b: IAdminImage) => {
        return (new Date(a.uploadDate).getTime() - new Date(b.uploadDate).getTime()) * direction;
      };
    }

    return undefined;
  }

  isSortedBy(sortBy: IViewSort) {
    return this.sortBy === sortBy;
  }

  private openDeleteModal() {
    const isImagesSelected = this.selectedFiles.some((item) =>
      item.hasOwnProperty("author")
    );

    if (!isImagesSelected) {
      this.adminService.setDeleteModalContent = "Folder";
      this.adminService.deletingItemsIds = this.selectedFiles.map(
        (img) => img.id
      );
    } else {
      this.adminService.setDeleteModalContent = "Images";
      this.adminService.deletingItemsIds = this.selectedImages.map(
        (img) => img.id
      );
    }

    this.modalService.openDeleteModal();
  }

  private sort() {
    const predicate = this.getSortingPredicate(this.sortBy, this.isSortOrderAsc ? 1 : -1);
    if (!predicate) {
      return;
    }

    this.images.sort(predicate);

    if (this.folders !== null && this.folders.length > 1) {
      // @ts-ignore fix this nonsense
      this.folders.sort(predicate);
    }
  }

  private static getSortByNamePredicate(direction: number) {
    const numericNameRexp = /^(\d+)$/;
    const numericPrefixRexp = /^(\d+)_/;
    const numericSuffixRexp = /_(\d+)$/;

    return (a: IAdminImage, b: IAdminImage) => {
      // Try to compare by numeric name/prefix/suffix number
      const aName = a.name.trim();
      const bName = b.name.trim();
      const aPrefix = numericPrefixRexp.exec(aName) || numericNameRexp.exec(aName);
      const aSuffix = numericSuffixRexp.exec(aName);
      const bPrefix = numericPrefixRexp.exec(bName) || numericNameRexp.exec(bName);
      const bSuffix = numericSuffixRexp.exec(bName);

      // items with numeric names & number-prefixed items go first
      // number-suffixed go second
      // all other go after
      if (aPrefix && bPrefix) {
        return (+aPrefix[1] - +bPrefix[1]) * direction;
      } else if (aSuffix && bSuffix) {
        return (+aSuffix[1] - +bSuffix[1]) * direction;
      } else if (aPrefix) {
        return -1;
      } else if (bPrefix) {
        return +1;
      }

      // Items without numeric prefix/suffix always go after
      return aName.normalize().localeCompare(bName.normalize());
    }
  }
}
