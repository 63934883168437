import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IAdminImage } from '../models/admin';
import { IImage } from '../models/image';
import { ICompilationPreview } from '../models/models';

@Injectable({
  providedIn: 'root',
})
export class ImageSourceService {
  constructor() {}

  returnUrl(
    photo: IImage | IAdminImage | null,
    type: 'MINI' | 'ORIGINAL' | 'THUMBNAIL',
    compilationPreview: ICompilationPreview | null = null
  ) {
    if (
      compilationPreview != null &&
      compilationPreview.files != null &&
      compilationPreview.files.length > 0
    ) {
      let file = compilationPreview.files.filter(
        (file) => file.format === 'MINI'
      )[0];

      return file.storageType === 'LOCAL'
        ? environment.imagesServerUrl + '/' + file.imageUrl
        : environment.imageCloudServerUrl + '/' + file.imageUrl;
    }

    if (photo && photo.__files__) {
      let file;

      if (
        // If two formats exist, select requested
        photo.__files__?.filter((value) => value.format === 'ORIGINAL')[0] &&
        photo.__files__?.filter((value) => value.format === 'MINI')[0]
      ) {
        file = photo.__files__?.filter((value) => value.format === type)[0];
      } else if (
        // If only one format exist, select that one
        photo.__files__?.filter((value) => value.format === 'ORIGINAL')[0] ||
        photo.__files__?.filter((value) => value.format === 'MINI')[0]
      ) {
        file = photo.__files__[0];
      }

      if (!file.imageUrl) {
        file.imageUrl = file.id + "." + file.extension;
      }

      // Returns URL to image depending on what type of Storage mentioned in object image
      switch (file?.storageType) {
        case 'LOCAL':
          return environment.imagesServerUrl + '/' + file.imageUrl;
        case 'S3':
          return environment.imageCloudServerUrl + '/' + file.imageUrl;
        default:
          return '';
      }
    }
    return '';
  }
}
