<div
  (click)="openFolder(); $event.stopPropagation()"
  class="admin__item"
  [class.myselected]="folder?.isSelected"
  [class.listing]="isListing"
  [class.bigger-listing]="ViewMode.BiggerList === viewMode"
>
  <div #checkbox class="admin__item-checkbox" >
    <app-checkbox
      [checked]="folder?.isSelected"
      (click)="$event.stopPropagation()"
      (checkboxClick)="toggleChecked()"
    ></app-checkbox>
  </div>

  <div class="admin__item-image">
    <img src="assets/images/folder.png" alt="" />
  </div>

  <div *ngIf="isListing" class="admin__item-name">
    {{ folder?.name }}
  </div>

  <div *ngIf="!isListing" class="admin__item-details">
    <div class="admin__item-details-name">
      {{ folder?.name }}
    </div>
  </div>
</div>