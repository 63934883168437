<div class="photos">
  <ngx-masonry [options]="masonryOptions" [ordered]="true">
    <div
      ngxMasonryItem
      class="photo masonry-item"
      *ngFor="let photo of photos; let i = index"
    >
      <img
        [src]="photo.img"
        [alt]="photo.altDescription"
        [title]="photo.imgTitle"
        class="photo__item"
      />

      <a
        [routerLink]="['/photos', photo.friendlyURL || photo.id]"
        [queryParams]="{ search: this.searchQuery, page: this.searchPage }"
        title="Перейти к фотографии"
        class="photo__hover photo__desktop-ui"
      >
        <app-photo-grid-info
          (click)="this.onInfoClick($event)"
          [photo]="photo"
          [toggleCarousel]="toggleCarousel.bind(this, i)"
          [searchQuery]="this.searchQuery"
          [searchPage]="this.searchPage"
          class="desktop-ui"
        ></app-photo-grid-info>
      </a>

      <div
        class="photo__hover photo__mobile-ui"
      >
        <app-photo-grid-info
          [photo]="photo"
          [toggleCarousel]="toggleCarousel.bind(this, i)"
          [searchQuery]="this.searchQuery"
          [searchPage]="this.searchPage"
          class="mobile-ui"
        ></app-photo-grid-info>
      </div>
      <!-- ./photo-hover -->
    </div>
    <!-- ./photo masonry-item -->
  </ngx-masonry>
</div>

<app-popup-carousel
  [photos]="photos"
  [index]="photoIndex"
  [searchQuery]="searchQuery"
  [searchPage]="searchPage"
  *ngIf="isPopupCarouselVisible"
  (closePopupCarousel)="toggleCarousel(0)"
></app-popup-carousel>
