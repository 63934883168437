<header class="header">
  <nav>
    <div>
      <form
        *ngIf="withSearch"
        (mouseover)="controlsToggle = true"
        (mouseout)="controlsToggle = false"
        (ngSubmit)="onSearch()"
        autocomplete="off"
      >
        <div
          class="input-wrapper"
          (mouseout)="controlsToggle = false"
          *ngIf="activeEssenceName !== 'Подборки'"
        >
          <input
            type="text"
            name="search"
            placeholder="Поиск"
            [(ngModel)]="searchQuery"
          />
          <button
            (click)="onSearch()"
            *ngIf="searchQuery.length > 0"
            class="search-btn"
            type="submit"
          >
            Поиск
          </button>
          <button *ngIf="searchQuery.length > 0" (click)="searchQuery = ''"></button>
        </div>
      </form>
    </div>

    <ul>
      <li class="profile">
        <a (click)="isProfile = true">
          <img
            class="profile-icon"
            src="../../../assets/images/no-avatar.png"
            alt=""
          />
        </a>
        <div
          (clickOutside)="onProfileOutside($event)"
          #action
          class="action-modal"
          *ngIf="isProfile"
        >
          <ul class="action-modal__list" #logout>
            <li
            *ngIf="isUserAdmin" 
              class="action-modal__list-item" 
              (click)="navigateToUsersPanel(); onProfileOutside($event)">
              <a class="action-modal__list-item-link" [class.active-page]="isUsersPage">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                  <path d="M2.87999 10.0181C1.35427 10.5072 0 11.6426 0 15H16C16 11.6426 14.6759 10.5169 13.12 10.0181C12.1067 9.69314 9.70666 9.42238 9.70666 8.06859C9.70666 5.95668 11.0933 6.44404 11.0933 3.41155C11.0933 0.866426 9.49333 0 8 0C6.50666 0 4.90667 0.866426 4.90667 3.41155C4.90667 6.44404 6.29333 5.95668 6.29333 8.06859C6.29333 9.42238 3.89333 9.69314 2.87999 10.0181Z"/>
                  </svg>
                Управление пользователями
              </a>
            </li>
            <li class="action-modal__list-item" (click)="onLogout()">
              <a class="action-modal__list-item-link">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.51009 12.8876C6.50316 12.9248 6.5102 12.9708 6.53065 13.025C6.53065 13.0522 6.53397 13.1198 6.5408 13.2282C6.54766 13.3366 6.5493 13.4262 6.54594 13.4973C6.54251 13.5681 6.53225 13.648 6.51524 13.7359C6.49816 13.824 6.4642 13.89 6.41327 13.934C6.36216 13.9779 6.29258 14 6.20412 14H2.93871C2.12926 14 1.43705 13.7139 0.862163 13.1415C0.287423 12.5696 0 11.8806 0 11.0749V3.92492C0 3.11919 0.287423 2.43032 0.862163 1.8582C1.43705 1.28608 2.12926 1 2.93871 1H6.20401C6.29244 1 6.36892 1.03219 6.43357 1.09637C6.49816 1.16083 6.5305 1.23685 6.5305 1.32485C6.5305 1.35207 6.5339 1.41976 6.54065 1.52805C6.54751 1.63637 6.54916 1.72615 6.5458 1.79719C6.54237 1.8683 6.53211 1.94777 6.5151 2.03578C6.49801 2.12379 6.46406 2.18985 6.41313 2.23385C6.36199 2.27796 6.29244 2.2998 6.20398 2.2998H2.93871C2.48974 2.2998 2.10535 2.45899 1.78559 2.77727C1.46585 3.0955 1.30606 3.47795 1.30606 3.92482V11.0749C1.30606 11.5217 1.46589 11.9042 1.78559 12.2225C2.10535 12.5409 2.48974 12.6999 2.93871 12.6999H6.12231L6.23965 12.7101C6.31795 12.7168 6.35702 12.727 6.35702 12.7405C6.35702 12.7539 6.38422 12.7727 6.43872 12.7963C6.49323 12.8199 6.51699 12.8506 6.51009 12.8876Z"
                  />
                  <path
                    d="M10.2548 1.51778L15.8058 7.04295C15.935 7.17162 15.9997 7.32416 15.9997 7.50017C15.9997 7.67626 15.935 7.82844 15.8058 7.95721L10.2548 13.4821C10.1256 13.6108 9.97252 13.6752 9.79567 13.6752C9.61876 13.6752 9.46565 13.6108 9.33638 13.4821C9.2071 13.3536 9.14252 13.201 9.14252 13.0251V10.1001H4.57109C4.39421 10.1001 4.24124 10.0355 4.11182 9.90708C3.98255 9.77848 3.91797 9.62609 3.91797 9.45007V5.54998C3.91797 5.37393 3.98255 5.22154 4.11182 5.09287C4.2411 4.96438 4.3941 4.89992 4.57109 4.89992H9.14248V1.97489C9.14248 1.79895 9.20707 1.64645 9.33634 1.51778C9.46565 1.38929 9.61876 1.32483 9.79564 1.32483C9.97252 1.32483 10.1256 1.38929 10.2548 1.51778Z"
                  />
                </svg>
                Выйти
              </a>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </nav>
</header>
