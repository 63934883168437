import { ComparatorFn } from './comparator';

export enum Direction {
  ASC = 1,
  DESC = -1,
};

type ComparatorSpecArg<T = any> = Comparator<T> | ComparatorSpec<T>;
export type Comparator<T = any> = ComparatorFn<T>;
export type ComparatorSpec<T = any> = {
  comparator: Comparator<T>;
  handlesDirection: boolean;
};

export type SortingSpec<T = any> = [string, ComparatorSpecArg<T>?];
export type SortingState = [string, Direction];

export class SortingModel<T = any> {
  public readonly sortings = new Map<keyof T | string, ComparatorSpec<T>>([]);
  public readonly activeSortings = new Map<keyof T | string, Direction>([]);;

  constructor(
    sortings: Array<SortingSpec<T>> | Record<string, ComparatorSpecArg<T>> = [],
    activeSortings: Array<SortingState> | Record<string, Direction> = [],
  ) {
    if (!Array.isArray(sortings)) {
      sortings = Object.entries(sortings).map((v) => v);
    }
    sortings.forEach(([name, comparatorSpec]) => this.registerSorting(name, comparatorSpec));

    if (!Array.isArray(activeSortings)) {
      activeSortings = Object.entries(activeSortings).map((v) => v);
    }
    activeSortings.forEach(([name, direction]) => this.activateSorting(name, direction));
  }

  public registerSorting(name: string, comparatorSpec?: ComparatorSpecArg<T>) {
    if (!comparatorSpec) {
      comparatorSpec = {
        // Delegate comparation to JS
        comparator: (a: T, b: T) => +a - +b,
        handlesDirection: false,
      };
    } else if (comparatorSpec instanceof Function) {
      comparatorSpec = {
        comparator: comparatorSpec,
        handlesDirection: false,
      };
    }
    this.sortings.set(name, comparatorSpec);

    return this;
  }

  public activateSorting(name: string, direction: Direction): void {
    if (!this.sortings.has(name)) {
      throw new Error(`Sorter with name '${name}' is not registered`);
    }
    this.activeSortings.set(name, direction);
  }

  public deactivateSorting(name: string): void {
    if (!this.sortings.has(name)) {
      throw new Error(`Sorter with name '${name}' is not registered`);
    }
    this.activeSortings.delete(name);
  }

  public deactivateAll(): void {
    this.activeSortings.clear();
  }

  public sort(items: Iterable<T>): Iterable<T> {
    const _items = [...items];
    if (!_items.length || !this.activeSortings.size) {
      return items;
    }

    const sortings = [...this.activeSortings.entries()];
    _items.sort((a, b) => {
      let result = 0;
      for (let i = 0, activeSorting = sortings[i]; i < sortings.length; i++) {
        if (undefined === activeSorting) {
          return result;
        }
        const [sortingName, direction] = activeSorting;
        const { comparator, handlesDirection } = this.sortings.get(sortingName)
        result = handlesDirection
          ? comparator(a, b, direction)
          : comparator(a, b) * direction
        ;
        if (0 !== result) {
          return result;
        }
      }

      return result;
    });

    return _items;
  }
}
