import { Component, OnInit } from "@angular/core";
import { LocalStorage } from "src/libs/storage/src";

import { ActivatedRoute } from "@angular/router";
import { IImage } from "src/app/models/image";
import { ICompilationPreview, IMainPageData } from "src/app/models/models";
import { BaseRequestService } from "src/app/services/base-request.service";
import { FavoriteService } from "src/app/services/favorite.service";
import { CartService } from "./../../services/cart.service";

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"],
})
export class MainComponent implements OnInit {
  compilations: ICompilationPreview[] = []
  popular: IImage[] = []

  isEmailConfirmedPopupVisible = false

  constructor(
    private _cartService: CartService,
    private _favoriteService: FavoriteService,
    private localStorage: LocalStorage,
    private requestService: BaseRequestService,
    private readonly route: ActivatedRoute,    
  ) {}

  ngOnInit() {
    if (this.route.snapshot.queryParams.email === "confirmed") {
      this.isEmailConfirmedPopupVisible = true
    }

    this.getData()

    if (!this.localStorage.getItem("accessToken")) {
      this._cartService.createLocalStorageCart();
      this._favoriteService.createLocalStorageFavorites();
    }

  }

  async getData() {
    // Should take off false from arguments later
    let response = await this.requestService.get<IMainPageData>("/main-page", {}, false) 
    this.compilations = response.compilations
    
    this.popular = response.images
  }
}
