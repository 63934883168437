<section class="photo-grid">
  <div class="photo" *ngFor="let photo of photos; let i = index">
    <img [src]="photo.src" [alt]="photo.alt" (click)="clickOnPhoto(i)" />
  </div>
</section>

<app-terms-of-use-popup
  [photos]="photos"
  [index]="photoIndex"
  *ngIf="isPopupCarouselVisible"
  (closePopupCarousel)="toggleCarousel(0)"
></app-terms-of-use-popup>
