<ul *ngFor="let folder of folders" class="folders-list" >
  <li 
    (click)="toggleFoldersSublist(folder); emitClickOnFolder(folder.id)" 
    [routerLinkActive]="highlightLastClickedFolder? '' : 'active'"
    [routerLink]="isNavigateToFolderOnClickActive? ['/workspace/folders', folder.id] : null"
    [class.folder-tab_has-children]="folder.children != null && folder.children.length > 0"
    [class.folder-tab_has-children_opened]="folder.isOpen"
    [class.folder-tab_highlighted]="highlightLastClickedFolder? folder.id === lastClickedFolderId : false"
    class="folder-tab" 
  >
    {{ folder?.name }}

    <button
      *ngIf="isOptionsButtons"
      class="folder-tab__settings-btn settings-btn"
      (click)="toggleFolderInfoModal(folder.id); $event.stopPropagation()"
    ></button>

  </li>

  <div *ngIf="folder.children != null && folder.children.length > 0 && folder.isOpen" class="node__children">
    <!-- Recursion -->
    <app-recursive-folders
      [folders]="folder.children"
      [isOptionsButtons]="isOptionsButtons"
      [isNavigateToFolderOnClickActive]="isNavigateToFolderOnClickActive"
      [highlightLastClickedFolder]="highlightLastClickedFolder"
      [lastClickedFolderId]="lastClickedFolderId"
      (folderInfoOpened)="toggleFolderInfoModal($event)"
      (clickOnFolder)="emitClickOnFolder($event)"
    ></app-recursive-folders>
  </div>
</ul> 