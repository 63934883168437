<div *ngIf="!isInEditMode" class="admin__item listing">
  <div class="admin__item-name">
    {{ l1City?.macroregion }}
  </div>

  <div class="admin__item-name">
    {{ l1City?.province }}
  </div>

  <div class="admin__item-name">
    {{ l1City?.name }}
  </div>

  <div class="admin__item-add-city admin__item-name">
    <button
      type="button"
      class="i-link"
      (click)="onAddChildItem()"
      [disabled]="!canAddNewChildItem"
    >
      Добавить
    </button>
  </div>

  <div class="admin__item-name">
    {{ l1City?.searchString }}
  </div>

  <div class="admin__item-name">
    <!-- showOnMainPage placeholder -->
  </div>

  <div class="table-row-actions">
    <button
      class="table-row-action table-row-action_edit"
      type="button"
      title="Редактировать"
      (click)="onEdit()"
    ></button>

    <button
      class="table-row-action table-row-action_delete"
      type="button"
      title="Удалить"
      (click)="onDelete()"
    ></button>
  </div>
</div>

<div *ngIf="isInEditMode">
  <app-admin-catalog-l1-city-form
    [l1City]="l1City"
    (submitted)="onSubmit($event)"
    (cancelRequested)="onCancelEdit($event)"
  ></app-admin-catalog-l1-city-form>
</div>
