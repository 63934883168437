import { Injectable } from "@angular/core";
import { BaseRequestService } from "./base-request.service";
import { IAutoComplete } from "../models/auto-complete";
import { IImage } from "../models/image";
import { PhotosService } from "./photos.service";

@Injectable({
  providedIn: "root",
})
export class SearchService {
  baseUrl: string = "/search";

  constructor(
    private readonly requestService: BaseRequestService,
    private readonly photoService: PhotosService
  ) { }

  async getPosts(request: string, page: number = 1, searchId: string = null): Promise<ImageSearchResultDto> {
    request = request?.trim()
    
    if (request == null || request.length < 2) {
      return {
        images: [],
      };
    }
    
    // Search by ID
    if (/^[0-9]+$/.test(request)) {      
      let photo: IImage = null;
      const images: IImage[] = [];
      
      try {
        photo = await this.photoService.getPhotoById(+request)
      } catch {}
      
      if (photo != null) {
        images.push(photo);
      }

      return {
        images,
        total: 1,
      };
    }

    const searchParameters = { 
      searchString: request,
      searchId,
      page: page,
    }
    const searchResult = await this.requestService.post<ImageSearchResultDto>(this.baseUrl, searchParameters);

    return searchResult
  }

  async getHints(request: string): Promise<IAutoComplete> {
    return await this.requestService.post<IAutoComplete>(`${this.baseUrl}/autoComplete`, { searchString: request });
  }
}

export type ImageSearchResultDto = {
  searchId?: string,
  images: IImage[],
  total?: number,
}