import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IFolder } from 'src/app/models/admin';

@Component({
  selector: 'app-recursive-folders',
  templateUrl: './recursive-folders.component.html',
  styleUrls: ['./recursive-folders.component.scss']
})
export class RecursiveFoldersComponent {
  
  @Input() folders: IFolder[]
  @Input() isOptionsButtons = true
  @Input() isNavigateToFolderOnClickActive = true
  @Input() highlightLastClickedFolder = false
  
  @Output() folderInfoOpened = new EventEmitter<number>();
  @Output() clickOnFolder = new EventEmitter<number>();
  
  isSublistOpened = true

  @Input() lastClickedFolderId: number
  
  constructor() { }

  toggleFolderInfoModal(folderId: number) {
    this.folderInfoOpened.emit(folderId)
  }

  toggleFoldersSublist(folder: IFolder) {   
    if (folder.children != null && folder.children.length > 0) {
      folder.isOpen = !folder.isOpen
    }
  }

  emitClickOnFolder(folderId: number) {
    this.lastClickedFolderId = folderId 
    this.clickOnFolder.emit(folderId)
  }
}
