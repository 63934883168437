import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    ViewChild
} from "@angular/core";
import { Router } from "@angular/router";
import { NgxMasonryComponent, NgxMasonryOptions } from "ngx-masonry";
import { IImage } from "src/app/models/image";
import { ImageSourceService } from "src/app/services/image-source.service";

@Component({
  selector: "app-photos-grid",
  templateUrl: "./photos-grid.component.html",
  styleUrls: ["./photos-grid.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PhotosGridComponent implements OnChanges {
  @Input() photos: IImage[] = [];
  @Input() searchQuery: string = undefined;
  @Input() searchPage = 1;

  // Used to define what to do on click on photo (open popup or navigate to photo page)
  @Input() isPopupCarouselUsed = false;

  @ViewChild(NgxMasonryComponent) masonry: NgxMasonryComponent;

  masonryOptions: NgxMasonryOptions = {
    itemSelector: ".masonry-item",
    gutter: 10,
    resize: true,
    // containerStyle: "width: 1200px"
  };

  isPopupCarouselVisible = false;
  photoIndex = 0;

  constructor(
    private router: Router,
    private _imageSourceService: ImageSourceService
  ) {
  }

  ngOnChanges() {
    this.defineImagesSource();
  }

  reloadMasonryLayout() {
    if (this.masonry !== undefined) {
      this.masonry.reloadItems();
      this.masonry.layout();
    }
  }

  toggleCarousel(index: number) {
    this.isPopupCarouselVisible = !this.isPopupCarouselVisible;
    this.photoIndex = index;
  }

  private defineImagesSource() {
    if (this.photos == null || this.photos.length < 1) {
      return;
    }

    for (let photo of this.photos) {

      if (photo.__files__ == null || photo.__files__.length < 1) {
        continue;
      }

      photo.img = this._imageSourceService.returnUrl(photo, "MINI");
    }
  }

  onInfoClick(event: Event): void {
    event.stopPropagation(); event.preventDefault();
  }
}
