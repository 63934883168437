<div class="searcher" (clickOutside)="hideHints()">
  <form
    (ngSubmit)="onSubmit()"
    ngNativeValidate
    class="search-form searcher__search-form"
  >
    <input
      [(ngModel)]="requestText"
      (input)="getHints()"
      [style.backgroundColor]="backgroundColor"
      [placeholder]="placeholder || 'Поиск изображений'"
      [class]="classes"
      type="text"
      minlength="2"
      required
      name="search"
      class="search-form__search-field"
    />
    <ul
      [class.search-form__variants--gray]="isGray"
      class="search-form__autocomplete-list"
      *ngIf="
        isHintsVisible &&
        hints != null &&
        (hints?.seoInfo?.length > 0 || hints?.tags?.length > 0)
      "
    >
      <li
        *ngFor="let hint of hints?.seoInfo"
        class="search-form__variant"
        (click)="setSearchFieldValue(hint.name)"
      >
        {{ hint.name }}
      </li>

      <ul
        *ngIf="hints?.tags != null && hints.tags.length > 0"
        class="search-from__tag-hints"
      >
        <p class="search-form__hint">Теги</p>
        <li
          *ngFor="let tag of hints?.tags"
          class="search-form__tag"
          (click)="setSearchFieldValue(tag.name)"
        >
          {{ tag.name }}
        </li>
      </ul>
    </ul>
    <!-- /.search-variants -->
    <button type="submit" class="search-form__submit-btn">
      <img
        src="../../../assets/icons/magnify.svg"
        alt="Кнопка поиска: Иконка лупы"
        class="search-form__btn-icon"
      />
    </button>
  </form>
  <!-- /.searcher -->

  <div
    *ngIf="undefined !== total"
    class="search__results-number"
  >Найдено {{ total }} фото</div>
</div>
