import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { IAdminImage, ILoadingImage } from "src/app/models/admin";
import { HttpResponse } from "@angular/common/http";
import { UploadService } from "src/app/services/admin/upload.service";
import { Subscription } from "rxjs/internal/Subscription";

@Component({
  selector: "app-loaded-popup",
  templateUrl: "./loadedpopup.component.html",
  styleUrls: ["./loadedpopup.component.scss"],
})
export class LoadedpopupComponent implements OnInit, OnDestroy {
  @Input() progress: number = 0;

  @Output() imagesLoaded = new EventEmitter<IAdminImage[]>();
  @Output() openBulkEditor = new EventEmitter<IAdminImage[]>();
  @Output() openImageModal = new EventEmitter<IAdminImage>();

  loadingFiles: ILoadingImage[] = [];
  loadedFiles: IAdminImage[] = [];

  loadingProgress = 0;
  isLoaded = false;

  statuses: string[] = [];

  isCollapsed = false;

  loadingSubscription: Subscription;

  constructor(private readonly uploadService: UploadService) {}

  ngOnInit() {
    this.loadingFiles = this.uploadService.getLoadingImages;

    this.loadingSubscription = this.uploadService
      .onLoadingBarChange()
      .subscribe(async (value) => {
        if (value instanceof HttpResponse) {
          this.loadedFiles = value.body.data.images;
          this.statuses.push(value.body.data.images[0].name);

          if (value.body.isInFolder) {
            // Reload current page
            setTimeout(() => {
              location.reload();
            }, 0);
            return;
          }

          this.imagesLoaded.emit(value.body.data.images);
        }

        this.loadingProgress = value;

        if (value === 100) {
          this.isLoaded = true;
        }
      });
  }

  ngOnDestroy(): void {
    this.uploadService.setLoadingImages([]);
    this.loadingSubscription.unsubscribe();
  }

  onClose() {
    this.uploadService.closeLoadingBar();
  }

  openEditor() {
    if (this.loadedFiles == null || this.loadedFiles.length < 1) {
      return;
    }

    if (this.loadedFiles.length === 1) {
      this.openImageModal.emit(this.loadedFiles[0]);
    } else {
      this.openBulkEditor.emit();
    }

    this.onClose();
  }
}
