<app-admin-modal (onClose)="closeModal()">
  <form [formGroup]="form" (ngSubmit)="saveTags()">
    <div class="header__title">
      <h2>Проставить тэги</h2>
    </div>

    <div class="admin__item-tags">
      <div class="tags">
        <form [formGroup]="form">
          <ul>
            <li *ngFor="let tag of tags">
              <div class="tags__content">
                <span>{{ tag }}</span>
              </div>
              <div
                class="tags__actions"
                (click)="deleteTag(tag); $event.stopPropagation()"
                #deleteButton
              >
                <button type="button">&#10761;</button>
              </div>
            </li>
            <li class="tags__input" [class.outlined]="true">
              <input
                type="text"
                placeholder="Добавить тег"
                (keyup.enter)="addTag(tagName.value)"
                formControlName="tagName"
                #tagName
              />
            </li>
          </ul>
        </form>
      </div>
    </div>

    <div class="controls">
      <button type="submit" class="btn">Сохранить</button>
    </div>
  </form>
</app-admin-modal>
