import { IAdminImage, ISeoTagsList, IUpdateImagesData, Tag } from "./../../../models/admin";
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { BaseRequestService } from "src/app/services/base-request.service";
import { AdminService } from "src/app/services/admin/admin.service";
import { ISameImageProperties } from "src/app/models/same-image-props";
import { Router } from "@angular/router";
import { TagService } from "src/app/services/admin/tag.service";

@Component({
  selector: "app-edit-modal",
  templateUrl: "./edit-modal.component.html",
  styleUrls: ["./edit-modal.component.scss"],
})
export class EditModalComponent implements OnInit, OnChanges {

  @Input() images: IAdminImage[] = [];

  @Output() close = new EventEmitter();

  sameTags: Tag[] = [];
  addedTags: Tag[] = []

  id = 0

  seoTags: ISeoTagsList = {
    RU: {
      title: "",
      description: "",
      keywords: "",
      language: "RU"
    },
    ENG: {
      title: "",
      description: "",
      keywords: "",
      language: "ENG"
    },
  }

  form: FormGroup = new FormGroup({
    author: new FormControl(""),
    description: new FormControl(""),
    coordinates: new FormControl(""),
    tagName: new FormControl(""),
    seoTitle: new FormControl(""),
    seoDescription: new FormControl(""),
    keywords: new FormControl(""),
    language: new FormControl("RU"),
    price: new FormControl("")
  });


  isAllPublished = false;
  onMainPage = false;

  constructor(
    private adminService: AdminService,
    private requestService: BaseRequestService,
    private readonly router: Router,
    private tagService: TagService
  ) { }

  async ngOnInit() {
    const props = await this.requestService.post<ISameImageProperties>("/images/check-same-props", { imageIds: this.images.map(item => item.id) })
    this.setSharedImagesProperties(props)
  }

  async ngOnChanges() {
    const props = await this.requestService.post<ISameImageProperties>("/images/check-same-props", { imageIds: this.images.map(item => item.id) })
    this.setSharedImagesProperties(props)
  }

  submit() {
    this.closeModal();
  }

  async updateImages() {
    const imageIds = this.images.map((img) => img.id);

    const imagesData: IUpdateImagesData = {
      imageIds,
      author: this.form.value.author,
      coordinates: this.form.value.coordinates,
      imageDescription: this.form.value.description,
      seoTitle: this.form.value.seoTitle,
      seoDescription: this.form.value.seoDescription,
      keywords: this.form.value.keywords,
      seoLanguage: this.form.value.language,
    };
    if (this.form.value.price > 0) {
      imagesData.price = this.form.value.price
    }

    await this.adminService.updateSeveralImages(imagesData)

    for (let tag of this.addedTags) {

      const tagsData = {
        imagesId: this.images.map(img => img.id),
        tagName: tag.name,
        tagType: "HASHTAG",
      };

      await this.tagService.addTagToSeveralImages(tagsData);
    }

    this.closeModal();
    // Reload current page
    setTimeout(() => location.reload(), 0);
  }

  async setSharedImagesProperties(props: ISameImageProperties) {
    if (props.published) {
      this.isAllPublished = this.images[0].published
    }

    if (props.onMainPage) {
      this.onMainPage = this.images[0].onMainPage
    }

    for (let field of ["author", "description", "coordinates"]) {
      if (props[field]) {
        this.form.controls[field].setValue(this.images[0][field])
      }
    }

    // Same tags
    if (props.__tags__ != null && props.__tags__.length > 0) {
      this.sameTags = props.__tags__
    }

    // Same seo tags setting
    if (props.__seoInfo__) {
      for (let seoTag of props.__seoInfo__) {
        this.seoTags[seoTag.language] = props.__seoInfo__.find(tag => tag.language === seoTag.language)
        this.changeFormSeoTags(seoTag.language)
      }
    }
  }


  changeFormSeoTags(language: "RU" | "EN" | string) {
    this.form.controls['seoTitle'].setValue(this.seoTags[language]?.title)
    this.form.controls['seoDescription'].setValue(this.seoTags[language]?.description)
    this.form.controls['keywords'].setValue(this.seoTags[language]?.keywords)
    this.form.controls['language'].setValue(language)
  }


  addTag(tag: string) {
    if (!this.addedTags.find(elem => elem.name === tag)) {
      this.addedTags.push({ id: 0, name: tag })
      this.form.get("tagName")?.setValue("")
    }
  }

  deleteTag(tag: Tag) {
    if (tag.id !== 0) {
      this.tagService.deleteTag(tag.id, this.images.map(image => image.id))
      this.sameTags.splice(this.sameTags.indexOf(tag), 1)

    } else {
      this.addedTags.splice(this.addedTags.indexOf(tag), 1)
    }
  }

  closeModal() {
    this.close.emit()
  }
}
