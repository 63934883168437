import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { AdminModalsService } from './admin-modals.service';
import { ConfirmModalComponent } from './confirm-modal.component';



@NgModule({
  declarations: [
    ConfirmModalComponent,
  ],
  imports: [
    MatDialogModule,
  ],
  providers: [
    AdminModalsService,
  ]
})
export class AdminModalsModule {}
