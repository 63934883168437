import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LocalStorage } from "src/libs/storage/src";
import { EventEmitter } from "@angular/core";
import { IImage } from "../models/image";
import { IAPIResponse } from "../models/models";
import { AuthService } from "./auth.service";
import { BaseRequestService } from "./base-request.service";

@Injectable({
  providedIn: "root",
})
export class FavoriteService {
  favorites: IImage[] = [];
  isItemInFavorites = false;

  onFavoritesChange = new EventEmitter();

  constructor(
    private requestService: BaseRequestService,
    private _localStorage: LocalStorage,
    private _authService: AuthService,
    private localStorage: LocalStorage
  ) {
    this.checkFavorites();
  }

  createLocalStorageFavorites(): void {
    if (!this._localStorage.getItem("favorites")) {
      this._localStorage.setItem("favorites", "[]");
    }
  }

  async checkFavorites() {
    if (
      !this._authService.isLogged() &&
      this._localStorage.getItem("favorites")
    ) {
      this.favorites = JSON.parse(this._localStorage.getItem("favorites")!);
      return;
    }
    if (this._authService.isLogged()) {
      try {
        let response = await this.requestService.get<Array<IImage>>(
          "/images/favorites",
          {}
        );
        if (response) {
          this.favorites = response;
        }
      } catch (error) {
        if (error instanceof HttpErrorResponse) {
          switch (error.status) {
            case 401:
              console.log("Unathorized");
              break;
          }
        }
      }
    }
    this.onFavoritesChange.emit();
  }

  async getAllFavorites() {
    await this.checkFavorites();
    this.onFavoritesChange.emit();
    return this.favorites;
  }

  async addToFavorites(id: number) {
    this.favorites = await this.requestService.patch<Array<IImage>>(
      "/images/" + id,
      null
    );
    this.onFavoritesChange.emit();
  }

  async deleteFromFavorites(idToRemove: number) {
    await this.requestService.delete<IAPIResponse<any>>(
      "/images/favorites/" + idToRemove,
      null
    );
    this.favorites = this.favorites.filter((image) => image.id !== idToRemove);
    this.onFavoritesChange.emit();
  }

  getFavoritesCounter() {
    return this.favorites.length;
  }

  checkItemInFavorites(photo: IImage): boolean {
    if (this.favorites && photo) {
      const id: any = this.favorites.find((favoriteItem: IImage) => {
        return +favoriteItem?.id === +photo?.id;
      });

      return !!id;
    }
    return false;
  }

  async synchronizeFavorites() {
    if (this.localStorage.getItem("favorites")) {
      let favorites = JSON.parse(this.localStorage.getItem("favorites") || "");

      for (let item of favorites) {
        await this.addToFavorites(item.id);
      }
    }
  }
}
