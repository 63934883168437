import { IFolder } from "../models/admin"

export class FolderUtils {

  // Return null if folder doesn't exist
  static restoreOpenedFolders(folders: IFolder[], currentFolderId: number): IFolder[] | null {
    // Check on root folder
    if (currentFolderId === 1) {
      return folders
    }

    let foundFolder = false 

    for (let folder of folders) {     
      if (folder.id === currentFolderId) {
        folder.isOpen = true
        foundFolder = true
        return folders
      }
      
      if (folder.children == null || folder.children.length < 1) {
        continue
      }
      
      const found = this.restoreOpenedFolders(folder.children, currentFolderId)

      if (found != null) {
        folder.isOpen = true
        foundFolder = true
        return folders
      }
    }

    return foundFolder? folders : null
  }

  // Take sorted by hierarchy filesystem and replaces folder in it with new. Use ID of newFolder fot search
  static updateFolderInHierarchy(foldersSortedByHierarchy: IFolder[], newFolder: IFolder): IFolder[] {
    for (let folder of foldersSortedByHierarchy) {
      
      if (folder.children == null || folder.children.length < 1) {
        continue
      }
      
      this.updateFolderInHierarchy(folder.children, newFolder)
      const folderToReplace = folder.children.find(item => item.id === newFolder.id)
      
      if (folderToReplace != null) {
        const index = folder.children.indexOf(folderToReplace)
        folder.children[index] = newFolder
        return folder.children
      }
    }

    return foldersSortedByHierarchy
  }

  static sortFoldersByHierarchy(folders: IFolder[]) {
    const rootFolderIndex = folders.findIndex(item => item.name === "root" && item.id === 1)
    
    // Removes root folder, because it is under the hood, user shouldn't see it
    if (rootFolderIndex != null) {
      folders.splice(rootFolderIndex, 1)
    }

    this.moveChildrenRecursivelyByHierarchy(folders)
    
    return folders
  }  

  static moveChildrenRecursivelyByHierarchy(foldersList: IFolder[], parent: IFolder = null, children: IFolder = null) {
    // If it is first start of function
    if (children == null && parent == null) {
      const firstLevelFolders = foldersList.filter(item => item.level === 1)
      
      for (const folder of firstLevelFolders) {
        if (folder.children != null && folder.children.length > 0) {
          for (let childrenFolder of folder.children) {
            this.moveChildrenRecursivelyByHierarchy(foldersList, folder, childrenFolder)
          }
        }
      }

    // If it's not a first start
    } else {
      const folder = foldersList.find(item => item.id === children.id)

      if (folder.children == null || folder.children.length < 1) {
        const index = foldersList.indexOf(folder)
        foldersList.splice(index, 1)
        return;
      }

      // Run through folder's childrens
      for (let children of folder.children) {
        this.moveChildrenRecursivelyByHierarchy(foldersList, folder, children)
      }

      // Replace children in parent on children from main list
      const childrenIndexInParent = parent.children.findIndex(item => item.id === folder.id)
      parent.children[childrenIndexInParent] = folder
      
      // Remove element from main list
      const childrenIndexInList = foldersList.indexOf(folder)
      foldersList.splice(childrenIndexInList, 1)
    }    
  }
}