<div [class.large]="isLarge" class="modal__wrapper">
  <div class="modal__bg" (click)="closeModal()"></div>

  <div
    class="modal__content"
    [style.width]="width"
    [style.maxWidth]="maxWidth"
    [class.scrollable]="isScrollable"
  >
    <button class="modal-close" (click)="closeModal()"></button>
    <ng-content></ng-content>
  </div>

  <button
  *ngIf="withControls"
    type="button"
    (click)="previous()"
    class="modal__leaf-button modal__leaf-button_previous"
  ></button>

  <button
  *ngIf="withControls"
    type="button"
    (click)="next()"
    class="modal__leaf-button modal__leaf-button_next"
  ></button>
</div>
