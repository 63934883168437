<div *ngIf="!isInEditMode" class="admin__item listing l2">
  <div class="admin__item-name">&nbsp;</div>
  <div class="admin__item-name">&nbsp;</div>
  <div class="admin__item-name">&nbsp;</div>

  <div class="admin__item-name">
    {{ l2City.name }}
  </div>

  <div class="admin__item-name">
    {{ l2City.searchString }}
  </div>

  <div class="admin__item-name">
    <input
      type="checkbox"
      [checked]="l2City.showOnMainPage"
      (change)="onShowOnMainPageChange($event)"
      title="Показывать на главной странице"
    />
  </div>

  <div class="table-row-actions">
    <button
      class="table-row-action table-row-action_edit"
      type="button"
      title="Редактировать"
      (click)="onEdit()"
    ></button>

    <button
      class="table-row-action table-row-action_delete"
      type="button"
      title="Удалить"
      (click)="onDelete()"
    ></button>
  </div>
</div>

<div *ngIf="isInEditMode">
  <app-admin-catalog-l2-city-form
    [l2City]="l2City"
    (submitted)="onSubmit($event)"
    (cancelRequested)="onCancelEdit($event)"
  ></app-admin-catalog-l2-city-form>
</div>
