<app-header [isBlack]="true" [isSearch]="true"></app-header>

<section class="contactsWrapper">
  <iframe
    id="map"
    src="https://yandex.ru/map-widget/v1/?um=constructor%3Af59b0bf6053a36361abe6e2ae6abe2db870d63c3330efb2cc3457ace028caecc&source=constructor&z=14"
    frameborder="0"
    height="640"
    allowfullscreen="true"
  ></iframe>

  <div class="contacts">
    <div>
      <h1>Контакты</h1>

      <div class="contacts__main-info">
        <p>{{ companyContacts?.tel }}</p>
        <p>{{ companyContacts?.email }}</p>
        <p>Москва, ул. Староалексеевская, 5</p>
      </div>

      <div class="contacts__addon-info">
        <p>
          Офис в Новосибирске:<br />
          ул. Ленина, д. 12
        </p>
      </div>

      <div class="contacts__channels">
        <a
          href="https://t.me/{{ companyContacts?.tg }}"
          title="Связаться через Telegram"
          target="_tg"
        >
          <img
            src="../../../assets/icons/telegram-color.svg"
            alt="Способ связи: Иконка телеграма"
            class="feedback__icon"
          />
        </a>

        <a
          href="https://wa.me/{{ companyContacts?.wa }}"
          title="Связаться через WhatsApp"
          target="_wa"
        >
          <img
            src="../../../assets/icons/whatsapp-color.png"
            alt="Способ связи: Иконка whatsapp"
            class="feedback__icon"
          />
        </a>
      </div>
    </div>

    <p>ООО «Гелио Пресс» | ИНН 5408287430</p>
  </div>
</section>

<app-footer></app-footer>
