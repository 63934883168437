<app-admin-modal (onClose)="closeModal()">
  <form>
    <h2 class="modal__title" >Переместить</h2>
    <div class="modal__content">
      <h4 class="modal__description">Выберите куда переместить выбранный файл</h4>
      <a
        (click)="selectDestinationFolder(1)"
        class="modal__root-folder"
        [class.modal__root-folder_selected]="destinationFolderId === 1"
        >Корневая папка
      </a>
      <div 
        class="modal__folders-list"
      >
        <app-recursive-folders
          [folders]="folders"
          [isOptionsButtons]="false"
          [isNavigateToFolderOnClickActive]="false"
          [highlightLastClickedFolder]="true"
          [lastClickedFolderId]="destinationFolderId"
          (clickOnFolder)="selectDestinationFolder($event)"
        ></app-recursive-folders>
      </div>
    </div>
    <div class="controls">
      <div>
        <button (click)="moveItems()" type="submit" class="btn">
          Переместить
        </button>
      </div>
    </div>
  </form>
</app-admin-modal>
