<aside class="workspace__sidebar" [class.closed]="!isSidebarOpen">
  <a
    (click)="navigateToFirstFolder(); onEssenceToggle('Папки')"
    class="workspace__sidebar-logo"
  >
    <img *ngIf="isSidebarOpen" src="assets/images/logo.black.svg" alt="Gelio" />
    <h4 *ngIf="!isSidebarOpen">G</h4>
  </a>

  <nav>
    <ul>
      <li><a routerLink="./seo/templates">SEO шаблоны</a></li>
    </ul>
  </nav>

  <div
    *ngIf="activeEssenceName === 'Папки'"
    class="workspace__sidebar-create"
  >
    <div class="button-wrapper">
      <a class="active" [class.disabled]="state" (click)="isActionModal = true">{{
        isSidebarOpen ? "Добавить" : ""
      }}</a>
    </div>

    <div
      *ngIf="isActionModal"
      (clickOutside)="onActionOutside($event)"
      class="action-modal"
      #action
    >
      <h3 #addButton>Добавить</h3>

      <ul #addMenu>
        <li *ngIf="activeEssenceName === 'Папки'" class="file-create">
          <input
            type="file"
            #fileInput
            multiple
            style="display: none"
            (change)="onFileSelected($event)"
            accept="image/*"
          />
          <a (click)="fileInput.click()"> Загрузить файлы </a>
        </li>

        <li class="file-create">
          <input
            type="file"
            #folderInput
            style="display: none"
            webkitdirectory
            directory
            multiple
            (change)="onFolderSelected($event)"
          />
          <a *ngIf="activeEssenceName === 'Папки'" (click)="folderInput.click()">Загрузить папку </a>
        </li>

        <li *ngIf="activeEssenceName === 'Папки'" class="file-create" (click)="toggleNewFolderModal()">
          <a> Новая папка </a>
        </li>
      </ul>
    </div>
  </div>

  <div class="workspace__sidebar-tabs">
    <a (click)="isSidebarOpen = !isSidebarOpen" class="toggle-collapse"></a>
    <div class="sort-wrapper">
      <a
        (click)="isEssenceDropdown = !isEssenceDropdown"
        class="essence-wrapper-active"
      >
        {{ activeEssenceName }}
      </a>

      <div
        (clickOutside)="onEssenceOutside($event)"
        *ngIf="isEssenceDropdown"
        class="dropdown"
      >
        <a
          (click)="onEssenceToggle('Каталог')"
          routerLinkActive="active"
          [routerLink]="['/workspace/catalog']"
          >Каталог</a
        >
        <a
          (click)="onEssenceToggle('Подборки')"
          routerLinkActive="active"
          [routerLink]="['/workspace/compilations']"
          >Подборки</a
        >
        <a
          (click)="onEssenceToggle('Папки')"
          routerLinkActive="active"
          [routerLink]="['/workspace/folders', folders[0]?.id]"
          >Папки</a
        >
      </div>
    </div>

    <div *ngIf="isSidebarOpen">
      <div *ngIf="activeEssenceName === 'Папки'" class="workspace__sidebar-tabs-folder sort-wrapper directory" >
          <a
            (click)="toggleFoldersTabs()"
            class="action-link"
            [class.action-link_opened]="isFoldersTabVisible"
            >Файловая система
          </a>
      </div>

      <div
        *ngIf="activeEssenceName === 'Папки'"
        class="sidebar__folders workspace__sidebar-tabs-folder"
        [class.workspace__sidebar-tabs-folder_rolled_up]="!isFoldersTabVisible"
      >
        <app-recursive-folders
          [folders]="folders"
          (folderInfoOpened)="toggleFolderInfoModal($event)"
        ></app-recursive-folders>
      </div>
    </div>
  </div>

  <ul class="workspace__sidebar-actions" >
    <li *ngIf="activeEssenceName === 'Папки'" (click)="toggleNewFolderModal()" class="sidebar-actions__action sidebar-actions__action_new-folder">
      <a title="Новая папка"></a>
    </li>
    <li *ngIf="activeEssenceName === 'Подборки'" (click)="toggleCompilationModal()" class="sidebar-actions__action sidebar-actions__action_new-compilation" >
      <a title="Новая подборка"></a>
    </li>
  </ul>
</aside>

<app-folder-modal
  *ngIf="isFolderCreationModalVisible"
  [currentFolderId]="currentId"
  (close)="toggleNewFolderModal()"
  (folderCreated)="reloadPage()"
></app-folder-modal>

<app-folder-info-modal
  *ngIf="isFolderInfoModalVisible"
  [currentFolderId]="chosenFolderId"
  (close)="toggleFolderInfoModal()"
  (folderChanged)="updateFolder($event)"
></app-folder-info-modal>

<app-compilation-modal
  *ngIf="isCompilationModal"
  (close)="toggleCompilationModal()"
  (compilationCreated)="updateCompilations()"
></app-compilation-modal>

<app-compilation-info-modal
  *ngIf="isCompilationInfoModalVisible"
  [currentCompilationId]="chosenCompilationId"
  (close)="toggleCompilationInfoModal()"
  (compilationEdited)="updateCompilations()"
></app-compilation-info-modal>
