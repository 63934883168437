<app-header [isBlack]="true" [isSearch]="true"></app-header>

<div class="container container--big">
  <h2 class="page-title">
    Подборки
  </h2>
</div>

<div class="items">
  <a
    *ngFor="let compilation of compilations?.items"
    class="item"
  >
    <img
      [src]="getCompilationPreview(compilation)"
      class="item__img"
      [routerLink]="['/compilation', compilation.friendlyURL || compilation.id]"
    />

    <a
      class="item__title"
      [routerLink]="['/compilation', compilation.friendlyURL || compilation.id]"
    >
      {{ compilation.title }}
    </a>

    <div
      class="item__description"
      [routerLink]="['/compilation', compilation.friendlyURL || compilation.id]"
    >
      {{ compilation.shortDescription }}
    </div>
  </a>
</div>

<app-paginator
  *ngIf="compilations && 1 < pages"
  [current]="currentPage"
  [total]="compilations.paging.total"
  [size]="5"
  (pageSelected)="onPageSelected($event)"
></app-paginator>

<app-footer></app-footer>
