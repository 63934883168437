<div
  class="user-item"
>
  <div class="user-item__name">
    {{ user.firstName }}
  </div>
  <div class="user-item__email">
      {{ user.email }}
  </div>

  <div class="user-item__role">
    <select class="user-item__select" [value]="user.role" (change)="selectRole(role.value)" #role>
        <option value="EDITOR">Редактор</option>
        <option value="MANAGER">Менеджер</option>
        <option value="ADMIN">Суперадмин</option>
    </select>
  </div>
  
  <div class="user-item__actions">
      <button class="user-item__actions-button" (click)="openEditUserPasswordModal()">
        Установить пароль
      </button>
      <button class="user-item__actions-button" (click)="openEditUserModal()">
        Редактировать
      </button>
  </div>
</div>