import { Injectable } from "@angular/core";
import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { ActivatedRouteSnapshot, Resolve, Router } from "@angular/router";

import { BaseRequestService } from "../services/base-request.service";
import { IImage } from "./../models/image";

@Injectable({
  providedIn: "root",
})
export class PhotoResolver implements Resolve<IImage|void> {
  constructor(
    private requestService: BaseRequestService,
    private router: Router,
  ) {}

  async resolve(route: ActivatedRouteSnapshot): Promise<IImage|void> {
    try {
      let response = await this.requestService.get<any[]>(
        `/images/friendly-url/${route.params["url"]}`
      );

      return response[0];
    } catch (err) {
      if (err instanceof HttpErrorResponse && HttpStatusCode.NotFound === err.status) {
        this.router.navigateByUrl('/404');
      }
    }
  }
}
