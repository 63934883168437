import { Component, Input } from '@angular/core';

@Component({
  selector: "app-paginator-bullet",
  templateUrl: "paginator-bullet.component.html",
  styles: [
    `
      .paginator-bullet {
        $size: 44px;
        $border-color: rgba(138, 138, 138, 1);
        $color: black;
        $bg-color_current: rgba(0, 153, 255, 1);
        $border-color_current: $bg-color_current;
        $color_current: white;

        display: block;
        box-sizing: border-box;
        padding: 0 5px;
        width: $size;
        height: $size;
        border: 1px solid $border-color;
        border-radius: 15px;

        line-height: 40px;
        text-align: center;
        text-overflow: ellipsis;

        font-size: 16px;
        font-weight: 700;
        color: $color;

        cursor: pointer;

        &.current {
          border-color: $border-color_current;
          background-color: $bg-color_current;
          color: $color_current;
        }

        :host:not(.ellipsis) &:hover {
          border-color: $border-color_current;
        }
      }
    `,
  ],
})
export class PaginatorBulletComponent {
  @Input()
  page?: number;

  @Input()
  isCurrent?: boolean = false;

  @Input()
  text?: string;

  get title() {
    return this.page ? `Перейти к странице ${this.page}` : undefined;
  }
}
