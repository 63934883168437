<div class="tags">
  <ul>
    <li *ngFor="let tag of tags">
      <div class="tags__content">
        <span>{{ tag }}</span>
      </div>
      <div class="tags__actions">
        <button>&#10761;</button>
      </div>
    </li>
    <li class="tags__input" [class.outlined]="outlined">
      <input type="text" placeholder="Добавить тег" />
    </li>
  </ul>
</div>
