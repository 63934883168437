
import { Injectable } from '@angular/core';
import { IOrder } from '../models/models';
import { BaseRequestService } from './base-request.service';


@Injectable({
  providedIn: "root",
})
export class OrderService {

  baseUrl = "/orders"
  
  constructor(
    private requestService: BaseRequestService
  ) { }

  async createOrder(photos: number[], firstName: string, phone: string, email: string): Promise<any> {
    try {
      return await this.requestService.post(`${this.baseUrl}/create`, { imagesId: photos, firstName, phone, email })  
    } catch (error) {
      throw error
    }
    
  }
  
  async getOrders(): Promise<Array<IOrder>> {
    try {
      return await this.requestService.get<Array<IOrder>>(`${this.baseUrl}/`, {})  
    } catch (error) {
      throw error
    }
  }

  async getOrder(orderId: number) {
    return await this.requestService.get(`${this.baseUrl}/${orderId}`, {})
  }

  async patchOrder(orderId: number, newProps: IOrder) {
    return await this.requestService.patch(`${this.baseUrl}/${orderId}`, newProps)

  }

  async deleteOrder(orderId: number) {
    return await this.requestService.delete(`${this.baseUrl}/${orderId}`, null)
  }
}
