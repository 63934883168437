import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ImageSourceService } from "src/app/services/image-source.service";
import { IImage } from "./../../../models/image";

@Component({
  selector: "app-cart-item",
  templateUrl: "./cart-item.component.html",
  styleUrls: ["./cart-item.component.scss"],
})
export class CartItemComponent implements OnInit {
  @Input() photo!: IImage;

  @Output() onDelete: EventEmitter<IImage> = new EventEmitter();

  constructor(private imageSource: ImageSourceService) {}

  ngOnInit(): void {
    this.getImageSource();
    this.getPrice();
    this.getName();
  }

  transferItemToDelete(photo: IImage): void {
    this.onDelete.emit(photo);
  }

  getImageSource() {
    this.photo.img = this.imageSource.returnUrl(this.photo, "MINI");
  }

  getPrice() {
    this.photo.price = this.photo.__prices__?.[0]?.value || 0;
  }

  getName() {
    const seo = this.photo.__seoInfo__.find(seo => seo.language === "RU")
    this.photo.name = seo?.title
  }
}
