// @ts-ignore
import Set from "core-js-pure/actual/set";
import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { SelectionChange } from "@angular/cdk/collections";

import { SearchService } from "@app/services/search.service";
import { AdminCompilationService, ChangeCompilationContentDto } from "src/app/services/admin/admin-compilation.service";
import { ViewMode } from "@app/views/admin/workspace/workspace.component";
import { Compilation } from "../admin-compilations-component/compilation";
import { IAdminImage, ICompilationInfo } from "src/app/models/admin";

export type AdminCompilationImagesModalData = {
  compilationId: Compilation["id"],
  tableViewMode?: ViewMode,
};

/**
 * UI for adding images to a compilation
 */
@Component({
  selector: "app-admin-compilation-modal",
  templateUrl: "./admin-compilation-images.modal.component.html",
  styleUrls: ["./admin-compilation-images.modal.component.scss"],
})
export class AdminCompilationImagesModalComponent implements OnInit {
  public readonly ViewMode = ViewMode;
  public tableViewMode: ViewMode = ViewMode.List;

  private compilationId!: Compilation["id"];

  @Output() compilationChanged = new EventEmitter<Compilation>();

  public foundImages: IAdminImage[] = [];

  public compilationImagesIds: Set<IAdminImage["id"]> = new Set([]);
  private addedImagesIds: Set<IAdminImage["id"]> = new Set([]);
  private removedImagesIds: Set<IAdminImage["id"]> = new Set([]);

  private _query: string = "";

  private _isLoading = false;

  public set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }

  public get isLoading() {
    return this._isLoading;
  }

  private queryInputTimeout: number;

  constructor(
    public dialogRef: MatDialogRef<
      AdminCompilationImagesModalComponent,
      ICompilationInfo | undefined
    >,
    @Inject(MAT_DIALOG_DATA)
    data: AdminCompilationImagesModalData,
    private readonly compilationService: AdminCompilationService,
    private readonly searchService: SearchService,
  ) {
    this.compilationId = data.compilationId;
    if (data.tableViewMode) {
      this.tableViewMode = data.tableViewMode;
    }
  }

  async ngOnInit(): Promise<void> {
    // TODO:perf Implement a method to fetch only images IDs
    const response = await this.compilationService.getCompilationImages(
      this.compilationId
    );
    const images = response.items;
    const ids = images.map((i) => i.id);
    this.compilationImagesIds = new Set(ids);
  }

  public onSelectionChange(change: SelectionChange<IAdminImage>): void {
    const addedIds = change.added.map((i) => i.id);
    const removedIds = change.removed.map((i) => i.id);
    const addedIdsSet = new Set(addedIds);
    const removedIdsSet = new Set(removedIds);
    // Add only absent
    const addedIdsFiltered = addedIdsSet.difference(this.compilationImagesIds);
    // Remove only preseent
    const removedIdsFiltered = removedIdsSet.intersection(
      this.compilationImagesIds
    );
    // Correctes of this algo is not clear when there's both added & removed items, but it's not considered a valid case
    this.addedImagesIds = this.addedImagesIds
      .union(addedIdsFiltered)
      .difference(removedIdsSet);
    this.removedImagesIds = this.removedImagesIds
      .union(removedIdsFiltered)
      .difference(addedIdsSet);
  }

  async onQueryChange(event: Event): Promise<void> {
    clearTimeout(this.queryInputTimeout);
    const query = (event.target as HTMLInputElement).value;

    if (2 > query.length) {
      return;
    }
    // @ts-ignore
    this.queryInputTimeout = setTimeout(async () => {
      this.setQuery(query);
    }, 1000);
  }

  public async save(): Promise<void> {
    const data: ChangeCompilationContentDto = {};
    if (this.addedImagesIds.size) {
      data.added = [...this.addedImagesIds.values()];
    }
    if (this.removedImagesIds.size) {
      data.removed = [...this.removedImagesIds.values()];
    }
    this.isLoading = true;
    await this.compilationService.changeCompilationContent(
      this.compilationId,
      data
    );
    this.isLoading = false;
    this.dialogRef.close();
  }

  private setQuery(query: string) {
    this._query = query;
    this.fetchImages();
  }

  private async fetchImages(): Promise<void> {
    this.isLoading = true;
    // @ts-ignore
    this.foundImages = (await this.searchService.getPosts(this._query, null)).images;
    this.isLoading = false;
  }

  public get noPhotosText(): string {
    return 2 > this._query.length
      ? "Введите запрос для поиска фотографий"
      : "Фотографии не найдены";
  }

  public hasChanges(): boolean {
    return 0 < this.addedImagesIds.size + this.removedImagesIds.size;
  }

  public getCompilationImagesIds(): Array<IAdminImage["id"]> {
    return [...this.compilationImagesIds.values()];
  }
}
