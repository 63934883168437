<app-admin-modal (onClose)="closeModal()">
	<form action="" class="folder-form" [formGroup]="form" (ngSubmit)="submit()">
	  <div class="header-title">
		<h2>SEO Info</h2>
	  </div>
	  <div *ngIf="!!seoInfo?.length">
		<button type="button" class="btn" (click)="setSeoElement(0)">
		  SEO({{seoInfo?.[0]?.language === "RU" ? ('rus'):('eng') || 'rus'}})
		</button>
		<button type="button" class="btn" (click)="setSeoElement(1)">
		  SEO({{seoInfo?.[1]?.language === "RU" ? ('rus'):('eng') || 'eng'}})
		</button>
	  </div>
	  <div class="form-group">
		<label>Заголовок(SEO)</label>
		<input formControlName="seotitle" type="text" />
	  </div>
	  <div class="form-group">
		<label>Описание(SEO)</label>
		<input formControlName="seodescription" type="text" />
	  </div>
	  <div class="form-group">
		<label>Ключевые слова(SEO)</label>
		<input formControlName="keywords" type="text" />
	  </div>
	  <div class="form-group">
		<label>
		  Язык
		  <div class="field">
			<select formControlName="language">
			  <option value="RU">Русский</option>
			  <option value="EN">English</option>
			</select>
		  </div>
		</label>
	  </div>
  
	  <div class="controls">
		<div>
			  <button
			  type="submit"
			  [disabled]="form.invalid"
			  class="btn"
			>
			  Добавить
			</button>
		</div>
	  </div>
	</form>
  </app-admin-modal>