<app-admin-modal (onClose)="closeModal()" width="fit-content" maxWidth="600px" >
  <div class="header-title">
    <h2>Удалить</h2>
  </div>
  <p>Вы уверены что хотите удалить {{ endWord }} ?</p>
  <div class="controls">
    <div class="buttons">
      <button class="btn delete-btn" (click)="onDelete()">Удалить</button>
      <button class="btn btn-light" (click)="closeModal()">Отменить</button>
    </div>
  </div>
</app-admin-modal>
