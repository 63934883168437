<app-admin-modal (onClose)="closeModal()">
  <form [formGroup]="form" class="comp-info">
    <div class="header-title">
      <h2>Редактирование подборки</h2>
    </div>
    <div class="seo-wrapper">
      <h3>SEO</h3>
      <div class="form-group">
        <label>
          Заголовок
          <div class="field">
            <input type="text" formControlName="title" />
          </div>
        </label>
      </div>
      <div class="form-group">
        <label>
          Описание
          <div class="field">
            <textarea rows="1" formControlName="description"></textarea>
          </div>
        </label>
      </div>
      <div class="form-group tags__wrapper">
        <label>Ключевые слова</label>
        <div class="tags__wrapper-items">
          <ul>
            <li
              *ngFor="let tag of currentCompilationInfo?.__tags__"
              class="tags__wrapper-item"
            >
              <div class="tag-content">
                <span>{{ tag.name }}</span>
              </div>
              <div class="tag-actions">
                <button type="button" (click)="deleteTag(tag.id)">
                  &#10761;
                </button>
              </div>
            </li>
            <li class="tags__wrapper-items-input">
              <input
                type="text"
                placeholder="Добавить тег"
                formControlName="tags"
                (keyup.enter)="addTagToCompilation()"
              />
            </li>
          </ul>
        </div>
      </div>
      <div class="form-group">
        <label>
          Язык (SEO)
          <div class="field">
            <select
              formControlName="language"
              (change)="toggleFormSeoLanguage(language.value)"
              #language
            >
              <option value="RU">Русский</option>
              <option value="EN">English</option>
            </select>
          </div>
        </label>
      </div>
    </div>

    <!-- <h3>SEO</h3>
    <div class="form-group">
      <label>Заголовок</label>
      <input formControlName="seoTitle" type="text" />
    </div>

    <div class="form-group">
      <label>Описание</label>
      <input formControlName="seoDescription" type="text" />
    </div>

    <div class="form-group">
      <label>Ключевые слова</label>
      <input
        formControlName="keywords"
        type="text"
        placeholder="Добавить тэг"
      />
    </div> -->

    <div class="form-group form-group-checkbox">
      <app-switch
        (checkboxClick)="publishChange()"
        [checked]="isPublished"
      ></app-switch>
      <label> Опубликовать данную подборку</label>
    </div>

    <div class="form-group form-group-checkbox">
      <app-switch
        (checkboxClick)="onMainPageChange()"
        [checked]="onMainPage"
      ></app-switch>
      <label> Отображать на главной странице</label>
    </div>

    <div class="controls">
      <button
        type="button"
        [disabled]="form.invalid"
        class="controls_saveBtn"
        (click)="editCompilation()"
      >
        Сохранить
      </button>
      <button type="button" (click)="deleteCompilation()">Удалить</button>
    </div>
  </form>
</app-admin-modal>

<app-seo-modal
  [seoInfo]="currentCompilationInfo?.__seoInfo__"
  [imageId]="currentCompilationInfo?.id"
  *ngIf="isSeoInfoModal"
  essence="compilationseo"
></app-seo-modal>
